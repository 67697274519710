import type { FC, ReactNode } from "react";
import { formatTime } from "../../../../../helpers/format-time";

type ChatMessageUserProps = {
	message: ReactNode | string;
	time?: string;
}
export const ChatMessageUser: FC<ChatMessageUserProps> = ({
	message,
	time,
}) => (<div className="flex flex-row justify-end items-end w-full gap-4">
	<div className="p-4 bg-lavender-200 rounded">
		<div>{message}</div>
		{time && <div className="flex flex-row justify-end pt-1 text-xs text-off-black-600">
			<span>{formatTime(time)}</span>
		</div>}
	</div>
</div>);
