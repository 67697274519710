import { type SubmitHandler, useForm } from "react-hook-form";
import z from "zod";
import {
	useAppDispatch,
	useAppSelector,
} from "../../../../../hooks/redux-hooks";

import RadioGroup from "../../../radio-group/RadioGroup";
import useScrollTopOnMount from "../../../../../hooks/use-scroll-top-on-mount";

import { zodResolver } from "@hookform/resolvers/zod";
import { SubStep } from "../../../../../services/apis/create-application.schema";
import { navigateToStep } from "../../../../../store/slices/stepper-slice";
import FormLayout from "../../../../ui/form/Form";
import { useMarkFormDirty } from "../../../../../hooks/use-mark-form-dirty";

import Button from "../../../../ui/button/Button";
import useTrackPageViewOnMount from "../../../../../hooks/use-track-on-mount";
import type { RadioInputType } from "../../../radio-input/RadioInput";
import { useEffect, useState } from "react";

import Modal from "../../../modal/Modal";
import {
	setDynamicHints,
} from "../../../../../store/slices/ui-slice";
import {
	type YesNoOrUndefined,
	setDidNotWantToPursueFHBScheme,
} from "../../../../../store/slices/form-slice";
import { useTracking } from "../../../../../hooks/use-tracking";

const PROCEED_OPTIONS: Array<RadioInputType> = [
	{
		label: "Yes - I'd stil like to progress with Sucasa",
		name: "didNotWantToPursueFHBScheme",
		value: "Yes",
		checked: false,
	},
	{
		label: "No - I'll use the Federal First Home Buyer Guarantee",
		name: "didNotWantToPursueFHBScheme",
		value: "No",
		checked: false,
	},
];

const FhbSchema = z.object({
	didNotWantToPursueFHBScheme: z.enum(["Yes"]),
});

type FormValues = z.infer<typeof FhbSchema>;

export const FhbFederalGuarantee = () => {
	useScrollTopOnMount();
	const dispatch = useAppDispatch();
	const { trackEvent } = useTracking();
	const [showModal, setShowModal] = useState(false);
	const didNotWantToPursueFHBSchemeState = useAppSelector(
		(state) => state.form.didNotWantToPursueFHBScheme
	);

	const {
		register,
		handleSubmit,
		watch,
		formState: { isDirty, isValid },
	} = useForm<
		{ didNotWantToPursueFHBScheme: YesNoOrUndefined },
		unknown,
		FormValues
	>({
		resolver: zodResolver(FhbSchema),
		defaultValues: {
			didNotWantToPursueFHBScheme: didNotWantToPursueFHBSchemeState,
		},
	});
	const didNotWantToPursueFHBScheme = watch("didNotWantToPursueFHBScheme");
	useEffect(() => {
		setShowModal(didNotWantToPursueFHBScheme === "No");
		if (didNotWantToPursueFHBScheme === "No") {
			dispatch(setDidNotWantToPursueFHBScheme(undefined));
			trackEvent("FHBG Modal Shown");
		}
	}, [didNotWantToPursueFHBScheme, dispatch, trackEvent]);

	useMarkFormDirty(isDirty);
	useTrackPageViewOnMount({
		page: "Your Details",
		subPage: "FHB Federal Guarantee",
	});

	useEffect(() => {
		dispatch(setDynamicHints([]));
		dispatch(
			setDynamicHints([
				{
					icon: <i className="icon-asterisk" />,
					text: (
						<>
							<p className="text-sm leading-6">
								You may want to check the eligibility criteria of the FHBG below
							</p>
							<a
								href="https://www.housingaustralia.gov.au/support-buy-home/first-home-guarantee"
								target="_blank"
								rel="noopener noreferrer"
								className="text-sm leading-6"
							>
								www.housingaustralia.gov.au/support-buy-home/first-home-guarantee
							</a>
						</>
					),
				},
			])
		);
		return () => {
			dispatch(setDynamicHints([]));
		};
	}, [dispatch]);

	const closeModal = () => {
		setShowModal(false);
	};

	const onSubmit: SubmitHandler<FormValues> = (data) => {
		trackEvent("FHBG Next Clicked", {
			wantsToProgressWithSucasa: data.didNotWantToPursueFHBScheme === "Yes",
		});
		if (data.didNotWantToPursueFHBScheme === "Yes") {
			dispatch(
				setDidNotWantToPursueFHBScheme(data.didNotWantToPursueFHBScheme)
			);
		}

		dispatch(
			navigateToStep({
				step: "loanStep",
				subStep: SubStep.CreateYourAccount,
			})
		);
	};

	return (
		<>
			<FormLayout
				header={
					<h1 className="text-primary text-[37.9px] font-normal">
						Federal First Home Buyer Guarantee
					</h1>
				}
				content={
					<div className="flex flex-col gap-9">
						{/* <div </div> */}
						<p className="text-off-black-600 text-[21.33px] font-normal leading-8">
							Subject to your income, you may be eligible for the{" "}
							<a
								className="underline"
								target="_blank"
								rel="noopener noreferrer"
								href="https://www.housingaustralia.gov.au/support-buy-home/first-home-guarantee"
							>
								federal first home buyer guarantee (FHBG).
							</a>{" "}
							As a Government subsidy, this is a great option if you are
							eligible!
						</p>
						<p className="text-off-black-600 text-[21.33px] font-normal leading-8">
							Sucasa is not a lender within the FHBG scheme and our value
							proposition is strongest for those who are ineligible and
							therefore considering an LMI loan
						</p>

						<RadioGroup
							radioOptions={PROCEED_OPTIONS}
							legend="Would you like to proceed with creating an application?"
							register={register}
							centerLabels
							height={16}
						/>
					</div>
				}
				footer={
					<div
						className="flex items-center justify-between gap-4"
						aria-labelledby="Actions wrapper"
					>
						<Button
							text="Back"
							variant="accent"
							iconPrefix={<i className="icon-arrow rotate-180" />}
							handleClick={() => {
								dispatch(setDidNotWantToPursueFHBScheme(undefined));
								dispatch(
									navigateToStep({
										step: "detailsStep",
										subStep: SubStep.YouAndYourHousehold,
									})
								);
							}}
						/>
						<Button
							text="Next"
							variant="primary"
							iconSuffix={<i className="icon-arrow" />}
							isDisabled={!isValid}
						/>
					</div>
				}
				onSubmit={handleSubmit(onSubmit)}
			/>
			<Modal
				isOpen={showModal}
				actions={[
					{
						text: "Go back to application",
						variant: "accent",
						size: "full",
						textAlign: "center",
						handleClick: () => {
							closeModal();
						},
					},
				]}
				title={
					<div className="flex flex-col gap-4 md:gap-8 items-center text-center text-[28.43px] font-medium leading-10 text-primary">
						<i className="icon-document-success text-3xl md:text-[80px]" />
						You've indicated you want use the Federal First Home Buyer Gaurantee
					</div>
				}
				onClose={() => {
					closeModal();
				}}
				content={
					<div className="flex flex-col gap-4">
						<p className="text-sm text-primary text-center">
							Please let us know if you decide to change your mind. You can
							always come back and modify your answers at any time.
						</p>
					</div>
				}
			/>
		</>
	);
};
