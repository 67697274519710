import type { UpdateCircumstancesResponse } from "../../update-circumstances.schema";

export function emptyOkResponseData(): UpdateCircumstancesResponse["data"] {
    return {
        status: "",
        statusCode: 200,
        message: "",
        content: {
            success: true,
            message: "",
            content: {}
        }
    }
}


export function emptyOkResponse(): UpdateCircumstancesResponse {
    return {
        data: emptyOkResponseData()
    }
}