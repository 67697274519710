import { useMemo } from "react";
import { useAppSelector } from "../../../hooks/redux-hooks";
import { StepperItem } from "./stepper-item/StepperItem";
import { selectIsJointApplicant } from "../../../store/slices/auth-slice";
import { stepsAll, stepsJoint } from "./Stepper.config";


export const Stepper = () => {
	const isJointApplicant = useAppSelector(selectIsJointApplicant);

	const steps = useMemo(() => isJointApplicant ? stepsJoint : stepsAll,
		[isJointApplicant]);

	return (
		<section className="hidden lg:block lg:sticky lg:top-36">
			<div className="text-lg text-primary leading-9 font-normal flex-col gap-x-3 overflow-hidden grid grid-cols-[3em_1fr] justify-center">
				{steps.map((item, index) => <StepperItem key={item.id} item={item} isJoint={isJointApplicant} isLastItem={index === steps.length - 1} />)}
			</div>
		</section>
	);
};
