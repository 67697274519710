import type { FieldErrors, FieldValues, UseFormRegister } from "react-hook-form";

import CheckboxInputSecondary from "../../../../checkbox-input-secondary/CheckboxInputSecondary";
import GenericInput from "../../../../generic-input/GenericInput";
import { ExitStrategy } from "../../../../../../services/apis/create-application.schema";
import type { FineDetailsAboutYouSlice } from "../FineDetailsAboutYou";
import ErrorMessage from "../../../../error-message/ErrorMessage";

type ExitStrategyType<TFieldValues extends FieldValues = FieldValues> = {
	register: UseFormRegister<TFieldValues>;
	selectedExitStrategies: Array<ExitStrategy>;
	errors?:  FieldErrors<FineDetailsAboutYouSlice>;
	onChange: (checked: boolean, value: string) => void;
};

const ExitStrategyFC = <TFieldValues extends FieldValues = FieldValues>({
	register,
	selectedExitStrategies,
	onChange,
	errors
}: ExitStrategyType<TFieldValues>) => {
	return (
		<div className="flex flex-col gap-4">
			<h1 className="text-primary text-[37.9px] font-normal">
				You'll be past retirement age at loan maturity
			</h1>
			<legend className="text-[21.33px] leading-8">
				Please outline how you plan to service the loan beyond retirement. (Ie.
				A suitable exit strategy)
			</legend>
			<CheckboxInputSecondary
				label={ExitStrategy.ByUsingMySavings}
				value={ExitStrategy.ByUsingMySavings}
				name="exitStrategy1"
				checked={selectedExitStrategies.includes(ExitStrategy.ByUsingMySavings)}
				onChange={(event) => {
					onChange(event.target.checked, event.target.value);
				}}
			/>
			<CheckboxInputSecondary
				label={ExitStrategy.ByReducingMyExpenses}
				value={ExitStrategy.ByReducingMyExpenses}
				name="exitStrategy2"
				checked={selectedExitStrategies.includes(
					ExitStrategy.ByReducingMyExpenses
				)}
				onChange={(event) => {
					onChange(event.target.checked, event.target.value);
				}}
			/>
			<CheckboxInputSecondary
				label={ExitStrategy.ByDownsizingMyHome}
				value={ExitStrategy.ByDownsizingMyHome}
				name="exitStrategy3"
				checked={selectedExitStrategies.includes(
					ExitStrategy.ByDownsizingMyHome
				)}
				onChange={(event) => {
					onChange(event.target.checked, event.target.value);
				}}
			/>
			<CheckboxInputSecondary
				label={ExitStrategy.BySecuringAdditionalIncome}
				value={ExitStrategy.BySecuringAdditionalIncome}
				name="exitStrategy4"
				checked={selectedExitStrategies.includes(
					ExitStrategy.BySecuringAdditionalIncome
				)}
				onChange={(event) => {
					onChange(event.target.checked, event.target.value);
				}}
			/>
			<CheckboxInputSecondary
				label={ExitStrategy.BySaleOfMyAssets}
				value={ExitStrategy.BySaleOfMyAssets}
				name="exitStrategy5"
				checked={selectedExitStrategies.includes(ExitStrategy.BySaleOfMyAssets)}
				onChange={(event) => {
					onChange(event.target.checked, event.target.value);
				}}
			/>
			<CheckboxInputSecondary
				label={ExitStrategy.ByUtilisingMySuperannuation}
				value={ExitStrategy.ByUtilisingMySuperannuation}
				name="exitStrategy6"
				checked={selectedExitStrategies.includes(
					ExitStrategy.ByUtilisingMySuperannuation
				)}
				onChange={(event) => {
					onChange(event.target.checked, event.target.value);
				}}
			/>
			<CheckboxInputSecondary
				label={ExitStrategy.ByContinuingToWork}
				value={ExitStrategy.ByContinuingToWork}
				name="exitStrategy7"
				checked={selectedExitStrategies.includes(
					ExitStrategy.ByContinuingToWork
				)}
				onChange={(event) => {
					onChange(event.target.checked, event.target.value);
				}}
			/>
			<CheckboxInputSecondary
				label={ExitStrategy.Other}
				value={ExitStrategy.Other}
				name="exitStrategy8"
				checked={selectedExitStrategies.includes(ExitStrategy.Other)}
				onChange={(event) => {
					onChange(event.target.checked, event.target.value);
				}}
			/>
			{errors?.exitStrategy && errors?.exitStrategy.message && <ErrorMessage message={errors?.exitStrategy.message} />}
			<GenericInput
				name="exitStrategyOther"
				register={register}
				type="text"
				placeholder="Enter your explanation"
				error={errors?.exitStrategyOther && errors?.exitStrategyOther.message}
			/>
			<h1 className="text-primary text-[21.33px] font-normal mt-4">
				Please provide further details to expand upon your applicable exit
				strategy selected above
			</h1>
			<GenericInput
				name="exitStrategyDetails"
				register={register}
				type="text"
				placeholder="Enter details"
				error={errors?.exitStrategyDetails && errors?.exitStrategyDetails.message}
			/>
		</div>
	);
};

export default ExitStrategyFC;
