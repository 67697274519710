export const formatFileType = (fileType?: string) => {
	if (!fileType) return '';

	switch (fileType.toLowerCase()) {
		case 'application/pdf': {
			return 'PDF';
		}
		case 'image/jpeg':
		case 'image/jpg': {
			return 'JPEG';
		}
		case 'image/png': {
			return 'PNG';
		}
		case 'image/gif': {
			return 'GIF';
		}
		case 'image/svg+xml': {
			return 'SVG';
		}
		case 'application/msword':
		case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
			return 'DOC';
		}
		case 'application/vnd.ms-excel':
		case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
			return 'XLS';
		}
		case 'application/vnd.ms-powerpoint':
		case 'application/vnd.openxmlformats-officedocument.presentationml.presentation': {
			return 'PPT';
		}
		case 'text/plain': {
			return 'TXT';
		}
		default: {
			return fileType.split('/')[1]?.toUpperCase() || '';
		}
	}
};
