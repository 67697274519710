import type { FC } from "react";
import { BoxPreApproval } from "./components/BoxPreApproval";
import { BoxUnconditional } from "./components/BoxUnconditional";

type ProgressToUnconditionalProps = {
	applicationId: number
}
export const ProgressToUnconditional: FC<ProgressToUnconditionalProps> = ({applicationId}) => {


	return (
		<>
			<h1
				className="flex flex-row text-primary text-[37.9px] font-normal"
				aria-labelledby="Confirm Step Title"
			>
				<div>Finding a home</div>
			</h1>

			<BoxPreApproval applicationId={applicationId} />
			<BoxUnconditional applicationId={applicationId} />
		</>
	);
};
