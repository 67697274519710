import { useNavigate } from "react-router-dom";
import Layout from "../common/layout/Layout";
import type { FunctionComponent } from "../common/types";
import IntroAside from "../components/layout/intro-aside/IntroAside";
import Button from "../components/ui/button/Button";
import CheckboxInput from "../components/forms/checkbox-input/CheckboxInput";
import { z } from "zod";
import { createSelector } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { setMarketingConsent, setPrivacyConsent } from "../store/slices/form-slice";
import { NamedRoute } from "../components/utils/NamedRoutes";
import { useRef } from "react";
import useScrollToRefWithDelay from "../hooks/use-scroll-to-ref-with-delay";

import useTrackPageViewOnMount from "../hooks/use-track-on-mount";
import { useGetApplicationById } from "../hooks/origination/use-get-application-by-id";
import { selectUseOrigination } from "../store/slices/origination-slice";

const JointIntroSchema = z
	.object({
		termsAndConditionsAndPrivacyConsent: z.boolean(),
		marketingConsent: z.boolean(),
	})
	.superRefine(({ termsAndConditionsAndPrivacyConsent }, context) => {
		if (!termsAndConditionsAndPrivacyConsent) {
			context.addIssue({
				code: z.ZodIssueCode.custom,
				message: "You must accept the credit guide and privacy consent",
				path: ["termsAndConditionsAndPrivacyConsent"],
			});
		}
	});

type JointIntroSchemaFormType = z.infer<typeof JointIntroSchema>;
export type JointIntroSchemaFormSlice = {
	termsAndConditionsAndPrivacyConsent: boolean;
	marketingConsent: boolean;
};
const selectJointIntro = createSelector(
	(state: RootState) => state.form,
	(form): JointIntroSchemaFormSlice => ({
		termsAndConditionsAndPrivacyConsent:
			form.termsAndConditionsAndPrivacyConsent,
		marketingConsent: form.marketingConsent,
	})
);
const JointIntro = (): FunctionComponent => {
	const { applicationId } = useGetApplicationById();
	console.log('applicationId', applicationId);

	const firstName = useAppSelector((state) => state.auth.user?.firstName ||  state.auth.originationUser?.firstName);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const formState = useAppSelector(selectJointIntro);
	const h1Ref = useRef<HTMLHeadingElement>(null);
	useScrollToRefWithDelay(h1Ref);
	useTrackPageViewOnMount({
		page: "Joint Borrower Intro",
		subPage: "Accept Credit Guide and Privacy Consent",
	});
	const useOrigination = useAppSelector(selectUseOrigination)

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: zodResolver(JointIntroSchema),
		defaultValues: formState,
	});
	const saveData = (rawData: JointIntroSchemaFormSlice) => {
		const data: JointIntroSchemaFormType = rawData as JointIntroSchemaFormType;
		dispatch(setPrivacyConsent(data.termsAndConditionsAndPrivacyConsent));
		if (useOrigination) {
			dispatch(setMarketingConsent(data.marketingConsent));
		}
		if (applicationId) {
			navigate(NamedRoute.jointBorrowerHome, {state: {applicationId}});
		} else {
			navigate(NamedRoute.jointBorrowerHome);
		}
	};

	const checkBoxNodeLabel = (
		<div>
			By ticking this box I confirm that I have read, understand and agree with
			the{" "}
			<a
				href="https://www.sucasa.com.au/credit-guide"
				target="_blank"
				rel="noopener noreferrer"
				className="underline"
			>
				Sucasa Credit Guide
			</a>{" "}
			and{" "}
			<a
				href="https://www.sucasa.com.au/privacy-policy"
				target="_blank"
				rel="noopener noreferrer"
				className="underline"
			>
				Sucasa Privacy Consent
			</a>
			. I confirm the information I provide will be accurate and understand it
			will be accessible to all applicants.
		</div>
	);
	return (
		<Layout leftColumn={<IntroAside />}>
			<form
				aria-labelledby="Joint Intro form"
				className="flex flex-col gap-8 h-full"
				onSubmit={handleSubmit(saveData)}
			>
				<h1
					ref={h1Ref}
					className="text-primary text-[37.9px] font-normal lg:mt-14"
					aria-labelledby="Hi User"
				>
					Hi {firstName},
				</h1>
				<p className="text-off-black-600 text-[21.33px] font-normal">
					Let’s complete your home loan application. This should only take 5
					minutes!
				</p>
				<CheckboxInput
					label={checkBoxNodeLabel}
					name="termsAndConditionsAndPrivacyConsent"
					checked={false}
					register={register}
					size="small"
					checkPosition="start"
					error={
						errors.termsAndConditionsAndPrivacyConsent &&
						errors.termsAndConditionsAndPrivacyConsent.message
					}
				/>
				{
					useOrigination && (
						<CheckboxInput
							label="I do not want to receive occasional marketing from Sucasa"
							name="marketingConsent"
							checked={false}
							register={register}
							size="small"
							checkPosition="start"
						/>
					)
				}
				<footer
					className="flex items-center justify-between"
					aria-labelledby="Actions wrapper"
				>
					<Button
						text="Next"
						variant="primary"
						iconSuffix={<i className="icon-arrow" />}
					/>
				</footer>
			</form>
		</Layout>
	);
};

export default JointIntro;
