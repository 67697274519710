/* eslint-disable camelcase */

import { z } from "zod";

export const BusinessIncomeSchema = z
	.object({
		netProfitAfterTax: z
			.number({
				invalid_type_error: "This field is required",
			})
			.min(0, { message: "This field is required, please enter zero if none" }),
		noNetProfitAfterTax: z.boolean(),
		otherIncomeAllowed: z
			.string({
				required_error: "This field is required",
				invalid_type_error: "This field is required",
			})
			.min(1, { message: "This field is required" }),
		otherIncomes: z
			.object({
				incomeType: z.object({
					id: z.number(),
					label: z.string(),
					value: z.string(),
				}),
				frequency: z.object({
					id: z.number(),
					label: z.string(),
					value: z.string(),
				}),
				estimatedAmount: z.number({
					invalid_type_error: "This field is required",
				}),
			})
			.array()
			.optional(),
	})
	.superRefine(({ otherIncomeAllowed, otherIncomes }, context) => {
		if (otherIncomeAllowed === "true" && otherIncomes?.length === 0) {
			context.addIssue({
				code: z.ZodIssueCode.custom,
				message:
					"Please select no if you do not have any other income to declare",
				path: ["otherIncomeAllowed"],
			});
		}
	});
