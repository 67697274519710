import { useEffect } from "react";
import { useAppDispatch } from "./redux-hooks";
import { setIsFormDirty } from "../store/slices/form-slice";

export const useMarkFormDirty = (isDirty: boolean) => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setIsFormDirty(isDirty));
		return () => {
			dispatch(setIsFormDirty(false));
		};
	}, [dispatch, isDirty]);
};
