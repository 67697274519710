import { type FormEvent, useEffect, useMemo, useState } from "react";
import {
	firstBuyerOptions,
	liveInOptions,
	selectOptions,
	vacantLandOptions,
} from "../../../../utils/BootList";
import SelectInput from "../../../select-input/SelectInput";

import { useForm } from "react-hook-form";
import type { OptionsType } from "../../../../../common/types";
import {
	useAppSelector,
	useAppDispatch,
} from "../../../../../hooks/redux-hooks";
import RadioGroup from "../../../radio-group/RadioGroup";
import {
	clearPropertyAddress,
	clearPropertyAddressParts,
	setBorrowerStage,
	setFirstHomeBuyer,
	setIsVacantLandOrHomeAndLand,
	setPropertyAddress,
	setPropertyAddressParts,
	setPropertyPurpose,
} from "../../../../../store/slices/form-slice";
import AddressSearch, {
	type Place,
} from "../../../address-search/AddressSearch";

import type { AddressParts } from "../../../../../services/apis/create-application.schema";

import Modal from "../../../modal/Modal";
import { useQueryPostcodeVerificationQuery } from "../../../../../services/api";
import { useTracking } from "../../../../../hooks/use-tracking";

type BuyingWorkflowFormType = {
	borrowerStage: OptionsType;
	propertyPurpose?: string;
	firstHomeBuyer?: string;
	isVacantLandOrHomeAndLand?: string;
	propertyAddress?: Place;
	propertyAddressParts?: AddressParts;
};
interface BuyingWorkflowProps {
	onFinish: (value: boolean) => void;
}
const BuyingWorkflow = ({ onFinish }: BuyingWorkflowProps) => {
	const formState = useAppSelector((state) => state.form);
	const { propertyAddress, isVacantLandOrHomeAndLand } = formState;
	const dispatch = useAppDispatch();
	const [showModal, setShowModal] = useState(false);
	const [currentPostcode, setCurrentPostcode] = useState<string | null>(null);

	const {
		data: postcodeVerificationData,
		isSuccess,
		isError,
		error,
	} = useQueryPostcodeVerificationQuery(currentPostcode ?? "", {
		skip: !currentPostcode || currentPostcode?.length !== 4,
	});
	const { trackEvent } = useTracking();

	const [showInvalidPostcodeModal, setShowInvalidPostcodeModal] =
		useState(false);

	// Effect to show the modal if the postcode is invalid
	useEffect(() => {
		if (
			isSuccess ||
			// @ts-expect-error: TODO fix this type error
			(isError && error && error.data?.message !== "Postcode not found")
		) {
			trackEvent("Postcode Validation", {
				valid: "true",
				postcode: currentPostcode,
			});
			setShowInvalidPostcodeModal(false);
		}
		// Reset the modal when there's an error or when the postcode becomes valid
		// @ts-expect-error: TODO fix this type error
		if (isError || (error && error.data.message === "Postcode not found")) {
			setShowInvalidPostcodeModal(true);
			trackEvent("Postcode Validation", {
				valid: "false",
				postcode: currentPostcode,
			});
			trackEvent("Not In Remit Modal Shown", {
				reason: "Postcode Invalid",
				postcode: currentPostcode,
			});
		}
	}, [
		isSuccess,
		isError,
		error,
		postcodeVerificationData,
		trackEvent,
		currentPostcode,
	]);

	const defaultValues: BuyingWorkflowFormType = {
		borrowerStage: formState.borrowerStage ?? {
			id: 5,
			label: "Select option",
			value: "select_option",
		},
		propertyPurpose: formState.propertyPurpose,
		firstHomeBuyer: formState.firstHomeBuyer,
		isVacantLandOrHomeAndLand: formState.isVacantLandOrHomeAndLand,
	};
	const {
		register,
		watch,
		setValue,
		setError,
		clearErrors,
		formState: { errors },
	} = useForm({
		defaultValues,
	});
	const stage = watch("borrowerStage");

	const isSuburb = useMemo(() => [1, 2, 3].includes(stage?.id), [stage?.id]);
	const [options, setOptions] = useState({
		types: isSuburb ? ['(regions)'] : ['address'],
		componentRestrictions: { country: 'au' },
	});

	useEffect(() => {
		setOptions({
			...options,
			types: isSuburb ? ['(regions)'] : ['address'],
		});
// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stage]);

	register("propertyPurpose", {
		onChange: (event: FormEvent<HTMLInputElement>) => {
			if (event.currentTarget.value === "Investment") {
				setShowModal(true);
				trackEvent("Not In Remit Modal Shown", {
					reason: "Investment Property",
				});
			}
			trackEvent("Property Purpose Selected", {
				selectedValue: event.currentTarget.value,
			});
			dispatch(setPropertyPurpose(event.currentTarget.value));
		},
	});
	register("firstHomeBuyer", {
		onChange: (event: FormEvent<HTMLInputElement>) => {
			dispatch(setFirstHomeBuyer(event.currentTarget.value));
		},
	});
	register("isVacantLandOrHomeAndLand", {
		onChange: (event: FormEvent<HTMLInputElement>) => {
			const value = event.currentTarget.value;
			dispatch(setIsVacantLandOrHomeAndLand(value));
			if (value === "yes") {
				setShowModal(true);
				trackEvent("Not In Remit Modal Shown", {
					reason: "Vacant Land or Home and Land Package",
				});
			}
			trackEvent("Vacant Land or Home and Land Selected", {
				selectedValue: value,
			});
		},
	});

	useEffect(() => {
		if (
			formState?.borrowerStage?.id !== 0 &&
			(formState?.propertyAddress
				|| (isSuburb && formState?.propertyAddressParts?.suburb)
				|| (!isSuburb && formState?.propertyAddressParts)) &&
			formState?.propertyPurpose &&
			formState?.firstHomeBuyer &&
			formState?.isVacantLandOrHomeAndLand
		) {
			onFinish(true);
		} else {
			onFinish(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		formState?.borrowerStage?.id,
		formState?.firstHomeBuyer,
		formState?.isVacantLandOrHomeAndLand,
		formState?.propertyAddress,
		formState?.propertyAddressParts,
		formState?.propertyPurpose,
		isSuburb,
	]);
	return (
		<>
			<SelectInput
				name="borrowerStage"
				value={
					stage || {
						id: 0,
						value: "",
						label: "Select an option",
					}
				}
				label="When are you buying?"
				options={selectOptions}
				register={register}
				onChangeHandler={(event: OptionsType) => {
					if (
						(stage?.id === 3 || stage?.id === 2 || stage?.id === 1) &&
						event.id === 4
					) {
						dispatch(clearPropertyAddress());
						dispatch(clearPropertyAddressParts());
						setValue("propertyAddress", undefined);
					} else if (
						stage?.id === 4 &&
						(event.id === 3 || event.id === 2 || event.id === 1)
					) {
						dispatch(clearPropertyAddress());
						dispatch(clearPropertyAddressParts());
						setValue("propertyAddress", undefined);
					}
					dispatch(setBorrowerStage(event));
				}}
			/>
			{stage?.id !== 0 && (
				<AddressSearch
					helperEnabled={true}
					id="propertyAddress"
					options={options}
					register={register}
					name="propertyAddress"
					onChange={({ place, addressParts, isManual }) => {
						clearErrors("propertyAddress");
						if (isSuburb) {
							// Check if postalCode, suburb, and state exist in addressParts
							const hasRequiredParts =
								addressParts?.postalCode &&
								addressParts?.suburb &&
								addressParts?.state;
							if (!isManual && !hasRequiredParts) {
								// Set form error if any of the required parts are missing
								setError("propertyAddress", {
									type: "manual",
									message: "Please ensure you select a valid suburb.",
								});
								return; // Stop further execution to ensure we don't dispatch incomplete addressParts
							}
						} else {
							// Check if streetNumber, streetName, suburb, and state exist in addressParts
							const hasRequiredParts =
								addressParts?.streetNumber &&
								addressParts?.streetName &&
								addressParts?.suburb &&
								addressParts?.state;
							if (!isManual && !hasRequiredParts) {
								// Set form error if any of the required parts are missing
								setError("propertyAddress", {
									type: "manual",
									message:
										"Please ensure you select a valid address that includes a street number.",
								});
								return; // Stop further execution to ensure we don't dispatch incomplete addressParts
							}
						}
						if (addressParts?.postalCode) {
							setCurrentPostcode(addressParts.postalCode);
						} else {
							// Reset if there's no postcode
							setCurrentPostcode(null);
						}
						place && dispatch(setPropertyAddress(place));
						addressParts && dispatch(setPropertyAddressParts(addressParts));
					}}
					label={
						isSuburb
							? "What's the suburb you are looking at?"
							: "Great! What's the address?"
					}
					value={propertyAddress}
					isSuburb={isSuburb}
					manualAddressParts={
						formState.propertyAddressParts || ({} as AddressParts)
					}
					error={errors.propertyAddress?.message}
				/>
			)}
			{(formState?.propertyAddress || formState.propertyAddressParts) && (
				<RadioGroup
					selectedValue={formState.propertyPurpose}
					radioOptions={liveInOptions}
					legend="Why are you buying?"
					register={register}
					name="propertyPurpose"

				/>
			)}
			{formState?.propertyPurpose && (
				<RadioGroup
					selectedValue={formState.firstHomeBuyer}
					radioOptions={firstBuyerOptions}
					legend="Are you a first home buyer?"
					register={register}
					name="firstHomeBuyer"
				/>
			)}
			{formState?.firstHomeBuyer && (
				<RadioGroup
					selectedValue={formState.isVacantLandOrHomeAndLand}
					radioOptions={vacantLandOptions}
					legend="Are you buying vacant land or a home-and-land package?"
					register={register}
					name="isVacantLandOrHomeAndLand"

				/>
			)}
			<Modal
				actions={[]}
				isOpen={showInvalidPostcodeModal}
				onClose={() => {
					setShowInvalidPostcodeModal(false);
				}}
				title={
					<div className="flex flex-col gap-4 md:gap-8 items-center text-center text-[28.43px] font-medium leading-10 text-primary">
						<i className="icon-document-success text-3xl md:text-[80px]" />
						Sucasa doesn't currently support loans for this postcode.
					</div>
				}
				content={
					<p className="text-center">
						If you submit your application, we'll be in touch when this changes.
					</p>
				}
			/>

			<Modal
				isOpen={showModal}
				actions={[]}
				title={
					<div className="flex flex-col gap-4 md:gap-8 items-center text-center text-[28.43px] font-medium leading-10 text-primary">
						<i className="icon-document-success text-3xl md:text-[80px]" />
						{isVacantLandOrHomeAndLand === "yes"
							? "Sucasa isn't currently able to support loans for vacant land or home and land packages."
							: "Sucasa isn't currently able to support investment loans."}
					</div>
				}
				onClose={() => {
					setShowModal(false);
				}}
				content={
					<div className="flex flex-col gap-4">
						<p className="text-sm text-primary text-center">
							{isVacantLandOrHomeAndLand === "yes"
								? "We don't currently support loans for vacant land or home and land packages. If you submit your application, we'll be in touch when this changes."
								: "We don't currently support investment loans. If you submit your application, we'll be in touch when this changes."}
						</p>
						<p className="text-sm text-primary text-center">
							Otherwise, please select another option.
						</p>
					</div>
				}
			/>
		</>
	);
};

export default BuyingWorkflow;
