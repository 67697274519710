import { useCallback } from "react";
import { useAppDispatch } from "./redux-hooks";
import {
	setUser,
	setAuthToken,
	setNotInRemit,
	setOriginationUser,
	setIsJointApplicant,
	setAccessToken,
	setApplications,
} from "../store/slices/auth-slice";
import { setHomeLoanSpecialist } from "../store/slices/home-loan-specialist-slice";
import { navigateToStep } from "../store/slices/stepper-slice";
import { navigateToJointStep } from "../store/slices/joint-stepper-slice";
import {
	setApplicationType,
	setJointBorrowerFirstName,
	setLoanTermInYears,
} from "../store/slices/form-slice";
import {
	ApplicationType,
	Step,
	SubStep,
} from "../services/apis/create-application.schema";
import type { ApplicationAndUserDetails, SuccessfulAuthentication } from "../services/apis/auth";
import { setApplication } from "../store/slices/application-slice";
import { identifyApplication, setUserId } from "../services/analytics";
import { setSentryContext } from "../services/sentry";
import { setFromApplication } from "../store/slices/origination-slice";
import type { SuccessfulOriginationAuthentication } from "../services/apis/origination/types";
// import { useLDClient } from "launchdarkly-react-client-sdk";

declare global {
	interface Window {
		hj: (method: string, id: string, options?: Record<string, unknown>) => void;
	}
}

export const mapApplicationStepToStepper = (applicationStep: string, isOrigination = false) => {
	switch (applicationStep as Step) {
		case Step.YourDetails: {
			return "detailsStep";
		}
		case Step.YourFinances: {
			return "financeStep";
		}
		case Step.Confirmation: {
			return "confirmationStep";
		}
		case Step.Submitted: {
			if (isOrigination) {
				return "uploadStep";
			}
			return "confirmationStep";
		}
		default: {
			return "detailsStep";
		}
	}
};
export type SuccessCallback = (
	response?: ApplicationAndUserDetails
) => void;
export const useAuthSuccessHandler = (onSuccess?: SuccessCallback) => {
	const dispatch = useAppDispatch();
	// const ldClient = useLDClient();

	const handleSuccess = useCallback(
		(response: SuccessfulAuthentication) => {
			const authToken = response.authToken;
			const applicationStep = response.application?.applicationStep;
			const applicationSubStep = response.application?.applicationSubStep;
			const applicationType = response.application?.applicationType;
			const application = response.application;
			const userDetails = response.userDetails;

			setUserId(userDetails.userId);
			setSentryContext({
				userId: userDetails.userId,
				applicationId: application?.appId || "",
			});
			if (window.hj) {
				window.hj("identify", userDetails.userId, {
					applicationId: application?.appId,
				});
			}
			// void ldClient?.identify({ kind: "user", key: userDetails.userId });
			application &&
				identifyApplication({
					appId: application.appId,
					applicationType: application.applicationType,
					applicantType: userDetails?.applicantType || "",
					applicationVersion: '1'
				});

			const homeLoanSpecialist = response.homeLoanSpecialist;
			const jointApplicantHasBeenInvited =
				response.application?.jointApplicantHasBeenInvited;
			const isPrimaryApplicant =
				userDetails?.applicantType === "Primary Applicant";
			const isSecondaryApplicant =
				userDetails?.applicantType === "Secondary Applicant";
			const loanTermInYears = response.application?.loanDetails.loanTermInYears;
			const jointBorrowerFirstName = response.jointBorrowerFirstName;
			const notInRemit = response.notInRemit;
			if (notInRemit) {
				dispatch(setNotInRemit(notInRemit));
			}
			if (userDetails) {
				dispatch(setUser(userDetails));
				dispatch(setIsJointApplicant(isSecondaryApplicant));
			}
			if (homeLoanSpecialist) {
				dispatch(setHomeLoanSpecialist(homeLoanSpecialist));
			}

			if (application) {
				dispatch(setApplication(application));
				dispatch(setFromApplication(application));
			}
			if (loanTermInYears) {
				dispatch(setLoanTermInYears(loanTermInYears as 30 | 25 | 20));
			}
			if (applicationType) {
				dispatch(setApplicationType(applicationType));
			}
			if (jointBorrowerFirstName) {
				dispatch(setJointBorrowerFirstName(jointBorrowerFirstName));
			}
			dispatch(setAuthToken(authToken || null));

			if (isPrimaryApplicant && applicationStep && applicationSubStep) {
				if (
					applicationType === ApplicationType.Joint &&
					!jointApplicantHasBeenInvited &&
					(applicationSubStep as SubStep) === SubStep.YourEmployment
				) {
					dispatch(
						navigateToStep({
							step: mapApplicationStepToStepper(Step.YourDetails),
							subStep: SubStep.JointApplicantInvite,
						})
					);
				} else {
					dispatch(
						navigateToStep({
							step: mapApplicationStepToStepper(applicationStep),
							subStep: applicationSubStep as SubStep,
						})
					);
				}
			} else if (
				isSecondaryApplicant &&
				applicationStep &&
				applicationSubStep
			) {
				dispatch(
					navigateToJointStep({
						step: mapApplicationStepToStepper(applicationStep),
						subStep: applicationSubStep as SubStep,
					})
				);
			} else if (isSecondaryApplicant) {
				dispatch(
					navigateToJointStep({
						step: "detailsStep",
						subStep: SubStep.JointBorrowerAboutYou,
					})
				);
			} else {
				dispatch(
					navigateToStep({
						step: "detailsStep",
						subStep: SubStep.FineDetailsAboutYou,
					})
				);
			}
			if (onSuccess) {
				onSuccess(response);
			}
		},
		[dispatch, onSuccess]
	);

	const handleSuccessOrigination = useCallback(({user, accessToken, isSecondaryApplicant}: SuccessfulOriginationAuthentication) => {
		setUserId(String(user.id));
		setSentryContext({
			userId: String(user.id),
			applicationId: "",
		});
		if (window.hj) {
			window.hj("identify", String(user.id), {
				applicationId: undefined,
			});
		}
		dispatch(setIsJointApplicant(isSecondaryApplicant || false));
		dispatch(setOriginationUser({
			email: user.email,
			firstName: user.firstName,
			lastName: user.lastName,
			middleName: user.middleName ?? undefined,
			name: `${user.firstName} ${user.lastName}`,
			streetAddress: "",
			userAddress: "",
			userId: String(user.id),
			userMobile: user.phone,
			fullName: `${user.firstName} ${user.middleName ?? ""} ${user.lastName}`,
			applications: user.applications,
		}));
		dispatch(setApplications(user.applications ?? []));

		// auth token set for legacy support
		if (accessToken) {
			dispatch(setAuthToken(accessToken));
			dispatch(setAccessToken(accessToken));
		}
	}, [dispatch]);

	return { handleSuccess, handleSuccessOrigination };
};
