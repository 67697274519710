import RadioInputSecondary, { type RadioInputSecondaryType } from "../radio-input-secondary/RadioInputSecondary";
import ErrorMessage from "../error-message/ErrorMessage";

export type RadioGroupSecondaryType = {
	selectedValue?: string;
	legend?: string;
	radioOptions: Array<RadioInputSecondaryType>;
	error?: string;
	onChange: (value: string) => void;
};

const RadioGroupSecondary = ({
	legend,
	radioOptions,
	selectedValue,
	error,
	onChange,
}: RadioGroupSecondaryType ) => {
	return (
		<div className="flex flex-col gap-4">
			{legend && <h4 className="text-primary text-[21.33px] font-normal">{legend}</h4>}
			<ul className="flex items-center gap-4">
				{radioOptions.map((option: RadioInputSecondaryType, index) => {
					return (
						<RadioInputSecondary
							key={index}
							label={option.label}
							value={option.value}
							checked={option.value === selectedValue}
							onChange={onChange}
						/>
					);
				})}
			</ul>
			{!!error && <ErrorMessage message={error} />}
		</div>
	);
};

export default RadioGroupSecondary;
