import { type FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import moment from "moment";
import {
	ValidationStatus,
	VerificationCallback,
	VerificationCaseType,
	VerificationRequestType,
	VerificationResponseOwner,
} from "@sucasa-finance/origination-trpc";
import Button from "../../../../ui/button/Button";
import { ChatMessageAdmin } from "./ChatMessageAdmin";
import { ChatMessageUser } from "./ChatMessageUser";
import { ChatMessageFile } from "./ChatMessageFile";
import { ChatTime } from "./ChatTime";
import { ChatExpandingDetails } from "./ChatExpandingDetails";
import { type SendMessageSchemaFormType, ChatSend } from "./ChatSend";
import type { VerificationCase, VerificationCaseResponse } from "../../../../../services/apis/origination/types";
import { getUserNameText } from "../../../../../helpers/get-user-name-text";
import { formatFileType } from "../../../../../helpers/format-file-type";
import { formatFileSize } from "../../../../../helpers/format-file-size";
import { useAddCaseResponse } from "../../../../../hooks/origination/use-add-case-response";
import { ChatMessageBiometricsAccept } from "./ChatMessageBiometricsAccept";
import { ChatMessageBiometricsSend } from "./ChatMessageBiometricsSend";
import useTrackEventOnMount from "../../../../../hooks/use-track-event-on-mount";
import { useTracking } from "../../../../../hooks/use-tracking";

type ChatProps = {
	onClose: () => void;
	verificationCase?: VerificationCase
}
export const Chat: FC<ChatProps> = ({onClose, verificationCase}) => {
	useTrackEventOnMount({
		eventName: "chat_opened",
		eventProperties: {
			caseType: verificationCase?.caseType,
			status: verificationCase?.status,
		},
	});
	const { trackEvent } = useTracking();
	const [rawCaseResponses, setRawCaseResponses] = useState<Partial<VerificationCaseResponse>[]>(verificationCase?.caseResponses || []);
	const { setSeenCaseResponses, onAcceptBiometrics, onSendBiometrics } = useAddCaseResponse();
	const [isBiometricsAccepted, setIsBiometricsAccepted] = useState(false);

	const lastCreatedAt = useRef<moment.Moment>();

	const caseResponses = useMemo(() => rawCaseResponses.flatMap(itm => {
		const currentCreatedAt = moment(itm.createdAt);
		// if there is no last comparison date, add it
		if (!lastCreatedAt.current) {
			lastCreatedAt.current = currentCreatedAt;
			return [
				currentCreatedAt.toISOString(),
				itm,
			]
		}
		// add date only if longer than a day
		if (lastCreatedAt.current?.diff(currentCreatedAt, 'day') >= 1) {
			lastCreatedAt.current = currentCreatedAt;
			return [
				currentCreatedAt.toISOString(),
				itm,
			]
		}
		// if date is today, and previous date is yesterday, add it as well
		const today = moment().startOf('day');
		const yesterday = today.subtract(1, 'days').startOf('day')
		if (yesterday?.diff(lastCreatedAt.current, 'd')
			&& today?.isSame(currentCreatedAt, 'd')) {
			lastCreatedAt.current = currentCreatedAt;
			return [
				currentCreatedAt.toISOString(),
				itm,
			]
		}
		return itm;
	}) || [], [rawCaseResponses]);

	const hasUnseenNotifications = useMemo(() =>
			rawCaseResponses.some(itm => itm.validationStatus === ValidationStatus.Pending),
		[rawCaseResponses]);

	const isBiometrics =  useMemo(() =>
			verificationCase?.caseType === VerificationCaseType.Biometrics,
		[verificationCase?.caseType]);

	const canTriggerBiometricsAccept =  useMemo(() =>
			verificationCase?.callbacksAvailable?.includes(VerificationCallback.BiometricsAccept),
		[verificationCase?.callbacksAvailable]);
	const hasTriggerBiometricsRun =  useMemo(() =>
			verificationCase?.callbacksTriggered?.includes(VerificationCallback.BiometricsRun),
		[verificationCase?.callbacksTriggered]);
	// const canTriggerBiometricsRun =  useMemo(() =>
	// 		verificationCase?.callbacksAvailable?.includes(VerificationCallback.BiometricsRun),
	// 	[verificationCase?.callbacksAvailable]);

	const createOptimisticResponse = useCallback((data: SendMessageSchemaFormType) => {
		return {
			...data,
			caseId: verificationCase?.id,
			createdAt: new Date(),
			validationStatus: ValidationStatus.Success,
			owner: VerificationResponseOwner.User,
			...('fileId' in data ? {
				fileName: data.name,
				fileContentType: '', 
				fileSize: 0,
			} : {}),
		}
	}, [verificationCase?.id]);

	const onAddResponse = useCallback((data: SendMessageSchemaFormType) => {
		trackEvent("message_sent", {
			caseType: verificationCase?.caseType,
			status: verificationCase?.status,
		})
		setRawCaseResponses((previousState) => [...previousState, createOptimisticResponse(data)]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [createOptimisticResponse, verificationCase?.caseType, verificationCase?.status]);

	const onAddFiles = useCallback((files: { fileId: number; name: string }[]) => {
		trackEvent("documents_uploaded", {
			caseType: verificationCase?.caseType,
			status: verificationCase?.status,
			numberOfFiles: files?.length,
			fromWhere: "chat",
		});
		setRawCaseResponses((previousState) => [
		...previousState,
		...files.map(file => createOptimisticResponse(file))
		]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [createOptimisticResponse, verificationCase?.caseType, verificationCase?.status]);

	const onRevertResponse = useCallback(() => {
		setRawCaseResponses(verificationCase?.caseResponses || []);
	}, [verificationCase?.caseResponses]);

	const onAcceptBiometricsCallback = useCallback(() => {
		verificationCase?.id && onAcceptBiometrics(verificationCase.id);
		setIsBiometricsAccepted(true);
	}, [onAcceptBiometrics, verificationCase?.id]);

	const onSendBiometricsCallback = useCallback(() => {
		verificationCase?.id && onSendBiometrics(verificationCase.id);
	}, [onSendBiometrics, verificationCase?.id]);

	const uploadEnabled =  useMemo(() =>
			verificationCase?.requestType === VerificationRequestType.Document,
		[verificationCase?.requestType]);

	useEffect(() => {
		if (hasUnseenNotifications && verificationCase?.id) {
			setSeenCaseResponses({ caseId: verificationCase.id });
		}
	}, [hasUnseenNotifications, verificationCase?.id]);

	return (<div className="flex flex-col justify-between bg-lavender-60 h-screen max-h-screen">
		<div>
			<div className="bg-off-black-900 flex flex-row items-center p-4">
				<Button
					className="w-10 h-10 text-white ms-0"
					text={<i className="icon-chevron-left text-2xl" />}
					variant="link"
					textAlign="center"
					handleClick={onClose}
				/> <span
				className="text-xl text-white">{verificationCase?.title} - {verificationCase?.user && getUserNameText(verificationCase.user)}</span>
			</div>
			<ChatExpandingDetails description={verificationCase?.description} requirements={verificationCase?.requirements} />
		</div>
		<div className="flex flex-col gap-4 justify-normal items-center p-4 h-full overflow-y-auto">
			{isBiometrics && <ChatMessageBiometricsAccept
				disabled={!canTriggerBiometricsAccept}
				onAccept={onAcceptBiometricsCallback}
			/>}
			{caseResponses.map((itm, index) => {
				if (typeof itm === "string" || itm instanceof String) {
					return <ChatTime key={index} time={itm as string} />;
				}
				if (itm.owner === VerificationResponseOwner.Admin) {
					return <ChatMessageAdmin
						key={index}
						message={itm.message}
						sender="Sucasa Credit"
						time={itm.createdAt?.toISOString()}
						notSeen={itm.validationStatus === ValidationStatus.Pending}
						isBiometrics={itm.body?.['callback'] === VerificationCallback.BiometricsRun}
					/>
				}
				if (itm.fileName) {
					return <ChatMessageFile
						key={index}
						name={itm.fileName}
						size={formatFileType(itm.fileContentType) && `${formatFileType(itm.fileContentType)} • ${formatFileSize(itm.fileSize)}`}
						time={itm.createdAt?.toISOString()}
					/>
				}
				// if (isBiometrics && itm.body?.['callback'] === VerificationCallback.BiometricsAccept) {
				// 	return (<div key={index} className="items-end w-full">
				// 		<ChatMessageUser
				// 			message={itm.message}
				// 			time={itm.createdAt?.toISOString()}
				// 		/>
				// 		{canTriggerBiometricsRun && <ChatMessageBiometricsSend
				// 			sent={hasTriggerBiometricsRun || false}
				// 			onSend={onSendBiometricsCallback}
				// 		/>}
				// 	</div>)
				// }
				return <ChatMessageUser
					key={index}
					message={itm.message}
					time={itm.createdAt?.toISOString()}
				/>
			})}
			{isBiometrics && (!canTriggerBiometricsAccept || isBiometricsAccepted) && (
				<ChatMessageBiometricsSend
					sent={hasTriggerBiometricsRun || false}
					onSend={onSendBiometricsCallback}
					// message="Resend verification link"
				/>
			)}
		</div>
		{verificationCase?.id && <ChatSend
			caseId={verificationCase.id}
			applicationId={verificationCase.applicationId}
			uploadEnabled={uploadEnabled}
			onAddResponse={onAddResponse}
			onRevertResponse={onRevertResponse}
			onAddFiles={onAddFiles}
		/>}
	</div>);
};
