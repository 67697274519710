import banner from "../../../../../assets/welcome-back.jpg";
// import WelcomeLoanItem from "../../../../ui/welcome-loan-item/WelcomeLoanItem";
import { Status } from "../../../../ui/status/Status";
import { selectPropertyAddress } from "../../../../../store/slices/origination-slice";
import { useAppSelector } from "../../../../../hooks/redux-hooks";


export const YourNewAddress = () => {
	const propertyAddress = useAppSelector(selectPropertyAddress);
	const splitAddress = propertyAddress?.split(",");
	return (<div
		className="card-bg min-h-56 bg-off-black-400 relative overflow-hidden flex flex-col justify-between before:right-0 before:left-0 before:top-0 before:bottom-0 before:absolute before:z-10 before:bg-no-repeat bg-gradient-to-r from-off-black-900 from-0% to-off-black-400 to-100% rounded">
		<img
			src={banner}
			alt="banner"
			className="absolute top-0 left-0 right-0 bottom-0 object-cover w-full h-full"
		/>
		<div className="p-4 md:p-8 flex flex-row justify-between relative z-10">
			<span className="text-white">Your New Address</span>
			<Status status="active" text="Approved" />
		</div>
		<div className="flex flex-row h-full relative z-10">
			<div className="w-full flex flex-col justify-end">
				<h2 className="p-4 pt-2 pb-1 md:px-8 text-white text-4xl font-medium">
					{splitAddress?.[0]}
				</h2>
				<h2 className="p-4 pb-2 pt-0 md:px-8 text-white text-4xl font-medium">
					{splitAddress?.[1]}
				</h2>
				<div
					aria-labelledby="loan details"
					className="flex justify-normal flex-wrap gap-6 md:gap-4 p-4 md:pt-4 md:pb-5 md:px-8"
				>
					{/* <WelcomeLoanItem label="Settlement Date" value="22nd August" />
					<WelcomeLoanItem label="Settlement Status" value="Not yet booked" /> */}
				</div>
			</div>
		</div>
	</div>);
};
