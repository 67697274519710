import type { FC } from "react";
import type { ContactNumberType } from "../../../common/types";

const ContactNumber: FC<ContactNumberType> = (_props) => {
	return null;
	// return (
	// 	<a
	// 		href="tel:1300332272"
	// 		className={`${props.showOnMobile ? "flex" : "hidden lg:flex"} lg:items-center gap-2 font-normal tracking-tight text-${props.size} transition-all hover:text-off-black-600`}
	// 		aria-labelledby="Phone support number"
	// 	>
	// 		<i className="icon-phone" aria-labelledby="phone">
	// 			<span className="sr-only">Contact number: 1300332272</span>
	// 		</i>
	// 		<span>1300 33 2272</span>
	// 	</a>
	// );
};

export default ContactNumber;
