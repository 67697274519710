import type { FunctionComponent } from "../../../../common/types";

const Copyrights = (): FunctionComponent => {
	return (
		<section
			className="flex flex-col text-sm font-normal gap-2"
			aria-labelledby="Footer copyrights section"
		>
			<p>
				Sucasa Loans Pty Ltd ACN 106 968 370 Australian Credit Licence 443249
			</p>
			<p>
				Australian Financial Complaints Association (AFCA) 93243. Copyright
				Sucasa Finance Pty Ltd.
			</p>
		</section>
	);
};

export default Copyrights;
