import { useApplicationQuery } from "../services/api";
import { useAppSelector } from "./redux-hooks";
import { selectIsLoggedInWithMiddlewareApplication } from "../store/slices/auth-slice";

export const useGetApplication = () => {
	const isLoggedInWithApplication = useAppSelector(selectIsLoggedInWithMiddlewareApplication);
	return useApplicationQuery(undefined, {
		refetchOnFocus: true,
		refetchOnMountOrArgChange: true,
		refetchOnReconnect: true,
		pollingInterval: 30_000,
		skip: !isLoggedInWithApplication,
	});
};
