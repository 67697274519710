import { z } from "zod";
import { useForm, type SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import {
	useAppDispatch,
	useAppSelector,
} from "../../../../../hooks/redux-hooks";
import {
	setEmail,
	setMarketingConsent,
	setMobile,
	setPrivacyConsent,
} from "../../../../../store/slices/form-slice";
import Button from "../../../../ui/button/Button";
import CheckboxInput from "../../../checkbox-input/CheckboxInput";
import GenericInput from "../../../generic-input/GenericInput";
import { useCreateApplicationMutation } from "../../../../../services/api";
import { selectApplicationPayload } from "../../../../../store/selectors/application-payload";
import { navigateToStep } from "../../../../../store/slices/stepper-slice";
import { useNavigate } from "react-router-dom";
import { NamedRoute } from "../../../../utils/NamedRoutes";
import { setAuthToken } from "../../../../../store/slices/auth-slice";
import { useAuthLock } from "../../../../../hooks/use-auth-context";
import {
	COMMON_PASSWORDS_BLACKLIST_REGEX,
	getPasswordRegex,
	PASSWORD_REGEX_8ULNS16,
} from "../../../../../lib/utils";
import { SubStep } from "../../../../../services/apis/create-application.schema";
import InputWithMask from "../../../input-with-mask/InputWithMask";
import useIsMobile from "../../../../../hooks/use-is-mobile";
import { CreateAccountHint } from "../../../../ui/hint/CreateAccountHint";
import {
	setFormLoading,
	setShowCreatAccountLoaderText,
} from "../../../../../store/slices/loader-slice";
import { setToast } from "../../../../../store/slices/toast-slice";
import FormLayout from "../../../../ui/form/Form";
import GenericPasswordInput from "../../../generic-password-input/GenericPasswordInput";
import { useMarkFormDirty } from "../../../../../hooks/use-mark-form-dirty";
import { useAuthSuccessHandler } from "../../../../../hooks/use-auth-success";
import useTrackPageViewOnMount from "../../../../../hooks/use-track-on-mount";
import { useTracking } from "../../../../../hooks/use-tracking";
import {
	addDynamicHint,
	removeDynamicHint,
	setDynamicHints,
} from "../../../../../store/slices/ui-slice";


const createYourAccountSchema = z
	.object({
		email: z.string().email({ message: "Invalid email address" }),
		mobile: z.string().min(11, "A valid mobile number is required"),
		password: z
			.string()
			.min(8, "Password must be at least 8 characters")
			.max(16, "Password must be at most 16 characters")
			.regex(
				PASSWORD_REGEX_8ULNS16,
				"Password must contain at least one uppercase, one lowercase, one number and one special character"
			)
			.regex(COMMON_PASSWORDS_BLACKLIST_REGEX, "Password is too common"),
		confirmPassword: z
			.string()
			.min(8, "Confirm Password must be at least 8 characters"),
		marketingConsent: z.boolean(),
		privacyConsent: z.boolean().refine((value) => value === true, {
			message:
				"You must accept the Sucasa Credit Guide and Privacy Consent to submit an application",
		}),
	})
	.refine((data) => data.password === data.confirmPassword, {
		message: "Passwords do not match",
		path: ["confirmPassword"],
	});

type CreateYourAccountFormType = z.infer<typeof createYourAccountSchema>;

const CreateYourAccount = () => {
	const dispatch = useAppDispatch();
	const isMobile = useIsMobile();
	const { setAuthLocked } = useAuthLock();
	const navigate = useNavigate();
	const formState = useAppSelector((state) => state.form);
	const applicationPayload = useAppSelector(selectApplicationPayload);
	const didNotWantToPursueFHBScheme = useAppSelector(
		(state) => state.form.didNotWantToPursueFHBScheme
	);
	const [createApplication, { isLoading }] = useCreateApplicationMutation();

	const {
		email,
		mobile,
		password,
		confirmPassword,
		privacyConsent,
		marketingConsent,
	} = formState;

	const formOptions = {
		resolver: zodResolver(createYourAccountSchema),
		defaultValues: {
			email,
			mobile,
			password,
			confirmPassword,
			privacyConsent,
			marketingConsent,
		},
	};
	const {
		register,
		handleSubmit,
		setError,
		setFocus,
		formState: { errors, isDirty },
	} = useForm<CreateYourAccountFormType>(formOptions);
	const { trackEvent } = useTracking();
	useMarkFormDirty(isDirty);
	useTrackPageViewOnMount({
		page: "Your Details",
		subPage: "Create Account",
	});
	const { handleSuccess } = useAuthSuccessHandler();

	const saveData: SubmitHandler<CreateYourAccountFormType> = async (data) => {


		dispatch(setEmail(data.email));
		dispatch(setMobile(data.mobile));
		dispatch(setPrivacyConsent(data.privacyConsent));
		dispatch(setMarketingConsent(data.marketingConsent));
		const passwordRegex = getPasswordRegex(
			formState.firstName,
			formState.middleName,
			formState.surname,
			data.email
		);
		if (passwordRegex.test(data.password)) {
			setAuthLocked(true);
			try {
				dispatch(setFormLoading(true));
				dispatch(setShowCreatAccountLoaderText(true));
				const response = await createApplication({
					...applicationPayload,
					primaryBorrowerPassword: data.password,
					primaryBorrowerPasswordConfirmation: data.confirmPassword,
					email: data.email,
					mobile: data.mobile,
					privacyConsent: data.privacyConsent,
					marketingConsent: data.marketingConsent,
					...(didNotWantToPursueFHBScheme === "Yes" && {
						didNotWantToPursueFHBScheme,
					}),
				}).unwrap();

				handleSuccess({
					authToken: response.authToken,
					...response.data.content,
				});
				trackEvent("Application Created");

				navigate(NamedRoute.home);
				if (response.authToken) {
					dispatch(setAuthToken(response.authToken));
				}
				if (response.data.content.notInRemit) {
					dispatch(
						navigateToStep({
							step: "detailsStep",
							subStep: SubStep.NotInRemit,
						})
					);
				} else {
					dispatch(
						navigateToStep({
							step: "detailsStep",
							subStep: SubStep.FineDetailsAboutYou,
						})
					);
				}
			} catch (error) {
				trackEvent("Application Failed to Create");
				if (
					// @ts-expect-error TODO(janyk): fix this
					error?.data &&
					// @ts-expect-error TODO(janyk): fix this
					 
					error?.data?.errors?.some(
						(error_: { field: string; message: string }) =>
							error_.field === "primaryBorrowerPassword" &&
							error_.message === "Invalid Password"
					)
				) {
					dispatch(
						setToast({
							open: true,
							type: "error",
							title: "Error",
							description:
								"Your password did not meet our requirements. Please use a more secure password.",
						})
					);
				} else if (
					// @ts-expect-error TODO(janyk): fix this
					error?.data &&
					// @ts-expect-error TODO(janyk): fix this
					 
					error?.data?.error === "Email address already in use."
				) {
					dispatch(
						setToast({
							open: true,
							type: "error",
							title: "Error",
							description:
								"Email address already in use. Please try again with a different email address.",
						})
					);
				} else {
					dispatch(
						setToast({
							open: true,
							type: "error",
							title: "Error",
							description: "An error occurred while creating your account.",
						})
					);
				}
			} finally {
				dispatch(setFormLoading(false));
				dispatch(setShowCreatAccountLoaderText(false));
				setAuthLocked(false);
			}
		} else {
			setError("password", {
				message:
					"Password cannot contain any of your names, email address or any spaces",
			});
			setFocus("password");
		}
	};

	useEffect(() => {
		dispatch(setDynamicHints([]));
		dispatch(addDynamicHint("CreateAccountHint"));
		return () => {
			dispatch(removeDynamicHint("CreateAccountHint"));
		};
	}, [dispatch]);

	const header = (
		<h1 className="text-primary text-[37.9px] font-normal">
			Create your account
		</h1>
	);
	const content = (
		<div className="flex flex-col gap-9">
			<GenericInput
				name="email"
				register={register}
				type="email"
				label="Email"
				placeholder="Email"
				error={errors.email && errors.email?.message}
			/>
			<InputWithMask
				name="mobile"
				register={register}
				type="tel"
				label="Mobile"
				placeholder="Mobile number"
				mask="+61 ___ ___ ___"
				modify={(input) => {
					if (input[0] === "0") {
						return {
							mask: "+61 ____ ___ ___",
						};
					}
				}}
				error={errors.mobile && errors.mobile?.message}
			/>
			<GenericPasswordInput
				name="password"
				register={register}
				label="Password"
				placeholder="Password"
				error={errors.password && errors.password?.message}
			/>
			<GenericPasswordInput
				name="confirmPassword"
				register={register}
				label="Confirm your password"
				placeholder="Password Confirmation"
				error={errors.confirmPassword && errors.confirmPassword?.message}
			/>
			<div className="flex flex-col gap-3">
				<p className="font-normal text-sm">
					<i className="icon-asterisk"></i>Minimum 8 characters
				</p>
				<p className="font-normal text-sm">
					<i className="icon-asterisk"></i>Must contain numbers and letters
				</p>
				<p className="font-normal text-sm">
					<i className="icon-asterisk"></i>Upper and Lower Case
				</p>
				<p className="font-normal text-sm">
					<i className="icon-asterisk"></i>At least one special character
				</p>
				<p className="font-normal text-sm">
					<i className="icon-asterisk"></i>Must not include your name
				</p>
				<p className="font-normal text-sm">
					<i className="icon-asterisk"></i>Must not include password
				</p>
				<p className="font-normal text-sm">
					<i className="icon-asterisk"></i>Must not be too simple
				</p>
			</div>

			<CheckboxInput
				label={
					<span>
						By ticking this box I confirm that I have read, understand and agree
						with the{" "}
						<a
							href="https://www.sucasa.com.au/credit-guide"
							target="_blank"
							rel="noopener noreferrer"
							className="text-sm font-normal underline hover:text-off-black-600"
						>
							Sucasa Credit Guide
						</a>{" "}
						and{" "}
						<a
							href="https://www.sucasa.com.au/privacy-policy"
							target="_blank"
							rel="noopener noreferrer"
							className="text-sm font-normal underline hover:text-off-black-600"
						>
							Sucasa Privacy Consent
						</a>
						. I confirm the information I provide will be accurate and
						understand it will be accessible to all applicants.
					</span>
				}
				name="privacyConsent"
				checked={false}
				register={register}
				size="small"
				checkPosition="start"
				error={errors.privacyConsent && errors.privacyConsent?.message}
			/>
			<CheckboxInput
				label="I do not want to receive occasional marketing from Sucasa"
				name="marketingConsent"
				checked={false}
				register={register}
				size="small"
				checkPosition="start"
			/>
			{isMobile && (<CreateAccountHint />)}
		</div>
	);
	const footer = (
		<div
			className="flex items-center justify-between gap-4"
			aria-labelledby="Actions wrapper"
		>
			<Button
				text="Back"
				variant="accent"
				iconPrefix={<i className="icon-arrow rotate-180" />}
				handleClick={() => {
					if (didNotWantToPursueFHBScheme === "Yes") {
						dispatch(
							navigateToStep({
								step: "detailsStep",
								subStep: SubStep.FHBFederalGuarantee,
							})
						);
					} else {
						dispatch(
							navigateToStep({
								step: "detailsStep",
								subStep: SubStep.YouAndYourHousehold as SubStep,
							})
						);
					}
				}}
				isDisabled={isLoading}
			/>
			<Button
				text="Create Account"
				variant="primary"
				iconSuffix={<i className="icon-arrow" />}
				type="submit"
				isDisabled={isLoading}
			/>
		</div>
	);
	return (
		<FormLayout
			header={header}
			content={content}
			footer={footer}
			onSubmit={handleSubmit(saveData)}
		/>
	);
};

export default CreateYourAccount;
