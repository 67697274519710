import { type FC, useState } from "react";
import Modal from "../../../modal/Modal";
import { useTracking } from "../../../../../hooks/use-tracking";

interface GenuineSavingsModalProps {
	isOpen: boolean;
	onClose: () => void;
	onYesClicked: () => void;
	purchasePrice: number;
	requiredSavings: number;
}

export const GenuineSavingsModal: FC<GenuineSavingsModalProps> = ({
	isOpen,
	onClose,
	purchasePrice,
	requiredSavings,
	onYesClicked,
}) => {
	const [showGoodbyeModal, setShowGoodbyeModal] = useState(false);
	const { trackEvent } = useTracking();

	const handleNo = () => {
		trackEvent("Will not have 5% savings in next 3 months Clicked");
		setShowGoodbyeModal(true);
	};

	const handleYes = () => {
		trackEvent("Will have 5% savings in next 3 months Clicked");
		onClose();
		onYesClicked();
	};

	const handleClose = () => {
		trackEvent("Genuine Savings Modal Closed");
		setShowGoodbyeModal(false);
		onClose();
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={handleClose}
			hideCloseButton={showGoodbyeModal}
			title={
				!showGoodbyeModal && (
					<div className="flex flex-col gap-4 md:gap-8 items-center text-center text-[28.43px] font-medium leading-10 text-primary">
						Hi!
					</div>
				)
			}
			content={
				showGoodbyeModal ? (
					<div className="flex flex-col gap-4">
						<p className="text-sm text-primary text-center">
							Sounds like it would be best to apply when you're within 3 months
							of having 5% in genuine savings.
						</p>
					</div>
				) : (
					<div className="flex flex-col gap-4">
						<p className="text-sm text-primary text-center">
							We require borrowers to have a minimum of 5% in genuine savings to
							cover the deposit, stamp duty, and other costs associated with
							purchasing a property.
						</p>
						<p className="text-sm text-primary text-center">
							This is $
							{requiredSavings.toLocaleString(undefined, {
								minimumFractionDigits: 0,
								maximumFractionDigits: 0,
							})}{" "}
							based on the purchase price of $
							{purchasePrice.toLocaleString(undefined, {
								minimumFractionDigits: 0,
								maximumFractionDigits: 0,
							})}{" "}
							you've entered.
						</p>
						<p className="text-sm text-primary text-center">
							Do you think you'll have this amount in the next 3 months?
						</p>
					</div>
				)
			}
			actions={
				showGoodbyeModal
					? [
							{
								text: "See you then!",
								handleClick: handleClose,
								variant: "primary",
								type: "button",
								textAlign: "center",
								size: "full",
							},
						]
					: [
							{
								text: "No",
								handleClick: handleNo,
								variant: "accent",
								type: "button",
								textAlign: "start",
								size: "full",
							},
							{
								text: "Yes",
								handleClick: handleYes,
								variant: "primary",
								type: "button",
								textAlign: "start",
								size: "full",
							},
						]
			}
		/>
	);
};
