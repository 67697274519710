import { useMemo } from "react";
import { VerificationStatus } from "@sucasa-finance/origination-trpc";
import { useCasesForApplicationId } from "./use-cases-for-application-id";

export const useCaseSummary = (applicationId: number) => {
    const data = useCasesForApplicationId(applicationId);
    return useMemo(() => {
        const active = data.filter(item => item.status === VerificationStatus.Active);
        const waiting = data.filter(item => item.status === VerificationStatus.Waiting);
        const finished = data.filter(item => item.status === VerificationStatus.Finished);
        return {
            active,
            waiting,
            finished,
            data
        }
    }, [data]);
}
