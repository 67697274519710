import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import type { FunctionComponent } from "../../../common/types";
import { useForgotPassword } from "../../../hooks/use-forgot-password";
import Button from "../../ui/button/Button";
import GenericInput from "../generic-input/GenericInput";
import { setFormLoading } from "../../../store/slices/loader-slice";
import { useAppDispatch } from "../../../hooks/redux-hooks";

const ForgotPasswordSchema = z.object({
	email: z.string().email({ message: "Invalid email address" }),
});
type ForgotPasswordSchemaType = z.infer<typeof ForgotPasswordSchema>;

const ForgotPasswordForm = (): FunctionComponent => {
	const dispatch = useAppDispatch();
	const {
		register,
		handleSubmit,
		formState: { errors },
		setError,
		watch,
	} = useForm<ForgotPasswordSchemaType>({
		resolver: zodResolver(ForgotPasswordSchema),
	});

	const email = watch("email");

	const { onSubmitHandler, isLoading, isError, isSuccess } =
		useForgotPassword();

	useEffect(() => {
		if (isLoading) {
			dispatch(setFormLoading(true));
		} else {
			dispatch(setFormLoading(false));
		}

		return () => {
			dispatch(setFormLoading(false));
		};
	}, [isLoading]);

	useEffect(() => {
		isError &&
			setError("email", {
				type: "custom",
				message: "Please enter a registered email address.",
			});
	}, [isError, setError]);

	useEffect(() => {
		const listener = (event: KeyboardEvent) => {
			if (event.code === "Enter" || event.code === "NumpadEnter") {
				event.preventDefault();
				if (email.length > 0) {
					void onSubmitHandler({ email });
				}
			}
		};

		document.addEventListener("keydown", listener);
		return () => {
			document.removeEventListener("keydown", listener);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [email]);
	return (
		<>
			<h1 className="text-primary text-6xl leading-snug font-normal">
				Forgot Password?
			</h1>
			{!isSuccess && (
				<p className="font-normal leading-8 mb-10 lg:mb-16">
					No worries! Let’s reset it for you. Please enter your Sucasa email in
					the form below
				</p>
			)}
			<form
				className="flex flex-col gap-8"
				onSubmit={handleSubmit(onSubmitHandler)}
			>
				{!isSuccess && (
					<>
						<GenericInput
							name="email"
							register={register}
							type="email"
							placeholder="Email"
							error={errors?.email?.message}
							iconPrefix={<i className="icon-email" />}
						/>
						<div className="lg:mt-10">
							<Button
								type="submit"
								text="Send me a link to restore password"
								variant="primary"
								size="full"
								iconSuffix={<i className="icon-arrow" />}
							/>
						</div>
					</>
				)}
				{isSuccess && (
					<p className="font-normal leading-8 mb-10 lg:mb-16">
						Thank you, your password reset is in progress. Please check your
						inbox for further instructions.
					</p>
				)}
			</form>
		</>
	);
};

export default ForgotPasswordForm;
