import { z } from "zod";
import { useForm, type SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useEffect } from "react";
import {
	useAppDispatch,
	useAppSelector,
} from "../../../../../hooks/redux-hooks";
import {
	setEmail,
	setMarketingConsent,
	setMobile,
	setPrivacyConsent,
} from "../../../../../store/slices/form-slice";
import Button from "../../../../ui/button/Button";
import CheckboxInput from "../../../checkbox-input/CheckboxInput";
import GenericInput from "../../../generic-input/GenericInput";
import { navigateToStep } from "../../../../../store/slices/stepper-slice";
import { setVerificationToken } from "../../../../../store/slices/auth-slice";
import { SubStep } from "../../../../../services/apis/create-application.schema";
import InputWithMask from "../../../input-with-mask/InputWithMask";
import {
	setFormLoading,
} from "../../../../../store/slices/loader-slice";
import { setToast } from "../../../../../store/slices/toast-slice";
import FormLayout from "../../../../ui/form/Form";
import { useMarkFormDirty } from "../../../../../hooks/use-mark-form-dirty";
import useTrackPageViewOnMount from "../../../../../hooks/use-track-on-mount";
import {
	addDynamicHint,
	removeDynamicHint,
	setDynamicHints,
} from "../../../../../store/slices/ui-slice";
import { DEFAULT_TOKEN_TYPE } from "../../../../../services/apis/auth.schema";
import { CreateAuth0AccountHint } from "../../../../ui/hint/CreateAuth0AccountHint";
import useIsMobile from "../../../../../hooks/use-is-mobile";
import { useTracking } from "../../../../../hooks/use-tracking";
import { captureException } from "../../../../../services/sentry";
import { useOTPSend } from "../../../../../services/apis/origination/api";

const requestYourPinSchema = z
	.object({
		email: z.string().email({ message: "Invalid email address" }),
		mobile: z.string().min(11, "A valid mobile number is required").regex(/^\+?([0-9] *){10,}$/, "A valid mobile number is required"),
		marketingConsent: z.boolean(),
		privacyConsent: z.boolean().refine((value) => value === true, {
			message:
				"You must accept the Sucasa Credit Guide and Privacy Consent to submit an application",
		}),
	});

type RequestYourPinFormType = z.infer<typeof requestYourPinSchema>;

export const RequestYourPin = () => {
	const dispatch = useAppDispatch();
	const isMobile = useIsMobile();
	const formState = useAppSelector((state) => state.form);
	const didNotWantToPursueFHBScheme = useAppSelector(
		(state) => state.form.didNotWantToPursueFHBScheme
	);
	const [otpSend, { isLoading }] = useOTPSend();
	const { trackEvent } = useTracking();

	const {
		email,
		mobile,
		privacyConsent,
		marketingConsent,
	} = formState;

	const formOptions = {
		resolver: zodResolver(requestYourPinSchema),
		defaultValues: {
			email,
			mobile,
			privacyConsent,
			marketingConsent,
		},
	};
	const {
		register,
		handleSubmit,
		formState: { errors, isDirty },
	} = useForm<RequestYourPinFormType>(formOptions);
	useMarkFormDirty(isDirty);
	useTrackPageViewOnMount({
		page: "Your Details",
		subPage: "Create Account",
	});

	const saveData = useCallback<SubmitHandler<RequestYourPinFormType>>(async (data) => {
		dispatch(setEmail(data.email));
		dispatch(setMobile(data.mobile));
		dispatch(setPrivacyConsent(data.privacyConsent));
		dispatch(setMarketingConsent(data.marketingConsent));
		try {
			dispatch(setFormLoading(true));
			trackEvent("Requesting Pin");
			const response = await otpSend({
				tokenType: DEFAULT_TOKEN_TYPE,
				email: data.email,
				mobile: data.mobile,
			}).unwrap();

			dispatch(setVerificationToken(response.data.verificationToken));
		} catch (error) {
			captureException(error as Error);
			trackEvent("Pin Request Failed", {
				// @ts-expect-error need to type api responses

				error: error?.data?.message || error?.message || "An error occurred while creating your account.",
			});
			dispatch(
				setToast({
					open: true,
					type: "error",
					title: "Error",
					// @ts-expect-error need to type api responses

					description: error?.data?.message || error?.message || "An error occurred while creating your account.",
				})
			);
		} finally {
			dispatch(setFormLoading(false));
		}
	}, [dispatch, otpSend]);

	useEffect(() => {
		dispatch(setDynamicHints([]));
		dispatch(addDynamicHint("CreateAuth0AccountHint"));
		return () => {
			dispatch(removeDynamicHint("CreateAuth0AccountHint"));
		};
	}, [dispatch]);

	const header = (
		<h1 className="text-primary text-[37.9px] font-normal">
			Create your account
		</h1>
	);
	const content = (
		<div className="flex flex-col gap-9">
			<GenericInput
				name="email"
				register={register}
				type="email"
				label="Email"
				placeholder="Email"
				error={errors.email && errors.email?.message}
			/>
			<InputWithMask
				name="mobile"
				register={register}
				type="tel"
				label="Mobile"
				placeholder="Mobile number"
				mask="+61 ___ ___ ___"
				modify={(input) => {
					if (input[0] === "0") {
						return {
							mask: "+61 ____ ___ ___",
						};
					}
				}}
				error={errors.mobile && errors.mobile?.message}
			/>

			<CheckboxInput
				label={
					<span>
						By ticking this box I confirm that I have read, understand and agree
						with the{" "}
						<a
							href="https://www.sucasa.com.au/credit-guide"
							target="_blank"
							rel="noopener noreferrer"
							className="text-sm font-normal underline hover:text-off-black-600"
						>
							Sucasa Credit Guide
						</a>{" "}
						and{" "}
						<a
							href="https://www.sucasa.com.au/privacy-policy"
							target="_blank"
							rel="noopener noreferrer"
							className="text-sm font-normal underline hover:text-off-black-600"
						>
							Sucasa Privacy Consent
						</a>
						. I confirm the information I provide will be accurate and
						understand it will be accessible to all applicants.
					</span>
				}
				name="privacyConsent"
				checked={false}
				register={register}
				size="small"
				checkPosition="start"
				error={errors.privacyConsent && errors.privacyConsent?.message}
			/>
			<CheckboxInput
				label="I do not want to receive occasional marketing from Sucasa"
				name="marketingConsent"
				checked={false}
				register={register}
				size="small"
				checkPosition="start"
			/>
			{isMobile && (<CreateAuth0AccountHint />)}
		</div>
	);
	const footer = (
		<div
			className="flex items-center justify-between gap-4"
			aria-labelledby="Actions wrapper"
		>
			<Button
				text="Back"
				variant="accent"
				iconPrefix={<i className="icon-arrow rotate-180" />}
				handleClick={() => {
					if (didNotWantToPursueFHBScheme === "Yes") {
						dispatch(
							navigateToStep({
								step: "detailsStep",
								subStep: SubStep.FHBFederalGuarantee,
							})
						);
					} else {
						dispatch(
							navigateToStep({
								step: "detailsStep",
								subStep: SubStep.YouAndYourHousehold as SubStep,
							})
						);
					}
				}}
				isDisabled={isLoading}
			/>
			<Button
				text="Next"
				variant="primary"
				iconSuffix={<i className="icon-arrow" />}
				type="submit"
				isDisabled={isLoading}
			/>
		</div>
	);
	return (
		<FormLayout
			header={header}
			content={content}
			footer={footer}
			onSubmit={handleSubmit(saveData)}
		/>
	);
};
