import { type FC, useMemo } from "react";
import { ApplicationStep } from "@sucasa-finance/origination-trpc";
import { wbAllSteps, wbJointSteps } from "../../wb-stepper/WbStepper.config";

type TWbProgressProps = {
	applicationStep: ApplicationStep;
	isJointApplicant?: boolean;
}
export const WbProgress: FC<TWbProgressProps> = ({applicationStep, isJointApplicant}) => {
	const okSteps = isJointApplicant ? wbJointSteps : wbAllSteps;
	// Determine the index of the current application step
	const currentStepIndex = useMemo(() =>
			applicationStep === ApplicationStep.Submitted
				? 4
				: okSteps.findIndex((step) => step.stepType === applicationStep),
		[applicationStep]);

	const currentStepName = wbAllSteps?.[currentStepIndex]?.label || applicationStep;

	return (<div className="py-4 text-base">
		<i className="icon-progress" /> Resume Step {currentStepIndex+1} of {okSteps.length}: {currentStepName}
	</div>)
};
