import { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
	useAppDispatch,
	useAppSelector,
} from "../../../../../../hooks/redux-hooks";
import {
	setEmploymentHistory,
	setCurrentEmploymentType,
	setPreviousFinanceActiveForm,
} from "../../../../../../store/slices/form-slice";
import {
	addDynamicHint,
	removeDynamicHint,
	setDynamicHints,
} from "../../../../../../store/slices/ui-slice";
import Button from "../../../../../ui/button/Button";
import {
	type FullTimeEmployeeFormSlice,
	type TwoYearsFullTimeEmployeeHistoryFormSlice,
	type TwoYearsFullTimeEmployeeHistoryFormType,
	TwoYearsFullTimeEmployeeHistorySchema,
	SelectTwoYearsFullTimeEmployeeHistoryForm,
} from "./FullTimeEmployee.Schema";
import { zodResolver } from "@hookform/resolvers/zod";
import FullTimeEmployeeHistoryDisplay from "./full-time-employee-history-display/FullTimeEmployeeHistoryDisplay";
import { sumYearsMonthsEmploymentHistory } from "../../../../../utils/helpers";
import { navigateToJointStep } from "../../../../../../store/slices/joint-stepper-slice";
import { navigateToStep } from "../../../../../../store/slices/stepper-slice";
import { SubStep } from "../../../../../../services/apis/create-application.schema";
import useIsMobile from "../../../../../../hooks/use-is-mobile";
import { EmploymentHistoryHint } from "../../../../../ui/hint/EmploymentHistoryHint";
import FormLayout from "../../../../../ui/form/Form";
import type { OptionsType } from "../../../../../../common/types";

const emptyEmploymentHistory = {} as FullTimeEmployeeFormSlice;
const FullTimeEmployee = ({
	isJoint,
	employemntOption,
}: {
	isJoint?: boolean;
	employemntOption: OptionsType;
}) => {
	const dispatch = useAppDispatch();
	const isMobile = useIsMobile();
	const formState = useAppSelector(SelectTwoYearsFullTimeEmployeeHistoryForm);
	const {
		setValue,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm({
		resolver: zodResolver(TwoYearsFullTimeEmployeeHistorySchema),
		defaultValues: formState,
		mode: "all", // Ensure validation checks for all fields including custom validation
	});
	const totalMonths = sumYearsMonthsEmploymentHistory(
		watch("employmentHistory") ?? []
	);
	const percentage = (totalMonths / 24) * 100;
	const saveData = (
		rawData: Partial<TwoYearsFullTimeEmployeeHistoryFormSlice>
	) => {
		const data: TwoYearsFullTimeEmployeeHistoryFormType =
			rawData as TwoYearsFullTimeEmployeeHistoryFormType;
		dispatch(setCurrentEmploymentType(employemntOption));
		dispatch(setPreviousFinanceActiveForm(SubStep.YourEmployment));
		dispatch(setEmploymentHistory(data.employmentHistory));
		if (totalMonths >= 24) {
			if (isJoint) {
				dispatch(
					navigateToJointStep({
						step: "financeStep",
						subStep: SubStep.YourEmployeeIncome,
					})
				);
			} else {
				dispatch(
					navigateToStep({
						step: "financeStep",
						subStep: SubStep.YourEmployeeIncome,
					})
				);
			}
		}
	};

	const onChangeHandler = (
		employmentHistory: Partial<FullTimeEmployeeFormSlice>,
		index: number
	) => {
		const employmentHistories = watch("employmentHistory");
		if (employmentHistories) {
			const updatedEmploymentHistory = [...employmentHistories];
			updatedEmploymentHistory[index] = {
				isCurrent: employmentHistory.isCurrent ?? false,
				industryMonths: employmentHistory.industryMonths,
				industryYears: employmentHistory.industryYears,
				jobStartDate: employmentHistory.jobStartDate ?? "",
				jobEndDate: employmentHistory.jobEndDate ?? "",
				inProbation: employmentHistory.inProbation,
				employerName: employmentHistory.employerName ?? "",
				jobTitle: employmentHistory.jobTitle ?? "",
			};
			dispatch(setEmploymentHistory(updatedEmploymentHistory));
			setValue("employmentHistory", updatedEmploymentHistory);
		}
	};
	const removeHandler = (index: number) => {
		const employmentHistories = watch("employmentHistory");
		if (employmentHistories) {
			const updatedEmploymentHistory = [...employmentHistories];
			updatedEmploymentHistory.splice(index, 1);
			dispatch(setEmploymentHistory(updatedEmploymentHistory));
			setValue("employmentHistory", updatedEmploymentHistory);
		}
	};
	useEffect(() => {
		if (
			!watch("employmentHistory") ||
			watch("employmentHistory")?.length === 0
		) {
			setValue("employmentHistory", [{ ...emptyEmploymentHistory }]);
		}
	}, [setValue, watch]);

	useEffect(() => {
		dispatch(setDynamicHints([]));
		dispatch(addDynamicHint("EmploymentHistoryHint"));
		return () => {
			dispatch(removeDynamicHint("EmploymentHistoryHint"));
		};
	}, [dispatch]);

	const header = <></>;
	const content = (
		<div className="flex flex-col gap-9">
			{isMobile && (
				<EmploymentHistoryHint percentage={percentage} totalMonths={totalMonths} />
			)}
			{watch("employmentHistory")?.map((employment, index) => (
				<FullTimeEmployeeHistoryDisplay
					key={index}
					employmentHistory={employment}
					index={index}
					errors={errors}
					onChangeHandler={onChangeHandler}
					removeHandler={removeHandler}
				/>
			))}
			{totalMonths < 24 && (
				<Button
					text={
						<span>
							<i className="icon-plus" />
							Add a previous employment
						</span>
					}
					type="button"
					size="full"
					variant="accent"
					textAlign="center"
					handleClick={() => {
						const employmentHistories = watch("employmentHistory");
						if (employmentHistories) {
							const updatedEmploymentHistory = [
								...employmentHistories,
								{ ...emptyEmploymentHistory },
							];
							setValue("employmentHistory", updatedEmploymentHistory);
						}
					}}
				/>
			)}
		</div>
	);
	const footer = (
		<div
			className="flex items-center justify-end gap-4"
			aria-labelledby="Actions wrapper"
		>
			<Button
				text="Next"
				variant="primary"
				iconSuffix={<i className="icon-arrow" />}
				type="submit" // Ensure button submits the form
				isDisabled={totalMonths < 24}
			/>
		</div>
	);
	return (
		<FormLayout
			header={header}
			content={content}
			footer={footer}
			onSubmit={handleSubmit(saveData)}
		/>
	);
};

export default FullTimeEmployee;
