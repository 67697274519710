import type { FC } from "react";

type ProgressBarProps = {
	progress: number;
}
export const ProgressBar: FC<ProgressBarProps> = ({
	progress,
}) => (
	<div className="w-full bg-off-black-200 h-0.5 absolute top-full">
		<i
			style={{ width: `${progress || 0}%`, transition: "width 1s" }}
			className="bg-primary block h-0.5"
		/>
	</div>
);
