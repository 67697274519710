import { type FC, type FormEvent, useEffect, useState } from "react";
import { liveInOptions } from "../../../../utils/BootList";
import {
	useAppSelector,
	useAppDispatch,
} from "../../../../../hooks/redux-hooks";
import RadioGroup from "../../../radio-group/RadioGroup";
import { useForm } from "react-hook-form";
import {
	setPropertyAddress,
	setPropertyAddressParts,
	setPropertyPurpose,
	setPropertyValuation,
} from "../../../../../store/slices/form-slice";
import AddressSearch, {
	type Place,
} from "../../../address-search/AddressSearch";
import type { AddressParts } from "../../../../../services/apis/create-application.schema";
import DollarInput from "../../../dollar-input/DollarInput";
import Modal from "../../../modal/Modal";
import { useTracking } from "../../../../../hooks/use-tracking";
import { useQueryPostcodeVerificationQuery } from "../../../../../services/api";

type RefinancingWorkflowFormType = {
	propertyPurpose?: string;
	propertyValuation?: string;
	propertyAddress?: Place;
	propertyAddressParts?: AddressParts;
};
interface RefinancingWorkflowProps {
	submitted: boolean;
	onFinish: (isValid: boolean, propertyValuation: number | undefined) => void;
}
const RefinancingWorkflow: FC<RefinancingWorkflowProps> = ({
	onFinish,
	submitted,
}) => {
	const formState = useAppSelector((state) => state.form);
	const dispatch = useAppDispatch();
	const [showModal, setShowModal] = useState(false);
	const [currentPostcode, setCurrentPostcode] = useState<string | null>(null);
	const [showInvalidPostcodeModal, setShowInvalidPostcodeModal] = useState(false);

	const {
		data: postcodeVerificationData,
		isSuccess,
		isError,
		error,
	} = useQueryPostcodeVerificationQuery(currentPostcode ?? "", {
		skip: !currentPostcode || currentPostcode?.length !== 4,
	});

	const defaultValues: Partial<RefinancingWorkflowFormType> = {
		propertyPurpose: formState.propertyPurpose,
		propertyValuation: formState.propertyValuation?.toString(),
		propertyAddress: undefined,
	};
	const {
		control,
		register,
		formState: { errors },
		setError,
		setValue,
		clearErrors,
	} = useForm({
		defaultValues,
	});
	const { trackEvent } = useTracking();

	useEffect(() => {
		if (
			isSuccess ||
			// @ts-expect-error: TODO fix this type error
			(isError && error && error.data?.message !== "Postcode not found")
		) {
			trackEvent("Postcode Validation", {
				valid: "true",
				postcode: currentPostcode,
			});
			setShowInvalidPostcodeModal(false);
		}
		// @ts-expect-error: TODO fix this type error
		if (isError || (error && error.data.message === "Postcode not found")) {
			setShowInvalidPostcodeModal(true);
			trackEvent("Postcode Validation", {
				valid: "false",
				postcode: currentPostcode,
			});
			trackEvent("Not In Remit Modal Shown", {
				reason: "Postcode Invalid",
				postcode: currentPostcode,
			});
		}
	}, [
		isSuccess,
		isError,
		error,
		postcodeVerificationData,
		trackEvent,
		currentPostcode,
	]);

	register("propertyPurpose", {
		onChange: (event: FormEvent<HTMLInputElement>) => {
			const value = event.currentTarget.value;
			if (value === "Investment") {
				setShowModal(true);
				trackEvent("Not In Remit Modal Shown", {
					reason: "Investment Property",
				});
			}
			trackEvent("Property Purpose Selected", {
				selectedValue: value,
			});
			dispatch(setPropertyPurpose(value));
		},
	});

	useEffect(() => {
		if (
			(formState?.propertyAddress || formState?.propertyAddressParts) &&
			formState?.propertyPurpose &&
			formState?.propertyValuation
		) {
			onFinish(true, formState.propertyValuation);
		} else {
			onFinish(false, formState.propertyValuation);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		formState?.propertyAddress,
		formState?.propertyPurpose,
		formState?.propertyValuation,
		submitted,
	]);

	const maximumLendAmount = formState.propertyValuation
		? (formState.propertyValuation * 0.98).toLocaleString(undefined, {
				minimumFractionDigits: 0,
				maximumFractionDigits: 0,
			})
		: undefined;

	const minimumLendAmount = formState.propertyValuation
		? (formState.propertyValuation * 0.85).toLocaleString(undefined, {
				minimumFractionDigits: 0,
				maximumFractionDigits: 0,
			})
		: undefined;

	const borrowAmountHelperMessage = `Sucasa lends between 85-98% LVR. For this property valuation, that is between $${minimumLendAmount} - $${maximumLendAmount}.`;
	console.log(submitted);
	const valuationError =
		submitted &&
		(!formState?.propertyValuation ||
			Number.isNaN(formState?.propertyValuation) ||
			formState?.propertyValuation < 300_000)
			? "Please enter a valuation amount of at least $300,000 for the property you are refinancing."
			: undefined;

	return (
		<>
			<RadioGroup
				selectedValue={formState?.propertyPurpose}
				radioOptions={liveInOptions}
				register={register}
				legend="What is your property used for?"
				error={
					errors.propertyPurpose &&
					"Please select the purpose of your property."
				}
			/>

			{(formState?.["propertyPurpose"] || formState.propertyPurpose) && (
				<AddressSearch
					helperEnabled={true}
					id="refinancePropertyAddress"
					options={{
						types: ["address"],
						componentRestrictions: { country: "au" },
					}}
					register={register}
					name="propertyAddress"
					onChange={({ place, addressParts, isManual }) => {
						clearErrors("propertyAddress");
						const hasRequiredParts =
							addressParts?.streetNumber &&
							addressParts?.streetName &&
							addressParts?.suburb &&
							addressParts?.state;
						if (!isManual && !hasRequiredParts) {
							setError("propertyAddress", {
								type: "manual",
								message:
									"Please ensure you select a valid address that includes a street number.",
							});
							return;
						}
						if (addressParts?.postalCode) {
							setCurrentPostcode(addressParts.postalCode);
						} else {
							setCurrentPostcode(null);
						}
						place && dispatch(setPropertyAddress(place));
						addressParts && dispatch(setPropertyAddressParts(addressParts));
					}}
					label={"What's the address?"}
					value={formState.propertyAddress}
					isSuburb={false}
					manualAddressParts={
						formState.propertyAddressParts || ({} as AddressParts)
					}
					error={errors.propertyAddress?.message}
				/>
			)}
			{(formState?.["propertyAddress"] || formState.propertyAddressParts) && (
				<DollarInput
					name="propertyValuation"
					control={control}
					onValueChange={(value) => {
						if (value) {
							setValue("propertyValuation", value?.toString());
							dispatch(setPropertyValuation(value));
						} else {
							setValue("propertyValuation", "");
							dispatch(setPropertyValuation(0));
						}
					}}
					type="numeric"
					iconPrefix={<i className="icon-dollar" />}
					label="What's the property valuation?"
					placeholder="Amount"
					value={formState?.propertyValuation}
					error={valuationError}
					helperMessage={
						valuationError
							? undefined
							: maximumLendAmount && borrowAmountHelperMessage
					}
				/>
			)}
			<Modal
				isOpen={showModal}
				actions={[]}
				title={
					<div className="flex flex-col gap-4 md:gap-8 items-center text-center text-[28.43px] font-medium leading-10 text-primary">
						<i className="icon-document-success text-3xl md:text-[80px]" />
						Sucasa isn't currently able to support investment loans.
					</div>
				}
				onClose={() => {
					setShowModal(false);
				}}
				content={
					<div className="flex flex-col gap-4">
						<p className="text-sm text-primary text-center">
							We're working hard to bring you this feature for investment loans.
							Feel free to submit your application and we'll be in touch when we
							can support this.
						</p>
						<p className="text-sm text-primary text-center">
							Otherwise, please select another option.
						</p>
					</div>
				}
			/>
			<Modal
				actions={[]}
				isOpen={showInvalidPostcodeModal}
				onClose={() => {
					setShowInvalidPostcodeModal(false);
				}}
				title={
					<div className="flex flex-col gap-4 md:gap-8 items-center text-center text-[28.43px] font-medium leading-10 text-primary">
						<i className="icon-document-success text-3xl md:text-[80px]" />
						Sucasa doesn't currently support loans for this postcode.
					</div>
				}
				content={
					<p className="text-center">
						If you submit your application, we'll be in touch when this changes.
					</p>
				}
			/>
		</>
	);
};

export default RefinancingWorkflow;
