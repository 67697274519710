import type { FC } from "react";
import HintCard from "../hint-card/HintCard";

export const CreateApplicationHint: FC = () => (<HintCard
	icon={<i className="icon-two-users" />}
	text={
		<>
			<span className="font-medium">
				Clicking "Create Application" will create a Sucasa application for you.
			</span>
			It might take a few moments before it gets created.
			<br />
		</>
	}
/>);
