import type { FC } from "react";
import type { ContactEmailType } from "../../../common/types";

const ContactEmail: FC<ContactEmailType> = (props) => {
	return (
		<a
			href="mailto:applications@sucasa.com.au"
			className={`flex items-center gap-2 font-normal tracking-tight text-${props.size} transition-all hover:text-off-black-600`}
			aria-labelledby="Support Email"
		>
			<i className="icon-email"></i>
			<span>applications@sucasa.com.au</span>
		</a>
	);
};

export default ContactEmail;
