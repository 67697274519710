import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import * as Toast from "@radix-ui/react-toast";
import { PersistGate } from "redux-persist/integration/react";
import { RoutesSelector } from "./routes";
import { persistor, store } from "./store";
import { AuthLockProvider } from "./hooks/use-auth-context";
import { TrpcProvider } from "./hooks/trpc-context";
import FormLoader from "./components/forms/form-loader/FormLoader";
import { Loader } from "./components/ui/loader/Loader";
export const AppRoot = () => (
	<React.StrictMode>
		<AuthLockProvider>
			<Provider store={store}>
				<Suspense fallback={<Loader />}>
					<PersistGate loading={null} persistor={persistor}>
						<TrpcProvider>
							<Toast.Provider swipeDirection="right">
								<RoutesSelector />
								<ToastContainer theme="dark" />
								<Toast.Viewport className="ToastViewport" />
							</Toast.Provider>
						</TrpcProvider>
					</PersistGate>
				</Suspense>
				<FormLoader />
			</Provider>
		</AuthLockProvider>
	</React.StrictMode>
);
