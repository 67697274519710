import type { FC, ReactNode } from "react";
import banner from "../../assets/login-cover.jpg";
import SiteBrand from "../../components/ui/site-brand/SiteBrand";

export type Props = {
	children: ReactNode;
};

const AuthLayout: FC<Props> = ({ children }) => {
	return (
		<div className="flex flex-col flex-grow relative w-full bg-white">
			<header className="flex container mx-auto p-4 lg:py-8 z-10 absolute lg:relative">
				<SiteBrand className="w-32 invert lg:invert-0 brightness-0" />
			</header>
			<img
				src={banner}
				alt="login banner cover"
				className="lg:absolute lg:top-0 lg:bottom-0 lg:right-0 w-full lg:w-1/2 object-cover h-64 lg:h-screen"
			/>
			<main className="container mx-auto flex-grow">{children}</main>
		</div>
	);
};

export default AuthLayout;
