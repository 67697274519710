import { createSelector } from "@reduxjs/toolkit";
import type { RootState } from "../index";
import type { UpdateExpensesRequest } from "../../services/apis/update-expenses.schema";
import { SubStep, Step } from "../../services/apis/create-application.schema";

export const selectUpdateExpensesPayload = createSelector(
	[(state: RootState) => state.form],
	(form) => {
		const payload: Partial<UpdateExpensesRequest> = {
            applicationStep: Step.YourFinances,
            applicationSubStep: SubStep.YourAssets,
            alimony: form.ailmonyMaintenanceExpenses?.toString(),
            childcare: form.childcareExpenses?.toString(),
            chronicHealthExpenses: form.chronicHealthExpenses?.toString(),
            clothingAndPersonal: form.clothingPersonalCareExpenses?.toString(),
            education: form.educationExpenses?.toString(),
            foodAndGroceries: form.foodGroceriesExpenses?.toString(),
            generalInsurance: form.generalInsuranceExpenses?.toString(),
            internetTVPhone: form.internetTVPhoneExpenses?.toString(),
            lifeOrAccidentInsurance: form.lifeOrAccidentExpenses?.toString(),
            medicalAndHealth: form.medicalHealthExcludingInsuranceExpenses?.toString(),
            otherExpenses: form.otherExpenses?.toString(),
            privateHealthInsurance: form.privateHealthInsuranceExpenses?.toString(),
            privateSchoolFees: form.privateSchoolFeesExpenses?.toString(),
            propertyExpenses: form.propertyUtilitiesExpenses?.toString(),
            recreationAndEntertainment: form.recreationEntertainmentExpenses?.toString(),
            rent: form.postSettlementRent?.toString(),
            transport: form.transportExpenses?.toString(),
            counselling: form.counsellingServicesExpenses?.toString()
		};
		// Filter out undefined, null, or "" values
		Object.keys(payload).forEach((key) => {
			const payloadKey = key as keyof typeof payload;
			if (
				payload[payloadKey] === undefined ||
				payload[payloadKey] === null ||
				Number.isNaN(payload[payloadKey])
			) {
				delete payload[payloadKey];
			}
		});

		return payload as UpdateExpensesRequest;
	}
);
