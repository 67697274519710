import { createRef, type ReactNode, type RefObject, useMemo } from "react";
import {
	type Control,
	type FieldValues,
	type PathValue,
	type Path,
	Controller,
} from "react-hook-form";
import ErrorMessage from "../error-message/ErrorMessage";
import HelperMessage from "../helper-message/HelperMessage";

type PinInputProps<TFieldValues extends FieldValues> = {
	name: Path<TFieldValues>;
	control: Control<TFieldValues>;
	length: number;
	label?: string;
	iconPrefix?: ReactNode;
	iconSuffix?: ReactNode;
	error?: string;
	value?: PathValue<TFieldValues, Path<TFieldValues>> | undefined;
	onValueChange?: (value?: string) => void;
	helperMessage?: string;
	disabled?: boolean;
	size?: "small" | "medium" | "large";
	layout?: "tertiary" | "secondary";
};

export const PinInput = <TFieldValues extends FieldValues>({
	name,
	control,
	length,
	label,
	iconPrefix,
	iconSuffix,
	error,
	value,
	onValueChange,
	helperMessage,
	disabled,
	size,
	layout = "tertiary",
}: PinInputProps<TFieldValues>) => {
	const inputId = `input-${label?.replace(/\s+/g, "")}-${name?.replace(/\s+/g, "")}`;
	const inputArray = Array.from({length: length},(_, index) => index);
	const inputRefs: Array<RefObject<HTMLInputElement>> = Array.from({length: length},() => createRef<HTMLInputElement>());

	const inputStyle = useMemo(() => ({
		textSize: size === "small" ? "text-base" :
			size === "large" ? "text-[28.33px]" : "text-[21.33px]",
		boxSize: {
			width: size === "small" ? 28 : size === "large" ? 58 : 44,
			height: size === "small" ? 24 : size === "large" ? 54 : 42,
		}
	}), [size]);

	return (
		<div
			className={`flex flex-col ${layout === "tertiary" ? "w-full gap-2" : "items-start md:flex-row md:items-center gap-4"}`}
			aria-labelledby={`${label}`}
		>
			{!!label && (
				<label
					htmlFor={inputId}
					className={`text-primary ${inputStyle.textSize} font-normal ${layout === "tertiary" ? "mb-2" : "w-full flex-shrink-0 md:w-1/2"}`}
				>
					{label}
				</label>
			)}
			<div
				className={`${layout === "secondary" && "flex flex-col flex-grow gap-2 w-full"}`}
			>
				<div
					className={`flex gap-2 ${layout === "tertiary" && "items-center pb-2"}`}
				>
					{iconPrefix}
					<Controller
						render={({ field }) => {
							const values: Array<string> = field?.value ? [...(field.value as number).toString()] : [];
							const focusIndex = values.length >= length ? 0 : values.length;
							return (<>
								<input type="hidden" ref={field.ref} value={field?.value || ""} />
								{inputArray.map((_, index) => (
									<input
										type={'tel'}
										pattern={'[0-9]*'}
										autoFocus={index === focusIndex}
										style={inputStyle.boxSize}
										key={index}
										data-id={index}
										value={values?.[index] || ""}
										ref={inputRefs[index]}
										onChange={(event) => {
											event.target.value = event.target.value?.replace(/\D/gi, '');
											if (event.target.value === "" || !event.target.validity.valid) {
												return;
											}

											let next;
											const newValues = [...values];
											if (event.target.value.length > 1) {
												let nextIndex = event.target.value.length + index - 1;
												if (nextIndex >= length) {
													nextIndex = length - 1;
												}
												next = inputRefs[nextIndex];
												const split = [...event.target.value];
												split.forEach((item, index_: number) => {
													const cursor = index + index_;
													if (cursor < length) {
														newValues[cursor] = item;
													}
												});
											} else {
												next = inputRefs[index + 1];
												newValues[index] = event.target.value;
											}
											field.onChange({...event, target: {...event.target, value: newValues.join('')}});
											if (next) {
												next.current!.focus();
												next.current!.select();
											}
											onValueChange?.(newValues.join(''));
										}}
										onKeyDown={(event) => {
											const previousIndex = index - 1;
											const nextIndex = index + 1;
											const previous = inputRefs[previousIndex];
											const next = inputRefs[nextIndex];
											switch (event.key) {
												case "Backspace": {
													event.preventDefault();
													const newValues = [...values];
													if (values[index]) {
														newValues[index] = '';
														field.onChange({...event, target: {...event.target, value: newValues.join('')}});
														onValueChange?.(newValues.join(''));
													} else if (previous) {
														newValues[previousIndex] = '';
														previous.current?.focus();
														field.onChange({...event, target: {...event.target, value: newValues.join('')}});
														onValueChange?.(newValues.join(''));
													}
													break;
												}
												case "ArrowLeft": {
													event.preventDefault();
													if (previous) {
														previous.current?.focus();
													}
													break;
												}
												case "ArrowRight": {
													event.preventDefault();
													if (next) {
														next.current?.focus();
													}
													break;
												}
												case "ArrowUp":
												case "ArrowDown":
												case "Enter": {
													event.preventDefault();
													break;
												}
												default: {
													break;
												}
											}
										}}
										onFocus={(event) => {
											let previousIndex = index - 1;
											let lastFreeIndex = index;
											while (previousIndex >= 0) {
												const previous = inputRefs[previousIndex];
												if (previous && !previous.current?.value) {
													lastFreeIndex = previousIndex;
												} else if (previous && previous.current?.value) {
													break;
												}
												previousIndex--;
											}
											if (lastFreeIndex < index) {
												inputRefs[lastFreeIndex]?.current?.focus();
											} else {
												event.target.select();
											}
										}}
										disabled={field.disabled}
										className={`${error ?
											"placeholder-error border-b-error" :
											field.disabled ?
												"placeholder-off-black-400 border-b-off-black-400 text-off-black-400" :
												"placeholder-off-black-600 border-b-off-black-900"
										} ${inputStyle.textSize} bg-transparent w-full focus-visible:outline-none border-b text-center`}
										onWheel={(event) => {
											event.currentTarget.blur();
										}}
									/>
								))}
							</>)
						}}
						name={name}
						control={control}
						defaultValue={value}
						disabled={disabled}
					/>
					{iconSuffix}
				</div>
				{layout === "secondary" && (
					<>
						{!!helperMessage && <HelperMessage message={helperMessage} />}
						{!!error && <ErrorMessage message={error} />}
					</>
				)}
			</div>
			{layout !== "secondary" && (
				<>
					{!!helperMessage && <HelperMessage message={helperMessage} />}
					{!!error && <ErrorMessage message={error} />}
				</>
			)}
		</div>
	);
};
