import { useEffect } from "react";

const useScrollTopOnMount = () => {
	useEffect(() => {
		// Scrolls to the top of the page on component mount
		window.scrollTo(0, 0);
	}, []); // Empty dependency array ensures this runs only once on mount
};

export default useScrollTopOnMount;
