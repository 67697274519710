import { ApplicationStatus } from "@sucasa-finance/origination-trpc";
import Layout from "../common/layout/Layout";
import { VerificationColumn } from "../components/layout/verification-column/VerificationColumn";
import { Stepper } from "../components/layout/stepper/Stepper";
import { UploadStep } from "../components/forms/steps/upload/UploadStep";
import { useGetApplicationByIdSuspense } from "../hooks/origination/use-get-application-by-id";
import { useAppSelector } from "../hooks/redux-hooks";
import { selectApplicationStatus } from "../store/slices/origination-slice";
import { ProgressToUnconditional } from "../components/forms/steps/upload/ProgressToUnconditional";
import { YourUnconditional } from "../components/forms/steps/upload/YourUnconditional";
import { CaseSummaryMobile } from "../components/layout/verification-column/CaseSummaryMobile";
import { WorkingTowards } from "../components/layout/header/working-towards/WorkingTowards";

export const Verification = () => {
	const { applicationId } = useGetApplicationByIdSuspense();
	const applicationStatus = useAppSelector(selectApplicationStatus);
	return (
		<Layout
			leftColumn={<Stepper />}
			rightColumn={<VerificationColumn applicationId={applicationId} />}
			progress={<CaseSummaryMobile applicationId={applicationId} />}
			workingTowards={<WorkingTowards />}
		>
			{![ApplicationStatus.PreApproved, ApplicationStatus.UnconditionallyApproved].includes(applicationStatus ?? '') && <UploadStep applicationId={applicationId} />}
			{applicationStatus === ApplicationStatus.PreApproved && <ProgressToUnconditional applicationId={applicationId} />}
			{applicationStatus === ApplicationStatus.UnconditionallyApproved && <YourUnconditional applicationId={applicationId} />}
		</Layout>
	);
};
