import { origination } from "../../origination";
import {useOriginationCallback, useOriginationMutationResult} from "./mutation";
import {legacy} from "../../api";
import {useMapEmploymentIncomeRequest} from "./mapping/employment";
import {emptyOkResponseData} from "./mapping/response";

export function useUpdateIncomeEmployment() {
    const [invokeOriginal, originalMutation] = legacy.useUpdateIncomeMutation();
    const mutation = origination.application.updateEmployments.useMutation();
    const mapRequest = useMapEmploymentIncomeRequest();
    const create = useOriginationCallback(
        invokeOriginal,
        mutation.mutateAsync,
        mapRequest,
        emptyOkResponseData
    )
    const mutationResult = useOriginationMutationResult(originalMutation, mutation);
    return [
        create,
        mutationResult
    ] as const;
}