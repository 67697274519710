import { useAppSelector } from "../../../hooks/redux-hooks";
import { selectVerificationToken } from "../../../store/slices/auth-slice";
import { RequestYourPin } from "./RequestYourPin";
import { SubmitYourPin } from "./SubmitYourPin";

export const AcceptInviteFormV2 = ({ inviteToken }: { inviteToken: string }) => {
	const verificationToken = useAppSelector(selectVerificationToken);

	return (<>
		{!verificationToken && <>
			<h1 className="text-primary text-6xl leading-snug font-normal">
				Welcome to Sucasa!
			</h1>
			<p className="font-normal leading-8 mb-10 lg:mb-16">
				Please click 'Verify my email' below to receive a verification code via email.
			</p>
			<RequestYourPin inviteToken={inviteToken} />
		</>}
		{verificationToken && <SubmitYourPin />}
	</>);
};
