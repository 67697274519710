import type { FunctionComponent } from "../../../../common/types";

const WbUserInfo = ({
	fullName,
	address,
}: {
	fullName: string;
	address: string;
}): FunctionComponent => {
	return (
		<div
			className="flex justify-between flex-col md:flex-row z-10 relative p-4 md:pt-6 md:px-8"
			aria-labelledby="card-header"
		>
			<h2 className="text-white text-base font-medium leading-7 md:max-w-56">
				{address}
			</h2>
			<div className="flex flex-col items-end gap-2">
				<span className="flex items-center gap-2 py-1 px-2 bg-primary opacity-80 rounded-3xl text-white font-medium text-xs">
					Active <i className="bg-green-500 w-2 h-2 rounded-full block" />
				</span>
				<h3 className="flex items-center gap-2 text-white font-medium leading-5 text-xs">
					<span>{fullName}</span>
					<i className="icon-person-in-the-house text-base" />
				</h3>
			</div>
		</div>
	);
};

export default WbUserInfo;
