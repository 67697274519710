import {
	useAppSelector,
} from "../../../../../hooks/redux-hooks";
import { selectIsLoggedIn, selectVerificationToken } from "../../../../../store/slices/auth-slice";
import { CreateApplicationWithToken } from "./CreateApplicationWithToken";
import { RequestYourPin } from "./RequestYourPin";
import { SubmitYourPin } from "./SubmitYourPin";

export const CreateYourAccountV2 = () => {
	const verificationToken = useAppSelector(selectVerificationToken);
	const isLoggedIn = useAppSelector(selectIsLoggedIn);

	return (<>
		{isLoggedIn && <CreateApplicationWithToken />}
		{!isLoggedIn && !verificationToken && <RequestYourPin />}
		{!isLoggedIn && verificationToken && <SubmitYourPin />}
	</>);
};
