import { forwardRef, useCallback, useImperativeHandle, useState } from "react";
import Button from "../../../../ui/button/Button";

export type InstructionsRef = {
	toggle: () => void;
}

export const Instructions = forwardRef<InstructionsRef>((_, ref) => {
	const [isVisible, setIsVisible] = useState(true);

	const toggleHandler = useCallback( () => {
		setIsVisible(!isVisible);
	}, [isVisible]);

	useImperativeHandle(ref, () => {
		return {
			toggle: () => {
				toggleHandler();
			},
		};
	});



	return (<>
		{isVisible && <div className="my-2 px-8 py-4 bg-lemon-100">
			<div className="flex flex-row justify-between pb-2 mb-2 border-b border-off-black-400">
				<div className="py-2">
					Your documents must comply with the following
				</div>
				<Button
					className="ms-2 hover:bg-none"
					text={<i className="icon-remove text-xl" />}
					variant="link"
					textAlign="center"
					handleClick={() => {
						toggleHandler();
					}}
				/>
			</div>
			<div className="flex flex-row flex-nowrap py-2">
				<i className="icon-bulb" /><span>Document must be clear with all required details visible</span>
			</div>
			<div className="flex flex-row flex-nowrap py-2">
				<i className="icon-lock" /><span>Document must not be password protected</span>
			</div>
			<div className="py-2 mt-2 border-t border-off-black-400">
				We are unable to assess your application until we have all documents.
			</div>
		</div>}
	</>);
});
