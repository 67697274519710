import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { ToastVariant } from "../../components/ui/toast/Toast";

type ToastState = {
	open: boolean;
	type: ToastVariant;
	title?: string;
	description: string;
};

const initialState: ToastState = {
	open: false,
	type: "success",
	title: "",
	description: "",
};

export const toastSlice = createSlice({
	name: "toast",
	initialState,
	reducers: {
		setToast: (state, action: PayloadAction<ToastState>) => {
			state.open = action.payload.open;
			state.type = action.payload.type;
			state.title = action.payload.title;
			state.description = action.payload.description;
		},
	},
});

export const { setToast } = toastSlice.actions;

export default toastSlice.reducer;
