import type { FieldErrors, FieldValues, UseFormRegister } from "react-hook-form";
import { LoanFeature } from "../../../../../../../services/apis/create-application.schema";
import CheckboxInputSecondary from "../../../../../checkbox-input-secondary/CheckboxInputSecondary";
import GenericInput from "../../../../../generic-input/GenericInput";
import type { NewHomeLoanFormSlice } from "../../../refinancing-workflow/new-home-loan/NewHomeLoanSchema";
import type { BuyingWorkflowLoanFormType } from "../../BuyingWorkflow";
import ErrorMessage from "../../../../../error-message/ErrorMessage";

type LoanBuyingFeaturesType<TFieldValues extends FieldValues = FieldValues> = {
	register: UseFormRegister<TFieldValues>;
	selectedLoanBuyingFeatures: Array<LoanFeature>;
	onChange: (checked: boolean, value: string) => void;
	errors?: FieldErrors<BuyingWorkflowLoanFormType | NewHomeLoanFormSlice>;
};

const LoanBuyingFeaturesFC = <TFieldValues extends FieldValues = FieldValues>({
	register,
	selectedLoanBuyingFeatures,
	onChange,
	errors,
}: LoanBuyingFeaturesType<TFieldValues>) => {
	// TODO(hamza): these return "on" as a value, need to set their actual values on the redux slice
	return (
		<div className="flex flex-col gap-4">
			<legend className="text-[21.33px] leading-8">
				What other loan features do you require?
			</legend>
			<CheckboxInputSecondary
				label={LoanFeature.InterestOnly}
				value={LoanFeature.InterestOnly}
				name="requestedLoanBuyingFeatures1"
				checked={selectedLoanBuyingFeatures.includes(
					LoanFeature.InterestOnly
				)}
				onChange={(event) => {
					onChange(event.target.checked, event.target.value);
				}}
			/>
			<CheckboxInputSecondary
				label={LoanFeature.FixedRate}
				value={LoanFeature.FixedRate}
				name="requestedLoanBuyingFeatures2"
				checked={selectedLoanBuyingFeatures.includes(
					LoanFeature.FixedRate
				)}
				onChange={(event) => {
					onChange(event.target.checked, event.target.value);
				}}
			/>

			<CheckboxInputSecondary
				label={LoanFeature.GuarantorLoan}
				value={LoanFeature.GuarantorLoan}
				name="requestedLoanBuyingFeatures3"
				checked={selectedLoanBuyingFeatures.includes(
					LoanFeature.GuarantorLoan
				)}
				onChange={(event) => {
					onChange(event.target.checked, event.target.value);
				}}
			/>
			<CheckboxInputSecondary
				label={LoanFeature.Other}
				value={LoanFeature.Other}
				name="requestedLoanBuyingFeatures4"
				checked={selectedLoanBuyingFeatures.includes(
					LoanFeature.Other
				)}
				onChange={(event) => {
					onChange(event.target.checked, event.target.value);
				}}
			/>
			
			{!!errors?.requestedLoanBuyingFeatures && errors?.requestedLoanBuyingFeatures.message && <ErrorMessage message={errors?.requestedLoanBuyingFeatures.message} />}
			<GenericInput
				name="requestedLoanBuyingFeaturesOther"
				register={register}
				type="text"
				placeholder="Add Description"
				error={errors && errors.requestedLoanBuyingFeaturesOther && errors.requestedLoanBuyingFeaturesOther.message}
			/>
		</div>
	);
};

export default LoanBuyingFeaturesFC;
