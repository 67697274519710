import type { FC } from "react";
import HintCard from "../hint-card/HintCard";

export const JoinedAccountHint: FC = () => (<HintCard
	icon={<i className="icon-two-users" />}
	text={
		<>
			<span className="font-medium">
				Your plus one will receive an invite once you submit your application.
			</span>
		</>
	}
/>);
