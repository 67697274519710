import { type FC, useMemo } from "react";

type FileUploadFileProps = {
  name: string;
  size: string;
  file: File;
}

export const FileUploadFile: FC<FileUploadFileProps> = ({ name, size, file }) => {
  const fileType = useMemo(() =>
    file.type === 'application/pdf' ? 'pdf'
      : file.type.startsWith('image/') ? 'image' : 'unknown', [file.type]);

  return (
    <div className="flex flex-col justify-end bg-white rounded h-44 w-28">
      <div id="file-preview" className="relative flex flex-col h-full items-center justify-center border-b border-off-black-400 bg-grass-hopper-400">
        {fileType === 'pdf' && <div className="relative w-full h-full flex items-center justify-center">
          <object
            data={URL.createObjectURL(file)}
            type="application/pdf"
            className="absolute inset-0 w-full h-full bg-grass-hopper-400 opacity-30"
          />
          <div className="absolute inset-0 flex items-center justify-center">
            <i className="icon-check text-3xl text-grass-hopper-900 z-10" />
          </div>
        </div>}
        {fileType === 'image' && <div className="relative w-full h-full flex items-center justify-center">
          <img
            src={URL.createObjectURL(file)}
            alt={name}
            className="absolute inset-0 w-full h-full object-contain"
          />
          <div className="absolute inset-0 bg-grass-hopper-400 opacity-70" />
          <i className="icon-check text-3xl text-grass-hopper-900 absolute z-10" />
        </div>}
        {fileType === 'unknown' && <div>
          <i className="icon-check text-3xl text-grass-hopper-900" />
        </div>}
      </div>
      <div className="flex flex-col gap-2 p-2">
        <span className="text-nowrap overflow-hidden overflow-ellipsis">{name}</span>
        <span className="text-xs text-off-black-600">{size}</span>
      </div>
    </div>
  );
};
