import { useEffect } from "react";
import { type PageViewType, useTracking } from "./use-tracking";

const useTrackPageViewOnMount = ({ page, subPage }: PageViewType) => {
	const { trackPageView } = useTracking();

	useEffect(() => {
		trackPageView({
			page,
			subPage,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};

export default useTrackPageViewOnMount;
