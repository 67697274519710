import axios, {type AxiosRequestConfig, type AxiosResponse} from 'axios';

import type {
	DeleteFileRequest,
	DeleteFileResponse,
	GetFileResponse,
	CreateFilesRequest,
	CreateFilesResponse,
	FileStorageEndpointParameters,
	UpdateFileRequest
} from "./file-storage.types";
import env from "../config";

export class FileStorageClient {
	private accessToken?: string | null;
	public setAccessToken(accessToken?: string | null) {
		this.accessToken = accessToken;
	}

	public async getFile(fileId: string): Promise<GetFileResponse> {
		const result = await this.request<GetFileResponse>(`/getFile/${fileId}`, 'GET', {});
		return result.data;
	}

	public async createFiles(payload: CreateFilesRequest[]): Promise<CreateFilesResponse[]> {
		const headers = {
			'Content-Type': 'application/json',
		};
		const response = await this.request<CreateFilesResponse[]>(
			'/createFiles',
			'POST',
			payload,
			headers,
		);
		return response.data;
	}

	public async updateFile(payload: UpdateFileRequest): Promise<CreateFilesResponse[]> {
		const headers = {
			'Content-Type': 'application/json',
		};
		const response = await this.request<CreateFilesResponse[]>(
			'/updateFile',
			'POST',
			payload,
			headers,
		);
		return response.data;
	}

	public async deleteFile(payload: DeleteFileRequest): Promise<DeleteFileResponse[]> {
		const headers = {
			'Content-Type': 'application/json',
		};
		const response = await this.request<DeleteFileResponse[]>(
			'/deleteFile',
			'DELETE',
			payload,
			headers,
		)
		return response.data;
	}

	public async upload<T = any>(url: string, file: File) {
		const config: AxiosRequestConfig<File> = {
			// headers: {
			// 	'Content-Type': file.type,
			// },
			data: file,
			method: 'PUT',
			url,
		};
		return axios.request<T>(config);
	}

	private async request<T = any>(
		endpoint: string,
		method: string,
		data: FileStorageEndpointParameters,
		headers?: Record<string, string>,
	): Promise<AxiosResponse<T>> {
		const url = `${env.VITE_ORIGINATION_API}storage/${endpoint.startsWith('/') ? endpoint.slice(1) : endpoint}`;
		const config: AxiosRequestConfig = {
			headers: {
				Accept: 'application/json',
				...(this.accessToken ? {'Authorization': `Bearer ${this.accessToken}`} : {}),
				...headers,
			},
			data,
			method,
			url,
			timeout: 120_000,
		};

		try {
			return await axios.request<T>(config);
		} catch (error: unknown) {
			throw new Error(`Error sending request to FileStorage: ${error}`);
		}
	}
}
