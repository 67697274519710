import { type FormEvent, useEffect } from "react";
import type {
	FunctionComponent,
	OtherIncome,
} from "../../../../../common/types";
import {
	useAppDispatch,
	useAppSelector,
} from "../../../../../hooks/redux-hooks";
import Button from "../../../../ui/button/Button";
import {
	setAllowanceAmount,
	setAllowanceForSixMonths,
	setAnnualBaseSalaryAmount,
	setBonusesLastTwelveMonthsPercentage,
	setBonusesPriorTwelveMonthsPercentage,
	setCommissionsLastTwelveMonthsPercentage,
	setCommissionsOrBonuses,
	setCommissionsPriorTwelveMonthsPercentage,
	setOtherIncomeAllowed,
	setOtherIncomes,
	setOvertimeAmount,
	setOvertimeForSixMonths,
	setOvertimeOrShiftAllowance,
	setPreviousFinanceActiveForm,
} from "../../../../../store/slices/form-slice";
import { zodResolver } from "@hookform/resolvers/zod";

import { type Control, useForm } from "react-hook-form";
import {
	CommissionsOrBonusesOptions,
	OtherIncomeAllowedOptions,
	OvertimeOrShiftAllowanceOptions,
} from "../../../../utils/BootList";
import RadioGroup from "../../../radio-group/RadioGroup";
import { EmployeeIncomeSchema } from "./EmployeeIncomeSchema";
import CheckboxInput from "../../../checkbox-input/CheckboxInput";
import OtherIncomesForm from "../../../other-incomes/OtherIncomes";
import { navigateToJointStep } from "../../../../../store/slices/joint-stepper-slice";
import { navigateToStep } from "../../../../../store/slices/stepper-slice";
import {
	mapEmploymentTypeToIncomeType,
	selectUpdateIncomePayload,
} from "../../../../../store/selectors/update-income-payload";

import {
	FrequencyType,
	type IncomeDetailsType,
	type EmploymentType,
	IncomeType,
} from "../../../../../services/apis/update-income.schema";
import {
	ApplicationType,
	SubStep,
} from "../../../../../services/apis/create-application.schema";
import { setFormLoading } from "../../../../../store/slices/loader-slice";
import { setToast } from "../../../../../store/slices/toast-slice";
import FormLayout from "../../../../ui/form/Form";
import DollarInput from "../../../dollar-input/DollarInput";
import useTrackPageViewOnMount from "../../../../../hooks/use-track-on-mount";
import { useUpdateIncomeEmployment } from "../../../../../services/apis/origination/income-employment";
import { captureException } from "../../../../../services/sentry";

export type YourEmploymeeIncomeFormType = {
	annualBaseSalaryAmount: number;
	overtimeOrShiftAllowance: string;
	allowanceAmount: number;
	allowanceForSixMonths: boolean;
	overtimeAmount: number;
	overtimeForSixMonths: boolean;
	commissionsOrBonuses: string;
	commissionsLastTwelveMonthsPercentage: number;
	commissionsPriorTwelveMonthsPercentage: number;
	bonusesLastTwelveMonthsPercentage: number;
	bonusesPriorTwelveMonthsPercentage: number;
	otherIncomeAllowed: string;
	otherIncomes: Array<OtherIncome>;
};

const setIncomeDetails = (
	data: Partial<YourEmploymeeIncomeFormType>,
	employmentType: EmploymentType
) => {
	console.log("Employment Type", employmentType);
	const incomeDetails: Array<IncomeDetailsType> = [];
	if (data.annualBaseSalaryAmount && data.annualBaseSalaryAmount > 0) {
		const baseSalary: IncomeDetailsType = {
			incomeType: mapEmploymentTypeToIncomeType(
				employmentType
			),
			currentIncomeAmount: data.annualBaseSalaryAmount,
			priorIncomeAmount: 0,
			receivedForLastSixMonths: false,
			frequency: FrequencyType.Annually,
		};
		incomeDetails.push({ ...baseSalary });
	}
	if (data.overtimeOrShiftAllowance === "true") {
		if (data.allowanceAmount && data.allowanceAmount > 0) {
			const shiftAllowance: IncomeDetailsType = {
				incomeType: IncomeType.OvertimeShiftAllowance,
				currentIncomeAmount: data.allowanceAmount,
				priorIncomeAmount: 0,
				receivedForLastSixMonths: data.allowanceForSixMonths!,
				frequency: FrequencyType.Other,
			};
			incomeDetails.push({ ...shiftAllowance });
		}
		if (data.overtimeAmount && data.overtimeAmount > 0) {
			const overtime: IncomeDetailsType = {
				incomeType: IncomeType.Overtime,
				currentIncomeAmount: data.overtimeAmount,
				priorIncomeAmount: 0,
				receivedForLastSixMonths: data.overtimeForSixMonths!,
				frequency: FrequencyType.Other,
			};
			incomeDetails.push({ ...overtime });
		}
	}
	if (data.commissionsOrBonuses === "true") {
		if (
			(data.commissionsLastTwelveMonthsPercentage &&
				data.commissionsLastTwelveMonthsPercentage > 0) ||
			(data.commissionsPriorTwelveMonthsPercentage &&
				data.commissionsPriorTwelveMonthsPercentage > 0)
		) {
			const commissionsOrBonuses: IncomeDetailsType = {
				incomeType: IncomeType.Commission,
				currentIncomeAmount: data.commissionsLastTwelveMonthsPercentage || 0,
				priorIncomeAmount: data.commissionsPriorTwelveMonthsPercentage || 0,
				receivedForLastSixMonths: false,
				frequency: FrequencyType.Annually,
			};
			incomeDetails.push({ ...commissionsOrBonuses });
		}
		if (
			(data.bonusesLastTwelveMonthsPercentage &&
				data.bonusesLastTwelveMonthsPercentage > 0) ||
			(data.bonusesPriorTwelveMonthsPercentage &&
				data.bonusesPriorTwelveMonthsPercentage > 0)
		) {
			const bonuses: IncomeDetailsType = {
				incomeType: IncomeType.Bonus,
				currentIncomeAmount: data.bonusesLastTwelveMonthsPercentage || 0,
				priorIncomeAmount: data.bonusesPriorTwelveMonthsPercentage || 0,
				receivedForLastSixMonths: false,
				frequency: FrequencyType.Annually,
			};
			incomeDetails.push({ ...bonuses });
		}
	}
	if (
		data.otherIncomeAllowed === "true" &&
		data.otherIncomes &&
		data.otherIncomes.length > 0
	) {
		for (const income of data.otherIncomes) {
			const otherIncome: IncomeDetailsType = {
				incomeType: income.incomeType.value as IncomeType, // Use the IncomeType enum here
				currentIncomeAmount: income.estimatedAmount!,
				priorIncomeAmount: 0,
				receivedForLastSixMonths: false,
				frequency: income.frequency.value as FrequencyType,
			};
			incomeDetails.push({ ...otherIncome });
		}
	}
	return incomeDetails;
};
const YourEmployeeIncome = ({
	isJoint,
}: {
	isJoint?: boolean;
}): FunctionComponent => {
	const dispatch = useAppDispatch();
	const [updateIncome, { isLoading }] = useUpdateIncomeEmployment();
	const updateIncomePayload = useAppSelector(selectUpdateIncomePayload);
	const formState = useAppSelector((state) => state.form);
	const YourEmploymeeIncomeDefaultValues: Partial<YourEmploymeeIncomeFormType> =
		{
			annualBaseSalaryAmount: formState.annualBaseSalaryAmount,
			overtimeOrShiftAllowance: formState.overtimeOrShiftAllowance,
			allowanceAmount: formState.allowanceAmount,
			allowanceForSixMonths: formState.allowanceForSixMonths,
			overtimeAmount: formState.overtimeAmount,
			overtimeForSixMonths: formState.overtimeForSixMonths,
			commissionsOrBonuses: formState.commissionsOrBonuses,
			commissionsLastTwelveMonthsPercentage:
				formState.commissionsLastTwelveMonthsPercentage,
			commissionsPriorTwelveMonthsPercentage:
				formState.commissionsPriorTwelveMonthsPercentage,
			bonusesLastTwelveMonthsPercentage:
				formState.bonusesLastTwelveMonthsPercentage,
			bonusesPriorTwelveMonthsPercentage:
				formState.bonusesPriorTwelveMonthsPercentage,
			otherIncomeAllowed: formState.otherIncomeAllowed,
			otherIncomes: undefined,
		};
	const {
		register,
		control,
		handleSubmit,
		watch,
		formState: { errors },
		setValue,
	} = useForm({
		resolver: zodResolver(EmployeeIncomeSchema),
		defaultValues: { ...YourEmploymeeIncomeDefaultValues },
	});

	useTrackPageViewOnMount({
		page: "Your Finances",
		subPage: "Your Income",
	});
	const backToPreviousState = (): void => {
		if (isJoint) {
			dispatch(
				navigateToJointStep({
					step: "financeStep",
					subStep: SubStep.YourEmployment,
				})
			);
		} else {
			dispatch(
				navigateToStep({
					step: "financeStep",
					subStep: SubStep.YourEmployment,
				})
			);
		}
	};
	const saveData = async (data: Partial<YourEmploymeeIncomeFormType>) => {
		dispatch(setAnnualBaseSalaryAmount(data.annualBaseSalaryAmount!));
		dispatch(setOvertimeOrShiftAllowance(data.overtimeOrShiftAllowance!));
		dispatch(setAllowanceAmount(data.allowanceAmount!));
		dispatch(setAllowanceForSixMonths(data.allowanceForSixMonths!));
		dispatch(setOvertimeAmount(data.overtimeAmount!));
		dispatch(setOvertimeForSixMonths(data.overtimeForSixMonths!));
		dispatch(setCommissionsOrBonuses(data.commissionsOrBonuses!));
		dispatch(
			setCommissionsLastTwelveMonthsPercentage(
				data.commissionsLastTwelveMonthsPercentage!
			)
		);
		dispatch(
			setCommissionsPriorTwelveMonthsPercentage(
				data.commissionsPriorTwelveMonthsPercentage!
			)
		);
		dispatch(
			setBonusesLastTwelveMonthsPercentage(
				data.bonusesLastTwelveMonthsPercentage!
			)
		);
		dispatch(
			setBonusesPriorTwelveMonthsPercentage(
				data.bonusesPriorTwelveMonthsPercentage!
			)
		);
		dispatch(setOtherIncomeAllowed(data.otherIncomeAllowed!));
		dispatch(setOtherIncomes(data.otherIncomes!));
		dispatch(setPreviousFinanceActiveForm(SubStep.YourEmployeeIncome));
		if (formState.applicationType === ApplicationType.Joint && !isJoint) {
			dispatch(
				navigateToStep({
					step: "financeStep",
					subStep: SubStep.YourJointBorrowerIncome,
				})
			);
		} else {
			try {
				await updateIncome({
					...updateIncomePayload,
					receivedCommissionOrBonus: data.commissionsOrBonuses === "true",
					receivedOverTimeOrAllowance: data.overtimeOrShiftAllowance === "true",
					hasAnyOtherIncome: data.otherIncomeAllowed === "true",
					incomeDetails: setIncomeDetails(
						data,
						formState.currentEmploymentType.value as EmploymentType
					),
					applicationSubStep: isJoint
						? SubStep.YourAssets
						: SubStep.YourHouseholdExpenses,
				}).unwrap();

				if (isJoint) {
					dispatch(
						navigateToJointStep({
							step: "financeStep",
							subStep: SubStep.YourAssets,
						})
					);
				} else {
					dispatch(
						navigateToStep({
							step: "financeStep",
							subStep: SubStep.YourHouseholdExpenses,
						})
					);
				}
			} catch (error) {
				captureException(new Error("Error saving income"), {data: {error}});
				dispatch(
					setToast({
						open: true,
						type: "error",
						title: "Error",
						description:
							"An error occurred while saving your employment income.",
					})
				);
			}
		}
	};

	useEffect(() => {
		dispatch(setFormLoading(isLoading));
		return () => {
			dispatch(setFormLoading(false));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);
	register("otherIncomeAllowed", {
		onChange: (event: FormEvent<HTMLInputElement>) => {
			if (event.currentTarget.value === "false") {
				setValue("otherIncomes", undefined);
			}
		},
	});
	const header = (
		<h1
			className="text-primary text-[37.9px] font-normal"
			aria-labelledby="Your Income"
		>
			Your Income
		</h1>
	);
	const content = (
		<div className="flex flex-col gap-9">
			<DollarInput
				name="annualBaseSalaryAmount"
				control={control}
				type="numeric"
				value={formState.annualBaseSalaryAmount}
				error={
					errors.annualBaseSalaryAmount && errors.annualBaseSalaryAmount.message
				}
				label="What is your annual base salary, before tax, excluding super? (Excluding overtime, allowances, commissions and bonus)"
				iconPrefix={<i className="icon-dollar" />}
				iconSuffix={<i className="flex shrink-0">p.a.</i>}
				placeholder="Salary amount"
				onValueChange={(value) => {
					setValue("annualBaseSalaryAmount", value);
				}}
			/>
			<RadioGroup
				name="overtimeOrShiftAllowance"
				radioOptions={OvertimeOrShiftAllowanceOptions}
				legend="Do you receive any overtime or shift allowance?"
				register={register}
				error={
					errors.overtimeOrShiftAllowance &&
					errors.overtimeOrShiftAllowance.message
				}
			/>
			{watch("overtimeOrShiftAllowance") === "true" && (
				<>
					<h4 className="text-primary text-lg font-normal">
						Over the last 6 months, I've earned on average:
					</h4>
					<div className="flex flex-col md:flex-row gap-4">
						<label className="text-primary text-base font-normal mb-2 min-w-[100px]">
							Allowances
						</label>
						<div className="flex flex-col gap-2 w-full">
							<DollarInput
								name="allowanceAmount"
								control={control}
								type="numeric"
								value={formState.allowanceAmount}
								error={errors.allowanceAmount && errors.allowanceAmount.message}
								label=""
								iconPrefix={<i className="icon-dollar" />}
								iconSuffix={<span className="flex shrink-0">monthly</span>}
								placeholder="Amount"
								onValueChange={(value) => {
									setValue("allowanceAmount", value);
								}}
							/>
							<CheckboxInput
								label="I've received this regularly for at least 6 months."
								name="allowanceForSixMonths"
								checked={false}
								register={register}
								size="small"
								checkPosition="start"
							/>
						</div>
					</div>
					<div className="flex flex-col md:flex-row gap-4">
						<label className="text-primary text-base font-normal mb-2 min-w-[100px]">
							Overtime
						</label>
						<div className="flex flex-col gap-2 w-full">
							<DollarInput
								name="overtimeAmount"
								control={control}
								type="numeric"
								value={formState.overtimeAmount}
								error={errors.overtimeAmount && errors.overtimeAmount.message}
								label=""
								iconPrefix={<i className="icon-dollar" />}
								iconSuffix={<span className="flex shrink-0">monthly</span>}
								placeholder="Amount"
								onValueChange={(value) => {
									setValue("overtimeAmount", value);
								}}
							/>
							<CheckboxInput
								label="I've received this regularly for at least 6 months."
								name="overtimeForSixMonths"
								checked={false}
								register={register}
								size="small"
								checkPosition="start"
							/>
						</div>
					</div>
				</>
			)}
			{watch("overtimeOrShiftAllowance") &&
				watch("overtimeOrShiftAllowance") !== "" && (
					<>
						<RadioGroup
							name="commissionsOrBonuses"
							radioOptions={CommissionsOrBonusesOptions}
							legend="Have you received commissions or bonuses over the last 2 years?"
							register={register}
							error={
								errors.commissionsOrBonuses &&
								errors.commissionsOrBonuses.message
							}
						/>
					</>
				)}

			{watch("commissionsOrBonuses") === "true" && (
				<>
					<div className="flex flex-col md:flex-row gap-4">
						<label className="text-primary text-base font-normal mb-2 min-w-[100px]">
							Commissions
						</label>
						<div className="flex flex-col gap-2 w-full">
							<DollarInput
								name="commissionsLastTwelveMonthsPercentage"
								control={control}
								type="numeric"
								value={formState.commissionsLastTwelveMonthsPercentage}
								error={
									errors.commissionsLastTwelveMonthsPercentage &&
									errors.commissionsLastTwelveMonthsPercentage.message
								}
								label=""
								iconPrefix={<i className="icon-dollar" />}
								iconSuffix={<span className="flex shrink-0">p.a</span>}
								placeholder="Last 12 months"
								onValueChange={(value) => {
									setValue("commissionsLastTwelveMonthsPercentage", value);
								}}
							/>
							<DollarInput
								name="commissionsPriorTwelveMonthsPercentage"
								control={control}
								type="numeric"
								value={formState.commissionsPriorTwelveMonthsPercentage}
								error={
									errors.commissionsPriorTwelveMonthsPercentage &&
									errors.commissionsPriorTwelveMonthsPercentage.message
								}
								label=""
								iconPrefix={<i className="icon-dollar" />}
								iconSuffix={<span className="flex shrink-0">p.a</span>}
								placeholder="Prior 12 months"
								onValueChange={(value) => {
									setValue("commissionsPriorTwelveMonthsPercentage", value);
								}}
							/>
						</div>
					</div>
					<div className="flex flex-col md:flex-row gap-4">
						<label className="text-primary text-base font-normal mb-2  min-w-[100px]">
							Bonuses
						</label>
						<div className="flex flex-col gap-2 w-full">
							<DollarInput
								name="bonusesLastTwelveMonthsPercentage"
								control={control}
								type="numeric"
								value={formState.bonusesLastTwelveMonthsPercentage}
								error={
									errors.bonusesLastTwelveMonthsPercentage &&
									errors.bonusesLastTwelveMonthsPercentage.message
								}
								label=""
								iconPrefix={<i className="icon-dollar" />}
								iconSuffix={<span className="flex shrink-0">p.a</span>}
								placeholder="Last 12 months"
								onValueChange={(value) => {
									setValue("bonusesLastTwelveMonthsPercentage", value);
								}}
							/>
							<DollarInput
								name="bonusesPriorTwelveMonthsPercentage"
								control={control}
								type="numeric"
								value={formState.bonusesPriorTwelveMonthsPercentage}
								error={
									errors.bonusesPriorTwelveMonthsPercentage &&
									errors.bonusesPriorTwelveMonthsPercentage.message
								}
								label=""
								iconPrefix={<i className="icon-dollar" />}
								iconSuffix={<span className="flex shrink-0">p.a</span>}
								placeholder="Prior 12 months"
								onValueChange={(value) => {
									setValue("bonusesPriorTwelveMonthsPercentage", value);
								}}
							/>
						</div>
					</div>
				</>
			)}
			{watch("commissionsOrBonuses") &&
				watch("commissionsOrBonuses") !== "" && (
					<>
						<RadioGroup
							name="otherIncomeAllowed"
							radioOptions={OtherIncomeAllowedOptions}
							legend="Do you receive any other income (excluding rental income)?"
							register={register}
							error={
								errors.otherIncomeAllowed && errors.otherIncomeAllowed.message
							}
						/>
						{watch("otherIncomeAllowed") === "true" && (
							<OtherIncomesForm
								onChange={(otherIncomes) => {
									setValue("otherIncomes", otherIncomes);
								}}
								control={
									control as Control<
										Pick<YourEmploymeeIncomeFormType, "otherIncomes">
									>
								}
								errors={errors.otherIncomes}
							/>
						)}
					</>
				)}
		</div>
	);
	const footer = (
		<div
			className="flex items-center justify-between gap-4"
			aria-labelledby="Actions wrapper"
		>
			<Button
				text="Back"
				variant="accent"
				iconPrefix={<i className="icon-arrow rotate-180" />}
				handleClick={backToPreviousState}
				isDisabled={isLoading}
			/>

			<Button
				text="Next"
				variant="primary"
				iconSuffix={<i className="icon-arrow" />}
				isDisabled={isLoading}
			/>
		</div>
	);
	return (
		<FormLayout
			header={header}
			content={content}
			footer={footer}
			onSubmit={handleSubmit(saveData)}
		/>
	);
};

export default YourEmployeeIncome;
