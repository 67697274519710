import type { CreateApplicationUserAddress, } from "@sucasa-finance/origination-trpc";
import type { AddressHistoryItem } from "../../update-details.schema";

export function mapAddressHistoryItem(from: AddressHistoryItem): CreateApplicationUserAddress {
    const {
        currentAddress,
        streetNumber,
        streetName,
        state,
        suburb,
        postalCode,
        country,
        unitNumber,
        startDate,
        endDate
    } = from;
    return {
        unit: unitNumber,
        street: streetName || '',
        number: streetNumber || '',
        city: suburb,
        country: country || 'Australia',
        state,
        isCurrentAddress: currentAddress,
        postCode: postalCode,
        startDate: startDate ? startDate.split('/').reverse().join('-') : undefined,
        endDate: endDate ? endDate.split('/').reverse().join('-') : undefined,
    }
}
