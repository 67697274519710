/* eslint-disable camelcase */
import { z } from "zod";
const requiredString = z
	.string({
		required_error: "This field is required",
		invalid_type_error: "This field is required",
	})
	.min(1, {
		message: "This field is required",
	});
export const SelfEmployeeSchema = z
	.object({
		businessName: requiredString,
		businessABN: z.union([z.number(), z.string()], {
			required_error: "Add a valid 11 digit ABN number",
			invalid_type_error: "Add a valid 11 digit ABN number",
		}),
		businessIncorporationDate: requiredString,
		businessStartDate: requiredString,
	})
	.superRefine(({ businessABN }, refinementContext) => {
		if (!businessABN || businessABN.toString().length !== 11) {
			refinementContext.addIssue({
				code: z.ZodIssueCode.custom,
				message: "Add a valid 11 digit ABN number",
				path: ["businessABN"],
			});
		}
	});
