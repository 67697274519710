import { createContext, useContext, type ReactNode, useState } from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import config from "../config";
import { DEFAULT_TOKEN_TYPE } from "../services/apis/auth.schema";
import { useLDFlags } from "./use-ld-flags";
import { NamedRoute } from "../components/utils/NamedRoutes";

interface AuthLockContextType {
  isAuthLocked: boolean;
  setAuthLocked: (locked: boolean) => void;
}

const AuthLockContext = createContext<AuthLockContextType | undefined>(undefined);

export const AuthLockProvider = ({ children }: { children: ReactNode }) => {
  const [isAuthLocked, setAuthLocked] = useState(false);
  const { auth0Login } = useLDFlags();

  return (
    <AuthLockContext.Provider value={{ isAuthLocked, setAuthLocked }}>
      {!auth0Login && children}
      {auth0Login && <Auth0Provider
        domain={config.VITE_AUTH0_LOGIN_DOMAIN}
        clientId={config.VITE_AUTH0_API_CLIENT_ID}
        authorizationParams={{
          audience: "https://api.sucasa.com.au/",
          scope: "profile email read:current_user offline_access",
          connection: DEFAULT_TOKEN_TYPE,
          // eslint-disable-next-line camelcase
          redirect_uri: new URL(`/#${NamedRoute.authCallback}`, window.location.origin).toString()
        }}>
        {children}
      </Auth0Provider>}
    </AuthLockContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useAuthLock = () => {
  const context = useContext(AuthLockContext);
  if (context === undefined) {
    throw new Error("useAuthLock must be used within an AuthLockProvider");
  }
  return context;
};
