import { Dialog, Transition } from "@headlessui/react";
import { Fragment, type ReactNode } from "react";

interface ModalProps {
	content: ReactNode;
	isOpen: boolean;
	onClose: () => void;
}
export const ModalChat = ({
	content,
	isOpen,
	onClose,
}: ModalProps) => {
	return (
		<>
			<Transition appear show={isOpen} as={Fragment}>
				<Dialog as="div" className="relative z-50" onClose={onClose}>
					<div className="fixed max-h-screen inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center md:justify-end text-center">
							<Transition.Child
								as={Fragment}
								enter="transition ease-in duration-300 transform"
								enterFrom="translate-x-full"
								enterTo="translate-x-0"
								leave="transition ease-in duration-300 transform"
								leaveFrom="translate-x-0"
								leaveTo="translate-x-full"
							>
								<Dialog.Panel className="w-full md:w-1/3 transform overflow-hidden bg-white text-left align-middle shadow-xl transition-all">
									{content}
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
	);
};
