import { legacy } from "../../api";
import { origination } from "../../origination";
import { useMapJointApplicantInviteRequest } from "./mapping/invite";
import { useOriginationCallback, useOriginationMutationResult } from "./mutation";

export function useJointApplicantInvite() {
    const [invokeOriginal, originalMutation] = legacy.useInviteJointApplicantMutation();
    const mutation = origination.application.inviteJointApplicant.useMutation();
    const mapRequest = useMapJointApplicantInviteRequest();
    const create = useOriginationCallback(
        invokeOriginal,
        mutation.mutateAsync,
        mapRequest,
        () => void 0,
    )
    const mutationResult = useOriginationMutationResult(originalMutation, mutation);
    return [
        create,
        mutationResult
    ] as const;
}