import { useCallback, useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { type SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { toast } from "react-toastify";
import { useAcceptInviteV2Mutation } from "../../../services/api";
import { useAppDispatch } from "../../../hooks/redux-hooks";
import Button from "../../ui/button/Button";
import { setFormLoading } from "../../../store/slices/loader-slice";
import { setVerificationToken } from "../../../store/slices/auth-slice";
import { setEmail, setMobile } from "../../../store/slices/form-slice";
import { DEFAULT_TOKEN_TYPE } from "../../../services/apis/auth.schema";
import { getDecodedToken } from "../../../lib/utils";
import { captureException } from "../../../services/sentry";

const requestYourPinSchema = z
	.object({
		email: z.string(),
		mobile: z.string(),
		inviteToken: z.string(),
	});

type RequestYourPinSchemaType = z.infer<typeof requestYourPinSchema>;

export const RequestYourPin = ({ inviteToken }: { inviteToken: string }) => {
	const dispatch = useAppDispatch();
	const [acceptInviteV2, { isLoading }] = useAcceptInviteV2Mutation();
	const decodedToken = getDecodedToken(inviteToken);
	const {
		handleSubmit,
	} = useForm<RequestYourPinSchemaType>({
		resolver: zodResolver(requestYourPinSchema),
		defaultValues: {
			email: decodedToken?.email,
			mobile: decodedToken?.mobile,
			inviteToken,
		},
	});

	const saveData = useCallback<SubmitHandler<RequestYourPinSchemaType>>(async (data) => {
		dispatch(setEmail(data.email));
		dispatch(setMobile(data.mobile));
		try {
			dispatch(setFormLoading(true));
			const response = await acceptInviteV2({
				tokenType: DEFAULT_TOKEN_TYPE,
				email: data.email,
				mobile: data.mobile,
				inviteToken: data.inviteToken,
			}).unwrap();

			dispatch(setVerificationToken(response.data.verificationToken));
		} catch (error) {
			captureException(error instanceof Error ? error : new Error("Unknown error accepting invite"), {
				data: {
					error,
				},
			});
			// @ts-expect-error need to type api responses
			toast.error(error?.data?.message || "An error occurred while accepting your invite.");
		} finally {
			dispatch(setFormLoading(false));
		}
	}, [acceptInviteV2, dispatch])

	useEffect(() => {
		if (!decodedToken?.email && !decodedToken?.mobile) {
			toast.error("Your token is invalid, please ask your primary borrower to re-send it.");
		}
	}, [decodedToken?.email, decodedToken?.mobile]);

	return (
		<form
			className="flex flex-col gap-8"
			onSubmit={handleSubmit(saveData)}
		>
			<div className="lg:mt-10">
				<Button
					type="submit"
					text="Verify my email"
					variant="primary"
					size="full"
					iconSuffix={<i className="icon-arrow" />}
					isDisabled={isLoading}
				/>
			</div>
		</form>
	);
};
