import type { FC, ReactNode } from "react";

type BoxProps = {
	children: ReactNode;
	className?: string;
};

export const Box: FC<BoxProps> = ({children, className}) => (<div className={`my-3 px-8 py-6 border bg-white border-e-4 border-b-4 border-off-black-900 ${className}`}>
	{children}
</div>);
