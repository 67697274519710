import type { FunctionComponent } from "../../../common/types";
import { useAppSelector } from "../../../hooks/redux-hooks";
import useIsMobile from "../../../hooks/use-is-mobile";
import HintCard from "../../ui/hint-card/HintCard";
import { Hint } from "../../ui/hint";

export interface HintsColumnProps {
	// defaults to true, pass false to disable sticky positioning
	sticky?: boolean;
}

const HintsColumn = ({sticky}: HintsColumnProps): FunctionComponent => {
	const uiState = useAppSelector((state) => state.ui);
	const isMobile = useIsMobile();
	
	if (isMobile) {
		return null;
	}

	return (
		<div className={sticky === false ? "" : "sticky top-36"}>
			{uiState.hints.map((hint, index) => {
				if (hint.hintType) {
					 
					const HintComponent = Hint[hint.hintType];
					return (<HintComponent key={index} />);
				}
				 
				return (<HintCard key={index} icon={hint.icon} text={hint.text} />);
			})}
		</div>
	);
};

export default HintsColumn;
