import type { stepperState } from "../../../store/slices/stepper-slice";
import type { jointStepperState } from "../../../store/slices/joint-stepper-slice";

// TODO[matej]: unify all steppers
export type StepsType = {
	id: number;
	value: keyof stepperState["steps"];
	label: string;
};

export type JointStepsType = {
	id: number;
	value: keyof jointStepperState["steps"];
	label: string;
};

export const stepsAll: Array<StepsType> = [
	{
		id: 1,
		value: "homeStep",
		label: "Your Home",
	},
	{
		id: 2,
		value: "loanStep",
		label: "Your Loan",
	},
	{
		id: 3,
		value: "detailsStep",
		label: "Your Details",
	},
	{
		id: 4,
		value: "financeStep",
		label: "Your Finances",
	},
	{
		id: 5,
		value: "confirmationStep",
		label: "Confirmation",
	},
	{
		id: 6,
		value: "uploadStep",
		label: "Upload Documents",
	},
	{
		id: 7,
		value: "assessmentStep",
		label: "Assessment",
	},
];

export const stepsJoint: Array<StepsType> = [
	{
		id: 1,
		value: "detailsStep",
		label: "Your Details",
	},
	{
		id: 2,
		value: "financeStep",
		label: "Your Finances",
	},
	{
		id: 3,
		value: "confirmationStep",
		label: "Confirmation",
	},
	{
		id: 4,
		value: "uploadStep",
		label: "Upload Documents",
	},
	{
		id: 5,
		value: "assessmentStep",
		label: "Assessment",
	},
];
