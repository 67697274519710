import { z } from "zod";

export const emailSchema = z.string().email({ message: "Invalid email address" });
export const mobileSchema = z.string().min(11, "Please enter a valid mobile number");

export const JointApplicantInvitePayloadSchema = z.object({
	firstName: z.string().min(1, "First name is required"),
	middleName: z.string().optional(),
	surname: z.string().min(1, "Surname is required"),
	title: z.string(),
	noMiddleName: z.boolean(),
	email: emailSchema,
	mobile: mobileSchema,
	applicationStep: z.string().optional(),
	applicationSubStep: z.string().optional(),
});

export type JointApplicantInviteRequest = z.infer<typeof JointApplicantInvitePayloadSchema>;
