export type RadioInputSecondaryType = {
	label: string;
	value: string | number | Array<string> | undefined;
	checked: boolean;
	onChange?: (value: string) => void;
};

const RadioInputSecondary = ({
	label,
	value,
	checked,
	onChange,
}: RadioInputSecondaryType) => {
	const optionId = `radio-option--${Math.random().toString(36).slice(2, 9)}`;

	return (
		<li
			className="w-full list-none"
			aria-labelledby={`Radio option for ${label}`}
		>
			<input
				type="radio"
				id={optionId}
				value={value}
				checked={checked}
				className="hidden peer"
				onChange={(event) => {
					onChange && onChange(event.currentTarget.value);
				}}
			/>
			<label
				tabIndex={0}
				htmlFor={optionId}
				className="peer-checked:bg-primary peer-checked:text-white bg-white w-full p-4 text-sm flex items-center justify-center transition-all cursor-pointer hover:opacity-75"
			>
				{label}
			</label>
		</li>
	);
};

export default RadioInputSecondary;
