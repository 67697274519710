import { type FC, useCallback, useMemo, useRef, useState } from "react";
import {
	ApplicationStep,
	ApplicationSubStep,
	ApplicationUserRole,
	VerificationCaseType,
	VerificationStatus,
} from "@sucasa-finance/origination-trpc";
import type { VerificationCase } from "../../../../services/apis/origination/types";
import { origination } from "../../../../services/origination";
import Button from "../../../ui/button/Button";
import Modal from "../../modal/Modal";
import { ModalChat } from "../../modal/ModalChat";
import { BoxPreApproval } from "./components/BoxPreApproval";
import { BoxUpload } from "./components/BoxUpload";
import { Chat } from "./components/Chat";
import { Instructions, type InstructionsRef } from "./components/Instructions";
import { BiometricsModal } from "./components/BiometricsModal";
import { BoxCompleted } from "./components/BoxCompleted";
import { BoxJointBorrower } from "./components/BoxJointBorrower";
import { useCasesForApplicationId } from "../../../../hooks/origination/use-cases-for-application-id";

const caseOrder = [
	VerificationCaseType.CreditCheck,
	VerificationCaseType.Serviceability,
	VerificationCaseType.Biometrics,
	VerificationCaseType.DriversLicence,
	VerificationCaseType.Passport,
	VerificationCaseType.PassportForeign,
	VerificationCaseType.VisaGrant,
	VerificationCaseType.MedicareCard,
	VerificationCaseType.PersonalDetails,
	VerificationCaseType.HouseholdQuery,
	VerificationCaseType.HouseholdQuerySolo,
	VerificationCaseType.Payslips,
	VerificationCaseType.PayslipsCommission,
	VerificationCaseType.PayslipsBonus,
	VerificationCaseType.SalaryCredits,
	VerificationCaseType.IncomeStatement,
	VerificationCaseType.IncomeStatementLastFY,
	VerificationCaseType.IncomeStatementLast2ndFY,
	VerificationCaseType.EmploymentContract,
	VerificationCaseType.IndividualTaxReturn,
	VerificationCaseType.IndividualTaxReturnLast2ndFY,
	VerificationCaseType.IndividualNoticeOfAssessment,
	VerificationCaseType.IndividualNoticeOfAssessmentLast2ndFY,
	VerificationCaseType.BasStatements,
	VerificationCaseType.SelfEmployedIncomeDocuments,
	VerificationCaseType.EmploymentLetter,
	VerificationCaseType.EmploymentLetterSabbatical,
	VerificationCaseType.EmploymentLetterRevisedHours,
	VerificationCaseType.PayslipDeductions,
	VerificationCaseType.Directorships,
	VerificationCaseType.OverseasCredit,
	VerificationCaseType.AccountConduct,
	VerificationCaseType.StatutoryDeclaration,
	VerificationCaseType.CreditFile,
	VerificationCaseType.CreditFileConduct,
	VerificationCaseType.CreditFileNotMatching,
	VerificationCaseType.Alimony,
	VerificationCaseType.ChildSupport,
	VerificationCaseType.ChildSupportBankStatements,
	VerificationCaseType.CreditCardStatement,
	VerificationCaseType.HomeLoanStatement,
	VerificationCaseType.HomeLoanStatementRefinancing,
	VerificationCaseType.CarLoanStatement,
	VerificationCaseType.PersonalLoanStatement,
	VerificationCaseType.OffshoreLiabilityStatement,
	VerificationCaseType.Hecs,
	VerificationCaseType.NovatedLeaseStatement,
	VerificationCaseType.CreditCardClosure,
	VerificationCaseType.PersonalLoanClosure,
	VerificationCaseType.CarLoanClosure,
	VerificationCaseType.HomeLoanClosure,
	VerificationCaseType.GiftAndLoanDeclaration,
	VerificationCaseType.CouncilRates,
	VerificationCaseType.ContractOfSale,
	VerificationCaseType.RentalStatement,
	VerificationCaseType.RentalAppraisal,
	VerificationCaseType.FundsToComplete,
	VerificationCaseType.ContractOfSaleNewPurchase,
	VerificationCaseType.DepositReceipt,
	VerificationCaseType.TitleSearch,
];

// orders current users cases to the top of the list and sorts by caseType and then puts the rest in order 
const compareCases = (currentUserId: number) => (a: VerificationCase, b: VerificationCase) => {
	// Ensure the currentUserId is top of the list
	if (a.user.id === currentUserId && b.user.id !== currentUserId) return -1;
	if (b.user.id === currentUserId && a.user.id !== currentUserId) return 1;

	// If both cases belong to the same user, compare by caseType
	if (a.user.id === b.user.id) {
		return caseOrder.indexOf(a.caseType) - caseOrder.indexOf(b.caseType);
	}

	// Otherwise, sort by caseType within their respective user groups
	return caseOrder.indexOf(a.caseType) - caseOrder.indexOf(b.caseType);
}

type UploadStepProps = {
	applicationId: number
}
export const UploadStep: FC<UploadStepProps> = ({applicationId}) => {
	const [chatVerificationCase, setChatVerificationCase] = useState<VerificationCase>();
	const [biometricsVerificationCase, setBiometricsVerificationCase] = useState<VerificationCase>();
	const data = useCasesForApplicationId(applicationId);
	const [application] = origination.application.getApplicationById.useSuspenseQuery({applicationId: Number(applicationId)});
	const currentUserId = application.currentUserId;
	const secondaryApplicant = useMemo(() => application.applicants.find(itm => itm.role === ApplicationUserRole.Secondary && itm.userId !== application.currentUserId), [application]);


	const sortedCases = useMemo(() => 
		data
			// don't want to show biometrics case for other users, as we need to consent to the biometrics
			.filter(itm => itm.caseType !== VerificationCaseType.Biometrics || itm.user.id === currentUserId)
			.sort(compareCases(currentUserId)), 
		[data, currentUserId]
	);

	const activeCases = useMemo(() => sortedCases.filter(itm =>
		[VerificationStatus.Active].includes(itm.status)) || [], [sortedCases]);
	const waitingCases = useMemo(() => sortedCases.filter(itm =>
		[VerificationStatus.Waiting].includes(itm.status)) || [], [sortedCases]);
	const finishedCases = useMemo(() => sortedCases?.filter(itm => itm.status === VerificationStatus.Finished) || [], [sortedCases]);

	const numberOfActiveCases = useMemo(() =>
			activeCases.length + waitingCases.length,
		[activeCases.length, waitingCases.length]);
	const instructionsRef = useRef<InstructionsRef>(null);
	const infoClickHandler = useCallback( () => {
		instructionsRef.current?.toggle();
	}, []);

	const onModalCloseCallback = useCallback(() => {
		setChatVerificationCase(undefined);
		setBiometricsVerificationCase(undefined);
	}, [])

	const onChatOpenCallback = useCallback((caseId?: number) => {
		//TODO(janyk): send an id to the chat instead so that cache invalidation works
		setChatVerificationCase(caseId ? data.find(itm => itm.id === caseId) : undefined)
	}, [data])

	const onBiometricsOpenCallback = useCallback((caseId?: number) => {
		setBiometricsVerificationCase(caseId ? data.find(itm => itm.id === caseId) : undefined)
	}, [data])

	
	

	const isJointApplicantAcceptedInvite = useMemo(() => {
		return Boolean(
			// If there is a step other than invited, something is happening.
			secondaryApplicant?.applicationSteps.find(item => item.step === ApplicationStep.Invited && item.subStep !== ApplicationSubStep.Invited)
		);
	}, [secondaryApplicant])

	// not sure how did we end up on this page while the secondary applicant is accepting an invite?
	const showOutstandingRequests = useMemo(() =>
			numberOfActiveCases === 0 && (!secondaryApplicant || isJointApplicantAcceptedInvite),
		[numberOfActiveCases, isJointApplicantAcceptedInvite, secondaryApplicant]);

	return (
		<>
			{showOutstandingRequests && <>
				<h1
					className="flex flex-row text-primary text-[37.9px] font-normal"
					aria-labelledby="Confirm Step Title"
				>
					<div>Outstanding Requests</div>
				</h1>
				<BoxCompleted />
			</>}
			{!showOutstandingRequests && <>
				<h1
					className="flex flex-row text-primary text-[37.9px] font-normal"
					aria-labelledby="Confirm Step Title"
				>
					<div>Outstanding Requests</div>
					{numberOfActiveCases > 0 && <Button
						className="ms-2 w-8-h-8 hover:bg-none"
						text={<i className="icon-question text-white text-sm bg-off-black-900 rounded-full" />}
						variant="link"
						textAlign="center"
						handleClick={infoClickHandler}
						disableHover
					/>}
				</h1>
				{numberOfActiveCases > 0 && <Instructions ref={instructionsRef} />}
				{application?.isJointApplicantInvited && secondaryApplicant && <BoxJointBorrower
					applicant={secondaryApplicant}
					applicationId={Number(applicationId)}
					isAccepted={isJointApplicantAcceptedInvite}
				/>}
				{activeCases.map((itm, index) => (
					<BoxUpload
						key={index}
						verificationCase={itm}
						onChatOpen={onChatOpenCallback}
						onBiometricsOpen={onBiometricsOpenCallback}
					/>
				))}
				{waitingCases.map((itm, index) => (
					<BoxUpload
						key={index}
						verificationCase={itm}
						onChatOpen={onChatOpenCallback}
						onBiometricsOpen={onBiometricsOpenCallback}
					/>
				))}
				{finishedCases.map((itm, index) => (
					<BoxUpload
						key={index}
						verificationCase={itm}
					/>
				))}
			</>}
			<BoxPreApproval applicationId={applicationId} />
			<ModalChat
				isOpen={chatVerificationCase !== undefined}
				onClose={onModalCloseCallback}
				content={<Chat verificationCase={chatVerificationCase} onClose={onModalCloseCallback}  />}
			/>
			<Modal
				isOpen={biometricsVerificationCase !== undefined}
				actions={[]}
				onClose={onModalCloseCallback}
				content={<BiometricsModal verificationCase={biometricsVerificationCase} />}
			/>
		</>
	);
};
