import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "./redux-hooks";
import { selectIsUserJointApplicant, setIsJointApplicant } from "../store/slices/auth-slice";
import { useNavigate } from "react-router-dom";
import { selectIsApplicationSubmitted, setFromApplication, setUseOrigination } from "../store/slices/origination-slice";
import { Step, SubStep } from "../services/apis/create-application.schema";
import { NamedRoute } from "../components/utils/NamedRoutes";
import { selectApplication } from "../store/slices/application-slice";
import { navigateToJointStep } from "../store/slices/joint-stepper-slice";
import { navigateToStep } from "../store/slices/stepper-slice";
import { mapApplicationStepToStepper } from "./use-auth-success";
import { getDocumentsRedirect } from "../lib/utils";
import { useTracking } from "./use-tracking";
import { identifyApplication } from "../services/analytics";

export const useContinueApplication = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const {trackEvent} = useTracking();
	const application = useAppSelector(selectApplication);
	const isJointApplicant = useAppSelector(selectIsUserJointApplicant);
	const isApplicationSubmitted = useAppSelector(selectIsApplicationSubmitted);
	const authToken = useAppSelector((state) => state.auth.token);

	const continueApplication = useCallback(() => {
		// for legacy applications use middleware
		dispatch(setUseOrigination(false));
		if (application) {
			identifyApplication({
				appId: application?.appId,
				applicationType: application?.applicationType,
				applicantType: isJointApplicant ? 'Secondary Applicant' : 'Primary Applicant',
				applicationVersion: '1',
			})
			trackEvent("resume_application_clicked", {
				applicationId: application?.appId,
				applicationVersion: '1',
			});
			// set the middleware application params before redirecting
			dispatch(setFromApplication(application));
			dispatch(setIsJointApplicant(isJointApplicant));

			if (isJointApplicant) {
				dispatch(
					navigateToJointStep({
						step: mapApplicationStepToStepper(application?.applicationStep),
						subStep: application?.applicationSubStep as SubStep,
					})
				);
			} else {
				dispatch(
					navigateToStep({
						step: mapApplicationStepToStepper(application?.applicationStep),
						subStep: application?.applicationSubStep as SubStep,
					})
				);
			}
		}

		if (isApplicationSubmitted && authToken) {
			window.location.href = getDocumentsRedirect(authToken);
		} else {
			const isNewUser = application?.applicationStep === Step.YourDetails || application?.applicationSubStep === SubStep.Welcome;
			// TODO(janyk): if joint borrower, got to joint borrower home
			if (isJointApplicant && isNewUser) {
				navigate(NamedRoute.jointBorrowerIntro);
			} else if (isJointApplicant) {
				navigate(NamedRoute.jointBorrowerHome);
			} else {
				navigate(NamedRoute.home);
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [application, authToken, dispatch, isApplicationSubmitted, isJointApplicant, navigate])

	return {
		continueApplication
	}
};
