import type {
	FieldErrors,
	FieldValues,
	UseFormRegister,
} from "react-hook-form";
import CheckboxInputSecondary from "../../../../../checkbox-input-secondary/CheckboxInputSecondary";
import GenericInput from "../../../../../generic-input/GenericInput";
import { ReasonForRefinancing } from "../../../../../../../services/apis/create-application.schema";
import type { NewHomeLoanFormSlice } from "../../../refinancing-workflow/new-home-loan/NewHomeLoanSchema";
import ErrorMessage from "../../../../../error-message/ErrorMessage";

type LoanRefinancingFeaturesType<
	TFieldValues extends FieldValues = FieldValues,
> = {
	register: UseFormRegister<TFieldValues>;
	selectedReasonForRefinancing: Array<ReasonForRefinancing>;
	onChange: (checked: boolean, value: string) => void;
	errors?: FieldErrors<NewHomeLoanFormSlice>;
};

const LoanRefinancingFeaturesFC = <
	TFieldValues extends FieldValues = FieldValues,
>({
	register,
	selectedReasonForRefinancing,
	onChange,
	errors,
}: LoanRefinancingFeaturesType<TFieldValues>) => {
	return (
		<div className="flex flex-col gap-4">
			<legend className="text-[21.33px] leading-8">
				Select the reasons why you are looking to refinance
			</legend>
			<CheckboxInputSecondary
				label={ReasonForRefinancing.ReduceMonthlyRepayments}
				value={ReasonForRefinancing.ReduceMonthlyRepayments}
				name="requestedLoanRefinancingFeatures1"
				checked={selectedReasonForRefinancing.includes(
					ReasonForRefinancing.ReduceMonthlyRepayments
				)}
				onChange={(event) => {
					onChange(event.target.checked, event.target.value);
				}}
			/>
			<CheckboxInputSecondary
				label={ReasonForRefinancing.ImproveInterestRate}
				value={ReasonForRefinancing.ImproveInterestRate}
				name="requestedLoanRefinancingFeatures2"
				checked={selectedReasonForRefinancing.includes(
					ReasonForRefinancing.ImproveInterestRate
				)}
				onChange={(event) => {
					onChange(event.target.checked, event.target.value);
				}}
			/>
			<CheckboxInputSecondary
				label={ReasonForRefinancing.NotHappyWithExistingLender}
				value={ReasonForRefinancing.NotHappyWithExistingLender}
				name="requestedLoanRefinancingFeatures3"
				checked={selectedReasonForRefinancing.includes(
					ReasonForRefinancing.NotHappyWithExistingLender
				)}
				onChange={(event) => {
					onChange(event.target.checked, event.target.value);
				}}
			/>
			<CheckboxInputSecondary
				label={ReasonForRefinancing.MoreFlexibility}
				value={ReasonForRefinancing.MoreFlexibility}
				name="requestedLoanRefinancingFeatures4"
				checked={selectedReasonForRefinancing.includes(
					ReasonForRefinancing.MoreFlexibility
				)}
				onChange={(event) => {
					onChange(event.target.checked, event.target.value);
				}}
			/>
			<CheckboxInputSecondary
				label={ReasonForRefinancing.AdditionalCashOut}
				value={ReasonForRefinancing.AdditionalCashOut}
				name="requestedLoanRefinancingFeatures5"
				checked={selectedReasonForRefinancing.includes(
					ReasonForRefinancing.AdditionalCashOut
				)}
				onChange={(event) => {
					onChange(event.target.checked, event.target.value);
				}}
			/>
			<CheckboxInputSecondary
				label={ReasonForRefinancing.DebtConsolidation}
				value={ReasonForRefinancing.DebtConsolidation}
				name="requestedLoanRefinancingFeatures6"
				checked={selectedReasonForRefinancing.includes(
					ReasonForRefinancing.DebtConsolidation
				)}
				onChange={(event) => {
					onChange(event.target.checked, event.target.value);
				}}
			/>
			<CheckboxInputSecondary
				label={ReasonForRefinancing.Other}
				value={ReasonForRefinancing.Other}
				name="requestedLoanRefinancingFeatures7"
				checked={selectedReasonForRefinancing.includes(
					ReasonForRefinancing.Other
				)}
				onChange={(event) => {
					onChange(event.target.checked, event.target.value);
				}}
			/>
			{!!errors?.requestedLoanRefinancingFeatures && errors?.requestedLoanRefinancingFeatures.message && <ErrorMessage message={errors?.requestedLoanRefinancingFeatures.message} />}
			
			<GenericInput
				name="requestedLoanRefinancingFeaturesOther"
				register={register}
				type="text"
				placeholder="Add Description"
				error={errors && errors.requestedLoanRefinancingFeaturesOther && errors.requestedLoanRefinancingFeaturesOther.message}
			/>
		</div>
	);
};

export default LoanRefinancingFeaturesFC;
