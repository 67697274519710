import { useCallback } from "react";
import { type CreateLiability, LiabilityType, type UpdateLiabilities } from '@sucasa-finance/origination-trpc';
import { useAppSelector } from "../../../../hooks/redux-hooks";
import type { CreditCardLiabilitySchema, HECSLiabilitySchema, LiabilitySchema, UpdateLiabilitiesRequest } from "../../update-liabilities.schema";
import { selectCurrentOriginationUser } from "../../../../store/slices/auth-slice";
import { selectFormApplicationId } from "../../../../store/slices/application-slice";
import { emptyOkResponse } from "./response";
import { mapStepAndSubStep } from "./step";

export function mapUpdateLiabilities(from: UpdateLiabilitiesRequest): CreateLiability[] {
    function fromLiabilitySchema(type: LiabilityType, from: LiabilitySchema[] | undefined) {
        return (from ?? []).map((liability): CreateLiability => ({
            type,
            name: liability.accountName,
            value: liability.loanAmount,
            debtConsolidation: liability.debtIsBeingConsolidated,
            openToClosing: liability.openToClosing,
            interestRate: liability.interestRate,
            remainingTermInMonths: liability.remainingTermInMonths,
            monthlyExpense: liability.monthlyRepayments,
        }));
    }

    function fromHECSLiabilities(from?: HECSLiabilitySchema[]) {
        return (from ?? []).map((liability): CreateLiability => ({
            type: LiabilityType.HECs,
            name: liability.accountName,
            value: liability.amount,
            debtConsolidation: liability.debtIsBeingConsolidated,
            openToClosing: liability.openToClosing,
        }));
    }

    function fromCreditCardLiabilities(from?: CreditCardLiabilitySchema[]) {
        return (from ?? []).map((liability): CreateLiability => ({
            type: LiabilityType.CreditCard,
            name: liability.accountName,
            value: liability.currentBalance,
            creditLimit: liability.cardLimit,
            debtConsolidation: liability.debtIsBeingConsolidated,
            openToClosing: liability.openToClosing,
        }));
    }

    return [
        ...fromLiabilitySchema(LiabilityType.PersonalLoan, from.personalLoans),
        ...fromLiabilitySchema(LiabilityType.CarLoan, from.carLoans),
        ...fromHECSLiabilities(from.hecs),
        ...fromCreditCardLiabilities(from.creditCards)
    ]
}

export function mapLiabilitiesRequest(from: UpdateLiabilitiesRequest, applicationId: number | string, userId: string | number | undefined): UpdateLiabilities {
    return {
        liabilities: mapUpdateLiabilities(from),
        applicationId: Number(applicationId),
        userId: Number(userId),
        ...mapStepAndSubStep(from)
    }
}

export function useMapUpdateLiabilitiesRequest() {
    const applicationId = useAppSelector(selectFormApplicationId);
    const user = useAppSelector(selectCurrentOriginationUser);
    return useCallback((from: UpdateLiabilitiesRequest) => mapLiabilitiesRequest(from, applicationId, user?.userId), [applicationId, user?.userId])
}

export function mapUpdateLiabilitiesResponse() {
    return emptyOkResponse();
}
