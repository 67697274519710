// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../index";
import type { THint } from "../../components/ui/hint";

type DynamicHint = {
	icon?: JSX.Element;
	text?: JSX.Element | string;
	hintType?: THint;
}

type UIState = {
	showLogoutModal: boolean;
	showLoginModal: boolean;
	headerHeight: number | null;
	hints: Array<DynamicHint>;
};

export const uiSlice = createSlice({
	name: "ui",
	initialState: {
		showLogoutModal: false,
		showLoginModal: false,
		headerHeight: null,
		hints: [],
	} as UIState,
	reducers: {
		setLogoutModal: (state, { payload }: PayloadAction<boolean>) => {
			state.showLogoutModal = payload;
		},
		setLoginModal: (state, { payload }: PayloadAction<boolean>) => {
			state.showLoginModal = payload;
		},
		setHeaderHeight: (state, { payload }: PayloadAction<number>) => {
			state.headerHeight = payload;
		},
		setDynamicHints: (state, { payload }: PayloadAction<Array<DynamicHint>>) => {
			state.hints = payload;
		},
		addDynamicHint: (state, { payload }: PayloadAction<THint>) => {
			if (!state.hints.some(hint => hint.hintType === payload)) {
				state.hints = [...state.hints, {hintType: payload}];
			}
		},
		removeDynamicHint: (state, { payload }: PayloadAction<THint>) => {
			state.hints = state.hints.filter(hint =>  hint.hintType !== payload);
		},
	},
});

export const {
	setLogoutModal,
	setLoginModal,
	setHeaderHeight,
	setDynamicHints,
	addDynamicHint,
	removeDynamicHint,
} = uiSlice.actions;

export default uiSlice.reducer;

export const selectHeaderHeight = (state: RootState) => state.ui.headerHeight;
