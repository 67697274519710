import type { FC } from "react";

type BottomBarItemType = {
	label: string;
	value: string;
	iconPrefix?: string;
	valueType: "text" | "phone" | "email";
};

const BottomBarItem: FC<BottomBarItemType> = (props) => {
	return (
		<div className="flex items-center gap-3">
			{props?.iconPrefix && (
				<img
					src={props?.iconPrefix}
					alt={props.label}
					className="rounded-full w-10 h-10"
				/>
			)}
			<div className="">
				<h6 className="text-white text-xs leading-3">{props.label}</h6>
				{props.valueType === "text" && (
					<p className="text-white text-sm leading-6">{props.value}</p>
				)}
				{/* {props.valueType === "phone" && (
					<a
						href={`tel:${props.value}`}
						className="text-white text-sm leading-6"
					>
						{props.value}
					</a>
				)} */}
				{props.valueType === "email" && (
					<a
						href={`mailto:${props.value}`}
						className="text-white text-sm leading-6"
					>
						{props.value}
					</a>
				)}
			</div>
		</div>
	);
};

export default BottomBarItem;
