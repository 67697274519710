import type { FieldValues, UseFormRegister } from "react-hook-form";
import type { OptionsType } from "../../../../../../common/types";
import SelectInput from "../../../../select-input/SelectInput";
import type { BuyingWorkflowLoanFormType } from "../BuyingWorkflow";

const loanPreferenceOptions: Array<OptionsType> = [
	{
		id: 1,
		label: "Lower Upfront Fees",
		value: "Lower Upfront Fees",
	},
	{
		id: 2,
		label: "Lower Interest Rate",
		value: "Lower Interest Rate",
	},
	{
		id: 3,
		label: "No Preference",
		value: "No Preference",
	},
];

type LoanPreferenceType<TFieldValues extends FieldValues = FieldValues> = {
	register: UseFormRegister<TFieldValues>;
	showQuestion: Partial<BuyingWorkflowLoanFormType>;
	errorMessage?: string;
};

const LoanPreferenceFC = <TFieldValues extends FieldValues = FieldValues>({
	register,
	showQuestion,
	errorMessage,
}: LoanPreferenceType<TFieldValues>) => {
	return (
		<div aria-labelledby="loan-preference">
			<h2 className="text-primary text-[37.9px] font-normal">
				Loan Preference
			</h2>
			<SelectInput
				name="loanPreference"
				value={
					showQuestion?.loanPreference || {
						id: 0,
						value: "",
						label: "Select an option",
					}
				}
				label="Which is most important for you and your financial situation?"
				options={loanPreferenceOptions}
				register={register}
				error={errorMessage}
			/>
		</div>
	);
};

export default LoanPreferenceFC;
