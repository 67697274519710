/* eslint-disable camelcase */

import { z } from "zod";
import { createSelector } from "@reduxjs/toolkit";
import type { RootState } from "../../../../../store";

export const YourCircumstancesSchema = z
	.object({
		hasOffshoreLiabilities: z
			.string({
				invalid_type_error: "This field is required",
				required_error: "This field is required",
			})
			.min(1, {
				message: "This field is required",
			}),
		hasOffshoreLiabilitiesExplanation: z.string().optional(),
		hasAdverseCreditHistory: z
			.string({
				invalid_type_error: "This field is required",
				required_error: "This field is required",
			})
			.min(1, {
				message: "This field is required",
			}),
		hasAdverseCreditHistoryExplanation: z.string().optional(),
		hasAnticipateChangesToCircumstances: z
			.string({
				invalid_type_error: "This field is required",
				required_error: "This field is required",
			})
			.min(1, {
				message: "This field is required",
			}),
		hasAnticipateChangesToCircumstancesExplanation: z.string().optional(),
	})
	.superRefine(
		(
			{
				hasOffshoreLiabilities,
				hasOffshoreLiabilitiesExplanation,
				hasAdverseCreditHistory,
				hasAdverseCreditHistoryExplanation,
				hasAnticipateChangesToCircumstances,
				hasAnticipateChangesToCircumstancesExplanation,
			},
			context
		) => {
			if (
				hasOffshoreLiabilities === "true" &&
				hasOffshoreLiabilitiesExplanation === ""
			) {
				context.addIssue({
					code: z.ZodIssueCode.custom,
					message: "Please provide an explanation",
					path: ["hasOffshoreLiabilitiesExplanation"],
				});
			}
			if (
				hasAdverseCreditHistory === "true" &&
				hasAdverseCreditHistoryExplanation === ""
			) {
				context.addIssue({
					code: z.ZodIssueCode.custom,
					message: "Please provide an explanation",
					path: ["hasAdverseCreditHistoryExplanation"],
				});
			}

			if (
				hasAnticipateChangesToCircumstances === "true" &&
				hasAnticipateChangesToCircumstancesExplanation === ""
			) {
				context.addIssue({
					code: z.ZodIssueCode.custom,
					message: "Please provide an explanation",
					path: ["hasAnticipateChangesToCircumstancesExplanation"],
				});
			}
		}
	);

export type YourCircumstancesFormType = z.infer<typeof YourCircumstancesSchema>;

export type YourCircumstancesFormSlice = {
	hasOffshoreLiabilities: string;
	hasOffshoreLiabilitiesExplanation?: string;
	hasAdverseCreditHistory: string;
	hasAdverseCreditHistoryExplanation?: string;
	hasAnticipateChangesToCircumstances: string;
	hasAnticipateChangesToCircumstancesExplanation?: string;
	loanPurpose?: string;
};

export const selectYourCircumstances = createSelector(
	(state: RootState) => state.form,
	(form): YourCircumstancesFormSlice => ({
		hasOffshoreLiabilities: form.hasOffshoreLiabilities,
		hasOffshoreLiabilitiesExplanation: form.hasOffshoreLiabilitiesExplanation,
		hasAdverseCreditHistory: form.hasAdverseCreditHistory,
		hasAdverseCreditHistoryExplanation: form.hasAdverseCreditHistoryExplanation,
		hasAnticipateChangesToCircumstances:
			form.hasAnticipateChangesToCircumstances,
		hasAnticipateChangesToCircumstancesExplanation:
			form.hasAnticipateChangesToCircumstancesExplanation,
		loanPurpose: form.loanPurpose,
	})
);
