import { createSelector } from "@reduxjs/toolkit";
import type { RootState } from "../index";
import type { BasicLoanLiabilitiesSchema, UpdateLiabilitiesRequest } from "../../services/apis/update-liabilities.schema";
import { SubStep, Step } from "../../services/apis/create-application.schema";
import type { LoanLiability } from "../../components/forms/steps/your-finances/your-liabilities/YourLiabilitiesSchema";

export const setLoanLiabilitiesForAPI = (formLoans: Array<LoanLiability>) => {
	const loans: BasicLoanLiabilitiesSchema = [];
	if (!formLoans) return loans;
	formLoans.forEach((loan) => {
		loans.push({
			accountName: loan.accountName,
			loanAmount: loan.loanAmount,
			interestRate: loan.interestRate,
			monthlyRepayments: loan.monthlyRepayments,
			remainingTermInMonths: Number(loan.remainingTermsInYears.value) * 12,
			openToClosing: loan.openToClosing,
			debtIsBeingConsolidated: loan.debtIsBeingConsolidated,
		});
	});
	return loans;
}
export const selectUpdateLiabilitiesPayload = createSelector(
	[(state: RootState) => state.form],
	(form) => {
		const payload: Partial<UpdateLiabilitiesRequest> = {
			applicationStep: Step.YourFinances,
			applicationSubStep: SubStep.YourCircumstances,
			creditCards: form.creditCardLiabilities,
			carLoans: setLoanLiabilitiesForAPI(form.vehicleLoansLiabilities!),
			personalLoans: setLoanLiabilitiesForAPI(form.personalLoansLiabilities!),
			hecs: form.hECsHELPLiabilities,
		};
		// Filter out undefined, null, or "" values
		Object.keys(payload).forEach((key) => {
			const payloadKey = key as keyof typeof payload;
			if (
				payload[payloadKey] === undefined ||
				payload[payloadKey] === null
			) {
				delete payload[payloadKey];
			}
		});

		return payload as UpdateLiabilitiesRequest;
	}
);
