import { useCallback } from "react";
import { toast } from "react-toastify";
import type { FunctionComponent } from "../../../common/types";
import { useAppSelector } from "../../../hooks/redux-hooks";
import { useLogOut } from "../../../hooks/use-logout";
import { useReInviteJointApplicantMutation } from "../../../services/api";
import {
	selectCanReInviteJointApplication,
} from "../../../store/slices/application-slice";
import type { User } from "../../../services/apis/auth";
import HeaderMenuDesktop from "./HeaderMenuDesktop";
import HeaderMenuMobile from "./HeaderMenuMobile";
import useIsMobile from "../../../hooks/use-is-mobile";
import { selectCurrentUser } from "../../../store/slices/auth-slice";
import { selectUseOrigination } from "../../../store/slices/origination-slice";

export interface HeaderMenuProps {
	canReInviteJointApplication: boolean;
	handleInvite: () => Promise<void>;
	handleLogout: () => void;
	currentUser: User | null;
}

const HeaderMenu = (): FunctionComponent => {
	const currentUser = useAppSelector(selectCurrentUser);
	const { handleLogout } = useLogOut();
	const isMobile = useIsMobile();
	const isOrigination = useAppSelector(selectUseOrigination);
	const [ reInviteJointApplicant ] = useReInviteJointApplicantMutation();

	const canReInviteJointApplication = useAppSelector(selectCanReInviteJointApplication);

	const handleInvite = useCallback(async() => {
		try {
			const response = await reInviteJointApplicant().unwrap();
			if (response) {
				toast.success("Invitation Sent to Joint Borrower ");
			}
		} catch {
			toast.error("An error occurred while re-inviting joint applicant.");
		}
	}, [reInviteJointApplicant]);

	return (<>
		{!isMobile && <HeaderMenuDesktop
			currentUser={currentUser}
			canReInviteJointApplication={isOrigination ? false : canReInviteJointApplication}
			handleInvite={handleInvite}
			handleLogout={handleLogout}
		/>}
		{isMobile && <HeaderMenuMobile
			currentUser={currentUser}
			canReInviteJointApplication={isOrigination ? false : canReInviteJointApplication}
			handleInvite={handleInvite}
			handleLogout={handleLogout}
		/>}
	</>);
};

export default HeaderMenu;
