import { z } from "zod";

export enum OauthTokenType {
	Sms = "sms",
	Email = "email",
}

export const DEFAULT_TOKEN_TYPE = OauthTokenType.Email;

const userOtpTokenSchemaBase = z.object({
	tokenType: z.nativeEnum(OauthTokenType),
	email: z.string().email().optional(),
	mobile: z.string().optional(),
})

export const acceptInviteV2Schema = userOtpTokenSchemaBase
	.extend({
		inviteToken: z.string(),
	}).superRefine((data, context) => {
		if (
			data.tokenType === OauthTokenType.Email &&
			(!data.email)
		) {
			context.addIssue({
				path: ["email"],
				message:
					"Email is required if tokenType='email'",
				code: "custom",
			});
		}
		if (
			data.tokenType === OauthTokenType.Sms &&
			(!data.mobile)
		) {
			context.addIssue({
				path: ["mobile"],
				message:
					"Mobile is required if tokenType='sms'",
				code: "custom",
			});
		}
	});
export type AcceptInviteV2Request = z.infer<typeof acceptInviteV2Schema>;

export const userSendOtpTokenSchema = userOtpTokenSchemaBase
	.superRefine((data, context) => {
		if (
			data.tokenType === OauthTokenType.Email &&
			(!data.email)
		) {
			context.addIssue({
				path: ["email"],
				message:
					"Email is required if tokenType='email'",
				code: "custom",
			});
		}
		if (
			data.tokenType === OauthTokenType.Sms &&
			(!data.mobile)
		) {
			context.addIssue({
				path: ["mobile"],
				message:
					"Mobile is required if tokenType='sms'",
				code: "custom",
			});
		}
	});
export type UserSendOtpTokenRequest = z.infer<typeof userSendOtpTokenSchema>;
export interface  UserSendOtpTokenResponse {
	data: {
		verificationToken: string;
		message?: string;
	};
}
export const userResendOtpTokenSchema = z.object({
	verificationToken: z.string(),
});
export type UserResendOtpTokenRequest = z.infer<typeof userResendOtpTokenSchema>;

export const userVerifyOtpTokenSchema = userResendOtpTokenSchema.extend({
	token: z.string(),
});
export type UserVerifyOtpTokenRequest = z.infer<typeof userVerifyOtpTokenSchema>;
