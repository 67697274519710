import type { BasicAsset, UpdateAssetsRequest } from "../../update-assets.schema";
import { type CreateAsset, AssetType, type UpdateAssets, type CreateLiability } from "@sucasa-finance/origination-trpc"
import { emptyOkResponse } from "./response";
import { useCallback } from "react";
import { selectFormApplicationId } from "../../../../store/slices/application-slice";
import { selectCurrentOriginationUser } from "../../../../store/slices/auth-slice";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import { mapStepAndSubStep } from "./step";
import { loanOnPropertyTypeMapping } from "./enum-mapping";
import { formatDate } from "./date";

export function mapUpdateAssets(from: UpdateAssetsRequest): Array<CreateAsset> {
    function fromBasicAssets<A extends BasicAsset>(type: AssetType, from: Array<A> | undefined, map?: (asset: A) => Partial<CreateAsset>) {
        return (from ?? []).map((asset): CreateAsset => ({
            type,
            name: asset.name,
            value: asset.value,
            ...map?.(asset)
        }));
    }

    function fromVehicles(from: UpdateAssetsRequest) {
        return fromBasicAssets(AssetType.Car, from.car, ({ year, make, model }) => ({
            assetYear: `${year}`,
            make,
            model,
        }))
    }

    function fromInvestmentProperties({ investmentProperty }: UpdateAssetsRequest): Array<CreateAsset> {
        return (investmentProperty || []).map((property): CreateAsset => ({
            type: AssetType.InvestmentProperty,
            name: property.address,
            value: property.propertyValue,
            monthlyExpense: property.monthlyRentalExpenses,
            monthlyIncome: property.monthlyRentalIncome,
            primaryOwnership: property.primaryApplicantOwnership,
            secondaryOwnership: property.jointApplicantOwnership,
            liabilities: property.loans?.map((loan): CreateLiability => ({
                type: loanOnPropertyTypeMapping[loan.interestType],
                name: property.address,
                value: loan.amount,
                interestRate: loan.interestRate,
                monthlyExpense: loan.monthlyRepayment,
                fixedRateExpiryDate: formatDate(loan.fixedRateExpiryDate)
            }))
        }))
    }

    function fromShares(from: UpdateAssetsRequest) {
        return fromBasicAssets(AssetType.Shares, from.shares, ({ timeOwnedInMonths }) => ({
            timeOwnedInMonths
        }))
    }

    return [
        ...fromVehicles(from),
        ...fromInvestmentProperties(from),
        ...fromBasicAssets(AssetType.OtherAssets, from.otherAssets),
        ...fromBasicAssets(AssetType.Savings, from.savings),
        ...fromBasicAssets(AssetType.Superannuation, from.superannuation),
        ...fromShares(from)
    ]
}

export function mapUpdateAssetsRequest(from: UpdateAssetsRequest, applicationId: string | number, userId: string | number | undefined): UpdateAssets {
    return {
        ...mapStepAndSubStep(from),
        assets: mapUpdateAssets(from),
        applicationId: Number(applicationId),
        userId: Number(userId)
    }
}

export function useMapUpdateAssetsRequest() {
    const applicationId = useAppSelector(selectFormApplicationId);
    const user = useAppSelector(selectCurrentOriginationUser);
    return useCallback((from: UpdateAssetsRequest) => mapUpdateAssetsRequest(from, applicationId, user?.userId), [applicationId, user?.userId])
}

export function mapUpdateAssetsResponse() {
    return emptyOkResponse();
}
