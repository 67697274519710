import type { FC } from "react";
import HintCard from "../hint-card/HintCard";

export const LoanHint: FC = () => (<HintCard
	icon={<i className="icon-asterisk" />}
	text={
		<>
			You can add multiple loans if you’ve split your loan across
			fixed and variable terms.
			<br />
			<br />
			If you’ve only got one loan, provide those details and continue.
		</>
	}
/>);
