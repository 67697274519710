import moment from "moment";

export const formatDateCalendar = (date?: string | Date) => moment(date).calendar(null,{
	lastDay : '[Yesterday]',
	sameDay : '[Today]',
	nextDay : '[Tomorrow]',
	lastWeek : '[last] dddd',
	nextWeek : 'dddd',
	sameElse : 'L'
});
