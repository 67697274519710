import type { FC } from "react";
import HintCard from "../../ui/hint-card/HintCard";
import { CaseSummaryHeader } from "./CaseSummaryHeader";
import { CaseSummaryContents } from "./CaseSummaryContents";
import { useCasesForApplicationId } from "../../../hooks/origination/use-cases-for-application-id";
import { CaseSummaryProgress } from "./CaseSummaryProgress";

export interface CaseSummaryProps {
    applicationId: number;
    className?: string;
}

export const CaseSummary: FC<CaseSummaryProps> = ({applicationId, className}) => {
	const data = useCasesForApplicationId(applicationId);
	if (data.length === 0) {
        return null;
    }
    return (
        <HintCard className={`flex flex-col w-full gap-0 p-8 ${className ?? ""}`} noGap>
            <CaseSummaryHeader applicationId={applicationId} />
            <CaseSummaryProgress applicationId={applicationId} />
            <CaseSummaryContents applicationId={applicationId} />
        </HintCard>
    );
}
