import { ApplicationStep, type ApplicationSubStep, applicationStepAllowedSubSteps } from "@sucasa-finance/origination-trpc";
import { Step, SubStep } from "../../create-application.schema";
import {
    applicationStepMapping,
    applicationSubStepMapping,
    reverseApplicationStepMapping,
    reverseApplicationSubStepMapping,
} from "./enum-mapping";

export interface StepAndSubStep {
    applicationStep?: Step | string;
    applicationSubStep?: SubStep | string;
}

export interface ApplicationStepAndSubStep {
    step?: ApplicationStep;
    subStep?: ApplicationSubStep;
}

export const mapStepAndSubStep = ({ applicationStep: sourceStep, applicationSubStep: sourceSubStep }: StepAndSubStep): ApplicationStepAndSubStep => {
    if (!sourceStep || !sourceSubStep) return {};
    const step = applicationStepMapping[sourceStep];
    const subStep = applicationSubStepMapping[sourceSubStep];
    if (!step || !subStep) return {};
    const allowedSubSteps = applicationStepAllowedSubSteps[step];
    const isAllowed = allowedSubSteps.includes(subStep);
    if (!isAllowed) {
        console.warn(`Incorrect step mapping in API vs UI, source: ${sourceStep}/${sourceSubStep}, target: ${step}/${subStep}, allowed: ${allowedSubSteps.join(', ')}`);
        return {};
    }
    return {
        step,
        subStep
    }
}

export const mapReverseStepAndSubStep = ({ step: sourceStep, subStep: sourceSubStep }: ApplicationStepAndSubStep): StepAndSubStep => {
    if (!sourceStep || !sourceSubStep) return {};
    let applicationStep = reverseApplicationStepMapping[sourceStep];
    let applicationSubStep = reverseApplicationSubStepMapping[sourceSubStep];
    if (sourceStep === ApplicationStep.NotInRemit) {
        applicationStep = Step.YourDetails;
        applicationSubStep = SubStep.NotInRemit;
    }
    if (sourceStep === ApplicationStep.Invited) {
        applicationStep = Step.YourDetails;
        applicationSubStep = SubStep.Welcome;
    }

    return {
        applicationStep,
        applicationSubStep
    }
}
