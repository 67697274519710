/* eslint-disable camelcase */
import { z } from "zod";
import { useForm, type SubmitErrorHandler, type SubmitHandler} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useEffect, useMemo } from "react";
import {
	useAppDispatch,
	useAppSelector,
} from "../../../../../hooks/redux-hooks";
import Button from "../../../../ui/button/Button";
import { ResendTokenButton } from "../../../../ui/button/ResendTokenButton";
import { PinInput } from "../../../pin-input/PinInput";
import { selectApplicationPayload } from "../../../../../store/selectors/application-payload";
import { navigateToStep } from "../../../../../store/slices/stepper-slice";
import { useNavigate } from "react-router-dom";
import { NamedRoute } from "../../../../utils/NamedRoutes";
import { selectVerificationToken, setAuthToken } from "../../../../../store/slices/auth-slice";
import { useAuthLock } from "../../../../../hooks/use-auth-context";
import { SubStep } from "../../../../../services/apis/create-application.schema";
import useIsMobile from "../../../../../hooks/use-is-mobile";
import { CreateAccountHint } from "../../../../ui/hint/CreateAccountHint";
import {
	setFormLoading,
	setShowCreatAccountLoaderText,
} from "../../../../../store/slices/loader-slice";
import { setToast } from "../../../../../store/slices/toast-slice";
import FormLayout from "../../../../ui/form/Form";
import { useMarkFormDirty } from "../../../../../hooks/use-mark-form-dirty";
import { useAuthSuccessHandler } from "../../../../../hooks/use-auth-success";
import useTrackPageViewOnMount from "../../../../../hooks/use-track-on-mount";
import { useTracking } from "../../../../../hooks/use-tracking";
import { useAuth0Token } from "../../../../../hooks/use-auth0-token";
import {
	addDynamicHint,
	removeDynamicHint,
	setDynamicHints,
} from "../../../../../store/slices/ui-slice";
import { DEFAULT_TOKEN_TYPE, OauthTokenType } from "../../../../../services/apis/auth.schema";

import { captureException } from "../../../../../services/sentry";
import { useSubmitApplication } from "../../../../../services/apis/origination/api";
import { setApplicationId } from "../../../../../store/slices/form-slice";
import type { UserDetailsResponse } from "../../../../../services/apis/origination/types";

const submitYourPinSchema = z
	.object({
		token: z.coerce.string().length(6, "A valid verification code is required"),
		verificationToken: z.string(),
	});

type SubmitYourPinFormType = z.infer<typeof submitYourPinSchema>;

export const SubmitYourPin = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const isMobile = useIsMobile();
	const { setAuthLocked } = useAuthLock();
	const { handleSuccess, handleSuccessOrigination } = useAuthSuccessHandler();
	const { resetToken, isLoading: isLoadingOtpResend } = useAuth0Token();
	const verificationToken = useAppSelector(selectVerificationToken);
	const applicationPayload = useAppSelector(selectApplicationPayload);
	const didNotWantToPursueFHBScheme = useAppSelector(
		(state) => state.form.didNotWantToPursueFHBScheme
	);
	const [createApplicationV2, { isLoading }] = useSubmitApplication();


	const formOptions = {
		resolver: zodResolver(submitYourPinSchema),
		defaultValues: {
			token: "",
			verificationToken,
		},
	};
	const {
		control,
		handleSubmit,
		setValue,
		formState: { errors, isValid, isDirty },
	} = useForm<SubmitYourPinFormType>(formOptions);
	const { trackEvent } = useTracking();
	const handleInvalidSubmit = useCallback<SubmitErrorHandler<SubmitYourPinFormType>>((errors) => {
        const errorReasons = Object.values(errors).map(error => error.message).join(", ");
        trackEvent("Invalid Pin Submission Attempt", {
            error_or_success: "error",
            error_reason: errorReasons,
        });
    }, []);
	useMarkFormDirty(isDirty);
	useTrackPageViewOnMount({
		page: "Your Details",
		subPage: "Create Account Pin",
	});

	const saveData = useCallback<SubmitHandler<SubmitYourPinFormType>>(async (data) => {
		setAuthLocked(true);
		try {
			dispatch(setFormLoading(true));
			dispatch(setShowCreatAccountLoaderText(true));
			const response = await createApplicationV2({
				...applicationPayload,
				...(didNotWantToPursueFHBScheme === "Yes" && {
					didNotWantToPursueFHBScheme,
				}),
				verificationToken: data.verificationToken,
				token: data.token,
			}).unwrap();

			if (response?.data.content?.applicationDetails?.applicationId) {
				handleSuccessOrigination({
					accessToken: response.authToken,
					user: {
						...(response.data.content.userDetails as unknown as UserDetailsResponse),
						id: Number(response.data.content.userDetails.userId),
					},
				});
				trackEvent("Application Created");

				trackEvent("Create Account", {
					error_or_success: "success",
				});

				// origination2.0 applications are fetched with applicationId
				navigate(NamedRoute.home, {state: {applicationId: response.data.content.applicationDetails.applicationId}});
				dispatch(setApplicationId(response.data.content.applicationDetails.applicationId));
			} else {
				handleSuccess({
					authToken: response.authToken,
					...response.data.content,
				});
				trackEvent("Application Created");

				trackEvent("Create Account", {
					error_or_success: "success",
				});

				navigate(NamedRoute.home);
				dispatch(setAuthToken(response.authToken ?? null));
			}

			if (response.data.content.notInRemit) {
				dispatch(
					navigateToStep({
						step: "detailsStep",
						subStep: SubStep.NotInRemit,
					})
				);
			} else {
				dispatch(
					navigateToStep({
						step: "detailsStep",
						subStep: SubStep.FineDetailsAboutYou,
					})
				);
			}
		} catch (error) {
			captureException(error as Error);
			trackEvent("Create Account", {
				error_or_success: "error",
				// @ts-expect-error need to type api responses

				error_reason: error?.data?.message || error?.data?.data?.message || error?.data?.error || error?.message || "Unknown error",
			});

			dispatch(
				setToast({
					open: true,
					type: "error",
					title: "Error",
					// @ts-expect-error need to type api responses

					description: error?.data?.message || error?.data?.data?.message || error?.data?.error || error?.message
						|| "An error occurred while creating your account.",
				})
			);
			setValue("token", "");
		} finally {
			dispatch(setFormLoading(false));
			dispatch(setShowCreatAccountLoaderText(false));
			setAuthLocked(false);
		}
	}, [
		applicationPayload,
		createApplicationV2,
		didNotWantToPursueFHBScheme,
		dispatch,
		handleSuccess,
		navigate,
		setAuthLocked,
		trackEvent,
		setValue
	]);

	useEffect(() => {
		dispatch(setDynamicHints([]));
		dispatch(addDynamicHint("CreateAccountHint"));
		return () => {
			dispatch(removeDynamicHint("CreateAccountHint"));
		};
	}, [dispatch]);

	const identifiers = useMemo(
		() => ({
			identifierType: DEFAULT_TOKEN_TYPE === OauthTokenType.Email ? "email" : "phone",
			value: DEFAULT_TOKEN_TYPE === OauthTokenType.Email ? applicationPayload?.email : applicationPayload?.mobile,
		}),
		[applicationPayload?.email, applicationPayload?.mobile]
	);

	const header = (
		<h1 className="text-primary text-[37.9px] font-normal">
			Verify Your Email Address
		</h1>
	);
	const content = (
		<div className="flex flex-col gap-9">
			<PinInput
				name="token"
				control={control}
				length={6}
				label={`Enter the code sent to your ${identifiers.identifierType} below:`}
				helperMessage={`Enter the code we sent to ${identifiers.value}`}
				size="medium"
				error={errors.token && errors.token?.message}
			/>
			<div className="flex flex-col gap-2">
				<div>Didn’t receive a code? Check your spam or junk folder, or click “Resend Code” below.</div>
				<div className="flex items-center justify-between gap-2">
					<ResendTokenButton />
					<Button
						text={`Use a different ${identifiers.identifierType}`}
						variant="accent"
						size="full"
						handleClick={resetToken}
						isDisabled={isLoadingOtpResend}
					/>
				</div>
			</div>
			{isMobile && (<CreateAccountHint />)}
		</div>
	);
	const footer = (
		<div
			className="flex items-center justify-between gap-4"
			aria-labelledby="Actions wrapper"
		>
			<Button
				text="Back"
				variant="accent"
				iconPrefix={<i className="icon-arrow rotate-180" />}
				handleClick={resetToken}
				isDisabled={isLoading}
			/>
			<Button
				text="Create Account"
				variant="primary"
				iconSuffix={<i className="icon-arrow" />}
				type="submit"
				isDisabled={isLoading || !isValid}
			/>
		</div>
	);
	return (
		<FormLayout
			header={header}
			content={content}
			footer={footer}
			onSubmit={handleSubmit(saveData, handleInvalidSubmit)}
		/>
	);
};
