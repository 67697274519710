import { type FC, useCallback, useState } from "react";
import Button from "../../../../ui/button/Button";

type ChatExpandingDetailsProps = {
	description?: string | null;
	requirements?: string[];
}
export const ChatExpandingDetails: FC<ChatExpandingDetailsProps> = ({description, requirements}) => {
	const [isExpanded, setIsExpanded] = useState(false);

	const toggleHandler = useCallback( () => {
		setIsExpanded(!isExpanded);
	}, [isExpanded]);

	return (<div className="bg-white">
		<div
			className="flex flex-row items-center p-4 border-b border-off-black-400">
			<Button
				className="w-8 h-8 ms-0 text-off-black-900"
				text={<i className={`${isExpanded ? "icon-minus" : "icon-plus"} text-lg`} />}
				variant="link"
				textAlign="center"
				handleClick={toggleHandler}
			/>
			<span className="text-lg">Request Details</span>
		</div>
		{isExpanded && <div>
			{description && <div className="px-8 py-4 flex flex-col border-b border-off-black-400">
				<span className="pb-2 text-lg">Description</span>
				<span>{description}</span>
			</div>}
			{requirements && requirements.length > 0 && <div className="px-8 py-4 flex flex-col">
				<span className="pb-2 text-lg">Requirements</span>
				{requirements?.map((itm, index) => <span key={index} className="flex flex-row flex-nowrap py-1">
					<i className="icon-verified" /><span>{itm}</span>
				</span>)}
			</div>}
		</div>}
	</div>);
};
