import { useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import type { FunctionComponent } from "../../../../common/types";
import Button from "../../../ui/button/Button";
import { useSubmit } from "../../../../services/api";

import { useAppDispatch, useAppSelector } from "../../../../hooks/redux-hooks";
import { getDocumentsRedirect } from "../../../../lib/utils";
import { NamedRoute } from "../../../utils/NamedRoutes";
import { setFormLoading } from "../../../../store/slices/loader-slice";
import { selectHeaderHeight } from "../../../../store/slices/ui-slice";
import { useTracking } from "../../../../hooks/use-tracking";
import useTrackPageViewOnMount from "../../../../hooks/use-track-on-mount";
import {
	selectIsApplicationSubmitted,
	selectIsJointApplication,
} from "../../../../store/slices/origination-slice";
import { captureException } from "../../../../services/sentry";

const ConfirmationStep = (): FunctionComponent => {
	const [submit, { isLoading }] = useSubmit();
	const isJointApplication = useAppSelector(selectIsJointApplication);
	const isApplicationSubmitted = useAppSelector(selectIsApplicationSubmitted);
	const authToken = useAppSelector((state) => state.auth.token);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { trackEvent } = useTracking();
	const buttonClickHandler = useCallback(async () => {
		try {
			trackEvent("Application Submitted");
			await submit({}).unwrap();
			if (!authToken) {
				throw new Error(
					"Token not found for document redirection. Redirecting to login"
				);
			}
			window.location.href = getDocumentsRedirect(authToken);
		} catch (error) {
			captureException(error as Error);
			navigate(NamedRoute.login);
		}
	}, [authToken, navigate, submit]);

	useEffect(() => {
		if (isApplicationSubmitted) {
			if (!authToken) {
				throw new Error(
					"Token not found for document redirection. Redirecting to login"
				);
			}
			window.location.href = getDocumentsRedirect(authToken);
		}
	}, [authToken, isApplicationSubmitted, navigate]);

	useEffect(() => {
		dispatch(setFormLoading(isLoading));
		return () => {
			dispatch(setFormLoading(false));
		};
	}, [isLoading, dispatch]);

	const h1Ref = useRef<HTMLHeadingElement>(null);

	const headerHeight = useAppSelector(selectHeaderHeight) ?? 100;

	useTrackPageViewOnMount({
		page: "Confirmation",
		subPage: "Submit Application",
	});

	useEffect(() => {
		const timer = setTimeout(() => {
			if (h1Ref.current) {
				const elementPosition = h1Ref.current.getBoundingClientRect().top;
				const offsetPosition =
					elementPosition + window.pageYOffset - headerHeight * 2;

				window.scrollTo({
					top: offsetPosition,
					behavior: "smooth",
				});
			}
		}, 300); // Added a 300ms delay before scrolling

		return () => {
			clearTimeout(timer);
		}; // Cleanup the timeout on component unmount
	}, [headerHeight]);

	return (
		<>
			<h1
				ref={h1Ref}
				className="text-primary text-[37.9px] font-normal"
				aria-labelledby="Confirm Step Title"
			>
				Great, nearly done!
			</h1>
			<div className="text-off-black-600 text-[21.33px] font-normal leading-8 flex flex-col gap-5 mb-5 lg:mb-12">
				<p>
					We need you to upload some documents to verify the information you've
					declared.
				</p>
				{isJointApplication ? (
					<p>
						We won't be able to assess your application until you've uploaded
						these documents and your joint applicant has completed their
						section.
					</p>
				) : (
					<p>
						As soon as you've provided these we'll be able to assess your
						application.
					</p>
				)}
			</div>
			<Button
				text="Submit Application & Upload Documents"
				variant="primary"
				textAlign="center"
				handleClick={buttonClickHandler}
				isDisabled={isLoading}
			/>
		</>
	);
};

export default ConfirmationStep;
