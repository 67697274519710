import { useEffect, useRef } from "react";

declare global {
	interface Window {
		Trustpilot: {
			loadFromElement: (element: HTMLElement, isOptIn: boolean) => void;
		}; // Consider using a more specific type if the Trustpilot API is well-known
	}
}
const TrustBox = () => {
	// Create a reference to the <div> element which will represent the TrustBox
	const ref = useRef(null);
	useEffect(() => {
		// If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
		// If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
		// When it is, it will automatically load the TrustBox.
		if (window.Trustpilot && ref.current) {
			window.Trustpilot.loadFromElement(ref.current, true);
		}
	}, []);
	return (
		<div
			ref={ref}
			className="trustpilot-widget mb-4 -ml-4 flex items-center gap-3"
			data-locale="en-US"
			data-template-id="5419b732fbfb950b10de65e5"
			data-businessunit-id="64fbfddf60003ffc450a4bae"
			data-style-height="24px"
			// data-style-width="100%"
			// data-theme="dark"
			// data-font-family="Roboto"
			// data-text-color="#ffffff"
		>
			<a
				href="https://www.trustpilot.com/review/sucasa.com.au"
				target="_blank"
				rel="noopener"
			>
				{" "}
				Trustpilot
			</a>
		</div>
	);
};
export default TrustBox;
