import { type FC, type ReactNode, useCallback, useState, useRef, useEffect } from "react";
import Button from "../../../../ui/button/Button";
import { useTracking } from "../../../../../hooks/use-tracking";

type ExpandingTextProps = {
	text: ReactNode | string;
	caseType?: string;
	status?: string;
};

export const ExpandingText: FC<ExpandingTextProps> = ({text, caseType, status}) => {
	const [isExpanded, setIsExpanded] = useState(false);
	const [showCaret, setShowCaret] = useState(false);
	const contentRef = useRef<HTMLDivElement>(null);
	const { trackEvent } = useTracking();

	useEffect(() => {
		if (contentRef.current) {
			const contentHeight = contentRef.current.scrollHeight;
			setShowCaret(contentHeight > 64);
		}
	}, [text]);

	const toggleHandler = useCallback(() => {
		setIsExpanded(!isExpanded);
		if (!isExpanded) {
			trackEvent("card_description_expanded", {
				caseType,
				status
			});
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isExpanded, caseType, status]);

	return (
		<>
			<div className="border-t border-off-black-400" />
			<div className="relative">
				<div
					ref={contentRef}
					className={`${isExpanded ? '' : 'max-h-14'} py-2 pr-10 overflow-hidden`}
				>
					<span className="text-off-black-600 leading-6 whitespace-pre-wrap">{text}</span>
				</div>
				{showCaret && (
					<Button
						className="absolute top-2 right-0 w-8 h-6 text-off-black-600 flex items-center justify-center"
						text={<i className={`${isExpanded ? 'icon-chevron-up' : 'icon-chevron-down'} text-lg`} />}
						variant="link"
						handleClick={toggleHandler}
					/>
				)}
			</div>
		</>
	);
};
