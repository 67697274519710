import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAcceptInviteMutation } from "../../../services/api";
import { useAppDispatch } from "../../../hooks/redux-hooks";
import { NamedRoute } from "../../utils/NamedRoutes";

import Button from "../../ui/button/Button";
import { useAuthLock } from "../../../hooks/use-auth-context";
import {
	PASSWORD_REGEX_8ULNS16,
	COMMON_PASSWORDS_BLACKLIST_REGEX,
} from "../../../lib/utils";
import { setFormLoading } from "../../../store/slices/loader-slice";
import GenericPasswordInput, {
	PasswordRequirements,
} from "../generic-password-input/GenericPasswordInput";
import { useAuthSuccessHandler } from "../../../hooks/use-auth-success";
import { setLogoutModal } from "../../../store/slices/ui-slice";

const acceptInviteSchema = z
	.object({
		password: z
			.string()
			.min(8, "Password must be at least 8 characters")
			.regex(
				PASSWORD_REGEX_8ULNS16,
				"Password must contain at least one uppercase, one lowercase, one number and one special character"
			)
			.regex(COMMON_PASSWORDS_BLACKLIST_REGEX, "Password is too common"),
		passwordConfirmation: z.string(),
		inviteToken: z.string(),
	})
	.refine((data) => data.password === data.passwordConfirmation, {
		message: "Passwords do not match",
		path: ["passwordConfirmation"],
	});

type SetPasswordSchemaType = z.infer<typeof acceptInviteSchema>;

const AcceptInviteForm = ({ inviteToken }: { inviteToken: string }) => {
	const navigate = useNavigate();
	const { setAuthLocked } = useAuthLock();
	const { handleSuccess } = useAuthSuccessHandler();
	const dispatch = useAppDispatch();
	const [acceptInvite, { isLoading }] = useAcceptInviteMutation();
	const {
		register,
		handleSubmit,
		formState: { errors, isDirty },
	} = useForm<SetPasswordSchemaType>({
		resolver: zodResolver(acceptInviteSchema),
		defaultValues: {
			inviteToken,
		},
	});

	const onSubmitHandler = async (data: SetPasswordSchemaType) => {
		try {
			setAuthLocked(true);
			dispatch(setFormLoading(true));

			const response = await acceptInvite(data).unwrap();
			// there is a weird bug if a user is polling application in a separate tab
			// we need to reset logout modal
			dispatch(setLogoutModal(false));
			if (response.data.message === "Password set successfully") {
				toast.success( "Password set successfully");
				setTimeout(() => {
					// If the api responds with the above message, it wasn't able to issue a session, so we need to navigate to the login page
					navigate(NamedRoute.login);
				}, 3000);
			} else {
				handleSuccess({
					authToken: response.authToken,
					...response.data.content,
				});
				navigate(NamedRoute.jointBorrowerIntro);
			}
		} catch (error) {
			// @ts-expect-error need to type api responses
			 
			toast.error(error?.data?.message || "An error occurred while accepting your invite.");
		} finally {
			dispatch(setFormLoading(false));
			setAuthLocked(false);
		}
	};

	return (
		<>
			<h1 className="text-primary text-6xl leading-snug font-normal">
				Welcome to Sucasa!
			</h1>
			<p className="font-normal leading-8 mb-10 lg:mb-16">
				Please set your password for your Sucasa account below
			</p>
			<form
				className="flex flex-col gap-8"
				onSubmit={handleSubmit(onSubmitHandler)}
			>
				<GenericPasswordInput
					name="password"
					register={register}
					placeholder="New Password"
					error={errors?.password?.message}
					iconPrefix={<i className="icon-lock" />}
				/>
				<GenericPasswordInput
					name="passwordConfirmation"
					register={register}
					placeholder="Confirm Password"
					error={errors?.passwordConfirmation?.message}
					iconPrefix={<i className="icon-lock" />}
				/>

				{PasswordRequirements}

				<div className="lg:mt-10">
					<Button
						type="submit"
						text="Set password and Log in"
						variant="primary"
						size="full"
						iconSuffix={<i className="icon-arrow" />}
						isDisabled={isLoading || !isDirty}
					/>
				</div>
			</form>
		</>
	);
};

export default AcceptInviteForm;
