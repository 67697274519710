import { type FC, useState } from "react";
import Button from "../../../../ui/button/Button";

type ChatMessageBiometricsAcceptProps = {
	disabled: boolean;
	onAccept?: () => void;
}
export const ChatMessageBiometricsAccept: FC<ChatMessageBiometricsAcceptProps> = ({
	disabled,
	onAccept,
}) => {
	const [isDisabled, setIsDisabled] = useState(disabled);
	return (<div className="flex flex-row justify-end items-end w-full gap-4">
		<div className="p-4 bg-off-black-900 text-white rounded">
			<div>I consent to collection, use and disclosure of my personal information in accordance with the
				Sucasa Privacy Policy and consent to my personal information being disclosed to a credit reporting
				agency or my information being checked with the document issuer or official record holder in
				connection with a request to verify my identity in accordance with the AML/CTF Act.</div>
			<div className="flex flex-row justify-end pt-1">
				<Button
					isDisabled={isDisabled}
					text="Agreed"
					className="min-w-min text-lg rounded bg-grass-hopper-900"
					iconSuffix={<i className="icon-check" />}
					variant="accent"
					textAlign="center"
					handleClick={() => { setIsDisabled(true); onAccept?.(); }}
				/>
			</div>
		</div>
	</div>)
};
