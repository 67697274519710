/* eslint-disable camelcase */

import { z } from "zod";
import { createSelector } from "@reduxjs/toolkit";
import type { RootState } from "../../../../../store";

export const YourJointBorrowerIncomeSchema = z.object({
	jointBorrowerIncome: z
		.number({
			invalid_type_error: "This field is required, please enter zero if none",
			required_error: "This field is required, please enter zero if none",
		})
		.min(0, "This field is required, please enter zero if none"),
});

export type YourJointBorrowerIncomeFormType = z.infer<typeof YourJointBorrowerIncomeSchema>;

export type YourJointBorrowerIncomeFormSlice = {
	jointBorrowerIncome?: number;
	previousFinancesActiveForm?: string;
	jointBorrowerFirstName?: string;
};

export const selectJointBorrowerIncome = createSelector(
	(state: RootState) => state.form,
	(form): YourJointBorrowerIncomeFormSlice => ({
		jointBorrowerIncome: form.jointBorrowerIncome,
		previousFinancesActiveForm: form.previousFinancesActiveForm,
		jointBorrowerFirstName: form.jointBorrowerFirstName,
	})
);
