import { useEffect, useState } from "react";
import Button from "../../../../../ui/button/Button";
import type { AssetsFormSlice, VehicleAsset } from "../YourAssetsSchema";
import type { Control, FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import GenericInputTeriary from "../../../../generic-input-teriary/GenericInputTeriary";
import DollarInput from "../../../../dollar-input/DollarInput";

type VehicleAssetErrorType = Merge<
	FieldError,
	Array<Merge<FieldError, FieldErrorsImpl<VehicleAsset>> | undefined>
>;

type TSubFormName = "carAssets" | "boatAssets";

const VehicleAssetsDisplay = ({
	index,
	vehicleAsset,
	subFormName,
	control,
	errors,
	onChangeVehicleAssetHandler,
	removeVehicleAssetHandler,
}: {
	index: number;
	vehicleAsset: VehicleAsset;
	onChangeVehicleAssetHandler: (value: VehicleAsset) => void;
	removeVehicleAssetHandler: (value: number) => void;
	subFormName: TSubFormName;
	control: Control<AssetsFormSlice>;
	errors?: VehicleAssetErrorType;
}) => (
	<div className="w-full flex flex-col gap-4">
		<div className="flex flex-col md:flex-row gap-4 md:gap-8">
			<GenericInputTeriary
				type="text"
				value={vehicleAsset.name}
				error={errors && errors[index]?.name && errors[index]?.name?.message}
				label=""
				placeholder="Asset Name"
				onChangeHandler={(value) => {
					const updateVehicleAsset = {
						...vehicleAsset,
					};
					updateVehicleAsset.name = value.toString();
					onChangeVehicleAssetHandler(updateVehicleAsset);
				}}
			/>
			<GenericInputTeriary
				type="number"
				value={vehicleAsset.year}
				error={errors && errors[index]?.year && errors[index]?.year?.message}
				label=""
				placeholder="Year"
				onChangeHandler={(value) => {
					const updateVehicleAsset = {
						...vehicleAsset,
					};
					updateVehicleAsset.year = Number(value);
					onChangeVehicleAssetHandler(updateVehicleAsset);
				}}
			/>
		</div>
		<div className="flex flex-col md:flex-row gap-4 md:gap-8">
			<GenericInputTeriary
				type="text"
				value={vehicleAsset.make}
				error={errors && errors[index]?.make && errors[index]?.make?.message}
				label=""
				placeholder="Make"
				onChangeHandler={(value) => {
					const updateVehicleAsset = {
						...vehicleAsset,
					};
					updateVehicleAsset.make = value.toString();
					onChangeVehicleAssetHandler(updateVehicleAsset);
				}}
			/>
			<GenericInputTeriary
				type="text"
				value={vehicleAsset.model}
				error={errors && errors[index]?.model && errors[index]?.model?.message}
				label=""
				placeholder="Model"
				onChangeHandler={(value) => {
					const updateVehicleAsset = {
						...vehicleAsset,
					};
					updateVehicleAsset.model = value.toString();
					onChangeVehicleAssetHandler(updateVehicleAsset);
				}}
			/>
		</div>
		<DollarInput
			name={`${subFormName}.${index}.value`}
			control={control}
			type="numeric"
			value={vehicleAsset.value}
			error={errors && errors[index]?.value && errors[index]?.value?.message}
			label=""
			placeholder="Enter Value"
			iconPrefix={<i className="icon-dollar" />}
			onValueChange={(value) => {
				const updateVehicleAsset = {
					...vehicleAsset,
				};
				updateVehicleAsset.value = value || 0;
				onChangeVehicleAssetHandler(updateVehicleAsset);
			}}
		/>
		<Button
			text="Remove"
			variant="plain"
			type="button"
			iconSuffix={<i className="icon-remove" />}
			handleClick={() => {
				removeVehicleAssetHandler(index);
			}}
		/>
	</div>
);
const emptyVehicleAsset = {} as VehicleAsset;
const VehicleAssetsForm = ({
	initialVehicleAssets,
	label,
	onChange,
	subFormName,
	control,
	errors,
}: {
	initialVehicleAssets?: Array<VehicleAsset>;
	subFormName: TSubFormName;
	control: Control<AssetsFormSlice>;
	errors?: VehicleAssetErrorType;
	label: string;
	onChange: (vehicleAssets: Array<VehicleAsset>) => void;
}) => {
	const [vehicleAssets, setVehicleAssets] = useState<Array<VehicleAsset>>(
		initialVehicleAssets ?? []
	);

	const handleVehicleAssetChange = (
		index: number,
		vehicleAsset: VehicleAsset
	) => {
		const updatedVehicleAssets = [...vehicleAssets];
		updatedVehicleAssets[index] = { ...vehicleAsset };
		setVehicleAssets(updatedVehicleAssets);
	};

	useEffect(() => {
		onChange(vehicleAssets);
	}, [vehicleAssets, onChange]);

	const removeVehicleAssetHandler = (index: number) => {
		const updatedVehicleAssets = [...vehicleAssets];
		updatedVehicleAssets.splice(index, 1);
		setVehicleAssets(updatedVehicleAssets);
	};
	return (
		<section className="flex flex-col gap-4">
			{vehicleAssets.map((vehicleAsset, index) => (
				<VehicleAssetsDisplay
					key={index}
					index={index}
					subFormName={subFormName}
					control={control}
					errors={errors}
					vehicleAsset={vehicleAsset}
					onChangeVehicleAssetHandler={(value: VehicleAsset) => {
						handleVehicleAssetChange(index, value);
					}}
					removeVehicleAssetHandler={removeVehicleAssetHandler}
				/>
			))}
			<Button
				text={
					<span>
						<i className="icon-plus" /> {`Add ${vehicleAssets.length > 0 ? "another " : ""}${label}`}
					</span>
				}
				type="button"
				size="fit"
				variant="accent"
				textAlign="center"
				handleClick={() => {
					const updatedVehicleAssets = [
						...vehicleAssets,
						{ ...emptyVehicleAsset },
					];
					setVehicleAssets(updatedVehicleAssets);
				}}
			/>
		</section>
	);
};

export default VehicleAssetsForm;
