import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import AuthLayout from "../common/layout/AuthLayout";
import type { FunctionComponent } from "../common/types";
import ResetPasswordForm from "../components/forms/reset-password-form/ResetPasswordForm";
import { useLogOut } from "../hooks/use-logout";
import useTrackPageViewOnMount from "../hooks/use-track-on-mount";

const ResetPassword = (): FunctionComponent => {
	const [searchParameters] = useSearchParams();
	const { clearOrRedirect } = useLogOut();
	const resetToken = searchParameters.get("reset_token");
	useTrackPageViewOnMount({
		page: "Reset Password",
	});

	useEffect(() => {
		clearOrRedirect();
	}, [clearOrRedirect]);

	return (
		<AuthLayout>
			<section className="lg:max-w-96 flex flex-col justify-center h-full p-4">
				{!resetToken && (
					<>
						<h1 className="text-primary text-6xl leading-snug font-normal">
							Oh no!
						</h1>
						<p className="font-normal leading-8 mb-10 lg:mb-16">
							This link is invalid. Please email support@sucasa.com for
							assistance
						</p>
					</>
				)}
				{resetToken && (
					<>
						<h1 className="text-primary text-6xl leading-snug font-normal">
							Set Your Password
						</h1>
						<p className="font-normal leading-8 mb-10 lg:mb-16">
							Please set your new password for your Sucasa account below
						</p>
						<ResetPasswordForm resetToken={resetToken} />
					</>
				)}
			</section>
		</AuthLayout>
	);
};

export default ResetPassword;
