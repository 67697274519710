import { AddressHistoryHint } from "./AddressHistoryHint";
import { CreateAccountHint } from "./CreateAccountHint";
import { CreateApplicationHint } from "./CreateApplicationHint";
import { CreateAuth0AccountHint } from "./CreateAuth0AccountHint";
import { CreditCheckHint } from "./CreditCheckHint";
import { EmploymentHistoryHint } from "./EmploymentHistoryHint";
import { HouseHoldExpensesHint } from "./HouseHoldExpensesHint";
import { JoinedAccountHint } from "./JoinedAccountHint";
import { JointApplicantInviteHint } from "./JointApplicantInviteHint";
import { LoanHint } from "./LoanHint";

export const Hint = {
	AddressHistoryHint: AddressHistoryHint,
	CreateAccountHint: CreateAccountHint,
	CreateApplicationHint: CreateApplicationHint,
	CreateAuth0AccountHint: CreateAuth0AccountHint,
	CreditCheckHint: CreditCheckHint,
	EmploymentHistoryHint: EmploymentHistoryHint,
	HouseHoldExpensesHint: HouseHoldExpensesHint,
	JoinedAccountHint: JoinedAccountHint,
	JointApplicantInviteHint: JointApplicantInviteHint,
	LoanHint: LoanHint,
}

export type THint = keyof typeof Hint;
