import type { FC } from "react";

interface CircularProgressBarProps {
  percentage: number;
}
const CircularProgressBar: FC<CircularProgressBarProps> = ({ percentage }) => {
  const radius = 45;
  const circumference = 2 * Math.PI * radius;
 // const strokeDashoffset = circumference - ((percentage / 100) * circumference + circumference / 4);
  const strokeDashoffset = (1 - percentage / 100) * circumference;

  return (
    <svg className="w-12 h-12" viewBox="0 0 100 100">
      <circle
        className="text-off-black-900"
        cx="50"
        cy="50"
        r={radius}
        fill="none"
        stroke="#757776"
        strokeWidth="3"
      />
      <circle
        cx="50"
        cy="50"
        r={radius}
        fill="none"
        strokeWidth="3"
        stroke={`${percentage >= 100 ? "#9FC946" : "#191D1A"}`}
        strokeDasharray={circumference}
        strokeDashoffset={strokeDashoffset}
        transform="rotate(-90, 50, 50)"
      />
      {percentage >= 100 && (
        <path
          d="M25,50 l15,15 l30,-30"
          fill="none"
          stroke="#9FC946"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
    </svg>
  );
};

export default CircularProgressBar;
