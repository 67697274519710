import { useState, useCallback } from "react";
import { VerificationCallback, VerificationResponseOwner } from "@sucasa-finance/origination-trpc";
import { origination } from "../../services/origination";
import { captureException } from "../../services/sentry";

export const useAddCaseResponse = () => {
  const [isUploading, setIsUploading] = useState(false);

  const utils = origination.useUtils();
  const { mutate, mutateAsync } = origination.verification.addCaseResponse.useMutation({
    onSettled: () => {
      void utils.verification.getCases.invalidate();
    },
    onError: (error) => {
      captureException(new Error("Error adding case response"), {data: {error}});
    },
  });

  const { mutate: triggerCallback } = origination.verification.triggerCallback.useMutation({
    onSettled: () => {
      void utils.verification.getCases.invalidate();
    },
    onError: (error) => {
      captureException(new Error("Error triggering callback"), {data: {error}});
    },
  });

  const { mutate: setSeenCaseResponses } = origination.verification.seenCaseResponses.useMutation({
    onSettled: () => {
      void utils.verification.getCases.invalidate();
    },
    onError: (error) => {
      captureException(new Error("Error setting seen case responses"), {data: {error}});
    },
  });

  const onMessage = useCallback(async (caseId: number, message: string) => {
    await mutateAsync({
      caseId,
      message,
      owner: VerificationResponseOwner.User,
    });
  }, [mutateAsync])

  const onUpload = useCallback((caseId: number, files?: {fileId: number; name: string}[]) => {
    files && files.forEach(itm => {
      mutate({
        caseId,
        fileId: itm.fileId,
        file: itm.name,
        owner: VerificationResponseOwner.User,
      });
    })
    setIsUploading(false);
  }, [mutate])

  const onAcceptBiometrics = useCallback((caseId: number) => {
    triggerCallback({ caseId, callback: VerificationCallback.BiometricsAccept });
  }, [triggerCallback])

  const onSendBiometrics = useCallback((caseId: number) => {
    triggerCallback({ caseId, callback: VerificationCallback.BiometricsRun });
  }, [triggerCallback])

  return {
    isUploading,
    setIsUploading,
    onMessage,
    onUpload,
    setSeenCaseResponses,
    onAcceptBiometrics,
    onSendBiometrics
  }
};
