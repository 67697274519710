import type { FC } from "react";
import { BoxPreApproval } from "./components/BoxPreApproval";
import { YourNewAddress } from "./components/YourNewAddress";

type ProgressToUnconditionalProps = {
	applicationId: number
}
export const YourUnconditional: FC<ProgressToUnconditionalProps> = ({applicationId}) => {

	return (
		<>
			<h1
				className="flex flex-row text-primary text-[37.9px] font-normal"
				aria-labelledby="Confirm Step Title"
			>
				<div>You've been unconditionally approved!</div>
			</h1>
			<YourNewAddress />
			<BoxPreApproval applicationId={applicationId} />
		</>
	);
};
