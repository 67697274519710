import { type FC, Fragment } from "react";
import { ApprovalType } from "@sucasa-finance/origination-trpc";

import moment from "moment";
import { Status } from "../../../../ui/status/Status";
import Button from "../../../../ui/button/Button";
import { Box } from "./Box";
import { formatDate } from "../../../../../helpers/format-date";
import { useApprovals } from "../../../../../hooks/origination/use-approvals";

const approvalTypeDescription = (approvalType: ApprovalType) => {
  return {
    [ApprovalType.PRE_APPROVAL]: "Pre-Approval Letter",
    [ApprovalType.UNCONDITIONAL_APPROVAL]: "Unconditional Approval Letter",
    [ApprovalType.CONDITIONAL_APPROVAL]: "Conditional Approval Letter",
  }[approvalType];
}
const APPROVAL_EXPIRY_DAYS = 90;

type BoxPreApprovalProps = {
  applicationId: number;
}
export const BoxPreApproval: FC<BoxPreApprovalProps> = ({applicationId}) => {
  const { approvals, fetchDownloadUrl } = useApprovals(applicationId);

  return (<>{approvals && approvals?.length > 0 && <Box className="flex flex-col gap-4">
    {approvals.map((approval, index) => {
      const isActive = moment().diff(moment(approval.approvalDate), 'days') <= APPROVAL_EXPIRY_DAYS;
      return (
        <Fragment key={index}>
          <div className="flex flex-row align-top justify-between">
            <div className="flex flex-row gap-4">
              <div>
                <Status status={isActive ? "active" : "alert"} text={isActive ? "Current" : "Expired"} />
              </div>
              <div className="flex flex-col gap-2">
              <span className="text-lg">
                {approvalTypeDescription(approval.type)}
              </span>
                <span className="text-off-black-600">{isActive
                  ? `Expires ${formatDate(moment(approval.approvalDate).add(APPROVAL_EXPIRY_DAYS, "days"))}`
                  : `Provided ${formatDate(approval.approvalDate)}`}</span>
              </div>
            </div>
            <Button
              className="ms-2 w-8-h-8 hover:bg-none"
              text={<i className="icon-download" />}
              variant="link"
              textAlign="center"
              handleClick={() => { fetchDownloadUrl(approval.id); }}
            />
          </div>
          {index < (approvals.length - 1) && <div className="border-t border-off-black-400" />}
        </Fragment>
      )
    })}
  </Box>}</>)
};
