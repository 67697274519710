import { type FC, useState, useEffect, useCallback, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import Footer from "../footer/Footer";
import { Header } from "../header/Header";
import type { LayoutType } from "../types";
import Modal from "../../components/forms/modal/Modal";
import type { ButtonType } from "../../components/ui/button/Button";
import { useLogOut } from "../../hooks/use-logout";
import { Toaster } from "../../components/ui/toast/Toast";
import { setHeaderHeight, setLoginModal } from "../../store/slices/ui-slice";
import { NamedRoute } from "../../components/utils/NamedRoutes";
import { Loader } from "../../components/ui/loader/Loader";

const LayoutContent: FC<LayoutType & { headerHeight: number }> = ({
	children,
	leftColumn,
	rightColumn,
	isFull,
	headerHeight,
	progress,
	workingTowards,
}) => {
	const showLogoutModal = useAppSelector((state) => state.ui.showLogoutModal);
	const showLoginModal = useAppSelector((state) => state.ui.showLoginModal);
	const { handleLogout } = useLogOut();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const handleLogin = useCallback(() => {
		navigate(NamedRoute.gettingStarted);
		dispatch(setLoginModal(false));
	}, [dispatch, navigate]);

	const logoutModalAction: Array<ButtonType> = [
		{
			text: "Sign Out",
			variant: "primary",
			handleClick: handleLogout,
			type: "button",
			textAlign: "start",
			size: "fit",
			iconSuffix: <i className="icon-arrow" />,
		},
	];

	const loginModalAction: Array<ButtonType> = [
		{
			text: "Start Application",
			variant: "primary",
			handleClick: handleLogin,
			type: "button",
			textAlign: "start",
			size: "fit",
			iconSuffix: <i className="icon-arrow" />,
		},
	];

	return (
		<Suspense fallback={<Loader />}>
			<div className="flex flex-col">
				<Header progress={progress} workingTowards={workingTowards} />
				<main
					className="flex-grow app-bg pb-16 lg:pt-20 lg:pb-20 xl:px-0"
					style={{ minHeight: `calc(100vh - ${headerHeight}px)` }}
				>
					{isFull && <div className="container px-4 mx-auto flex flex-col lg:flex-row gap-9 h-full">
						<section className="w-full lex-grow h-full">
							{children}
						</section>
					</div>}
					{!isFull && <div className="container px-4 mx-auto flex flex-col lg:flex-row gap-9 h-full">
						<aside className="lg:max-w-64 lg:w-full xl:max-w-80 2xl:max-w-96">
							{leftColumn}
						</aside>
						<section className="w-full lg:max-w-lg xl:max-w-2xl 2xl:max-w-3xl flex-grow h-full">
							{children}
						</section>
						<aside className="lg:max-w-64 lg:w-full xl:max-w-80 2xl:max-w-96">
							{rightColumn}
						</aside>
					</div>}
				</main>
				{showLogoutModal && (
					<Modal
						isOpen={showLogoutModal}
						actions={logoutModalAction}
						title={
							<div className="flex flex-col gap-4 md:gap-8 items-center text-[28.43px] font-medium leading-10 text-primary">
								<i className="icon-warning text-3xl md:text-[80px]" />
							</div>
						}
						onClose={handleLogout}
						content={
							<p className="text-sm text-primary text-center">
								Your session expired, please login again to resume your
								application
							</p>
						}
					/>
				)}
				{showLoginModal && (
					<Modal
						isOpen={showLoginModal}
						actions={loginModalAction}
						title={
							<div className="flex flex-col gap-4 md:gap-8 items-center text-[28.43px] font-medium leading-10 text-primary">
								<i className="icon-warning text-3xl md:text-[80px]" />
							</div>
						}
						onClose={handleLogin}
						content={
							<p className="text-sm text-primary text-center">
								Welcome to Sucasa, you do not have an application yet.
								Please click "Start Application" to begin.
							</p>
						}
					/>
				)}
				<Toaster />
				<Footer />
			</div>
		</Suspense>
	);
};

const Layout: FC<LayoutType> = (props) => {
	const dispatch = useAppDispatch();
	const [headerHeight, setHeight] = useState(0);

	useEffect(() => {
		const header = document.querySelector("header");
		if (header) {
			setHeight(header.getBoundingClientRect().height);
			dispatch(setHeaderHeight(header.getBoundingClientRect().height));
		}
	}, [dispatch]);

	return <LayoutContent {...props} headerHeight={headerHeight} />;
};

export default Layout;
