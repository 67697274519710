import type { FC } from "react";
// import Button from "../../../ui/button/Button";
// import { useStartApplication } from "../../../../hooks/use-start-application";

type TWbHeaderNewProps = {
	firstName?: string;
}
export const WbHeaderNew: FC<TWbHeaderNewProps> = ({ firstName }) => {
	// const startApplication = useStartApplication();
	return (
		<header
			aria-labelledby="Welcome back content header"
			role="heading"
			className="mb-4 mt-4 md:mt-0 lg:mb-6 lg:mt-14"
		>
			<div className="flex flex-row justify-between items-center lg:mt-14">
				<div className="flex flex-col">
					<h1
						className="text-primary text-[37.9px] text-left font-normal"
						aria-labelledby="Apply in 15 minutes"
					>
						Welcome back{firstName && `, ${firstName}`}
					</h1>

					<div className="text-off-black-600 text-[21.33px] text-left font-normal leading-8 flex flex-col gap-5">
						<p>Let’s get going again, shall we?</p>
					</div>
				</div>
				{/* <div className="flex-col items-center justify-center">
					<Button
						className="rounded hidden md:inline"
						text="New application"
						variant="primary"
						iconSuffix={<i className="icon-plus" />}
						handleClick={startApplication}
					/>
					<Button
						className="rounded md:hidden min-w-0 border border-primary text-primary p-2"
						text={<i className="icon-plus" />}
						variant="plain"
						handleClick={startApplication}
					/>
				</div> */}
			</div>
		</header>
	);
};
