import { useEffect } from "react";
import AuthLayout from "../common/layout/AuthLayout";
import type { FunctionComponent } from "../common/types";
import ForgotPasswordForm from "../components/forms/forgot-password-form/ForgotPasswordForm";
import { useLogOut } from "../hooks/use-logout";
import useTrackPageViewOnMount from "../hooks/use-track-on-mount";

const ForgotPassword = (): FunctionComponent => {
	const { clearOrRedirect } = useLogOut();

	useTrackPageViewOnMount({
		page: "Forgot Password",
	});

	useEffect(() => {
		clearOrRedirect();
	}, [clearOrRedirect]);

	return (
		<AuthLayout>
			<section className="lg:max-w-96 flex flex-col justify-center h-full p-4">
				<ForgotPasswordForm />
			</section>
		</AuthLayout>
	);
};

export default ForgotPassword;
