import ReactDOM from "react-dom/client";
import "react-toastify/dist/ReactToastify.css";
import "./services/sentry";
import { initAnalytics } from "./services/analytics";
import "./services/gtm";
import "./styles/tailwind.css";
import "./components/ui/toast/toast.css";
import { AppRoot } from "./app-root";
initAnalytics();

const rootElement = document.querySelector("#root") as Element;
if (!rootElement.innerHTML) {
	const root = ReactDOM.createRoot(rootElement);
	root.render(
		<AppRoot />
	);
}
