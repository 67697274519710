/* eslint-disable camelcase */
import { Combobox } from "@headlessui/react";
import { type ChangeEvent, useEffect, useState } from "react";
import usePlacesAutocompleteService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import type { FieldValues, Path, UseFormRegister } from "react-hook-form";
import type { AddressParts } from "../../../services/apis/create-application.schema";
import Button from "../../ui/button/Button";
import ManualAddress from "./manual-address/ManualAddress";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hooks";
import {
	addAddressInput,
	setIsManualAddressInput,
} from "../../../store/slices/form-slice";
import ErrorMessage from "../error-message/ErrorMessage";
import { useTracking } from "../../../hooks/use-tracking";
import { AustraliaStateOptions } from "../../utils/BootList";


export type Place = {
	description?: string;
	place_id?: string;
};



interface AddressSearchProps<TFieldValues extends FieldValues> {
	id: string;
	options?: Partial<google.maps.places.AutocompletionRequest>
	error?: string;
	register?: UseFormRegister<TFieldValues>;
	name: string;
	label?: string;
	value?: Place;
	onChange: (data: {
		place: Place | null;
		addressParts: AddressParts | null;
		isManual: boolean;
	}) => void;
	manualAddressParts: AddressParts;
	isSuburb: boolean;
	helperEnabled?: boolean;
}

const AddressSearch = <TFieldValues extends FieldValues>({
	options = {
		types: [],
		componentRestrictions: { country: "au" },
		bounds: {
			north: -9.2,
			south: -43.7,
			west: 112.9,
			east: 153.6
		},
	},
	error,
	register,
	name,
	label,
	value,
	onChange,
	manualAddressParts,
	isSuburb,
	id,
	helperEnabled = false,
}: AddressSearchProps<TFieldValues>) => {
	const dispatch = useAppDispatch();
	const { trackEvent } = useTracking();

	const [isManualInputState, setIsManualInputState] = useState(false);
	const setIsManualInput = (isManual: boolean) => {
		setIsManualInputState(isManual);
		dispatch(setIsManualAddressInput({ id, isManual }));
	};
	const addressInputState = useAppSelector(
		(state) =>
			state.form.addressInputs?.find((input) => input.id === id) ?? undefined
	);

	useEffect(() => {
		dispatch(addAddressInput({ id, isManualAddressInput: false }));
	}, [dispatch, id]);

	useEffect(() => {
		if (addressInputState) {
			setIsManualInput(addressInputState.isManualAddressInput);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [addressInputState]);

	const googleMapsApiKey: string = import.meta.env[
		"VITE_APP_GOOGLE_MAPS_API_KEY"
	] as string;

	const {
		placePredictions,
		getPlacePredictions,
		placesService,
		isPlacePredictionsLoading,
	} = usePlacesAutocompleteService({
		apiKey: googleMapsApiKey,
		options: {
			input: "",
			...options,
			componentRestrictions: {
				...options.componentRestrictions,
				country: options.componentRestrictions?.country || null, // Ensure country is null instead of undefined
			},
		},
	});

	const [selectedPlace, setSelectedPlace] = useState<Place | undefined>(
		value || { description: "", place_id: "" }
	);
	const [inputValue, setInputValue] = useState("");

	useEffect(() => {
		if (selectedPlace?.place_id) {
			(placesService as google.maps.places.PlacesService)?.getDetails(
				{ placeId: selectedPlace.place_id },
				(placeDetails) => {
					if (placeDetails) {
						const stateCode = placeDetails.address_components?.find((component) =>
							component.types.includes("administrative_area_level_1")
						)?.short_name ?? "";
						const addressParts: AddressParts = {
							streetNumber: placeDetails.address_components?.find((component) =>
								component.types.includes("street_number")
							)?.long_name,
							streetName: placeDetails.address_components?.find((component) =>
								component.types.includes("route")
							)?.long_name,
							suburb:
								placeDetails.address_components?.find((component) =>
									component.types.includes("locality")
								)?.long_name ?? "",
							state: stateCode,
							postalCode:
								placeDetails.address_components?.find((component) =>
									component.types.includes("postal_code")
								)?.long_name ?? "",
							unitNumber: placeDetails.address_components?.find((component) =>
								component.types.includes("subpremise")
							)?.long_name,
							stateOptions:
								AustraliaStateOptions.find(itm => itm.value === stateCode)
								?? {
									id: 0,
									value: "",
									label: "State",
								},
							streetType: "",
						};
						onChange({ place: selectedPlace, addressParts, isManual: false });
					} else {
						onChange({
							place: selectedPlace,
							addressParts: null,
							isManual: false,
						});
					}
				}
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedPlace, placesService]);

	const handleSelect = (place: Place | undefined) => {
		setSelectedPlace(place || { description: "", place_id: "" });
	};

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		setInputValue(event.target.value);
		getPlacePredictions({
			input: event.target.value,
			types: options.types,
		});
	};

	const locationString = label ?? "Search for an address";

	return (
		<div className="w-full flex flex-col gap-2">
			<label
				htmlFor={name}
				className="text-primary text-[21.33px] mb-2 font-normal"
			>
				{locationString}
			</label>

			{!isManualInputState && (
				<div
					className={`${error ? "border-b-error" : "border-b-off-black-900"} flex gap-2 border-b pb-2 relative`}
				>
					<i className="icon-map-pin" />
					<Combobox
						value={selectedPlace}
						onChange={handleSelect}
						as={"div"}
						className="flex flex-col w-full"
					>
						<Combobox.Input
							{...(register && register(name as Path<TFieldValues>))}
							className={`${error ? "placeholder-error" : "placeholder-off-black-600"} bg-transparent w-full`}
							onChange={handleInputChange}
							displayValue={(place: Place) => {
								return place?.description || "";
							}}
							placeholder="Type to search..."
						/>
						<Combobox.Options className="bg-white border border-off-black-900 absolute top-8 left-0 right-0 z-10">
							{isPlacePredictionsLoading ? (
								<Combobox.Option
									value={{ description: "Loading...", place_id: undefined }}
									disabled
								>
									<div className="text-sm leading-6 p-4 cursor-not-allowed text-off-black-600">
										Loading...
									</div>
								</Combobox.Option>
							) : (
								<>
									{placePredictions.map((prediction: Place) => (
										<Combobox.Option
											key={prediction?.place_id}
											value={prediction}
										>
											{({ active }) => (
												<div
													className={`${active ? "bg-primary text-white" : ""} text-sm leading-6 p-4 cursor-pointer transition-all`}
												>
													{prediction?.description}
												</div>
											)}
										</Combobox.Option>
									))}
									{/* Always render this option as the last item */}
									{inputValue.trim() !== "" && (
										<Combobox.Option
											value={{
												description:
													"We couldn't find that address, please try again",
												place_id: undefined,
											}}
											disabled
										>
											<div className="text-sm leading-6 p-3 cursor-not-allowed text-off-black-600 flex flex-col md:flex-row gap-2 items-center">
												{placePredictions.length > 0
													? "Can't find the address you're looking for?"
													: "We couldn't find that address, please try again and make sure it's correct"}
												<Button
													type="button"
													size="full"
													text="Enter address manually "
													variant="accent"
													textAlign="center"
													handleClick={() => {
														setInputValue("");
														setSelectedPlace(undefined);
														setIsManualInput(true);
													}}
												/>
											</div>
										</Combobox.Option>
									)}
								</>
							)}
						</Combobox.Options>
					</Combobox>
				</div>
			)}
			{isManualInputState && (
				<>
					<ManualAddress
						isSuburb={isSuburb}
						onUpdateAddressParts={(addressParts) => {
							onChange({ place: null, addressParts, isManual: true });
						}}
						manualAddressParts={manualAddressParts}
					/>
					<Button
						type="button"
						size="fit"
						text="Search for address"
						variant="accent"
						textAlign="center"
						handleClick={() => {
							setInputValue("");
							setSelectedPlace(undefined);
							setIsManualInput(false);
						}}
					/>
				</>
			)}
			{!error && helperEnabled && (
				<span className="text-xs text-gray-500 font-medium md:leading-3 mb-2">
					You can view our list of eligible postcodes{" "}
					<a
						className="underline"
						href="https://www.sucasa.com.au/eligible-postcodes"
						target="_blank"
						onClick={() => {
							trackEvent("Eligible Postcodes Link Clicked");
						}}
					>
						here.
					</a>
				</span>
			)}
			{!!error && <ErrorMessage message={error} />}
		</div>
	);
};

export default AddressSearch;
