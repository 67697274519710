import type { ReactNode } from "react";
import type { FieldValues, Path, UseFormRegister } from "react-hook-form";
import { InputMask } from "@react-input/mask";
import ErrorMessage from "../error-message/ErrorMessage";

type ModifyFunction = (input: string) =>
	| {
			mask?: string;
			replacement?: { [key: string]: RegExp };
			showMask?: boolean;
			separate?: boolean;
	  }
	| undefined;

type InputMaskWithType<TFieldValues extends FieldValues> = {
	name: string;
	placeholder?: string;
	iconPrefix?: ReactNode;
	iconSuffix?: ReactNode;
	error?: string;
	required?: boolean;
	register: UseFormRegister<TFieldValues>;
	type?: string;
	label?: string;
	mask: string;
	modify?: ModifyFunction;
};

const InputWithMask = <TFieldValues extends FieldValues>({
	name,
	error,
	iconPrefix,
	iconSuffix,
	placeholder,
	required,
	register,
	type,
	label,
	mask,
	modify,
}: InputMaskWithType<TFieldValues>) => {
	const inputId = `input-${name}`;

	return (
		<div className="w-full flex flex-col gap-2">
			{!!label && (
				<label
					htmlFor={inputId}
					className="text-primary text-[21.33px] font-normal mb-2"
				>
					{label}
				</label>
			)}
			<div
				className={`${error ? "border-b-error" : "border-b-off-black-900"} flex items-center gap-2 border-b pb-2`}
			>
				{iconPrefix}
				<InputMask
					showMask
					mask={mask}
					replacement={{ _: /\d/ }} // Only allow digits
					{...register(name as Path<TFieldValues>, {
						setValueAs: (value: string): number | string => {

							const sanitizedValue = value?.replace?.(/\s+/g, "");
							if (!sanitizedValue && !Number.isNaN(Number(value))) {
								return value;
							}

							return type === "number"
								? Number.parseFloat(sanitizedValue) || 0
								: sanitizedValue;
						},
					})}
					id={inputId}
					name={name}
					placeholder={placeholder}
					required={required}
					// autoComplete="off" // Assuming you might want to turn off autocomplete for phone number fields
					className={`${error ? "placeholder-error" : "placeholder-off-black-600"} bg-transparent w-full focus-visible:outline-none`}
					modify={modify}
					type={type === "number" ? undefined : type}
				/>
				{iconSuffix}
			</div>
			{error && <ErrorMessage message={error} />}
		</div>
	);
};

export default InputWithMask;
