import type { FunctionComponent } from "../../../../common/types";

const WbHeader = (): FunctionComponent => {
	return (
		<header
			aria-labelledby="Welcome back content header"
			role="heading"
			className="mb-4 lg:mb-6 lg:mt-14"
		>
			<h1
				className="text-primary text-[37.9px] text-center font-normal mb-4 lg:mb-6 lg:mt-14"
				aria-labelledby="Apply in 15 minutes"
			>
				Welcome back
			</h1>

			<div className="text-off-black-600 text-[21.33px] text-center font-normal leading-8 flex flex-col gap-5">
				<p>Let’s get going again, shall we?</p>
			</div>
		</header>
	);
};

export default WbHeader;
