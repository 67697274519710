import type { FC } from "react";
import HintCard from "../hint-card/HintCard";

export const CreateAccountHint: FC = () => (<HintCard
	icon={<i className="icon-two-users" />}
	text={
		<>
			<span className="font-medium">
				Clicking "Create Account" will create a Sucasa account for you.
			</span>
			It might take a few moments before you are logged into your new
			account.
			<br />
		</>
	}
/>);
