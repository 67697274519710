import { Box } from "./Box";
import Button from "../../../../ui/button/Button";
import { Status } from "../../../../ui/status/Status";
import { useCallback, useState } from "react";
import { emailSchema, mobileSchema } from "../../../../../services/apis/joint-applicant-invite";
import { useTracking } from "../../../../../hooks/use-tracking";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useMarkFormDirty } from "../../../../../hooks/use-mark-form-dirty";
import GenericInput from "../../../generic-input/GenericInput";
import type { ApplicationStep, ApplicationSubStep } from "@sucasa-finance/origination-trpc";
import { origination } from "../../../../../services/origination";

const jointBorrowerInviteSchema = z.object({
	jointBorrowerEmail: emailSchema.optional(),
	jointBorrowerPhone: mobileSchema.optional(),
});

type JointBorrowerInviteFormType = z.infer<typeof jointBorrowerInviteSchema>;

export interface ApplicantStep {
	step: ApplicationStep;
	subStep: ApplicationSubStep;
	createdAt: Date;
}

export interface Applicant {
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	applicationSteps: ApplicantStep[];
}

export interface BoxJointBorrowerProps {
	applicant: Applicant
	applicationId: number
	isAccepted: boolean
}

export const BoxJointBorrower = ({applicant, applicationId, isAccepted}: BoxJointBorrowerProps) => {
	const [isEditing, setIsEditing] = useState(false);
	const onToggleEdit = useCallback(() => { setIsEditing(previous => !previous); }, []);
	const { trackEvent } = useTracking();
	const utils = origination.useUtils();
	const inviteJointApplicant = origination.application.inviteJointApplicant.useMutation({
		onSuccess() {
			return utils.application.getApplicationById.invalidate({
				applicationId
			});
		}
	});
	const formOptions = {
		resolver: zodResolver(jointBorrowerInviteSchema),
		defaultValues: {
			jointBorrowerEmail: applicant.email,
			jointBorrowerPhone: applicant.phone,
		},
	};
	const {
		register,
		handleSubmit,
		formState: { errors, isDirty },
	} = useForm<JointBorrowerInviteFormType>(formOptions);
	useMarkFormDirty(isDirty);

	const onSubmit = useCallback((data: JointBorrowerInviteFormType) => {
		setIsEditing(false);
		trackEvent("resend_invite_coborrower_clicked", { applicationId });
		const { jointBorrowerEmail: email, jointBorrowerPhone: phone } = data;
		const fieldsChanged = [];
		if (email && email !== applicant.email) {
			fieldsChanged.push(["email", email]);
		}
		if (phone && phone !== applicant.phone) {
			fieldsChanged.push(["phone", email]);
		}
		if (fieldsChanged.length > 0) {
			trackEvent("coborrower_details_edited", { applicationId, fieldsEdited: Object.fromEntries(fieldsChanged) });
		}
		inviteJointApplicant.mutate({
			firstName: applicant.firstName,
			lastName: applicant.lastName,
			email: email || applicant.email,
			phone: phone || applicant.phone,
			applicationId: applicationId 
		})
	}, [inviteJointApplicant, applicationId, applicant, trackEvent])

	const validationErrorMessage = (
		errors.jointBorrowerEmail?.message ||
		errors.jointBorrowerPhone?.message
	);
	return (
		<Box className="flex flex-col gap-3">
			<div className="flex flex-row justify-between">
				<div className="text-lg"><i className="icon-user" />&nbsp;Joint Applicant</div>
				<Status status={isAccepted ? "active" : "required"} text={isAccepted ? "Accepted" : "Yet to accept"} icon={<i className={isAccepted ? "icon-check" : "icon-warning"} />} />
			</div>
			{
				isAccepted ? undefined : (
					<>
						<div className="p-4 bg-peach-60 text-black-100">
							Note: You will be unable to successfully lodge your application without your Joint Applicant accepting their invitation and completing the verification
						</div>
						<div className={`flex flex-col justify-start divide-y devide-solid divide-black-10 my-4 border-t border-b ${isEditing ? "border-b-slate-500" : ""}`}>
							<div className="flex flex-row justify-between items-center py-4">
								<div>
									<i className="icon-warning text-peach-100" />&nbsp;{applicant.firstName} {applicant.lastName}
								</div>
							</div>
							<div className="flex flex-row justify-between items-center py-4">
								<div className={`flex flex-row items-center ${isEditing ? "font-medium" : "tracking-wide"}`}>
									<i className="icon-mobile" />&nbsp;
									<GenericInput
										register={register} 
										type="text" 
										disabled={!isEditing} 
										inputContainerClassName="border-none pb-0"
										inputClassName="text-off-black-900"
										name="jointBorrowerPhone"
										placeholder="Joint Applicant Phone"
										noBorder
									/>
								</div>
								<div className={`grow flex flex-row items-center ${isEditing ? "font-medium" : ""}`}>
									<i className="icon-email" />&nbsp;
									<GenericInput 
										register={register}
										type="email" 
										disabled={!isEditing} 
										className="flex-grow" 
										inputContainerClassName="border-none p-0"
										inputClassName="text-off-black-900"
										name="jointBorrowerEmail"
										placeholder="Joint Applicant Email Address"
										noBorder
									/>
								</div>
								{
									isEditing ? undefined : (
										<Button
											text="Edit"
											className="min-w-min rounded"
											iconPrefix={<i className="icon-edit" />}
											variant="link"
											textAlign="center"
											handleClick={onToggleEdit}
										/>
									)
								}
							</div>
						</div>
						{
							inviteJointApplicant.error || (isEditing && validationErrorMessage) ? (
								<div className="p-4 bg-peach-60 text-red-500">
									<i className="icon-warning" />&nbsp;{(isEditing && validationErrorMessage) || String(inviteJointApplicant.error?.message)}
								</div>
							) : undefined
						}
						<div className="flex flex-row justify-between items-center">
							{
								inviteJointApplicant.isSuccess ? (
									<span className="text-lg">
										Invite sent!
									</span>
								) : (
									(isEditing || inviteJointApplicant.isPending) ? <span /> : (<span/>
										// <Button
										// 	text="Talk to a consultant"
										// 	className="min-w-min text-lg rounded"
										// 	iconSuffix={<i className="icon-message" />}
										// 	variant="accent"
										// 	textAlign="center"
										// 	handleClick={() => {
										// 		// TODO add link to open chat
										// 		console.log("Talk to a consultant");
										// 	}}
										// />
									)
								)
							}
							<Button
								text="Resend Invite"
								className="min-w-min text-lg rounded"
								iconSuffix={<i className="icon-send" />}
								variant="primary"
								textAlign="center"
								isDisabled={Boolean(validationErrorMessage) || inviteJointApplicant.isPending || (!isDirty && inviteJointApplicant.isSuccess)}
								handleClick={handleSubmit(onSubmit)}
							/>
						</div>
					</>
				)
			}
		</Box>
	)
};