import { type ChangeEvent, useEffect, useState } from "react";

// Reusable component for displaying age of dependents
const AgeDisplay = ({
	label,
	age,
	onAgeChange,
}: {
	label: string;
	age: number;
	onAgeChange: (event: ChangeEvent<HTMLInputElement>) => void;
}) => (
	<div className="mt-6 w-full flex flex-col gap-2" aria-labelledby={label}>
		{!!label && (
			<label className="text-primary text-[21.33px] font-normal mb-2">{label}</label>
		)}
		<div className="flex items-center gap-2 border-b pb-2 border-b-off-black-900">
			<input
				value={age}
				onChange={onAgeChange}
				placeholder="0"
				className="bg-transparent  w-full focus-visible:outline-none self-start px-5 pb-2 mt-2 text-5xl font-medium tracking-tight whitespace-nowrap text-zinc-900 max-md:text-[37.9px]"
			/>
		</div>
	</div>
);

const ActionButton = ({
	symbol,
	onClick,
	isDisabled,
}: {
	symbol: string;
	onClick: () => void;
	isDisabled: boolean;
}) => (
	<button
		type="button" // Prevents the button from submitting the form
		className={`w-full flex justify-center items-center self-stretch px-6 py-1 my-auto aspect-[1.5] bg-zinc-900 text-white text-2xl font-bold transition-all cursor-pointer hover:opacity-75 ${isDisabled ? "opacity-20" : ""}`}
		onClick={(event) => {
			event.preventDefault(); // Further ensures the form is not submitted
			onClick();
		}}
		disabled={isDisabled}
	>
		{symbol}
	</button>
);

const DependentInfoForm = ({
	initialDependents,
	onChange,
}: {
	initialDependents?: Array<number>;
	onChange: (dependants: Array<number>) => void;
}) => {
	const [dependents, setDependents] = useState<Array<number>>(initialDependents ?? []);

	const handleAgeChange = (index: number, newAgeValue: string) => {
		const newAge = newAgeValue === "" ? 0 : Math.max(0, Number(newAgeValue));
		const updatedDependents = [...dependents];
		updatedDependents[index] = newAge;
		setDependents(updatedDependents);
	};

	useEffect(() => {
		onChange(dependents);
	}, [dependents, onChange]);

	return (
		<section className="flex flex-col pb-2 max-w-[528px]">
			<h4 className="text-primary text-[21.33px] font-normal">
				Number of dependents (for all applicants)?
			</h4>
			<div className="flex gap-5 justify-between items-center self-start px-3 mt-4">
				<ActionButton
					symbol="-"
					onClick={() => {
						setDependents(dependents.slice(0, -1));
					}}
					isDisabled={dependents.length === 0}
				/>
				<div className="self-stretch text-5xl font-medium tracking-tight leading-[64.77px] text-zinc-900 max-md:text-[37.9px]">
					{dependents.length}
				</div>
				<ActionButton
					symbol="+"
					onClick={() => {
						setDependents([...dependents, 0]);
					}}
					isDisabled={false}
				/>
			</div>
			{dependents.map((age, index) => (
				<AgeDisplay
					key={index}
					label={`Age of Dependent ${index + 1}`}
					age={age}
					onAgeChange={(event) => {
						const value = event.target.value;
						// Allow only numeric values or an empty string (to handle backspace)
						if (/^\d*$/.test(value)) {
							// Changed regex to allow empty string
							handleAgeChange(index, value);
						}
					}}
				/>
			))}
		</section>
	);
};

export default DependentInfoForm;
