import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { NamedRoute } from "../components/utils/NamedRoutes";
import { useLDFlags } from "./use-ld-flags";
import { useResetSession } from "./use-reset-session";

export const useLogOut = () => {
	const navigate = useNavigate();
	const { auth0Login } = useLDFlags();
	const { logout } = useAuth0();

	const clearSession = useResetSession();

	const handleLogout = useCallback(() => {
		clearSession();
		if (auth0Login) {
			void logout({ logoutParams: { returnTo: window.location.origin } });
		} else {
			navigate(NamedRoute.intro);
		}
	}, [auth0Login, clearSession, logout, navigate]);

	const clearOrRedirect = useCallback((logout = false) => {
		if (auth0Login && logout) {
			clearSession();
			navigate(NamedRoute.intro, { replace: true });
		} else if (auth0Login) {
			navigate(NamedRoute.intro, { replace: true });
		} else {
			clearSession();
		}
	}, [auth0Login, clearSession, navigate]);

	return {
		handleLogout,
		clearSession,
		clearOrRedirect,
	};
};
