import type { FC, ReactNode } from "react";

export type TStatus = 'active' | 'inactive' | 'alert' | 'required' | 'pending';
type TStatusProps = {
	status: TStatus;
	text?: string;
	icon?: ReactNode;
}
export const Status: FC<TStatusProps> = ({ status, text, icon }) => (
	<span className={`flex items-center gap-2 py-2 px-3 rounded-sm font-medium text-xs text-nowrap h-fit capitalize ${status === 'active'
	&& 'bg-grass-hopper-400 text-primary'} ${status === 'inactive'
	&& 'bg-off-black-200 text-primary'} ${status === 'alert'
	&& 'bg-red-brick-10 text-red-brick-100'} ${status === 'required'
	&& 'bg-peach-60 text-peach-100'} ${status === 'pending'
	&& 'bg-lavender-100 text-off-black-900'}`}>
		{text || status}
		{icon}
	</span>
);
