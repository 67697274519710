import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { SubStep } from "../../services/apis/create-application.schema";
import { getProgress } from "../../lib/progress";

export const jointStepOrder = [
	"detailsStep",
	"financeStep",
	"confirmationStep",
	"uploadStep",
	"assessmentStep",
];

export type jointStepperState = {
	steps: Record<
		(typeof jointStepOrder)[number],
		{
			isCompleted: boolean;
			isCurrent: boolean;
		}
	>;
	selectedSubStep: SubStep;
	progress: number;
};

const initialState: jointStepperState = {
	steps: {
		detailsStep: {
			isCompleted: false,
			isCurrent: true,
		},
		financeStep: {
			isCompleted: false,
			isCurrent: false,
		},
		confirmationStep: {
			isCompleted: false,
			isCurrent: false,
		},
		uploadStep: {
			isCompleted: false,
			isCurrent: false,
		},
		assessmentStep: {
			isCompleted: false,
			isCurrent: false,
		},
	},
	selectedSubStep: SubStep.BeforeWeGetStarted,
	progress: 0,
};

export const jointStepperSlice = createSlice({
	name: "jointStepper",
	initialState: initialState,
	reducers: {
		navigateToJointStep: (
			state,
			{
				payload,
			}: PayloadAction<{
				step: keyof jointStepperState["steps"];
				subStep: SubStep;
			}>
		) => {
			// Mark all steps as not current initially
			Object.keys(state).forEach((key) => {
				const step = state.steps[key];
				if (step) step.isCurrent = false;
			});

			state.selectedSubStep = payload.subStep;
			const progress = getProgress(payload.subStep, true);
			// if progress is undefined for some reason, leave previous progress as it is
			if (progress !== undefined) {
				state.progress = progress;
			}
			// Mark the target step as current
			const currentStep = state.steps[payload.step];
			if (currentStep) currentStep.isCurrent = true;

			const currentIndex = jointStepOrder.indexOf(payload.step);

			// Reset isCompleted for all steps
			jointStepOrder.forEach((step, index) => {
				if (index < currentIndex) {
					// For steps before the current one, mark as completed
					const previousStep = state.steps[step];
					if (previousStep) previousStep.isCompleted = true;
				} else if (index > currentIndex) {
					// For steps after the current one, mark as not completed
					const nextStep = state.steps[step];
					if (nextStep) nextStep.isCompleted = false;
				}
			});
		},
		setSubStep: (state, { payload }: PayloadAction<SubStep>) => {
			state.selectedSubStep = payload;
			const progress = getProgress(payload, true);
			if (progress !== undefined) {
				state.progress = progress;
			}
		},
		resetJointStepper: () => initialState,
	},
});

export const {
	navigateToJointStep,
	setSubStep,
	resetJointStepper,
} = jointStepperSlice.actions;

export default jointStepperSlice.reducer;
