import { useForm } from "react-hook-form";
import { creditCheckOptions } from "../../../utils/BootList";
import RadioGroup from "../../radio-group/RadioGroup";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux-hooks";
import type { RootState } from "../../../../store";
import { setCreditCheckPreference } from "../../../../store/slices/form-slice";
import {
	addDynamicHint,
	removeDynamicHint,
	setDynamicHints,
} from "../../../../store/slices/ui-slice";
import {
	CreditCheckPreference,
	SubStep,
} from "../../../../services/apis/create-application.schema";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { createSelector } from "@reduxjs/toolkit";
import Button from "../../../ui/button/Button";
import { useEffect } from "react";
import { navigateToStep } from "../../../../store/slices/stepper-slice";
import { navigateToJointStep } from "../../../../store/slices/joint-stepper-slice";
import useIsMobile from "../../../../hooks/use-is-mobile";
import { CreditCheckHint } from "../../../ui/hint/CreditCheckHint";
import FormLayout from "../../../ui/form/Form";
import { useMarkFormDirty } from "../../../../hooks/use-mark-form-dirty";
import useTrackPageViewOnMount from "../../../../hooks/use-track-on-mount";

const YourHomeLandingSchema = z.object({
	creditCheckPreference: z.nativeEnum(CreditCheckPreference),
});

type YourHomeLandingSchemaFormType = z.infer<typeof YourHomeLandingSchema>;

const selectYourHomeLanding = createSelector(
	(state: RootState) => state.form,
	(form) => ({
		creditCheckPreference: form.creditCheckPreference,
	})
);

const YourHomeLanding = ({ isJoint }: { isJoint?: boolean }) => {
	const dispatch = useAppDispatch();
	const isMobile = useIsMobile();
	const formState = useAppSelector(selectYourHomeLanding);
	const {
		register,
		handleSubmit,
		formState: { errors, isValid, isDirty },
	} = useForm<typeof formState, unknown, YourHomeLandingSchemaFormType>({
		resolver: zodResolver(YourHomeLandingSchema),
		defaultValues: formState,
	});

	useMarkFormDirty(isDirty);

	useTrackPageViewOnMount({
		page: isJoint ? "Your Details" : "Your Home",
		subPage: "Credit Check Preference",
	});

	const saveData = (data: YourHomeLandingSchemaFormType) => {
		dispatch(setCreditCheckPreference(data.creditCheckPreference));
		if (isJoint) {
			dispatch(
				navigateToJointStep({
					step: "detailsStep",
					subStep: SubStep.JointBorrowerAboutYou,
				})
			);
		} else {
			dispatch(
				navigateToStep({ step: "homeStep", subStep: SubStep.LetsStart })
			);
		}
	};
	useEffect(() => {
		dispatch(setDynamicHints([]));
		dispatch(addDynamicHint("CreditCheckHint"));
		return () => {
			dispatch(removeDynamicHint("CreditCheckHint"));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<FormLayout
			header={
				<h1 className="text-primary text-[37.9px] font-normal">
					Before we get started.
				</h1>
			}
			content={
				<RadioGroup
					radioOptions={creditCheckOptions}
					legend="When should we run a credit check?"
					register={register}
					error={
						errors.creditCheckPreference && errors.creditCheckPreference.message
					}
				/>
			}
			footer={
				<>
					{isMobile && (<CreditCheckHint />)}
					<span className="flex justify-end mt-5 md:mt-0">
						<Button
							text="Next"
							variant="primary"
							iconSuffix={<i className="icon-arrow" />}
							isDisabled={!isValid}
						/>
					</span>
				</>
			}
			onSubmit={handleSubmit(saveData)}
		/>
	);
};

export default YourHomeLanding;
