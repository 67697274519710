import { zodResolver } from "@hookform/resolvers/zod";
import { type FormEvent, useEffect } from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import type { OptionsType } from "../../../../../common/types";
import {
	useAppDispatch,
	useAppSelector,
} from "../../../../../hooks/redux-hooks";
import {
	setTwoYearsAddressHistoryForm,
	setJointBorrowerFirstName,
} from "../../../../../store/slices/form-slice";
import {
	setDynamicHints,
	addDynamicHint,
	removeDynamicHint,
} from "../../../../../store/slices/ui-slice";
import { setFormLoading } from "../../../../../store/slices/loader-slice"; // Added for form loading
import Button from "../../../../ui/button/Button";
import GenericInput from "../../../generic-input/GenericInput";
import SelectInput from "../../../select-input/SelectInput";
import CheckboxInput from "../../../checkbox-input/CheckboxInput";
import {
	type JointApplicantInviteFormSlice,
	type JointApplicantInviteFormType,
	JointApplicantInviteSchema,
} from "./JointApplicantInviteSchema";
import { useJointApplicantInvite } from "../../../../../services/api";
import { navigateToStep } from "../../../../../store/slices/stepper-slice";
import {
	SubStep,
	Step,
} from "../../../../../services/apis/create-application.schema";
import useIsMobile from "../../../../../hooks/use-is-mobile";
import { JointApplicantInviteHint } from "../../../../ui/hint/JointApplicantInviteHint";
import InputWithMask from "../../../input-with-mask/InputWithMask";
import { setToast } from "../../../../../store/slices/toast-slice";
import FormLayout from "../../../../ui/form/Form";
import useTrackPageViewOnMount from "../../../../../hooks/use-track-on-mount";

const titleOptions: Array<OptionsType> = [
	{
		id: 1,
		label: "Mr",
		value: "mr",
	},
	{
		id: 2,
		label: "Mrs",
		value: "mrs",
	},
	{
		id: 3,
		label: "Miss",
		value: "miss",
	},
	{
		id: 4,
		label: "Ms",
		value: "ms",
	},
	{
		id: 5,
		label: "Dr",
		value: "dr",
	},
	{
		id: 6,
		label: "Other",
		value: "other",
	},
];

const JointApplicantInvite = () => {
	const dispatch = useAppDispatch();
	const isMobile = useIsMobile();
	const formState = useAppSelector((state) => state.form);
	const JointApplicantInviteDefaultValues: JointApplicantInviteFormSlice = {
		firstName: "",
		middleName: "",
		surname: "",
		title: {
			id: 0,
			label: "Select an option",
			value: "",
		},
		noMiddleName: false,
		email: "",
		mobile: "",
	};

	const {
		register,
		handleSubmit,
		watch,
		setValue,
		formState: { errors },
	} = useForm<JointApplicantInviteFormType>({
		resolver: zodResolver(JointApplicantInviteSchema),
		defaultValues: { ...JointApplicantInviteDefaultValues },
	});

	useTrackPageViewOnMount({
		page: "Your Details",
		subPage: "Joint Applicant Invite",
	});

	const [inviteJointApplicant, { isLoading }] =
		useJointApplicantInvite();

	const saveData: SubmitHandler<JointApplicantInviteFormType> = async (
		data: JointApplicantInviteFormType
	) => {

		try {
			await inviteJointApplicant({
				email: data.email,
				firstName: data.firstName,
				surname: data.surname,
				title: data.title.value,
				mobile: data.mobile.toString(),
				noMiddleName: data.noMiddleName,
				middleName: data.middleName,
				applicationStep: Step.YourFinances,
				applicationSubStep: SubStep.YourEmployment,
			}).unwrap();
			dispatch(setJointBorrowerFirstName(data.firstName));

			dispatch(
				setTwoYearsAddressHistoryForm(formState.previousAddressHistoryForm!)
			);
			dispatch(
				navigateToStep({
					step: "financeStep",
					subStep: SubStep.YourEmployment,
				})
			);
		} catch (error) {
			if ((error as Error)?.message == 'Primary applicant cannot be invited as secondary applicant (separate email addresses are required)') {
				dispatch(
					setToast({
						open: true,
						type: "error",
						title: "Error",
						description: "Looks like you're trying to invite yourself as a joint applicant. Please use a different email address.",
					})
				);
				return;
			}

			// @ts-expect-error TODO(janyk): fix this
			if (error.status === 409) {
				dispatch(
					setToast({
						open: true,
						type: "error",
						title: "Error",
						description: "This user already has an account.",
					})
				);
				return;
			}
			dispatch(
				setToast({
					open: true,
					type: "error",
					title: "Error",
					description: "An error occurred while inviting the joint applicant.",
				})
			);
		}
	};

	useEffect(() => {
		dispatch(setFormLoading(isLoading));
		return () => {
			dispatch(setFormLoading(false));
		};
	}, [isLoading, dispatch]);

	useEffect(() => {
		dispatch(setDynamicHints([]));
		dispatch(addDynamicHint("JointApplicantInviteHint"));
		return () => {
			dispatch(removeDynamicHint("JointApplicantInviteHint"));
		};
	}, [dispatch]);

	register("noMiddleName", {
		onChange: (event: FormEvent<HTMLInputElement>) => {
			if (event.currentTarget.checked) {
				setValue("middleName", "");
			}
		},
	});
	const header = (
		<h1 className="text-primary text-[37.9px] font-normal">
			Joint Applicant Details
		</h1>
	);

	const content = (
		<div className="flex flex-col gap-9">
			<SelectInput
				name="title"
				value={
					watch("title") || {
						id: 0,
						value: "",
						label: "Select an option",
					}
				}
				label="Title"
				options={titleOptions}
				register={register}
				error={errors.title?.id?.message}
			/>
			<GenericInput
				name="firstName"
				register={register}
				type="text"
				label="First Name"
				placeholder="First name"
				error={errors.firstName?.message}
			/>
			{!watch("noMiddleName") && (
				<GenericInput
					name="middleName"
					register={register}
					type="text"
					label="Middle Name"
					placeholder="Middle Name"
				/>
			)}
			<div className="-mt-6">
				<CheckboxInput
					label="They don't have a middle name"
					name="noMiddleName"
					checked={false}
					register={register}
					size="small"
				/>
			</div>
			<GenericInput
				name="surname"
				register={register}
				type="text"
				label="Surname"
				placeholder="Surname"
				error={errors.surname?.message}
			/>
			<GenericInput
				name="email"
				register={register}
				type="email"
				label="Email"
				placeholder="Email"
				error={errors.email?.message}
			/>
			<InputWithMask
				name="mobile"
				register={register}
				type="tel"
				label="Mobile"
				placeholder="Mobile number"
				mask="+61 ___ ___ ___"
				modify={(input) => {
					if (input[0] === "0") {
						return {
							mask: "+61 ____ ___ ___",
						};
					}
				}}
				error={errors.mobile && errors.mobile?.message}
			/>
			{isMobile && (<JointApplicantInviteHint />)}
		</div>
	);
	const footer = (
		<div className="flex items-center justify-between gap-4">
			<Button
				text="Back"
				variant="accent"
				iconPrefix={<i className="icon-arrow rotate-180" />}
				handleClick={() => {
					dispatch(
						navigateToStep({
							step: "detailsStep",
							subStep: formState.previousAddressHistoryForm as SubStep,
						})
					);
				}}
				isDisabled={isLoading}
			/>
			<Button
				text="Next"
				variant="primary"
				iconSuffix={<i className="icon-arrow" />}
				isDisabled={isLoading}
			/>
		</div>
	);
	return (
		<FormLayout
			header={header}
			content={content}
			footer={footer}
			onSubmit={handleSubmit(saveData)}
		/>
	);
};

export default JointApplicantInvite;
