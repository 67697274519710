import { useCallback } from "react";
import { useAppSelector } from "../../../hooks/redux-hooks";
import { legacy } from "../../api";
import { origination } from "../../origination";
import { DEFAULT_TOKEN_TYPE } from "../auth.schema";
import { useOriginationCallback, useOriginationMutationResult } from "./mutation";

export function useOTPSend() {
	const [invokeOriginal, originalMutation] = legacy.useOtpSendMutation();
    const mutation = origination.auth.startUserPasswordlessLogin.useMutation();
    const create = useOriginationCallback(
        invokeOriginal,
        mutation.mutateAsync,
        (value) => value,
        (response) =>({
            data: response
        })
    )
    const mutationResult = useOriginationMutationResult(originalMutation, mutation);
    return [
        create,
        mutationResult
    ] as const;
}

export function useOTPResend() {
	const [invokeOriginal, originalMutation] = legacy.useOtpResendMutation();
    const mutation = origination.auth.startUserPasswordlessLogin.useMutation();
    const { email, mobile } = useAppSelector(({ form }) => ({
        email: form.email,
        mobile: form.mobile
    }));
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const mapRequest = useCallback((_ignored: { verificationToken: string }) => ({
        email,
        mobile,
        tokenType: DEFAULT_TOKEN_TYPE
    }), [email, mobile])
    const create = useOriginationCallback(
        invokeOriginal,
        mutation.mutateAsync,
        mapRequest,
        (response) =>({
            data: response
        })
    )
    const mutationResult = useOriginationMutationResult(originalMutation, mutation);
    return [
        create,
        mutationResult
    ] as const;
}