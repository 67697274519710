import type { FC } from "react";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import { Step } from "../../../../services/apis/create-application.schema";
import { selectApplicationStep } from "../../../../store/slices/origination-slice";
import WbStepperItem from "./wb-stepper-item/WbStepperItem";
import { wbAllSteps, wbJointSteps } from "./WbStepper.config";

const WbStepper: FC<{ isJointApplicant: boolean }> = ({
	isJointApplicant,
}) => {
	const applicationStep = useAppSelector(selectApplicationStep);
	const stepsToShow = isJointApplicant ? wbJointSteps : wbAllSteps;

	// Determine the index of the current application step
	const currentStepIndex =
		applicationStep === Step.Submitted
			? 4
			: stepsToShow.findIndex((step) => step.label === applicationStep);

	// Update steps based on the current application step
	const updatedSteps = stepsToShow.map((step, index) => ({
		...step,
		isCompleted: index < currentStepIndex,
		isCurrent: step.label === applicationStep,
	}));

	return (
		<div
			className="card-footer p-4 md:pb-6 md:px-8 relative text-white z-10"
			aria-labelledby="Card Stepper info"
		>
			<ul className="text-xs flex justify-between overflow-x-auto">
				{updatedSteps.map((item) => (
					<WbStepperItem
						key={item.id}
						id={item.id}
						isCompleted={item.isCompleted}
						isCurrent={item.isCurrent}
						label={item.label}
						value={item.value}
						isLastItem={item.isLastItem}
						stepType={item.stepType}
					/>
				))}
			</ul>
		</div>
	);
};

export default WbStepper;
