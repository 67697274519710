import { type FC, useMemo } from "react";
import type { ApplicationStep } from "@sucasa-finance/origination-trpc";
import { WbStepperItemNew } from "./WbStepperItemNew";
import { useWbSteps } from "../../../../../hooks/use-wb-steps";


type TWbStepperNewProps = {
	isJointApplicant: boolean;
	applicationStep: ApplicationStep;
}
export const WbStepperNew: FC<TWbStepperNewProps> = ({
	isJointApplicant,
	applicationStep,
}) => {
	const { steps, currentStepIndex } = useWbSteps({ isJointApplicant, applicationStep });

	// Update steps based on the current application step
	const updatedSteps = useMemo(() => steps.map((step, index) => ({
		...step,
		isCompleted: index < currentStepIndex,
		isCurrent: index === currentStepIndex,
	})), [currentStepIndex, steps]);

	return (
		<div
			className="ps-4 py-4 md:pb-6 md:ps-8 text-white"
			aria-labelledby="Card Stepper info"
		>
			<ul className="text-xs flex flex-col justify-between overflow-x-auto pe-1 border-e border-off-black-400">
				{updatedSteps.map((item) => (
					<WbStepperItemNew
						key={item.id}
						id={item.id}
						isCompleted={item.isCompleted}
						isCurrent={item.isCurrent}
						label={item.label}
						stepType={item.stepType}
						value={item.value}
						isLastItem={item.isLastItem}
					/>
				))}
			</ul>
		</div>
	);
};
