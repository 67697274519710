import { useForm } from "react-hook-form";
import {
	useAppDispatch,
	useAppSelector,
} from "../../../../../hooks/redux-hooks";
import {
	setBirthDate,
	setExitStrategy,
	setExitStrategyDetails,
	setExitStrategyOther,
} from "../../../../../store/slices/form-slice";
import Button from "../../../../ui/button/Button";
import ExitStrategyFC from "./exit-strategy/ExitStrategy";
import {
	ExitStrategy,
	SubStep,
} from "../../../../../services/apis/create-application.schema";
import { isLoanTermValid } from "../../../../utils/helpers";
import { DatePickerInput } from "../../../date-picker-input/DatePickerInput";
import { z } from "zod";
import { createSelector } from "@reduxjs/toolkit";
import type { RootState } from "../../../../../store";
import { zodResolver } from "@hookform/resolvers/zod";
import { navigateToStep } from "../../../../../store/slices/stepper-slice";
import { getMaxBirthDate } from "../../../../../lib/utils";
import FormLayout from "../../../../ui/form/Form";
import useTrackPageViewOnMount from "../../../../../hooks/use-track-on-mount";

const FineDetailsAboutYouSchema = z
	.object({
		birthDate: z.string().min(8, "Please provide your date of birth"),
		exitStrategyOther: z.string().optional(),
		exitStrategyDetails: z.string().optional(),
		exitStrategy: z.nativeEnum(ExitStrategy).array().optional(),
		loanTermInYears: z.coerce.number().optional(),
	})
	.superRefine(
		(
			{
				birthDate,
				loanTermInYears,
				exitStrategy,
				exitStrategyOther,
				exitStrategyDetails,
			},
			context
		) => {
			if (birthDate && !isLoanTermValid(birthDate, Number(loanTermInYears!))) {
				if (!exitStrategy || exitStrategy.length === 0) {
					context.addIssue({
						code: z.ZodIssueCode.custom,
						message: `Please select at least one exit strategy`,
						path: ["exitStrategy"],
					});
				}
				if (
					exitStrategy?.includes("Other") &&
					(!exitStrategyOther || exitStrategyOther.length === 0)
				) {
					context.addIssue({
						code: z.ZodIssueCode.custom,
						message: `Please describe your exit strategy`,
						path: ["exitStrategyOther"],
					});
				}
				if (!exitStrategyDetails || exitStrategyDetails.length === 0) {
					context.addIssue({
						code: z.ZodIssueCode.custom,
						message: `Please explain your exit strategy`,
						path: ["exitStrategyDetails"],
					});
				}
			}
		}
	);
type FineDetailsAboutYouFormType = z.infer<typeof FineDetailsAboutYouSchema>;
export type FineDetailsAboutYouSlice = {
	birthDate: string;
	exitStrategy?: Array<ExitStrategy>;
	exitStrategyOther?: string;
	exitStrategyDetails?: string;
	loanTermInYears?: number;
};

const selectFineDetailsAboutYou = createSelector(
	(state: RootState) => state.form,
	(form): FineDetailsAboutYouSlice => ({
		birthDate: form.birthDate,
		exitStrategy: form.exitStrategy,
		exitStrategyOther: form.exitStrategyOther,
		exitStrategyDetails: form.exitStrategyDetails,
		loanTermInYears: form.loanTermInYears,
	})
);

const FineDetailsAboutYou = () => {
	const dispatch = useAppDispatch();
	const formState = useAppSelector(selectFineDetailsAboutYou);
	const {
		register,
		handleSubmit,
		watch,
		setValue,
		formState: { errors },
	} = useForm({
		resolver: zodResolver(FineDetailsAboutYouSchema),
		defaultValues: formState,
	});

	useTrackPageViewOnMount({
		page: "Your Details",
		subPage: "Birth Date & Exit Strategy",
	});

	const saveData = (rawData: FineDetailsAboutYouSlice) => {
		const data: FineDetailsAboutYouFormType =
			rawData as FineDetailsAboutYouFormType;
		dispatch(setBirthDate(data.birthDate));
		dispatch(setExitStrategy(data.exitStrategy!));
		dispatch(setExitStrategyOther(data.exitStrategyOther!));
		dispatch(setExitStrategyDetails(data.exitStrategyDetails!));
		dispatch(
			navigateToStep({
				step: "detailsStep",
				subStep: SubStep.TwoYearsAddressHistory,
			})
		);
	};
	const onChangeExitStrategy = (checked: boolean, value: string) => {
		const label = value as ExitStrategy;
		let updatedExitStrategy: Array<ExitStrategy> = [];
		const exitStrategy = watch("exitStrategy");
		if (exitStrategy) {
			updatedExitStrategy = [...exitStrategy];
		}
		const indexToCheck = updatedExitStrategy.indexOf(label);
		if (checked && indexToCheck === -1) {
			updatedExitStrategy.push(label);
		} else {
			updatedExitStrategy.splice(indexToCheck, 1);
		}
		if (!updatedExitStrategy.includes("Other")) {
			setValue("exitStrategyOther", "");
		}
		setValue("exitStrategy", updatedExitStrategy);
	};
	const header = (
		<h1 className="text-primary text-[37.9px] font-normal">
			Final Details About You
		</h1>
	);
	const content = (
		<div className="flex flex-col gap-9">
			<DatePickerInput
				name="birthDate"
				onChange={(value) => {
					setValue("birthDate", value!);
				}}
				value={watch("birthDate")}
				label="Birthday"
				error={errors.birthDate?.message}
				maxDate={getMaxBirthDate()}
			/>
			{watch("birthDate") &&
				!isLoanTermValid(
					watch("birthDate"),
					Number(formState.loanTermInYears)
				) && (
					<>
						<ExitStrategyFC
							register={register}
							errors={errors}
							selectedExitStrategies={watch("exitStrategy") || []}
							onChange={onChangeExitStrategy}
						/>
					</>
				)}
		</div>
	);
	const footer = (
		<div
			className="flex items-center justify-end gap-4"
			aria-labelledby="Actions wrapper"
		>
			<Button
				text="Next"
				variant="primary"
				iconSuffix={<i className="icon-arrow" />}
				type="submit" // Ensure button submits the form
			/>
		</div>
	);
	return (
		<FormLayout
			header={header}
			content={content}
			footer={footer}
			onSubmit={handleSubmit(saveData)}
		/>
	);
};

export default FineDetailsAboutYou;
