import type { FC } from "react";

export interface ProgressProps {
    value: number;
    max: number;
    className?: string;
}

export const Progress: FC<ProgressProps> = ({value, max, className}) => {
    return (
        <div className={`w-full h-1 bg-off-black-200 ${className ?? ""}` }>
            <div className="w-full bg-grass-hopper-900 h-1" style={{width: `${value / max * 100}%`}} />
        </div>
    )
}