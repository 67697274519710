import { legacy } from "../../api";
import { origination } from "../../origination";
import { mapUpdateAssetsResponse, useMapUpdateAssetsRequest } from "./mapping/asset";
import { useOriginationCallback, useOriginationMutationResult } from "./mutation";

export function useUpdateAssets() {
    const [invokeOriginal, originalMutation] = legacy.useUpdateAssetsMutation();
    const mutation = origination.application.updateAssets.useMutation();
    const mapRequest = useMapUpdateAssetsRequest();
    const create = useOriginationCallback(
        invokeOriginal,
        mutation.mutateAsync,
        mapRequest,
        mapUpdateAssetsResponse
    )
    const mutationResult = useOriginationMutationResult(originalMutation, mutation);
    return [
        create,
        mutationResult
    ] as const;
}