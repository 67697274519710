import { useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AuthLayout from "../common/layout/AuthLayout";
import { useLogOut } from "../hooks/use-logout";
import useTrackPageViewOnMount from "../hooks/use-track-on-mount";
import { AcceptApplicantInviteForm } from "../components/forms/accept-invite/AcceptApplicantInviteForm";
import { useAuth0 } from "@auth0/auth0-react";
import { useAuthSuccessHandler } from "../hooks/use-auth-success";
import { mapJointInviteDetailsToSuccessfulAuthentication } from "../services/apis/origination/mapping/invite";
import { setApplicationId } from "../store/slices/form-slice";
import { NamedRoute } from "../components/utils/NamedRoutes";
import { useAppDispatch } from "../hooks/redux-hooks";
import type { AcceptInviteResponse } from "../services/apis/origination/types";
import { captureException } from "@sentry/react";

export const AcceptApplicantInvite = () => {

	const {invite} = useParams();

	if (!invite) {
		throw new Error("Invite is required");
	}

	const { loginWithRedirect } = useAuth0();
	const { clearSession } = useLogOut();

	useTrackPageViewOnMount({
		page: "Accept Invite",
	});

	useEffect(() => {
		clearSession();
	}, [clearSession]);

	const {handleSuccessOrigination} = useAuthSuccessHandler()

	const onInvalid = useCallback(() => {
		void loginWithRedirect();
	}, [loginWithRedirect]);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const onValid = useCallback((details: AcceptInviteResponse) => {
		try {
			const auth = mapJointInviteDetailsToSuccessfulAuthentication(details)
			handleSuccessOrigination(auth);
			// origination2.0 applications are fetched with applicationId
			dispatch(setApplicationId(String(details.applicationId)));
			navigate(NamedRoute.jointBorrowerIntro, {state: {applicationId: String(details.applicationId)}});
		} catch (error) {
			captureException(error instanceof Error ? error : new Error("Unknown error accepting invite"), {
				data: {
					error,
				},
			});
			onInvalid();
			return;
		}
	}, [onInvalid, handleSuccessOrigination, navigate, dispatch]);

	return (
		<AuthLayout>
			<section className="lg:max-w-96 flex flex-col justify-center h-full p-4">
				<AcceptApplicantInviteForm invite={invite} onValid={onValid} onInvalid={onInvalid} />
			</section>
		</AuthLayout>
	);
};
