import type { ReactNode } from "react";
import ErrorMessage from "../error-message/ErrorMessage";

type GenericInputTeriaryType = {
	type: string;
	label?: string;
	placeholder?: string;
	iconPrefix?: ReactNode;
	iconSuffix?: ReactNode;
	required?: boolean;
	error?: string;
	value?: string | number;
	max?: string;
	onChangeHandler: (value: number | string) => void;
};

const GenericInputTeriary = (props: GenericInputTeriaryType) => {
	const inputId = `input-${props?.label?.replace(/\s+/g, "")}-${props?.placeholder?.replace(/\s+/g, "")}`;

	return (
		<div
			className="w-full flex flex-col gap-2"
			aria-labelledby={`${props.label}`}
		>
			{!!props.label && (
				<label
					htmlFor={inputId}
					className="text-primary text-[21.33px] font-normal mb-2"
				>
					{props.label}
				</label>
			)}
			<div
				className={`${props.error ? "border-b-error" : "border-b-off-black-900"} flex items-center gap-2 border-b pb-2`}
			>
				{props.iconPrefix}
				<input
					onChange={(event) => {
						props.onChangeHandler(
							props.type === "number"
								? Number.parseFloat(event.currentTarget.value) || 0
								: event.currentTarget.value
						);
					}}
					onKeyDown={(event) => {
						if (event.key === "Enter") {
							event.preventDefault();
						}
					}}
					id={inputId}
					type={props.type}
					value={props.value ?? ""}
					placeholder={props.placeholder}
					required={props.required}
					className={`${props.error ? "placeholder-error" : "placeholder-off-black-600"} bg-transparent w-full focus-visible:outline-none`}
					onWheel={(event) => {
						event.currentTarget.blur();
					}}
					max={props.max}
					step="0.01" // Allow decimal values
				/>
				{props.iconSuffix}
			</div>
			{!!props.error && <ErrorMessage message={props.error} />}
		</div>
	);
};

export default GenericInputTeriary;
