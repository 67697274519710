import type { FC } from "react";

type ErrorType = {
	message: string;
	className?: string;
};

const ErrorMessage: FC<ErrorType> = (props) => {
	return (
		<span
			className={`text-xs text-error font-medium leading-3 ${props.className}`}
		>
			{props.message}
		</span>
	);
};

export default ErrorMessage;
