import { useCallback, useMemo } from "react";

import type { UseTRPCMutationResult } from "@trpc/react-query/shared";
import { useAppSelector } from "../../../hooks/redux-hooks";
import { selectUseOrigination } from "../../../store/slices/origination-slice";
import { captureException } from "../../sentry";

interface WrappedPromise<T> {
    unwrap(): Promise<T>
}

export const useOriginationCallback = <OriginalRequest, OriginalResponse, NewRequest, NewResponse>(
    originalFn: (request: OriginalRequest) => WrappedPromise<OriginalResponse>,
    updatedFn: (request: NewRequest) => Promise<NewResponse>,
    mapInput: (request: OriginalRequest) => NewRequest,
    mapOutput: (response: NewResponse, request: OriginalRequest) => OriginalResponse
) => {
    const useOrigination = useAppSelector(selectUseOrigination);
    return useCallback((request: OriginalRequest): WrappedPromise<OriginalResponse> => {
        if (!useOrigination) {
            return originalFn(request);
        }
        try {
            const mapped = mapInput(request);
            const promise = updatedFn(mapped);
            return {
                async unwrap() {
                    const response = await promise;
                    return mapOutput(response, request);
                }
            }
        } catch (error) {
            console.error("Failure mapping request in", error);
            captureException(error as Error);
            throw error;
        }
    }, [useOrigination, originalFn, mapInput, updatedFn, mapOutput]);
}

export const useOriginationMutationResult = <T extends { isLoading: boolean, isSuccess: boolean }>(
  original: T,
  updated: Pick<UseTRPCMutationResult<unknown, unknown, unknown, unknown>, "isSuccess" | "isPending">
) => {
    const useOrigination = useAppSelector(selectUseOrigination);
    return useMemo(() => {
        if (useOrigination) {
            return {
                isLoading: updated.isPending,
                isSuccess: updated.isSuccess
            }
        }

        return original;
    }, [original, updated.isPending, updated.isSuccess, useOrigination])
}
