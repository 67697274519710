import type { FC } from "react";
import HintCard from "../hint-card/HintCard";

export const HouseHoldExpensesHint: FC = () => (<HintCard
	icon={<i className="icon-asterisk" />}
	text={
		<>
			<span className="font-medium">
				Household Expenses include all loan applicants and their dependants
			</span>
		</>
	}
/>);
