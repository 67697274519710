import type { FunctionComponent } from "../../../../common/types";
import Button from "../../../ui/button/Button";
import { useContinueApplication } from "../../../../hooks/use-continue-application";

const WbFooter = (): FunctionComponent => {
	const { continueApplication } = useContinueApplication()

	return (
		<footer
			className="flex items-center justify-end mt-9"
			aria-labelledby="Actions wrapper"
		>
			<Button
				text="Continue this application"
				variant="primary"
				iconSuffix={<i className="icon-arrow" />}
				handleClick={continueApplication}
			/>
		</footer>
	);
};

export default WbFooter;
