import notInRemitBanner from "../assets/not-in-remit-banner.jpg";
import Layout from "../common/layout/Layout";
import WbFooter from "../components/layout/welcome-back/wb-footer/WbFooter";
import WbHeader from "../components/layout/welcome-back/wb-header/WbHeader";
import WelcomeBackAside from "../components/layout/welcome-back/welcome-back-aside/WelcomeBackAside";
import { WbLoanDetails } from "../components/layout/welcome-back/wp-loan-details/WbLoanDetails";
import { useAppSelector } from "../hooks/redux-hooks";
import {
	selectIsNotInRemit,
} from "../store/slices/auth-slice";
import {
	selectCanUseApplication,
} from "../store/slices/application-slice";
import useTrackPageViewOnMount from "../hooks/use-track-on-mount";

export const WelcomeBack = () => {
	const canUseApplication = useAppSelector(selectCanUseApplication);
	const notInRemit = useAppSelector(selectIsNotInRemit);
	useTrackPageViewOnMount({
		page: "Welcome Back",
	});

	if (!canUseApplication) {
		return (
			<Layout>
				<div className="bg-white p-4 md:p-16 flex flex-col items-center gap-8">
					<img
						src={notInRemitBanner}
						alt="not-in-remit-banner"
						className="w-full hidden lg:block"
					/>
					<div className="flex flex-col items-center gap-2">
						<h1
							className="text-primary text-[37.9px] font-normal"
							aria-labelledby="Welcome back!"
						>
							Welcome back!
						</h1>
						<div className="text-off-black-600 text-[21.33px] font-normal leading-8 text-center">
							<p>
								It looks like you started your application in the previous
								version of the application portal. Please contact
								support@sucasa.com.au to start your application portal again.
							</p>
						</div>
					</div>
				</div>
			</Layout>
		);
	}

	if (notInRemit) {
		return (
			<Layout rightColumn={<WelcomeBackAside />}>
				<div className=" p-4 md:p-16 flex flex-col items-center gap-8">
					<img
						src={notInRemitBanner}
						alt="not-in-remit-banner"
						className="w-full hidden lg:block"
					/>
					<div className="flex flex-col gap-2">
						<h1
							className="text-primary text-[37.9px] font-normal"
							aria-labelledby="Welcome back!"
						>
							Looks like Sucasa isn't a fit (yet)
						</h1>
						<div className="text-off-black-600 text-[21.33px] font-normal leading-8 ">
							<p>
								From what you've told us, Sucasa isn't the best fit for your
								needs right now.
							</p>
							<br />
							<p>We'll reach out as soon as we can help.</p>
						</div>
					</div>
				</div>
			</Layout>
		);
	}

	return (
		<Layout rightColumn={<WelcomeBackAside />}>
			<WbHeader />
			<WbLoanDetails />
			<WbFooter />
		</Layout>
	);
};
