import type { ReactNode } from "react";
import type { FieldValues, Path, UseFormRegister } from "react-hook-form";
import ErrorMessage from "../error-message/ErrorMessage";

type TextAreaType<TFieldValues extends FieldValues> = {
	name: keyof TFieldValues;
	label?: string;
	placeholder?: string;
	iconPrefix?: ReactNode;
	iconSuffix?: ReactNode;
	register: UseFormRegister<TFieldValues>;
	required?: boolean;
	error?: string;
	value?: string;
    resize?: boolean; 
};

const TextArea = <TFieldValues extends FieldValues>(
	props: TextAreaType<TFieldValues>
) => {
	const inputId = `input-${props?.label?.replace(/\s+/g, "")}-${props?.placeholder?.replace(/\s+/g, "")}`;

	return (
		<div
			className="w-full flex flex-col gap-2"
			aria-labelledby={`${props.label}`}
		>
			{!!props.label && (
				<label
					htmlFor={inputId}
					className="text-primary text-[21.33px] font-normal mb-2"
				>
					{props.label}
				</label>
			)}
			<div
				className={`${props.error ? "border-b-error" : "border-off-black-900"} flex items-center gap-2 border p-3`}
			>
				{props.iconPrefix}
				<textarea
					{...props.register(props.name as Path<TFieldValues>, {
						setValueAs: (value: string): string => value,
					})}
                    rows={5}
					id={inputId}
					defaultValue={props.value}
					placeholder={props.placeholder}
					required={props.required}
					className={`${props.error ? "placeholder-error" : "placeholder-off-black-600"} bg-transparent w-full focus-visible:outline-none ${props.resize && "resize-none"}`}
					onWheel={(event) => {
						event.currentTarget.blur();
					}}
				/>
				{props.iconSuffix}
			</div>
			{!!props.error && <ErrorMessage message={props.error} />}
		</div>
	);
};

export default TextArea;
