import { useCallback } from "react";
import type { FieldValues } from "react-hook-form";
import { format, parse } from "date-fns";
import DatePicker from "react-datepicker";
import ErrorMessage from "../error-message/ErrorMessage";
import useIsMobile from "../../../hooks/use-is-mobile";

import "react-datepicker/dist/react-datepicker.css";
import "./date-input.css";

type DateInputType<TFieldValues extends FieldValues> = {
	name: keyof TFieldValues;
	onChange: (date?: string) => void;
	label?: string;
	dateFormat?: string;
	placeholder?: string;
	required?: boolean;
	error?: string;
	value?: string | number;
	maxDate?: Date;
	minDate?: Date;
};

function parseDate(myDate: string): Date {
	return parse(myDate, "dd/MM/yyyy", Date.now());
}

const DEFAULT_DATE_FORMAT = "dd/MM/yyyy";

// TODO[matej]: add form control to DatePickerInput
export const DatePickerInput = <TFieldValues extends FieldValues>({
	name,
	onChange,
	label,
	dateFormat = DEFAULT_DATE_FORMAT,
	placeholder = "DD/MM/YYYY",
	required,
	error,
	value,
	maxDate,
	minDate,
}: DateInputType<TFieldValues>) => {
	const isMobile = useIsMobile();
	const inputId = `input-${label?.replace(/\s+/g, "")}-${placeholder?.replace(/\s+/g, "")}`;

	const handleSelect = useCallback(
		(date?: Date | null) => {
			onChange?.(date ? format(date, dateFormat) : undefined);
		},
		[onChange, dateFormat]
	);

	return (
		<div className="w-full flex flex-col gap-2" aria-labelledby={`${label}`}>
			{!!label && (
				<label
					htmlFor={inputId}
					className="text-primary text-[21.33px] font-normal mb-2"
				>
					{label}
				</label>
			)}
			<div
				className={`${error ? "border-b-error" : "border-b-off-black-900"} flex items-center gap-2 border-b pb-2`}
				onKeyDown={(event) => {
					if (event.key === "Enter") {
						event.preventDefault();
					}
				}}
			>
				<i className="icon-calendar" />
				{isMobile && <div className="datepicker-input-wrapper">
					<div className={`${error ? "text-error" : (!value && "text-off-black-600")} bg-transparent w-full focus-visible:outline-none datepicker-input-wrapper`}>{value || placeholder || dateFormat}</div>
					<input
						onKeyDown={(event) => {
							if (event.key === "Enter") {
								event.preventDefault();
							}
						}}
						id={inputId}
						type="date"
						defaultValue={value ? format(parseDate(value as string), 'yyyy-MM-dd') : undefined}
						placeholder={placeholder}
						required={required}
						autoComplete="off"
						className={`datepicker-input`}
						onWheel={(event) => {
							event.currentTarget.blur();
						}}
						onChange={(event) => {
							handleSelect(event?.target?.value ? new Date(event.target.value) : null);
						}}
						min={minDate ? format(minDate, 'yyyy-MM-dd') : undefined}
						max={maxDate ? format(maxDate, 'yyyy-MM-dd') : undefined}
					/>
				</div>}
				{!isMobile && <DatePicker
					id={inputId}
					name={String(name)}
					autoComplete="off"
					className={`${error ? "placeholder-error" : "placeholder-off-black-600"} bg-transparent w-full focus-visible:outline-none`}
					selected={value ? parseDate(value as string) : undefined}
					onChange={handleSelect}
					minDate={minDate}
					maxDate={maxDate}
					placeholderText={placeholder || dateFormat}
					dateFormat={dateFormat}
					required={required}
					showYearDropdown={true}
					showMonthDropdown={true}
					scrollableYearDropdown={true}
					dropdownMode="select"
					autoFocus={false}
				/>}
			</div>
			{!!error && <ErrorMessage message={error} />}
		</div>
	);
};
