import { useAppSelector } from "./redux-hooks";
import { selectApplicantType } from "../store/slices/application-slice";
import { selectApplicationType } from "../store/slices/origination-slice";
import { track } from "../services/analytics";
import { useCallback } from "react";

export type PageViewType = {
	page: string;
	subPage?: string;
};


export type TrackEventName =
	| "Application Started"
	| "Application Created"
	| "Application Failed to Create"
	| "Application Submitted"
	| "Redirect to Calculator"
	| "Not In Remit Modal Shown"
	| "Genuine Savings Modal Shown"
	| "FHBG Modal Shown"
	| "Eligible Postcodes Link Clicked"
	| "Property Purpose Selected"
	| "Proceed with current expenses Clicked"
	| "Review your expenses Clicked"
	| "Vacant Land or Home and Land Selected"
	| "Postcode Validation"
	| "Will have 5% savings in next 3 months Clicked"
	| "Will not have 5% savings in next 3 months Clicked"
	| "Genuine Savings Modal Closed"
	| "Does meet needs and objectives Clicked"
	| "Does not meet needs and objectives Clicked"
	| "FHBG Next Clicked"
	| "Create Account"
	| "Clicked Resend Code"
	| "Invalid Pin Submission Attempt"
	| "Requesting Pin"
	| "Pin Request Failed"
	| "resend_invite_coborrower_clicked"
	| "coborrower_details_edited"
	| "resume_application_clicked"
	| "biometrics_consent_clicked"
	| "resent_biometrics_clicked"
	| "upload_clicked"
	| "documents_uploaded"
	| "card_description_expanded"
	| "chat_opened"
	| "message_sent";

export type TrackEventFunction = (
	eventName: TrackEventName,
	eventProperties?: Record<string, unknown>
) => void;

export const useTracking = () => {
	const applicationType = useAppSelector(selectApplicationType);
	const applicantType = useAppSelector(selectApplicantType);
	// Check for the debug query parameter, we provide this to our e2e test suite to filter out test events
	const urlParameters = new URLSearchParams(window.location.search);
	const isDebugMode = urlParameters.has("debug");

	const trackPageView = useCallback(
		({ page, subPage }: PageViewType) => {
			if (isDebugMode) {
				console.log(
					"Debug mode enabled, not tracking page view:",
					page,
					subPage
				);
				return;
			}
			track(`Page View - ${page} ${subPage ? `- ${subPage}` : ""}`, {
				page,
				subPage,
				applicationType,
				applicantType,
			});
		},
		[applicationType, applicantType, isDebugMode]
	);
	const trackEvent: TrackEventFunction = useCallback(
		(
			eventName: TrackEventName,
			eventProperties?: Record<string, unknown>
		) => {
			if (isDebugMode) {
				console.log(
					"Debug mode enabled, not tracking event:",
					eventName,
					eventProperties
				);
				return;
			}

			track(eventName, {
				...eventProperties,
				applicationType,
				applicantType,
			});
		},
		[applicationType, applicantType, isDebugMode]
	);
	return { trackPageView, trackEvent };
};
