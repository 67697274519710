import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { pushFullUrlToDataLayer } from "../services/gtm";

const useTrackLocation = () => {
	const location = useLocation();

	useEffect(() => {
		pushFullUrlToDataLayer();
	}, [location]);
};

export default useTrackLocation;
