import type { Action, ThunkAction } from "@reduxjs/toolkit";
import {
	FLUSH,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
	REHYDRATE,
	persistReducer,
	persistStore,
} from "redux-persist";

import storage from "redux-persist/lib/storage";

// eslint-disable-next-line no-duplicate-imports
import { combineSlices, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { api } from "../services/api";
import { slice } from "./slices/auth-slice";
import { applicationSlice } from "./slices/application-slice";
import { formSlice } from "./slices/form-slice";
import { stepperSlice } from "./slices/stepper-slice";
import { homeLoanSpecialistSlice } from "./slices/home-loan-specialist-slice";
import { jointStepperSlice } from "./slices/joint-stepper-slice";
import { loaderSlice } from "./slices/loader-slice";
import { toastSlice } from "./slices/toast-slice";
import { uiSlice } from "./slices/ui-slice";
import { originationSlice } from "./slices/origination-slice";

const persistConfig = {
	key: "root",
	version: 1,
	storage,
	// this is required to prevent the cache mechanisms from RTK and Redux Persist from conflicting
	blacklist: ["api", "loader", "ui"],
};

const rootReducer = combineSlices(
	slice,
	applicationSlice,
	originationSlice,
	formSlice,
	stepperSlice,
	jointStepperSlice,
	homeLoanSpecialistSlice,
	api,
	loaderSlice,
	toastSlice,
	uiSlice
);

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Infer the `RootState` type from the root reducer
export type RootState = ReturnType<typeof rootReducer>;

// The store setup is wrapped in `makeStore` to allow reuse
// when setting up tests that need the same store config

export const store = configureStore({
	reducer: persistedReducer,
	// Adding the api middleware enables caching, invalidation, polling,
	// and other useful features of `rtk-query`.
	middleware: (getDefaultMiddleware) => {
		return getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [
					FLUSH,
					REHYDRATE,
					PAUSE,
					PERSIST,
					PURGE,
					REGISTER,
					"ui/setDynamicHints",
				],
				ignoredPaths: [
					"ui.hints.0.icon.$$typeof",
					"ui.hints.0.text.$$typeof",
					"payload.0.icon.$$typeof",
					"payload.0.icon.$$typeof",
					"ui.hints.0.text.type",
					"ui.hints.0.text.props.children.0.$$typeof",
					"ui.hints.0.text.props.children.1.$$typeof",
				],
			},
			// eslint-disable-next-line unicorn/prefer-spread
		}).concat(api.middleware);
	},
});
// configure listeners using the provided defaults
// optional, but required for `refetchOnFocus`/`refetchOnReconnect` behaviors
setupListeners(store.dispatch);

export const persistor = persistStore(store);

// Infer the type of `store`
export type AppStore = typeof store;
// Infer the `AppDispatch` type from the store itself
export type AppDispatch = AppStore["dispatch"];
export type AppThunk<ThunkReturnType = void> = ThunkAction<
	ThunkReturnType,
	RootState,
	unknown,
	Action
>;
