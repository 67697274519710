import { z } from "zod";
import { SubStep, Step } from "./create-application.schema";

// Define an enum for frequency types
export enum FrequencyType {
	Monthly = "Monthly",
	Annually = "Annually",
	Weekly = "Weekly",
	Fortnightly = "Fortnightly",
	Irregular = "Irregular",
	Other = "Other",
}

export enum EmploymentType {
	// Clerical = "Clerical",
	// Executive = "Executive",
	// Government = "Government",
	// HourlyWageEarner = "Hourly Wage Earner",
	// Life = "Life",
	// Manager = "Manager",
	// MilitaryEnlisted = "Military Enlisted",
	// MilitaryOfficer = "Military officer",
	// Professional = "Professional",
	// Retired = "Retired",
	// Sales = "Sales",
	// SelfEmployed = "Self Employed",
	// Service = "Service",
	// Student = "Student",
	// UnemployedWithIncome = "Unemployed with Income",
	// UnemployedWithoutIncome = "Unemployed without Income",
	FullTimeEmploymentPAYG = "Full Time Employment (PAYG)",
	PartTimeEmploymentPAYG = "Part Time Employment (PAYG)",
	SelfEmployedBusinessOwner = "Self Employed / Business Owner",
	NotEmployed = "Not Employed",
	Contractor = "Contractor",
	Casual = "Casual",
}

// Define an enum for income types
export enum IncomeType {
	EmploymentAndSalary = "Employment and Salary (PAYG)",
	SelfEmployedBusinessOwner = "Self Employed / Business Owner",
	CarAllowance = "Car Allowance",
	Commission = "Commission",
	Bonus = "Bonus",
	OtherIncome = "Other Income (Rental, Benefits etc)",
	SuperannuationAnnuities = "Superannuation / Annuities",
	RentalIncomeResidential = "Rental Income (Residential)",
	OvertimeShiftAllowance = "Overtime / Shift Allowance",
	InvestmentIncome = "Investment Income",
	InterestIncome = "Interest Income",
	GovernmentPension = "Government Pension",
	ContractIncomeFixed = "Contract Income (Fixed)",
	ContractIncomeVariable = "Contract (Variable)",
	ChildSupport = "Child Support",
	CasualIncome = "Casual Income",
	MaternityLeave = "Maternity Leave",
	Overtime = "Overtime",
	ShiftAllowance = "Shift Allowance",
}
export const employmentInformationSchema = z.object({
	isCurrent: z.boolean(),
	employerName: z.string().optional(),
	jobTitle: z.string().optional(),
	jobStartDate: z.string().optional(),
	inProbation: z.boolean().optional(),
	industryExperienceInMonths: z.number().optional(),
	jobEndDate: z.string().optional(),
	currentEmploymentType: z.string(),
	nameOfTheBusiness: z.string().optional(),
	businessIncorporationDate: z.string().optional(),
	abn: z.string().optional(),
});
export const incomeDetailsSchema = z.object({
	incomeType: z.nativeEnum(IncomeType), // Use the IncomeType enum here
	currentIncomeAmount: z.number(),
	priorIncomeAmount: z.number(),
	receivedForLastSixMonths: z.boolean(),
	frequency: z.nativeEnum(FrequencyType),
});
export const incomeSchema = z.object({
	applicationStep: z.literal(Step.YourFinances),
	applicationSubStep: z.union([z.literal(SubStep.YourHouseholdExpenses), z.literal(SubStep.YourAssets)]),
	currentEmploymentType: z.nativeEnum(EmploymentType),
	receivedOverTimeOrAllowance: z.boolean(),
	receivedCommissionOrBonus: z.boolean(),
	hasAnyOtherIncome: z.boolean(),
	employmentInformation: employmentInformationSchema.array(),
	incomeDetails: incomeDetailsSchema.array(),
	jointBorrowerIncome: z.number().optional(),
});

export type UpdateIncomeRequest = z.infer<typeof incomeSchema>;
export type EmploymentInformationType = z.infer<
	typeof employmentInformationSchema
>;
export type IncomeDetailsType = z.infer<typeof incomeDetailsSchema>;
export interface ValidationErrorDetail {
	code: string;
	expected: string;
	received: string;
	path: Array<string | number>;
	message: string;
}

interface UpdateIncomeErrorResponse {
	status: "ERROR";
	statusCode: 400;
	message: string;
	details: Array<ValidationErrorDetail>;
}

export type UpdateIncomeResponse =
	| {
			statusCode: number;
			status: string;
			message: string;
			content: {
				success: boolean;
				message: string;
			};
	  }
	| UpdateIncomeErrorResponse;
