import { type CreateIncome, IncomeType as TargetIncomeType, FrequencyType as TargetFrequencyType } from "@sucasa-finance/origination-trpc";
import { incomeFrequencyTypeMapping, incomeTypeMapping } from "./enum-mapping";
import type { IncomeDetailsType, UpdateIncomeRequest, UpdateIncomeResponse  } from "../../update-income.schema";
import { emptyOkResponseData } from "./response";

export const mapIncome = (detail: IncomeDetailsType): CreateIncome => {
	let sourceType = incomeTypeMapping[detail.incomeType];
	// we are splitting IncomeType.OvertimeShiftAllowance type
	// into IncomeType.ShiftAllowance and IncomeType.Overtime
	if (sourceType === TargetIncomeType.OvertimeShiftAllowance) {
		sourceType = TargetIncomeType.ShiftAllowance;
	}
	let frequency = incomeFrequencyTypeMapping[detail.frequency];
	// ShiftAllowance and Overtime frequency is monthly, old system maps to other
	if ([TargetIncomeType.ShiftAllowance, TargetIncomeType.Overtime].includes(sourceType)
		&& frequency === TargetFrequencyType.Other) {
		frequency = TargetFrequencyType.Monthly
	}
	return {
		sourceType,
		amount: detail.currentIncomeAmount,
		frequency,
		declaredReceivedForLastSixMonths: detail.receivedForLastSixMonths,
		priorAmount: detail.priorIncomeAmount
	}
}

export const mapIncomeArray = (from: Array<IncomeDetailsType>): Array<CreateIncome> => {
	return from.map(detail => mapIncome(detail));
}

export const mapIncomeRequest = (from: Pick<UpdateIncomeRequest, "incomeDetails">): Array<CreateIncome> => {
	return mapIncomeArray(from.incomeDetails)
}

export const mapIncomeResponse = (): UpdateIncomeResponse => {
	return emptyOkResponseData();
}
