export const PRIVACY_POLICY_URL = "https://www.sucasa.com.au/privacy-policy";
export const CREDIT_GUIDE_URL = "https://www.sucasa.com.au/credit-guide";
export const TMD_URL = "https://www.sucasa.com.au/target-market-determination";
export const CONTACT_URL = "https://www.sucasa.com.au/contact";

export const sitemapLinks = [
    {
        id: 1,  
        route: PRIVACY_POLICY_URL,
        displayText: "Privacy Policy",
    },
    {
        id: 2,
        route: CREDIT_GUIDE_URL,
        displayText: "Credit Guide",
    },
    {
        id: 3,
        route: TMD_URL,
        displayText: "TMD",
    },
    {
        id: 4,
        route: CONTACT_URL,
        displayText: "Contact Us",
    },
];