import { useEffect, useState } from "react";
import Button from "../../../../../../ui/button/Button";
import type { Control, FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import type { LoanOnProperty } from "../../../../../../../services/apis/create-application.schema";
import RadioGroupSecondary from "../../../../../radio-group-secondary/RadioGroupSecondary";
import { LoanTypeSecondaryOptions } from "../../../../../../utils/BootList";
import { DatePickerInput } from "../../../../../date-picker-input/DatePickerInput";
import TotalSavings from "../../../buying-workflow/total-savings/TotalSavings";
import DollarInput from "../../../../../dollar-input/DollarInput";
import type { CurrentHomeLoanSchemaFormType, CurrentHomeLoanSchemaSlice } from "../CurrentHomeLoan";
import type { LoanOnPropertyType } from "../../../../../../../services/apis/create-application.schema";

type LoanOnPropertyErrorType = Merge<
	FieldError,
	Array<Merge<FieldError, FieldErrorsImpl<LoanOnProperty>> | undefined>
>;

const LoanOnPropertyDisplay = ({
	index,
	loanOnProperty,
	onChangeLoanOnPropertyHandler,
	removeLoanOnPropertyHandler,
	control,
	errors,
}: {
	index: number;
	loanOnProperty: LoanOnProperty;
	onChangeLoanOnPropertyHandler: (value: LoanOnProperty) => void;
	removeLoanOnPropertyHandler: (value: number) => void;
	control: Control<CurrentHomeLoanSchemaFormType, CurrentHomeLoanSchemaSlice>;
	errors?: LoanOnPropertyErrorType;
}) => (
	<div className="w-full flex flex-col gap-4">
		<div className="flex justify-between items-center">
			<h3 className="text-[21.33px] leading-8 font-medium">{`Loan ${index + 1}`}</h3>
			{index > 0 && (
				<Button
					text="Remove"
					variant="link"
					type="button"
					iconPrefix={<i className="icon-delete" />}
					handleClick={() => {
						removeLoanOnPropertyHandler(index);
					}}
				/>
			)}
		</div>
		<RadioGroupSecondary
			radioOptions={LoanTypeSecondaryOptions}
			selectedValue={loanOnProperty.type}
			onChange={(value) => {
				const updateLoanOnProperty = {
					...loanOnProperty,
				};
				updateLoanOnProperty.type = value.toString() as LoanOnPropertyType;
				onChangeLoanOnPropertyHandler(updateLoanOnProperty);
			}}
			error={errors && errors[index]?.type && "Please select interest type"}
		/>
		<div className="flex flex-col lg:flex-row gap-4">
			<DollarInput
				name={`loansOnProperty.${index}.amount`}
				control={control}
				type="numeric"
				value={loanOnProperty.amount}
				error={
					errors && errors[index]?.amount && errors[index]?.amount?.message
				}
				label=""
				placeholder="Loan Amount"
				iconPrefix={<i className="icon-dollar" />}
				onValueChange={(value) => {
					const updateLoanOnProperty = {
						...loanOnProperty,
					};
					updateLoanOnProperty.amount = value;
					onChangeLoanOnPropertyHandler(updateLoanOnProperty);
				}}
			/>
			<DollarInput
				name={`loansOnProperty.${index}.interestRate`}
				control={control}
				type="decimal"
				value={loanOnProperty.interestRate}
				error={
					errors &&
					errors[index]?.interestRate &&
					errors[index]?.interestRate?.message
				}
				label=""
				placeholder="Interest Rate"
				iconSuffix={<i className="flex shrink-0">% p.a.</i>}
				onValueChange={(value) => {
					const updateLoanOnProperty = {
						...loanOnProperty,
					};
					updateLoanOnProperty.interestRate = value;
					onChangeLoanOnPropertyHandler(updateLoanOnProperty);
				}}
			/>
		</div>
		<div className="flex flex-col lg:flex-row gap-4">
			<DollarInput
				name={`loansOnProperty.${index}.monthlyRepayment`}
				control={control}
				type="numeric"
				value={loanOnProperty.monthlyRepayment}
				error={
					errors &&
					errors[index]?.monthlyRepayment &&
					errors[index]?.monthlyRepayment?.message
				}
				label=""
				placeholder="Monthly Repayment"
				iconPrefix={<i className="icon-dollar" />}
				onValueChange={(value) => {
					const updateLoanOnProperty = {
						...loanOnProperty,
					};
					updateLoanOnProperty.monthlyRepayment = value;
					onChangeLoanOnPropertyHandler(updateLoanOnProperty);
				}}
			/>
			{loanOnProperty.type && loanOnProperty.type.toLowerCase() === "fixed" && (
				<DatePickerInput
					name="fixedRateExpiryDate"
					onChange={(value) => {
						const updateLoanOnProperty = {
							...loanOnProperty,
						};
						updateLoanOnProperty.fixedRateExpiryDate = value;
						onChangeLoanOnPropertyHandler(updateLoanOnProperty);
					}}
					value={loanOnProperty.fixedRateExpiryDate}
					label=""
					placeholder="Fixed Rate Expiry"
					error={errors?.[index]?.fixedRateExpiryDate?.message}
					minDate={new Date()}
				/>
			)}
		</div>
	</div>
);
const emptyLoanOnProperty = {} as LoanOnProperty;
const LoanOnPropertyForm = ({
	control,
	initialLoanOnProperties,
	onChange,
	errors,
}: {
	control: Control<CurrentHomeLoanSchemaFormType, CurrentHomeLoanSchemaSlice>;
	initialLoanOnProperties?: Array<LoanOnProperty>;
	errors?: LoanOnPropertyErrorType;
	onChange: (loanOnProperties: Array<LoanOnProperty>) => void;
}) => {
	const [loanOnProperties, setLoanOnProperties] = useState<
		Array<LoanOnProperty>
	>(
		initialLoanOnProperties ?? [
			{
				...emptyLoanOnProperty,
			},
		]
	);

	const calculateTotalLoanAmount = () => {
		let totalAmount = 0;
		for (const loan of loanOnProperties) {
			totalAmount += Number(loan.amount) || 0; // Ensure loan.amount is not undefined or null
		}
		return totalAmount;
	};
	const handleLoanOnPropertyChange = (
		index: number,
		loanOnProperty: LoanOnProperty
	) => {
		const updatedLoanOnProperties = [...loanOnProperties];
		updatedLoanOnProperties[index] = { ...loanOnProperty };
		setLoanOnProperties(updatedLoanOnProperties);
	};

	useEffect(() => {
		onChange(loanOnProperties);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loanOnProperties]);

	const removeLoanOnPropertyHandler = (index: number) => {
		const updatedLoanOnProperties = [...loanOnProperties];
		updatedLoanOnProperties.splice(index, 1);
		setLoanOnProperties(updatedLoanOnProperties);
	};
	return (
		<section className="flex flex-col gap-4">
			{loanOnProperties.map((loanOnProperty, index) => (
				<LoanOnPropertyDisplay
					key={index}
					index={index}
					errors={errors}
					control={control}
					loanOnProperty={loanOnProperty}
					onChangeLoanOnPropertyHandler={(value: LoanOnProperty) => {
						handleLoanOnPropertyChange(index, value);
					}}
					removeLoanOnPropertyHandler={removeLoanOnPropertyHandler}
				/>
			))}
			<Button
				text={
					<span>
						<i className="icon-plus" />
						Add another loan
					</span>
				}
				type="button"
				size="fit"
				variant="accent"
				textAlign="center"
				handleClick={() => {
					const updatedLoanOnProperties = [
						...loanOnProperties,
						{ ...emptyLoanOnProperty },
					];
					setLoanOnProperties(updatedLoanOnProperties);
				}}
			/>
			<TotalSavings
				label="Total loan outstanding"
				value={calculateTotalLoanAmount()}
			/>
		</section>
	);
};

export default LoanOnPropertyForm;
