import { useEffect, useState } from "react";
import Button from "../../../../../ui/button/Button";
import type { HECsHELPLiability, LiabilitiesFormSlice } from "../YourLiabilitiesSchema";
import type { Control, FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import GenericInputTeriary from "../../../../generic-input-teriary/GenericInputTeriary";
import CheckboxInputSecondary from "../../../../checkbox-input-secondary/CheckboxInputSecondary";
import DollarInput from "../../../../dollar-input/DollarInput";

type HECsHELPLiabilityErrorType = Merge<
	FieldError,
	Array<Merge<FieldError, FieldErrorsImpl<HECsHELPLiability>> | undefined>
>;

const HECsHELPLiabilityDisplay = ({
	index,
	hECsHELPLiability,
	loanAmountPlaceHolder = "Outstanding Loan Amount",
	accountNamePlaceHolder = "Account Name",
	control,
	errors,
	onChangeHandler,
	removeHandler,
	showDebtConsolidation,
}: {
	index: number;
	loanAmountPlaceHolder?: string;
	accountNamePlaceHolder?: string;
	hECsHELPLiability: HECsHELPLiability;
	onChangeHandler: (value: HECsHELPLiability) => void;
	removeHandler: (value: number) => void;
	control: Control<LiabilitiesFormSlice>;
	errors?: HECsHELPLiabilityErrorType;
	showDebtConsolidation?: boolean;
}) => (
	<div className="w-full flex flex-col gap-4">
		<GenericInputTeriary
			type="text"
			value={hECsHELPLiability.accountName}
			error={
				errors &&
				errors[index]?.accountName &&
				errors[index]?.accountName?.message
			}
			label=""
			placeholder={accountNamePlaceHolder}
			onChangeHandler={(value) => {
				const updateHECsHELPLiability = {
					...hECsHELPLiability,
				};
				updateHECsHELPLiability.accountName = value.toString();
				onChangeHandler(updateHECsHELPLiability);
			}}
		/>
		<DollarInput
			name={`hECsHELPLiabilities.${index}.amount`}
			control={control}
			type="numeric"
			value={hECsHELPLiability.amount}
			error={
				errors &&
				errors[index]?.amount &&
				errors[index]?.amount?.message
			}
			label=""
			placeholder={loanAmountPlaceHolder}
			iconPrefix={<i className="icon-dollar" />}
			onValueChange={(value) => {
				const updateHECsHELPLiability = {
					...hECsHELPLiability,
				};
				updateHECsHELPLiability.amount = value || 0;
				onChangeHandler(updateHECsHELPLiability);
			}}
		/>
		{showDebtConsolidation && (
			<CheckboxInputSecondary
				label="This debt is being consolidated."
				value="debtIsBeingConsolidated"
				name="debtIsBeingConsolidated"
				size="small"
				checked={hECsHELPLiability.debtIsBeingConsolidated!}
				onChange={(event) => {
					const updateHECsHELPLiability = {
						...hECsHELPLiability,
					};
					updateHECsHELPLiability.debtIsBeingConsolidated = event.target.checked;
					onChangeHandler(updateHECsHELPLiability);
				}}
			/>
		)}
		<Button
			text="Remove"
			variant="plain"
			type="button"
			iconSuffix={<i className="icon-remove" />}
			handleClick={() => {
				removeHandler(index);
			}}
		/>
	</div>
);
const emptyHECsHELPLiability = {} as HECsHELPLiability;
const HECsHELPLiabilitiesForm = ({
	initialHECsHELPLiabilities,
	loanAmountPlaceHolder = "Outstanding Loan Amount",
	accountNamePlaceHolder = "Account Name",
	addButtonLabel = "Add HECs/HELP",
	onChange,
	control,
	errors,
	showDebtConsolidation,
}: {
	initialHECsHELPLiabilities?: Array<HECsHELPLiability>;
	loanAmountPlaceHolder?: string;
	accountNamePlaceHolder?: string;
	addButtonLabel?: string;
	control: Control<LiabilitiesFormSlice>;
	errors?: HECsHELPLiabilityErrorType;
	onChange: (hECsHELPLiabilities: Array<HECsHELPLiability>) => void;
	showDebtConsolidation?: boolean;
}) => {
	const [hECsHELPLiabilities, setHECsHELPLiabilities] = useState<
		Array<HECsHELPLiability>
	>(initialHECsHELPLiabilities ?? []);

	const handleChange = (
		index: number,
		hECsHELPLiability: HECsHELPLiability
	) => {
		const updateHECsHELPLiabilities = [...hECsHELPLiabilities];
		updateHECsHELPLiabilities[index] = { ...hECsHELPLiability };
		setHECsHELPLiabilities(updateHECsHELPLiabilities);
	};

	useEffect(() => {
		onChange(hECsHELPLiabilities);
	}, [hECsHELPLiabilities, onChange]);

	const removeHandler = (index: number) => {
		const updateHECsHELPLiabilities = [...hECsHELPLiabilities];
		updateHECsHELPLiabilities.splice(index, 1);
		setHECsHELPLiabilities(updateHECsHELPLiabilities);
	};
	return (
		<section className="flex flex-col gap-4">
			{hECsHELPLiabilities.map((hECsHELPLiability, index) => (
				<HECsHELPLiabilityDisplay
					loanAmountPlaceHolder={loanAmountPlaceHolder}
					accountNamePlaceHolder={accountNamePlaceHolder}
					key={index}
					index={index}
					control={control}
					errors={errors}
					hECsHELPLiability={hECsHELPLiability}
					onChangeHandler={(value: HECsHELPLiability) => {
						handleChange(index, value);
					}}
					removeHandler={removeHandler}
					showDebtConsolidation={showDebtConsolidation}
				/>
			))}
			<Button
				text={
					<span>
						<i className="icon-plus" />
						{addButtonLabel}
					</span>
				}
				type="button"
				size="fit"
				variant="accent"
				textAlign="center"
				handleClick={() => {
					const updateHECsHELPLiabilities = [
						...hECsHELPLiabilities,
						{
							...emptyHECsHELPLiability,
							openToClosing: false,
							debtIsBeingConsolidated: false,
						},
					];
					setHECsHELPLiabilities(updateHECsHELPLiabilities);
				}}
			/>
		</section>
	);
};

export default HECsHELPLiabilitiesForm;
