import { type FC, useState } from "react";
import Button from "../../../../ui/button/Button";

type ChatMessageBiometricsSendProps = {
	sent?: boolean;
	onSend?: () => void;
}
export const ChatMessageBiometricsSend: FC<ChatMessageBiometricsSendProps> = ({
	sent,
	onSend,
}) => {
	const [isDisabled, setIsDisabled] = useState(false);
	return (<div className="flex flex-row justify-end items-end w-full gap-4 pt-4">
		<div className="p-4 bg-white">
			<div className="flex flex-row justify-end pt-1">
				<Button
					isDisabled={isDisabled}
					text={sent ? "Re-send verification link" : "Send verification link"}
					className="min-w-min text-lg rounded"
					iconSuffix={<i className="icon-send" />}
					variant="primary"
					textAlign="center"
					handleClick={() => { setIsDisabled(true); onSend?.(); }}
				/>
			</div>
		</div>
	</div>)
};
