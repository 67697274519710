/* eslint-disable camelcase */
import { z } from "zod";
export const OtherIncomeTypeSchema = z
	.object({
		incomeType: z.object({
			id: z.number(),
			label: z.string(),
			value: z.string(),
		}),
		frequency: z.object({
			id: z.number(),
			label: z.string(),
			value: z.string(),
		}),
		estimatedAmount: z
			.number({
				invalid_type_error: "Please enter estimated amount",
				required_error: "Please enter estimated amount",
			})
			.min(1, {
				message: "Please enter estimated amount",
			}),
	})
	.array()
	.optional();
export type OtherIncomeType = z.infer<typeof OtherIncomeTypeSchema>;
export const EmploymentSchema = z
	.object({
		currentEmploymentType: z.object({
			id: z.number(),
			label: z.string(),
			value: z.string(),
		}),
		otherIncomeAllowed: z.string().nullish().optional(),
		otherIncomes: OtherIncomeTypeSchema,
	})
	.superRefine(
		(
			{ currentEmploymentType, otherIncomeAllowed, otherIncomes },
			refinementContext
	) => {
			if (currentEmploymentType.id === 6) {
				if (!otherIncomeAllowed || otherIncomeAllowed === "") {
					refinementContext.addIssue({
						code: z.ZodIssueCode.custom,
						message: "This field is required",
						path: ["otherIncomeAllowed"],
					});
				}
				if (otherIncomeAllowed && otherIncomeAllowed === "true") {
					if (otherIncomes && otherIncomes.length > 0) {
						otherIncomes.forEach(({ incomeType, frequency }, index) => {
							if (!incomeType || incomeType.id === 0) {
								refinementContext.addIssue({
									code: z.ZodIssueCode.custom,
									message: `Please select income type`,
									path: ["otherIncomes", index, "incomeType"],
								});
							}
							if (!frequency || frequency.id === 0) {
								refinementContext.addIssue({
									code: z.ZodIssueCode.custom,
									message: `Please select frequency`,
									path: ["otherIncomes", index, "frequency"],
								});
							}
						});
					} else {
						refinementContext.addIssue({
							code: z.ZodIssueCode.custom,
							message: "Please add at least one other income",
							path: ["otherIncomes"],
						});
					}
				}
			}
		}
	);
