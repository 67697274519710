import { useNavigate } from "react-router-dom";
import Layout from "../common/layout/Layout";
import type { FunctionComponent } from "../common/types";
import IntroAside from "../components/layout/intro-aside/IntroAside";
import Button from "../components/ui/button/Button";
import TrustBox from "../components/ui/trustpilot/TrustBox";
import { useAppDispatch } from "../hooks/redux-hooks";
import { useEffect, useRef } from "react";
import { resetStepper } from "../store/slices/stepper-slice";
import { resetJointStepper } from "../store/slices/joint-stepper-slice";
import { resetFormState } from "../store/slices/form-slice";
import { NamedRoute } from "../components/utils/NamedRoutes";
import { setNotInRemit, setVerificationToken } from "../store/slices/auth-slice";
import useScrollToRefWithDelay from "../hooks/use-scroll-to-ref-with-delay";
import useTrackPageViewOnMount from "../hooks/use-track-on-mount";
import { useTracking } from "../hooks/use-tracking";

const Intro = (): FunctionComponent => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const targetRef = useRef<HTMLHeadingElement>(null);
	const { trackEvent } = useTracking();
	useTrackPageViewOnMount({
		page: "Landing Page",
	});

	useScrollToRefWithDelay(targetRef, 500, 3);

	useEffect(() => {
		dispatch(resetStepper());
		dispatch(resetJointStepper());
		dispatch(resetFormState());
		dispatch(setVerificationToken(undefined));
		dispatch(setNotInRemit(false));
	}, [dispatch]);
	return (
		<Layout leftColumn={<IntroAside />}>
			<h1
				ref={targetRef}
				className="text-primary text-[37.9px] font-normal mb-4 lg:mb-6 lg:mt-14"
				aria-labelledby="Apply in 15 minutes"
			>
				Apply in 15 minutes
			</h1>
			<TrustBox />
			<div className="text-off-black-600 text-[21.33px] font-normal leading-8 flex flex-col gap-5 mb-5 lg:mb-12">
				<p>
					Before you apply, please ensure you have estimated your borrowing
					capacity using our web calculators.
				</p>
				<p>
					Once you have done this, click apply and we'll grab your details so we
					can find loan options that match your needs.
				</p>
				<p>
					Don't worry, we won't run a credit check yet. We'll ask you prior to
					running any credit checks.
				</p>
			</div>
			<div
				className="flex flex-col sm:flex-row items-center gap-4 w-full"
				aria-labelledby="Actions wrapper"
			>
				<Button
					text="I'm ready to apply"
					size="fit"
					textAlign="center"
					variant="primary"
					handleClick={() => {
						trackEvent("Application Started");
						navigate(NamedRoute.gettingStarted);
					}}
					className="w-full sm:w-auto"
				/>
				<Button
					text="Estimate Borrowing Capacity"
					size="fit"
					textAlign="center"
					variant="accent"
					handleClick={() => {
						trackEvent("Redirect to Calculator");
						window.location.href = "https://www.sucasa.com.au/calculators";
					}}
					className="w-full sm:w-auto"
				/>
			</div>
		</Layout>
	);
};

export default Intro;
