import { useNavigate } from "react-router-dom";
import { NamedRoute } from "../components/utils/NamedRoutes";
import { useLoginMutation } from "../services/api";
import type { LoginRequest } from "../services/apis/auth";
import { useAuthSuccessHandler } from "./use-auth-success";

export const useLogin = () => {
	const navigate = useNavigate();
	const [login, { isLoading, isError, isSuccess }] = useLoginMutation();
	const { handleSuccess } = useAuthSuccessHandler();
	const loginHandler = async ({
		email,
		password,
	}: LoginRequest): Promise<void> => {
		try {
			const response = await login({ email, password }).unwrap();
			handleSuccess({
				authToken: response.authToken,
				...response.data.content.content,
			});

			navigate(NamedRoute.welcome);
		} catch (error: unknown) {
			// @ts-expect-error: we don't know what the error object looks like
			if (error?.status === 401) {
				return;
			}
			// Handle or rethrow the error based on your application's needs
			throw error;
		}
	};

	return {
		loginHandler,
		isLoading,
		isError,
		isSuccess,
	};
};
