import { type FC, useEffect, useMemo, useRef, useState } from "react";
import { ApplicationUserInviteStatus } from "@sucasa-finance/origination-trpc";
import { origination } from "../../../services/origination";
import Modal from "../modal/Modal";
import type { ButtonType } from "../../ui/button/Button";
import type { AcceptInviteResponse } from "../../../services/apis/origination/types";

type AcceptApplicantInviteFormProps = {
    invite: string
    onValid(details: AcceptInviteResponse): void
    onInvalid(): void
}

export const AcceptApplicantInviteForm: FC<AcceptApplicantInviteFormProps> = ({ invite, onValid, onInvalid }) => {
    const [isModelOpen, setIsModelOpen] = useState(false);
    const mutation = origination.application.acceptInvite.useMutation({
        onSuccess(data) {
            if (data.status === ApplicationUserInviteStatus.Valid) {
                onValid(data);
                setIsModelOpen(false);
            } else {
                setIsModelOpen(true);
            }
            return data;
        },
        onError() {
            setIsModelOpen(true);
        }
    })
    const hasMutated = useRef(false);
    const { mutate, isIdle } = mutation;
    useEffect(() => {
        if (hasMutated.current || !isIdle){
            return;
        }
        mutate({
            invite
        });
        hasMutated.current = true;
    }, [mutate, invite, isIdle]);
    const actions = useMemo((): ButtonType[] => [
        {
            text: "Complete your application",
            variant: "primary",
            handleClick: onInvalid,
            type: "button",
            textAlign: "center",
            size: "fit",
        },
    ], [onInvalid])
    return (<>
        <h1 className="text-primary text-6xl leading-snug font-normal">
            Welcome to Sucasa!
        </h1>
        <p className="font-normal leading-8 mb-10 lg:mb-16">
            Please wait while we load your account details
        </p>
        <Modal
          actions={actions}
          isOpen={isModelOpen}
          title={
              <div
                className="flex flex-col gap-4 md:gap-8 items-center text-[28.43px] font-medium leading-10 text-primary">
                  <i className="icon-warning text-2xl md:text-[80px]" />
              </div>
          }
          hideCloseButton
          onClose={onInvalid}
          content={
              <p className="text-sm text-primary text-center mt-10">
                  This invite link has expired, please sign in to access your application
              </p>
          }
        />
    </>);
};
