import { useCallback } from "react";
import { setLogoutModal } from "../store/slices/ui-slice";
import { useAppDispatch } from "./redux-hooks";
import { useResetSession } from "./use-reset-session";

export const useResetAuth = () => {
    const dispatch = useAppDispatch();
    const resetSession = useResetSession();

    return useCallback(() => {
        resetSession();
        dispatch(setLogoutModal(true));
    }, [dispatch, resetSession]);
}