import { EmploymentType, type CreateEmployment, type CreateIncome } from "@sucasa-finance/origination-trpc"
import type { EmploymentInformationType, IncomeDetailsType, UpdateIncomeRequest } from "../../update-income.schema";
import { mapIncome, mapIncomeArray } from "./income";
import { employmentTypeMapping } from "./enum-mapping";
import { employmentTypeToIncomeTypeMapping } from "../../../../store/selectors/update-income-payload";
import { selectFormApplicationId } from "../../../../store/slices/application-slice";
import { selectCurrentOriginationUser } from "../../../../store/slices/auth-slice";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import { useCallback } from "react";
import { mapStepAndSubStep } from "./step";
import { formatDate } from "./date";

export interface CreateEmploymentAndIncome {
    currentEmployment?: {
        employment: CreateEmployment,
        income: CreateIncome
    },
    employments: Array<CreateEmployment>;
    incomes: Array<CreateIncome>;
    userId: number;
    applicationId: number;
}

export function mapEmploymentInformationType(from: EmploymentInformationType, request?: UpdateIncomeRequest): CreateEmployment {
    // if the employment is not current, we want to use the unknown employment type, until we allow customers to define it in the UI
    const employmentType = from.isCurrent ? employmentTypeMapping[from.currentEmploymentType] : EmploymentType.Unknown;
    if (!employmentType) {
        throw new Error(`Unexpected employement type "${from.currentEmploymentType}"`);
    }
    return {
        employerName: from.employerName,
        abn: from.abn,
        isCurrent: from.isCurrent,
        jobStartDate: formatDate(from.jobStartDate),
        // If not given we want no empty string to be given to the API
        jobEndDate: formatDate(from.jobEndDate) || undefined,
        jobTitle: from.jobTitle,
        businessName: from.nameOfTheBusiness,
        employmentType,
        experienceInMonths: from.industryExperienceInMonths,
        declaredOverTimeOrAllowance: request?.receivedOverTimeOrAllowance,
        declaredCommissionOrBonus: request?.receivedCommissionOrBonus,
        declaredAnyOtherIncome: request?.hasAnyOtherIncome,
        businessIncorporationDate: formatDate(from.businessIncorporationDate),
    }
}

function getCurrentEmployment(from: UpdateIncomeRequest): EmploymentInformationType | undefined {
    return from.employmentInformation.find(employment => employment.isCurrent);
}

function getEmploymentIncome(from: UpdateIncomeRequest, employment: EmploymentInformationType): IncomeDetailsType | undefined {
    const incomeType = employmentTypeToIncomeTypeMapping[employment.currentEmploymentType];
    if (!incomeType) return undefined;
    return from.incomeDetails.find(income => income.incomeType === incomeType);
}

function hasJointBorrowerIncome(from: UpdateIncomeRequest) {
    return from?.jointBorrowerIncome !== undefined && from?.jointBorrowerIncome !== null && from?.jointBorrowerIncome >= 0;
}

function getJointBorrowerIncome(from: UpdateIncomeRequest) {
    return from?.jointBorrowerIncome;
}

function objectSpreadJointBorrowerIncome(from: UpdateIncomeRequest) {
    return {
        ...(hasJointBorrowerIncome(from) ? {
            jointBorrowerIncome: getJointBorrowerIncome(from)
        } : {})
    }
}
    

export function hasCurrentEmploymentIncome(from: UpdateIncomeRequest) {
    const currentEmployment = getCurrentEmployment(from);
    if (!currentEmployment) return false;
    return Boolean(getEmploymentIncome(from, currentEmployment));
}

export function mapEmploymentIncomeRequest(from: UpdateIncomeRequest, applicationId: string | number, userId: string | number | undefined) {
    const currentEmployment = getCurrentEmployment(from);

    let employmentIncome: IncomeDetailsType | undefined;
    if (currentEmployment) {
        employmentIncome = getEmploymentIncome(from, currentEmployment);
    }

    const remainingIncome = employmentIncome ? from.incomeDetails.filter(income => income !== employmentIncome) : from.incomeDetails;

    const remainingEmployment = currentEmployment ? from.employmentInformation.filter(employment => employment !== currentEmployment) : from.employmentInformation;

    const result = {
        incomes: mapIncomeArray(remainingIncome),
        employments: remainingEmployment.map(employment => mapEmploymentInformationType(employment)),
        currentEmployment: currentEmployment && {
            employment: mapEmploymentInformationType(currentEmployment, from),
            ...(employmentIncome ? {
                income: mapIncome(employmentIncome)
            } : {}) as {
                income: ReturnType<typeof mapIncome>
            }
        },
        applicationId: Number(applicationId),
        userId: Number(userId),
        ...objectSpreadJointBorrowerIncome(from),
        ...mapStepAndSubStep(from)
    };


    return result;
}

export function useMapEmploymentIncomeRequest() {
    const applicationId = useAppSelector(selectFormApplicationId);
    const user = useAppSelector(selectCurrentOriginationUser);
    return useCallback((from: UpdateIncomeRequest) => mapEmploymentIncomeRequest(from, applicationId, user?.userId), [applicationId, user?.userId]);
}
