import { ApplicationStep } from "@sucasa-finance/origination-trpc";

// TODO[matej]: unify all steppers
export type TWbStepsType = {
	id: number;
	value: string;
	label: string;
	stepType: ApplicationStep,
	isCompleted: boolean;
	isCurrent: boolean;
	isLastItem?: boolean;
};

export const wbJointSteps: Array<TWbStepsType> = [
	{
		id: 1,
		value: "details",
		label: "Your Details",
		stepType: ApplicationStep.Details,
		isCompleted: false, // Dynamically set below
		isCurrent: false,
	},
	{
		id: 2,
		value: "finances",
		label: "Finances",
		stepType: ApplicationStep.Finances,
		isCompleted: false, // Dynamically set below
		isCurrent: true,
	},
	{
		id: 3,
		value: "upload",
		label: "Upload Documents",
		stepType: ApplicationStep.Submitted,
		isCompleted: false, // Dynamically set below
		isCurrent: false,
	},
	{
		id: 4,
		value: "submitted",
		stepType: ApplicationStep.Confirmation,
		label: "Assessment",
		isCompleted: false, // Dynamically set below
		isCurrent: false,
		isLastItem: true,
	},
];

export const wbAllSteps: Array<TWbStepsType> = [
	{
		id: 1,
		value: "home",
		label: "Your Home",
		// TODO[matej]: wrong missing step type, come back after
		stepType: ApplicationStep.Invited,
		isCompleted: false, // Dynamically set below
		isCurrent: false,
	},
	{
		id: 2,
		value: "loan",
		label: "Your Loan",
		// TODO[matej]: wrong missing step type, come back after
		stepType: ApplicationStep.NotInRemit,
		isCompleted: false, // Dynamically set below
		isCurrent: false,
	},
	{
		id: 3,
		value: "details",
		label: "Your Details",
		stepType: ApplicationStep.Details,
		isCompleted: false, // Dynamically set below
		isCurrent: false,
	},
	{
		id: 4,
		value: "finances",
		label: "Finances",
		stepType: ApplicationStep.Finances,
		isCompleted: false, // Dynamically set below
		isCurrent: true,
	},
	{
		id: 5,
		value: "upload",
		label: "Upload Documents",
		stepType: ApplicationStep.Submitted,
		isCompleted: false, // Dynamically set below
		isCurrent: false,
	},
	{
		id: 6,
		value: "submitted",
		stepType: ApplicationStep.Confirmation,
		label: "Assessment",
		isCompleted: false, // Dynamically set below
		isCurrent: false,
		isLastItem: true,
	},
];
