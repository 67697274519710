import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../index";
import type { HomeLoanSpecialist } from "../../services/apis/create-application.schema";

const initialState: HomeLoanSpecialist = {
	email: "",
	name: "",
	phone: "",
	meetingLink: "",
};

export const homeLoanSpecialistSlice = createSlice({
	name: "homeLoanSpecialist",
	initialState,
	reducers: {
		setHomeLoanSpecialist: (
			state,
			action: PayloadAction<HomeLoanSpecialist>
		) => {
			const { email, name, phone, meetingLink } = action.payload;
			state.email = email;
			state.name = name;
			state.phone = phone;
			state.meetingLink = meetingLink;
		},
	},
});

export const { setHomeLoanSpecialist } = homeLoanSpecialistSlice.actions;

export const selectHomeLoanSpecialist = (state: RootState) =>
	state.homeLoanSpecialist;

export default homeLoanSpecialistSlice.reducer;
