import { useEffect } from "react";
import Layout from "../common/layout/Layout";
import CreateYourAccount from "../components/forms/steps/your-details/create-your-account/CreateYourAccount";
import TellUsAboutYou from "../components/forms/steps/your-details/tell-us-about-you/TellUsAboutYou";
import YouAndYourHousehold from "../components/forms/steps/your-details/you-and-your-household/YouAndYourHousehold";
import LetsStartHome from "../components/forms/steps/your-home/LetsStart";
import YourHomeLanding from "../components/forms/steps/your-home/YourHomeLanding";
import BuyingWorkflowLoan from "../components/forms/steps/your-loan/buying-workflow/BuyingWorkflow";
import CurrentHomeLoan from "../components/forms/steps/your-loan/refinancing-workflow/current-home-loan/CurrentHomeLoan";
import NewHomeLoan from "../components/forms/steps/your-loan/refinancing-workflow/new-home-loan/NewHomeLoan";
import HintsColumn from "../components/layout/hints-column/HintsColumn";
import { Stepper } from "../components/layout/stepper/Stepper";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { SubStep } from "../services/apis/create-application.schema";
import { FhbFederalGuarantee } from "../components/forms/steps/your-details/fhb-federal-guarantee/FhbFederalGuarantee";
import { CreateYourAccountV2 } from "../components/forms/steps/your-details/create-your-account/CreateYourAccountV2";
import { useLDFlags } from "../hooks/use-ld-flags";
import { setUseOrigination } from "../store/slices/origination-slice";

const GettingStarted = () => {
	const dispatch = useAppDispatch();
	const stepperState = useAppSelector((state) => state.stepper);
	const isFormDirty = useAppSelector((state) => state.form.isFormDirty);
	const { auth0Login } = useLDFlags();

	useEffect(() => {
		const beforeUnloadListener = (event: BeforeUnloadEvent) => {
			if (isFormDirty) {
				event.preventDefault();
				event.returnValue = "";
			}
		};

		window.addEventListener("beforeunload", beforeUnloadListener);

		return () => {
			window.removeEventListener("beforeunload", beforeUnloadListener);
		};
	}, [isFormDirty]);

	useEffect(() => {
		// for all new applications, use the origination service by default
		dispatch(setUseOrigination(true));
	}, [dispatch]);

	return (
		<Layout
			leftColumn={<Stepper />}
			rightColumn={<HintsColumn />}
		>
			{stepperState.steps?.["homeStep"] &&
				stepperState.steps["homeStep"].isCurrent &&
				<>
					{stepperState.selectedSubStep === SubStep.LetsStart &&
						<LetsStartHome />}
					{[SubStep.BeforeWeGetStarted, SubStep.Empty, undefined].includes(stepperState.selectedSubStep) &&
						<YourHomeLanding />}
				</>}
			{stepperState.steps?.["loanStep"] &&
				stepperState.steps["loanStep"].isCurrent &&
				<>
					{stepperState.selectedSubStep === SubStep.BuyingWorkflow &&
						<BuyingWorkflowLoan />}
					{stepperState.selectedSubStep === SubStep.CurrentHomeLoan &&
						<CurrentHomeLoan />}
					{stepperState.selectedSubStep === SubStep.NewHomeLoan &&
						<NewHomeLoan />}
				</>}
			{stepperState.steps?.["detailsStep"] &&
				stepperState.steps["detailsStep"].isCurrent &&
				<>
					{stepperState.selectedSubStep === SubStep.TellUsAboutYou &&
						<TellUsAboutYou />}
					{stepperState.selectedSubStep === SubStep.YouAndYourHousehold &&
						<YouAndYourHousehold />}
					{stepperState.selectedSubStep === SubStep.FHBFederalGuarantee &&
						<FhbFederalGuarantee />}
					{stepperState.selectedSubStep === SubStep.CreateYourAccount &&
						!auth0Login &&  <CreateYourAccount />}
					{stepperState.selectedSubStep === SubStep.CreateYourAccount &&
						auth0Login && <CreateYourAccountV2 />}
				</>}
		</Layout>
	);
};

export default GettingStarted;
