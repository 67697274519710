/* eslint-disable camelcase */
import { z } from "zod";
import type { RootState } from "../../../../../store";
import { createSelector } from "@reduxjs/toolkit";
import type {
	AddressParts,
	ApplicationType,
} from "../../../../../services/apis/create-application.schema";
import type { OptionsType } from "../../../../../common/types";
import type { Place } from "../../../address-search/AddressSearch";

export type AddressHistoryFormSlice = {
	livingAddress?: Place;
	livingAddressParts?: AddressParts;
	currentLivingSituation?: OptionsType;
	livingStartDate: string;
	livingEndDate?: string;
	applicationType?: string;
};
export const TwoYearsAddressHistorySchema = z
	.object({
		addressHistory: z
			.object({
				// livingAddress: z.object(
				// 	{
				// 		description: z.string(),
				// 		place_id: z.string(),
				// 	},
				// 	{ required_error: "Please select living address" }
				// ),
				currentLivingSituation: z
					.object(
						{
							id: z.number(),
							value: z.string(),
							label: z.string(),
						},
						{ required_error: "Please select living situation" }
					)
					.optional(),
				livingStartDate: z
					.string({
						required_error: "Please select living start date",
						invalid_type_error: "Please select living start date",
					})
					.min(1, {
						message: "Please select living start date",
					}),
				livingEndDate: z.string().optional(),
			})
			.array(),
	})
	.superRefine(({ addressHistory }, context) => {
		addressHistory.forEach(
			({ currentLivingSituation, livingEndDate }, index) => {
				if (
					index === 0 &&
					(!currentLivingSituation || currentLivingSituation.id === 0)
				) {
					context.addIssue({
						code: z.ZodIssueCode.custom,
						message: `Please select living situation`,
						path: ["addressHistory", index, "currentLivingSituation"],
					});
				}
				if (index > 0 && (!livingEndDate || livingEndDate === "")) {
					context.addIssue({
						code: z.ZodIssueCode.custom,
						message: `Please select provide an end date`,
						path: ["addressHistory", index, "livingEndDate"],
					});
				}
			}
		);
	});
export type TwoYearsAddressHistoryFormType = z.infer<
	typeof TwoYearsAddressHistorySchema
>;
export type TwoYearsAddressHistoryFormSlice = {
	addressHistory?: Array<AddressHistoryFormSlice>;
	applicationType?: string;
};
export const SelectTwoYearsAddressHistoryForm = createSelector(
	(state: RootState) => state.form,
	(form): TwoYearsAddressHistoryFormSlice => ({
		addressHistory: form.addressHistory,
		applicationType: form.applicationType as ApplicationType,
	})
);
