import { useEffect } from "react";
import { useTracking, type TrackEventName } from "./use-tracking";

const useTrackEventOnMount = ({ eventName, eventProperties }: { eventName: TrackEventName, eventProperties: Record<string, unknown> }) => {
	const { trackEvent } = useTracking();

	useEffect(() => {
		trackEvent(eventName, eventProperties);
		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};

export default useTrackEventOnMount;
