import { Fragment, useState } from "react";
import type { FunctionComponent } from "../../../common/types";
import { Dialog, Transition } from "@headlessui/react";
import type { HeaderMenuProps } from "./HeaderMenu";

const HeaderMenuMobile = ({
	currentUser,
	canReInviteJointApplication,
	handleInvite,
	handleLogout
}: HeaderMenuProps): FunctionComponent => {
	const [isOpen, setIsOpen] = useState(false);

	return (<>
			<Transition show={isOpen} as={Fragment}>
				<Dialog
					unmount={false}
					onClose={() => { setIsOpen(false); }}
					className="fixed z-40 inset-0 overflow-y-auto"
				>
					<div className="flex w-3/4 h-screen min-h-svh max-h-dvh">
						<Transition.Child
							as={Fragment}
							enter="transition-opacity ease-in duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-30"
							entered="opacity-30"
							leave="transition-opacity ease-out duration-300"
							leaveFrom="opacity-30"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="z-40 fixed inset-0 bg-black opacity-50" />
						</Transition.Child>

						<Transition.Child
							as={Fragment}
							enter="transition ease-in-out duration-300 transform"
							enterFrom="-translate-x-full"
							enterTo="translate-x-0"
							leave="transition ease-in-out duration-300 transform"
							leaveFrom="translate-x-0"
							leaveTo="-translate-x-full"
						>
							<div
								className="flex flex-col justify-between bg-black z-40
                          w-full max-w-sm p-6 overflow-hidden text-left
                          align-middle shadow-xl text-white">
								<div className="origin-top-right divide-y divide-off-black-600">
									<div className="pt-5 pb-5">
										<div className="leading-4">{currentUser?.fullName}</div>
										<div><small className="text-off-black-400 text-sm leading-1">{currentUser?.email}</small></div>
									</div>
									{canReInviteJointApplication && <div className="py-3">
										<button
											className="flex items-center gap-2 leading-6 transition-all ext-base font-normal w-fit"
											onClick={handleInvite}
										>
											<i className="icon-asterisk" />
											<span className="flex-grow text-start">Resend Joint Borrower Invitation</span>
										</button>
									</div>}
								</div>
								<div className="pt-3 pb-5 mt-10">
									<button
										className="flex items-center gap-2 leading-6 transition-all ext-base font-normal w-fit"
										onClick={handleLogout}
									>
										<i className="icon-log-out" />
										<span className="flex-grow text-start">Logout</span>
									</button>
								</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
			<button
				className="flex items-center gap-2 leading-6 transition-all ext-base font-normal w-fit"
				onClick={() => {
					setIsOpen(true);
				}}
			>
				<span className="flex-grow text-start">Menu</span>
				<i className="icon-hamburger" />
			</button>
		</>
	);
};

export default HeaderMenuMobile;
