// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { User } from "../../services/apis/auth";
import type { RootState } from "../index";
import { selectAppId } from "./application-slice";

type AuthState = {
	user: User | null;
	originationUser: User | null;
	token: string | null;
	accessToken: string | null;
	notInRemit: boolean;
	isJointApplicant: boolean;
	verificationToken?: string;
	applications: number[];
};

export const slice = createSlice({
	name: "auth",
	initialState: {
		user: null,
		originationUser: null,
		token: null,
		accessToken: null,
		notInRemit: false,
		isJointApplicant: false,
		verificationToken: undefined,
		applications: [],
	} as AuthState,
	reducers: {
		setAuthToken: (state, { payload }: PayloadAction<string | null>) => {
			state.token = payload;
		},
		setAccessToken: (state, { payload }: PayloadAction<string | null>) => {
			state.accessToken = payload;
		},
		setUser: (state, { payload }: PayloadAction<User | null>) => {
			state.user = payload;
		},
		setOriginationUser: (state, { payload }: PayloadAction<User | null>) => {
			state.originationUser = payload;
		},
		setCredentials: (
			state,
			{ payload: { user, token } }: PayloadAction<{ user: User; token: string }>
		) => {
			state.user = user;
			state.token = token;
		},
		signOut: (state) => {
			state.user = null;
			state.originationUser = null;
			state.token = null;
			state.accessToken = null;
			state.notInRemit = false;
			state.verificationToken = undefined;
			state.applications = [];
		},
		setNotInRemit: (state, { payload }: PayloadAction<boolean>) => {
			state.notInRemit = payload;
		},
		setIsJointApplicant: (state, { payload }: PayloadAction<boolean>) => {
			state.isJointApplicant = payload;
		},
		setVerificationToken: (state, { payload }: PayloadAction<string| undefined>) => {
			state.verificationToken = payload;
		},
		setApplications: (state, { payload }: PayloadAction<number[]>) => {
			state.applications = payload;
		},
	},
});

export const {
	setCredentials,
	setUser,
	setOriginationUser,
	setAuthToken,
	setAccessToken,
	signOut,
	setNotInRemit,
	setIsJointApplicant,
	setVerificationToken,
	setApplications,
} = slice.actions;

export default slice.reducer;

export const selectCurrentMiddlewareUser = (state: RootState) => state.auth.user;
export const selectCurrentOriginationUser = (state: RootState) => state.auth.originationUser;
export const selectCurrentOriginationUserApplications = (state: RootState) => state.auth.applications;

export const selectCurrentUser = createSelector(
	[
		selectCurrentMiddlewareUser,
		selectCurrentOriginationUser,
	],
	(user, originationUser) => user?.userId ? user : originationUser
);

export const selectIsJointApplicant = (state: RootState) => state.auth.isJointApplicant;

export const selectIsUserJointApplicant = (state: RootState) =>
	state.auth.user?.applicantType === "Secondary Applicant"

export const selectCurrentUserFullName = createSelector(
	selectCurrentUser,
	(user) => user?.fullName
);
export const selectIsLoggedIn = (state: RootState) => !!state.auth.token;

// TODO[matej]: this token should be removed with middleware
export const selectAuthToken = (state: RootState) => state.auth.token;

export const selectAccessToken = (state: RootState) => state.auth.accessToken;

export const selectIsNotInRemit = (state: RootState) => state.auth.notInRemit;

export const selectVerificationToken = (state: RootState) => state.auth.verificationToken;

export const selectIsLoggedInWithMiddlewareApplication = createSelector(
	[
		selectIsLoggedIn,
		selectAppId,
	],
	(isLoggedIn, appId) => isLoggedIn && appId !== ""
);

export const selectIsLoggedInWithOriginationApplication = createSelector(
	[
		selectIsLoggedIn,
		selectCurrentOriginationUser,
		selectCurrentOriginationUserApplications,
	],
	(isLoggedIn, originationUser, applications) => isLoggedIn && Boolean(originationUser) && Boolean(applications?.length)
);

export const selectIsLoggedInWithApplication = createSelector(
	[
		selectIsLoggedIn,
		selectAppId,
		selectCurrentOriginationUser,
		selectCurrentOriginationUserApplications,
	],
	(isLoggedIn, appId, originationUser, applications) => isLoggedIn && (appId !== "" || (Boolean(originationUser) && Boolean(applications?.length)))
);
