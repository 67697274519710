import type { FC } from "react";
import type { TWbStepsType } from "../WbStepper.config";

const WbStepperItem: FC<TWbStepsType> = (item) => {
	return (
		<li
			className={`w-20 shrink-0 flex-flex flex-col items-center gap-1 font-medium relative before:w-full before:h-0.5 ${item.isCompleted ? "before:bg-grass-hopper-900" : "before:bg-off-black-200"} before:block before:absolute before:top-3 before:bottom-0 before:left-1/2 before:-z-10 ${item.isLastItem ? "before:hidden" : ""}`}
		>
			{item.isCompleted ? (
				<span className="relative w-6 h-6 flex items-center justify-center">
					<i className="icon-completed text-grass-hopper-900 text-[21.33px] flex items-center justify-center" />
					<i className="icon-check text-primary flex items-center justify-center absolute top-0 bottom-0 left-0 right-0 !font-bold" />
				</span>
			) : (
				<span
					className={`bg-primary text-white w-6 h-6 rounded-full flex items-center justify-center text-sm leading-6 font-medium`}
				>
					{item.id}
				</span>
			)}
			<span
				className={`${item.isCurrent ? "font-medium" : " text-off-black-400"} ${item.isCompleted ? "text-white" : ""}`}
			>
				{item.label}
			</span>
		</li>
	);
};

export default WbStepperItem;
