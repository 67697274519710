import { legacy } from "../../api";
import { origination } from "../../origination";
import { mapUpdateLiabilitiesResponse, useMapUpdateLiabilitiesRequest } from "./mapping/liabilities";
import { useOriginationCallback, useOriginationMutationResult } from "./mutation";

export function useUpdateLiabilities() {
    const [invokeOriginal, originalMutation] = legacy.useUpdateLiabilitiesMutation();
    const mutation = origination.application.updateLiabilities.useMutation();
    const mapRequest = useMapUpdateLiabilitiesRequest();
    const create = useOriginationCallback(
        invokeOriginal,
        mutation.mutateAsync,
        mapRequest,
        mapUpdateLiabilitiesResponse
    )
    const mutationResult = useOriginationMutationResult(originalMutation, mutation);
    return [
        create,
        mutationResult
    ] as const;
}