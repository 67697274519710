import type { FC } from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/Logo.png";
import { useAppSelector } from "../../../hooks/redux-hooks";
import { selectIsLoggedIn } from "../../../store/slices/auth-slice";

type SiteBrandType = {
	className?: string;
};

const SiteBrand: FC<SiteBrandType> = ({ className }) => {
	const isLoggedIn = useAppSelector(selectIsLoggedIn);

	// If the user is logged in, redirect to root of the application, otherwise redirect to the marketing site
	return isLoggedIn ? (
		<Link
			to={"/"}
			className={className}
			aria-labelledby="Sucasa App Header Logo"
		>
			<img src={logo} alt="Sucasa Logo" />
		</Link>
	) : (
		<a
			href={"https://sucasa.com.au"}
			className={className}
			aria-labelledby="Sucasa App Header Logo"
		>
			<img src={logo} alt="Sucasa Logo" />
		</a>
	);
};

export default SiteBrand;
