import { Dialog, Transition } from "@headlessui/react";
import { Fragment, type ReactNode } from "react";
import Button, { type ButtonType } from "../../ui/button/Button";

interface ModalProps {
	isOpen: boolean;
	onClose: () => void;
	actions: Array<ButtonType>;
	content: ReactNode;
	title?: ReactNode;
	hideCloseButton?: boolean;
}
const Modal = ({
	title,
	content,
	isOpen,
	onClose,
	actions,
	hideCloseButton,
}: ModalProps) => {
	return (
		<>
			<Transition appear show={isOpen} as={Fragment}>
				<Dialog as="div" className="relative z-10" onClose={onClose}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black/25" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center text-center p-4">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel className="w-full md:w-[800px] transform overflow-hidden bg-white py-4 px-4 md:py-16 md:px-36 text-left align-middle shadow-xl transition-all">
									{!hideCloseButton && (
										<i
											className="icon-remove text-3xl absolute top-4 right-4 cursor-pointer"
											onClick={onClose}
										/>
									)}
									{title && <Dialog.Title as="h3">{title}</Dialog.Title>}
									<div className="mt-4">{content}</div>

									<div
										className={`flex flex-col md:flex-row mt-8 gap-4 ${actions.length === 1 && "justify-center"}`}
									>
										{actions.map((action, index) => {
											return (
												<Button
													key={`button-${index}`}
													text={action.text}
													variant={action.variant}
													iconPrefix={action.iconPrefix}
													handleClick={action.handleClick}
													type={action.type}
													size={action.size}
													iconSuffix={action.iconSuffix}
													isDisabled={action.isDisabled}
													textAlign={action.textAlign}
												/>
											);
										})}
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
	);
};

export default Modal;
