import type {
	FieldErrors,
	FieldValues,
	UseFormRegister,
} from "react-hook-form";
import { useAppSelector } from "../../../../../../hooks/redux-hooks";
import { loanTermOptions } from "../../../../../utils/BootList";
import RadioGroup from "../../../../radio-group/RadioGroup";
import type { BuyingWorkflowLoanFormType } from "../BuyingWorkflow";
import LoanBuyingFeaturesFC from "./loan-features/LoanBuyingFeatures";
import LoanRefinancingFeaturesFC from "./loan-features/LoanRefinancingFeatures";
import LoanTermNoFC from "./loan-term-no/LoanTermNo";
import LoanTermYesFC from "./loan-term-yes/LoanTermYes";
import {
	type LoanFeature,
	LoanPurpose,
	type ReasonForRefinancing,
} from "../../../../../../services/apis/create-application.schema";
import type { NewHomeLoanFormSlice } from "../../refinancing-workflow/new-home-loan/NewHomeLoanSchema";

type LoanTermType<TFieldValues extends FieldValues = FieldValues> = {
	register: UseFormRegister<TFieldValues>;
	showQuestion: Partial<BuyingWorkflowLoanFormType | NewHomeLoanFormSlice>;
	onChangeLoanRefinancing?: (checked: boolean, value: string) => void;
	selectedReasonForRefinancing?: Array<ReasonForRefinancing>;
	onChangeLoanBuyingFeatures?: (checked: boolean, value: string) => void;
	selectedLoanBuyingFeatures?: Array<LoanFeature>;
	errors?: FieldErrors<BuyingWorkflowLoanFormType | NewHomeLoanFormSlice>;
};

const LoanTermFC = <TFieldValues extends FieldValues = FieldValues>({
	register,
	showQuestion,
	selectedReasonForRefinancing,
	selectedLoanBuyingFeatures,
	onChangeLoanRefinancing,
	onChangeLoanBuyingFeatures,
	errors,
}: LoanTermType<TFieldValues>) => {
	const formState = useAppSelector((state) => state.form);
	return (
		<div aria-labelledby="loan-Term" className="flex flex-col gap-9">
			<span>
				<h2 className="text-primary text-[37.9px] font-normal">Loan Term</h2>
				<RadioGroup
					radioOptions={loanTermOptions}
					legend="Our maximum loan term is 30 years. Does that suit your needs?"
					register={register}
					error={errors && errors.loanTerm && errors.loanTerm.message}
				/>
			</span>
			{showQuestion.loanTerm && showQuestion.loanTerm === "yes" && (
				<LoanTermYesFC
					register={register}
					error={
						errors &&
						errors.meetsNeedsAndObjectives &&
						errors.meetsNeedsAndObjectives.message
					}
				/>
			)}
			{showQuestion.loanTerm && showQuestion.loanTerm === "no" && (
				<LoanTermNoFC
					register={register}
					showQuestion={showQuestion}
					errors={errors}
				/>
			)}
			{showQuestion.meetsNeedsAndObjectives === "false" &&
				onChangeLoanBuyingFeatures &&
				selectedLoanBuyingFeatures && (
					<LoanBuyingFeaturesFC
						register={register}
						onChange={onChangeLoanBuyingFeatures}
						selectedLoanBuyingFeatures={selectedLoanBuyingFeatures}
						errors={errors}
					/>
				)}
			{showQuestion.meetsNeedsAndObjectives &&
				formState.loanPurpose === LoanPurpose.Refinance &&
				onChangeLoanRefinancing &&
				selectedReasonForRefinancing && (
					<LoanRefinancingFeaturesFC
						register={register}
						onChange={onChangeLoanRefinancing}
						selectedReasonForRefinancing={selectedReasonForRefinancing}
						errors={errors}
					/>
				)}
		</div>
	);
};

export default LoanTermFC;
