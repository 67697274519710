import type { FC } from "react";
import { Progress } from "../../ui/progress/Progress";
import { useCaseSummary } from "../../../hooks/origination/use-case-summary";

export interface CaseSummaryProgressProps {
    applicationId: number;
    className?: string;
}

export const CaseSummaryProgress: FC<CaseSummaryProgressProps> = ({applicationId, className}) => {
    const {finished, data} = useCaseSummary(applicationId)
    return (
        <Progress value={finished.length} max={data.length} className={`mt-2 ${className ?? ""}`} />
    );
}
