import { useCallback } from "react";
import { toast } from "react-toastify";
import { useResetPasswordMutation } from "../services/api";
import type { ResetPasswordRequest } from "../services/apis/auth";
import { useAuthLock } from "./use-auth-context";
import { useAppDispatch } from "./redux-hooks";
import { setAccessToken, setAuthToken } from "../store/slices/auth-slice";
import { NamedRoute } from "../components/utils/NamedRoutes";
import { useAuthSuccessHandler } from "./use-auth-success";
// import { setToast } from "../store/slices/toast-slice";

export const useResetPassword = (navigate: (url: string) => void) => {
	const dispatch = useAppDispatch();
	const { handleSuccess } = useAuthSuccessHandler();
	const { setAuthLocked } = useAuthLock();
	const [resetPassword, { isLoading }] = useResetPasswordMutation();

	const onSubmitHandler = useCallback(
		async (data: ResetPasswordRequest) => {
			try {
				setAuthLocked(true);

				const response = await resetPassword(data).unwrap();
				if (
					response.data.message === "Password set successfully" ||
					!response.authToken ||
					!response.data.content
				) {
					toast.success("Password set successfully");
					setTimeout(() => {
						dispatch(setAuthToken(null));
						dispatch(setAccessToken(null));
						// If the api responds with the above message, it wasn't able to issue a session, so we need to navigate to the login page
						navigate(NamedRoute.login);
					}, 3000);
				} else {
					handleSuccess({
						authToken: response.authToken,
						...response.data.content,
					});

					navigate(NamedRoute.welcome);
				}
			} catch (error) {
				console.log(error);
				if (
					//@ts-expect-error - error.data is not defined in the type

					error?.data?.message ===
					"Error, the password is invalid as it is already in use."
				) {
					toast.error(
						"You've already used this password before, please enter a different password."
					);
					// dispatch(
					// 	setToast({
					// 		open: true,
					// 		type: "error",
					// 		title: "Error",
					// 		description:
					// 			"You've already used this password before, please enter a different password.",
					// 	})
					// );
				} else {
					toast.error("An error occurred while resetting your password.");
					// dispatch(
					// 	setToast({
					// 		open: true,
					// 		type: "error",
					// 		title: "Error",
					// 		description: "An error occurred while resetting your password.",
					// 	})
					// );
				}
			} finally {
				setAuthLocked(false);
			}
		},
		[dispatch, handleSuccess, navigate, resetPassword, setAuthLocked]
	);

	return {
		onSubmitHandler,
		isLoading,
	};
};
