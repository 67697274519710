import type { FunctionComponent } from "../../../common/types";
import { useAppSelector } from "../../../hooks/redux-hooks";
import {
	selectIsFullScreen,
	selectIsLoading,
	selectShowCreatAccountLoaderText,
} from "../../../store/slices/loader-slice";
import { Loader } from "../../ui/loader/Loader";

const FormLoader = (): FunctionComponent => {
	const isLoading = useAppSelector(selectIsLoading);
	const isFullScreen = useAppSelector(selectIsFullScreen);
	const showCreatAccountLoaderText = useAppSelector(selectShowCreatAccountLoaderText);

	return <Loader isLoading={isLoading} isFullScreen={isFullScreen} showCreateAccountLoaderText={showCreatAccountLoaderText} />;
};

export default FormLoader;
