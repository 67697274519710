import type { FieldValues, UseFormRegister } from "react-hook-form";
import { loanMeetsNeedsAndObjectivesOptions } from "../../../../../../utils/BootList";
import RadioGroup from "../../../../../radio-group/RadioGroup";
import LoanStructureFC from "../../loan-structure/LoanStructure";

type LoanTermYesType<TFieldValues extends FieldValues = FieldValues> = {
	register: UseFormRegister<TFieldValues>;
	error?: string;
};

const LoanTermYesFC = <TFieldValues extends FieldValues = FieldValues>({
	register,
	error,
}: LoanTermYesType<TFieldValues>) => {
	return (
		<>
			<LoanStructureFC />
			<RadioGroup
				radioOptions={loanMeetsNeedsAndObjectivesOptions}
				legend="Does this meet your needs and objectives?"
				register={register}
				error={error}
			/>
		</>
	);
};

export default LoanTermYesFC;
