import { ApplicationSummary } from "./ApplicationSummary";
import { CaseSummary } from "./CaseSummary";
import type { FC } from "react";
import { JointApplicant } from "./JointApplicant"

export interface VerificationColumnProps {
	applicationId: number;
}

export const VerificationColumn: FC<VerificationColumnProps> = ({applicationId}: VerificationColumnProps) => {

	return (
		<div className="md:sticky md:top-36">
			{applicationId && <CaseSummary className="hidden md:flex" applicationId={applicationId} />}
			{applicationId && <ApplicationSummary className="mt-0 md:mt-4" applicationId={applicationId} />}
			{applicationId && <JointApplicant applicationId={applicationId} />}
		</div>
	);
};
