import type { FC, ReactNode } from "react";
import { MobileProgress } from "../../components/layout/header/mobile-progress/MobileProgress";
import { ProgressBar } from "../../components/layout/header/progress-bar/ProgressBar";
import ContactNumber from "../../components/ui/contact-number/ContactNumber";
import LoginLink from "../../components/ui/login-link/LoginLink";
import HeaderMenu from "../../components/ui/header-menu/HeaderMenu";
import SiteBrand from "../../components/ui/site-brand/SiteBrand";
import { useAppSelector } from "../../hooks/redux-hooks";
import { selectIsLoggedIn } from "../../store/slices/auth-slice";

type HeaderProps = {
	progress?: ReactNode;
	workingTowards?: ReactNode;
}

export const Header: FC<HeaderProps> = ({ progress: externalProgress, workingTowards}) => {
	const isLoggedIn = useAppSelector(selectIsLoggedIn);
	const progress = useAppSelector((state) => state.stepper.progress);

	return (
		<>
			<header className={`sticky bg-white top-0 w-full py-4 lg:py-8 transition-all z-40 box-border ${externalProgress ? "md:border-b border-off-black-200" : ""} shadow-lg md:shadow-none`}>
				<div className="container px-4 mx-auto flex justify-between items-center flex-wrap">
					{!externalProgress && progress > 0 && <MobileProgress progress={progress} />}
					<SiteBrand className="w-32 lg:w-40" />
					{workingTowards}
					<div className="flex items-center gap-2 md:gap-12">
						<ContactNumber size="normal" />
						{isLoggedIn ? <HeaderMenu /> : <LoginLink />}
					</div>
				</div>
				{!externalProgress && <ProgressBar progress={progress} />}
			</header>
			{externalProgress}
		</>
	);
};
