import { type FormEvent, useEffect, useRef } from "react";
import type { OptionsType, OtherIncome } from "../../../../../common/types";
import {
	useAppDispatch,
	useAppSelector,
} from "../../../../../hooks/redux-hooks";
import { navigateToStep } from "../../../../../store/slices/stepper-slice";
import Button from "../../../../ui/button/Button";
import {
	setBusinessABN,
	setBusinessIncorporationDate,
	setBusinessName,
	setBusinessStartDate,
	setCurrentEmployerName,
	setCurrentEmploymentType,
	setCurrentJobTitle,
	setCurrentRoleStartDate,
	setIndustryMonths,
	setIndustryYears,
	setOtherIncomeAllowed,
	setOtherIncomes,
	setPreviousEmployerName,
	setPreviousFinanceActiveForm,
	setPreviousJobTitle,
	setPreviousRoleEndDate,
	setPreviousRoleStartDate,
	setProbation,
} from "../../../../../store/slices/form-slice";
import SelectInput from "../../../select-input/SelectInput";
import { zodResolver } from "@hookform/resolvers/zod";
import { type Control, useForm } from "react-hook-form";
import {
	EmploymentOptions,
	OtherIncomeAllowedOptions,
} from "../../../../utils/BootList";
import RadioGroup from "../../../radio-group/RadioGroup";
import { EmploymentSchema } from "./EmployementSchema";
import OtherIncomesForm from "../../../other-incomes/OtherIncomes";
import { navigateToJointStep } from "../../../../../store/slices/joint-stepper-slice";
import { selectUpdateIncomePayload } from "../../../../../store/selectors/update-income-payload";
import type {
	EmploymentType,
	FrequencyType,
	IncomeDetailsType,
	IncomeType,
} from "../../../../../services/apis/update-income.schema";
import {
	SubStep,
	Step,
	ApplicationType,
} from "../../../../../services/apis/create-application.schema";

import { setFormLoading } from "../../../../../store/slices/loader-slice";
import { setToast } from "../../../../../store/slices/toast-slice";
import FullTimeEmployment from "./full-time-employee/FullTimeEmployee";
import SelfEmployment from "./self-employee/SelfEmployee";
import { selectHeaderHeight } from "../../../../../store/slices/ui-slice";
import useTrackPageViewOnMount from "../../../../../hooks/use-track-on-mount";
import { useUpdateIncomeEmployment } from "../../../../../services/apis/origination/income-employment";
import { captureException } from "../../../../../services/sentry";

export type YourEmploymentFormType = {
	currentEmploymentType: OptionsType;
	otherIncomeAllowed: string;
	otherIncomes: Array<OtherIncome>;
};

const setOtherIncomeDetails = (
	isAllowed: boolean,
	otherIncomes: Array<OtherIncome>
) => {
	const incomeDetails: Array<IncomeDetailsType> = [];
	if (isAllowed && otherIncomes && otherIncomes.length > 0) {
		for (const income of otherIncomes) {
			const otherIncome: IncomeDetailsType = {
				incomeType: income.incomeType.value as IncomeType, // Use the IncomeType enum here
				currentIncomeAmount: income.estimatedAmount!,
				priorIncomeAmount: 0,
				receivedForLastSixMonths: false,
				frequency: income.frequency.value as FrequencyType,
			};
			incomeDetails.push({ ...otherIncome });
		}
	}
	return incomeDetails;
};
const YourEmployment = ({ isJoint }: { isJoint?: boolean }) => {
	const dispatch = useAppDispatch();
	const formState = useAppSelector((state) => state.form);
	const [updateIncome, { isLoading }] = useUpdateIncomeEmployment();
	const updateIncomePayload = useAppSelector(selectUpdateIncomePayload);
	const YourEmploymentDefaultValues: Partial<YourEmploymentFormType> = {
		currentEmploymentType: formState.currentEmploymentType,
		otherIncomeAllowed: formState.otherIncomeAllowed,
		otherIncomes:
			formState.otherIncomeAllowed === "true" ? formState.otherIncomes : [],
	};

	const {
		register,
		control,
		handleSubmit,
		watch,
		setValue,
		formState: { errors },
	} = useForm({
		resolver: zodResolver(EmploymentSchema),
		defaultValues: { ...YourEmploymentDefaultValues },
	});

	useTrackPageViewOnMount({
		page: "Your Finances",
		subPage: "Your Employment",
	});

	const clearOtherIncomeFields = () => {
		setValue("otherIncomeAllowed", "");
		setValue("otherIncomes", []);
	};
	const clearFormBasedOnEmploymentType = (
		currentEmploymentType: OptionsType
	) => {
		if ([1, 2, 3, 5].includes(currentEmploymentType.id)) {
			dispatch(setBusinessName(""));
			dispatch(setBusinessABN(undefined));
			dispatch(setBusinessIncorporationDate(""));
			dispatch(setBusinessStartDate(""));
			dispatch(setCurrentEmployerName(""));
			dispatch(setCurrentJobTitle(""));
			dispatch(setCurrentRoleStartDate(""));
			dispatch(setProbation(""));
			dispatch(
				setIndustryMonths({
					id: 0,
					value: "",
					label: "Months",
				})
			);
			dispatch(
				setIndustryYears({
					id: 0,
					value: "",
					label: "Years",
				})
			);
			dispatch(setPreviousEmployerName(""));
			dispatch(setPreviousJobTitle(""));
			dispatch(setPreviousRoleEndDate(""));
			dispatch(setPreviousRoleStartDate(""));
			clearOtherIncomeFields();
		}
	};
	const saveData = async (data: Partial<YourEmploymentFormType>) => {
		dispatch(setCurrentEmploymentType(data.currentEmploymentType!));
		dispatch(setPreviousFinanceActiveForm(SubStep.YourEmployment));
		dispatch(setOtherIncomeAllowed(data.otherIncomeAllowed!));
		if (data.otherIncomeAllowed === "true") {
			dispatch(setOtherIncomes(data.otherIncomes!));
		} else {
			dispatch(setOtherIncomes([]));
		}
		if (
			// Not employeed
			data.currentEmploymentType?.id === 6
		) {
			if (formState.applicationType === ApplicationType.Joint && !isJoint) {
				dispatch(
					navigateToStep({
						step: "financeStep",
						subStep: SubStep.YourJointBorrowerIncome,
					})
				);
			} else {
				try {
					await updateIncome({
						...updateIncomePayload,
						currentEmploymentType: data.currentEmploymentType
							?.value as EmploymentType,
						hasAnyOtherIncome: data.otherIncomeAllowed === "true",
						employmentInformation: [
							{
								currentEmploymentType: data.currentEmploymentType
									?.value as EmploymentType,
								isCurrent: true,
								inProbation: false,
							},
						],
						receivedCommissionOrBonus: false,
						receivedOverTimeOrAllowance: false,
						incomeDetails: setOtherIncomeDetails(
							data.otherIncomeAllowed === "true",
							data.otherIncomes!
						),
						applicationStep: Step.YourFinances,
						applicationSubStep: isJoint
							? SubStep.YourAssets
							: SubStep.YourHouseholdExpenses, //TODO fix with is joint after fetch api works
					}).unwrap();

					if (isJoint) {
						dispatch(
							navigateToJointStep({
								step: "financeStep",
								subStep: SubStep.YourAssets,
							})
						);
					} else {
						dispatch(
							navigateToStep({
								step: "financeStep",
								subStep: SubStep.YourHouseholdExpenses,
							})
						);
					}
				} catch (error) {
					captureException(new Error("Error saving employment details"), {data: {error}});
					dispatch(
						setToast({
							open: true,
							type: "error",
							title: "Error",
							description:
								"An error occurred while saving your employment details",
						})
					);
				}
			}
		}
	};
	useEffect(() => {
		dispatch(setFormLoading(isLoading));
		return () => {
			dispatch(setFormLoading(false));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);
	register("otherIncomeAllowed", {
		onChange: (event: FormEvent<HTMLInputElement>) => {
			if (event.currentTarget.value === "false") {
				setValue("otherIncomes", undefined);
			}
		},
	});
	const h1Ref = useRef<HTMLHeadingElement>(null);

	const headerHeight = useAppSelector(selectHeaderHeight) ?? 100;

	useEffect(() => {
		const timer = setTimeout(() => {
			if (h1Ref.current) {
				const elementPosition = h1Ref.current.getBoundingClientRect().top;
				const offsetPosition =
					elementPosition + window.pageYOffset - headerHeight * 2;

				window.scrollTo({
					top: offsetPosition,
					behavior: "smooth",
				});
			}
		}, 300); // Added a 300ms delay before scrolling

		return () => {
			clearTimeout(timer);
		}; // Cleanup the timeout on component unmount
	}, [headerHeight]);
	return (
		<div
			aria-labelledby="YourEmployement form"
			className="flex flex-col gap-8 h-full"
		>
			<h1
				ref={h1Ref}
				className="text-primary text-[37.9px] font-normal"
				aria-labelledby="Your Employment"
			>
				Your Employment
			</h1>
			<div
				className={`flex flex-col ${watch("currentEmploymentType")?.id === 6 ? "gap-9" : ""}`}
			>
				<SelectInput
					name="currentEmploymentType"
					value={
						watch("currentEmploymentType") || {
							id: 0,
							value: "",
							label: "Select option",
						}
					}
					label="Current employment type"
					options={EmploymentOptions}
					register={register}
					onChangeHandler={(event: OptionsType) => {
						setValue("currentEmploymentType", event);
						clearFormBasedOnEmploymentType(event);
					}}
				/>
				{/* FOR Not employed */}
				{!!watch("currentEmploymentType")?.id &&
					watch("currentEmploymentType")?.id === 6 && (
						<>
							<RadioGroup
								name="otherIncomeAllowed"
								radioOptions={OtherIncomeAllowedOptions}
								legend="Do you receive any other income (excluding rental income)?"
								register={register}
								error={
									errors.otherIncomeAllowed && errors.otherIncomeAllowed.message
								}
							/>
							{watch("otherIncomeAllowed") === "true" && (
								<OtherIncomesForm
									initialOtherIncomes={formState.otherIncomes}
									onChange={(otherIncomes) => {
										setValue("otherIncomes", otherIncomes);
									}}
									control={
										control as Control<
											Pick<YourEmploymentFormType, "otherIncomes">
										>
									}
									errors={errors.otherIncomes}
								/>
							)}
						</>
					)}

				{/* FOR FT, PT, Contractor, Casual */}
				{!!watch("currentEmploymentType")?.id &&
					[1, 2, 3, 4].includes(watch("currentEmploymentType")?.id || 0) && (
						<FullTimeEmployment
							isJoint={isJoint}
							employemntOption={watch("currentEmploymentType")!}
						/>
					)}
				{/* FOR SELF Employeed */}
				{!!watch("currentEmploymentType")?.id &&
					watch("currentEmploymentType")?.id === 5 && (
						<SelfEmployment
							isJoint={isJoint}
							employemntOption={watch("currentEmploymentType")!}
						/>
					)}
			</div>
			<footer
				className="flex items-center justify-end gap-4"
				aria-labelledby="Actions wrapper"
			>
				{watch("currentEmploymentType")?.id === 6 && (
					<Button
						text="Next"
						variant="primary"
						iconSuffix={<i className="icon-arrow" />}
						type="button"
						handleClick={handleSubmit(saveData)}
						isDisabled={isLoading}
					/>
				)}
			</footer>
		</div>
	);
};

export default YourEmployment;
