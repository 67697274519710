import { useEffect, useState } from "react";
import Button from "../../../../../ui/button/Button";
import type { AssetsFormSlice, InvestmentPropertyAsset } from "../YourAssetsSchema";
import type { Control, FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import GenericInputTeriary from "../../../../generic-input-teriary/GenericInputTeriary";
import CheckboxInputSecondary from "../../../../checkbox-input-secondary/CheckboxInputSecondary";
import LoanOnPropertyForm from "./loan-on-property-form/LoanOnPropertyForm";
import type { LoanOnProperty } from "../../../../../../services/apis/create-application.schema";
import DollarInput from "../../../../dollar-input/DollarInput";

type InvestmentPropertyAssetErrorType = Merge<
	FieldError,
	Array<Merge<FieldError, FieldErrorsImpl<InvestmentPropertyAsset>> | undefined>
>;
//Initializing loan on property with undefined values
const initLoanOnProperty = {} as LoanOnProperty;
const InvestmentPropertyAssetsDisplay = ({
	index,
	investmentPropertyAsset,
	control,
	errors,
	onChangeInvestmentPropertyAssetHandler,
	removeInvestmentPropertyAssetHandler,
}: {
	index: number;
	investmentPropertyAsset: InvestmentPropertyAsset;
	onChangeInvestmentPropertyAssetHandler: (
		value: InvestmentPropertyAsset
	) => void;
	removeInvestmentPropertyAssetHandler: (value: number) => void;
	control: Control<AssetsFormSlice>;
	errors?: InvestmentPropertyAssetErrorType;
}) => (
	<div className="w-full flex flex-col gap-4">
		<GenericInputTeriary
			type="text"
			value={investmentPropertyAsset.propertyAddress}
			error={
				errors &&
				errors[index]?.propertyAddress &&
				errors[index]?.propertyAddress?.message
			}
			label=""
			placeholder="Property Address"
			onChangeHandler={(value) => {
				const updateInvestmentPropertyAsset = {
					...investmentPropertyAsset,
				};
				updateInvestmentPropertyAsset.propertyAddress = value.toString();
				onChangeInvestmentPropertyAssetHandler(updateInvestmentPropertyAsset);
			}}
		/>
		<DollarInput
			name={`investmentPropertyAssets.${index}.propertyValue`}
			control={control}
			type="numeric"
			value={investmentPropertyAsset.propertyValue}
			error={
				errors &&
				errors[index]?.propertyValue &&
				errors[index]?.propertyValue?.message
			}
			label=""
			placeholder="Enter Property Value"
			iconPrefix={<i className="icon-dollar" />}
			onValueChange={(value) => {
				const updateInvestmentPropertyAsset = {
					...investmentPropertyAsset,
				};
				updateInvestmentPropertyAsset.propertyValue = value || 0;
				onChangeInvestmentPropertyAssetHandler(updateInvestmentPropertyAsset);
			}}
		/>
		<div className="flex flex-col md:flex-row gap-4">
			<DollarInput
				name={`investmentPropertyAssets.${index}.monthlyRentalIncome`}
				control={control}
				type="numeric"
				value={investmentPropertyAsset.monthlyRentalIncome}
				error={
					errors &&
					errors[index]?.monthlyRentalIncome &&
					errors[index]?.monthlyRentalIncome?.message
				}
				label=""
				placeholder="Monthly Rental Income"
				iconPrefix={<i className="icon-dollar" />}
				iconSuffix={<span className="flex shrink-0">/ month</span>}
				onValueChange={(value) => {
					const updateInvestmentPropertyAsset = {
						...investmentPropertyAsset,
					};
					updateInvestmentPropertyAsset.monthlyRentalIncome = value || 0;
					onChangeInvestmentPropertyAssetHandler(updateInvestmentPropertyAsset);
				}}
			/>
			<DollarInput
				name={`investmentPropertyAssets.${index}.monthlyExpenses`}
				control={control}
				type="numeric"
				value={investmentPropertyAsset.monthlyExpenses}
				error={
					errors &&
					errors[index]?.monthlyExpenses &&
					errors[index]?.monthlyExpenses?.message
				}
				label=""
				placeholder="Monthly Expenses"
				iconPrefix={<i className="icon-dollar" />}
				iconSuffix={<span className="flex shrink-0">/ month</span>}
				onValueChange={(value) => {
					const updateInvestmentPropertyAsset = {
						...investmentPropertyAsset,
					};
					updateInvestmentPropertyAsset.monthlyExpenses = value || 0;
					onChangeInvestmentPropertyAssetHandler(updateInvestmentPropertyAsset);
				}}
			/>
		</div>
		<div className="flex flex-col md:flex-row gap-4">
			<DollarInput
				name={`investmentPropertyAssets.${index}.primaryApplicantOwnership`}
				control={control}
				type="numeric"
				value={investmentPropertyAsset.primaryApplicantOwnership}
				error={
					errors &&
					errors[index]?.primaryApplicantOwnership &&
					errors[index]?.primaryApplicantOwnership?.message
				}
				label=""
				placeholder="Primary Applicant Ownership"
				iconSuffix={<span className="flex shrink-0">%</span>}
				onValueChange={(value) => {
					const updateInvestmentPropertyAsset = {
						...investmentPropertyAsset,
					};
					updateInvestmentPropertyAsset.primaryApplicantOwnership = value || 0;
					onChangeInvestmentPropertyAssetHandler(updateInvestmentPropertyAsset);
				}}
			/>
			<DollarInput
				name={`investmentPropertyAssets.${index}.jointApplicantOwnership`}
				control={control}
				type="numeric"
				value={investmentPropertyAsset.jointApplicantOwnership}
				error={
					errors &&
					errors[index]?.jointApplicantOwnership &&
					errors[index]?.jointApplicantOwnership?.message
				}
				label=""
				placeholder="Joint Applicant Ownership"
				iconSuffix={<span className="flex shrink-0">%</span>}
				onValueChange={(value) => {
					const updateInvestmentPropertyAsset = {
						...investmentPropertyAsset,
					};
					updateInvestmentPropertyAsset.jointApplicantOwnership = value || 0;
					onChangeInvestmentPropertyAssetHandler(updateInvestmentPropertyAsset);
				}}
			/>
		</div>
		<CheckboxInputSecondary
			label="I don’t have a loan for this property"
			name="noLoanOnProperty"
			value="noLoanOnProperty"
			size="small"
			checked={investmentPropertyAsset.noLoanOnProperty === true}
			onChange={(event) => {
				const updateInvestmentPropertyAsset = {
					...investmentPropertyAsset,
				};
				updateInvestmentPropertyAsset.noLoanOnProperty = event.target.checked;
				updateInvestmentPropertyAsset.loansOnProperty = event.target.checked
					? []
					: [{ ...initLoanOnProperty }];
				onChangeInvestmentPropertyAssetHandler(updateInvestmentPropertyAsset);
			}}
			error={errors && errors[index]?.noLoanOnProperty?.message}
		/>
		{!investmentPropertyAsset.noLoanOnProperty && (
			<LoanOnPropertyForm
				initialLoanOnProperties={investmentPropertyAsset.loansOnProperty}
				onChange={(loansOnProperty) => {
					const updateInvestmentPropertyAsset = {
						...investmentPropertyAsset,
					};
					updateInvestmentPropertyAsset.loansOnProperty = loansOnProperty;
					onChangeInvestmentPropertyAssetHandler(updateInvestmentPropertyAsset);
				}}
				subFormName={`investmentPropertyAssets.${index}.loansOnProperty`}
				control={control}
				errors={errors && errors[index]?.loansOnProperty}
			/>
		)}
		<Button
			text="Remove property"
			variant="plain"
			type="button"
			iconSuffix={<i className="icon-remove" />}
			handleClick={() => {
				removeInvestmentPropertyAssetHandler(index);
			}}
		/>
	</div>
);
const emptyInvestmentPropertyAsset = {} as InvestmentPropertyAsset;
const InvestmentPropertyAssetsForm = ({
	initialInvestmentPropertyAssets,
	onChange,
	control,
	errors,
}: {
	initialInvestmentPropertyAssets?: Array<InvestmentPropertyAsset>;
	control: Control<AssetsFormSlice>;
	errors?: InvestmentPropertyAssetErrorType;
	onChange: (investmentPropertyAssets: Array<InvestmentPropertyAsset>) => void;
}) => {
	const [investmentPropertyAssets, setInvestmentPropertyAssets] = useState<
		Array<InvestmentPropertyAsset>
	>(initialInvestmentPropertyAssets ?? []);

	const handleInvestmentPropertyAssetChange = (
		index: number,
		investmentPropertyAsset: InvestmentPropertyAsset
	) => {
		const updatedInvestmentPropertyAssets = [...investmentPropertyAssets];
		updatedInvestmentPropertyAssets[index] = { ...investmentPropertyAsset };
		setInvestmentPropertyAssets(updatedInvestmentPropertyAssets);
	};

	useEffect(() => {
		onChange(investmentPropertyAssets);
	}, [investmentPropertyAssets, onChange]);

	const removeInvestmentPropertyAssetHandler = (index: number) => {
		const updatedInvestmentPropertyAssets = [...investmentPropertyAssets];
		updatedInvestmentPropertyAssets.splice(index, 1);
		setInvestmentPropertyAssets(updatedInvestmentPropertyAssets);
	};
	return (
		<section className="flex flex-col gap-4">
			<div className="flex flex-col md:flex-row gap-4 md:justify-between md:items-end">
				<h1 className="text-primary text-[21.33px] font-normal mt-4">
					<i className="icon-house" />
					{" Investment Property"}
				</h1>
				{investmentPropertyAssets.length > 0 && (
					<Button
						text={"Add another property"}
						variant="plain"
						type="button"
						iconSuffix={<i className="icon-plus" />}
						handleClick={() => {
							const updatedInvestmentPropertyAssets = [
								...investmentPropertyAssets,
								{ ...emptyInvestmentPropertyAsset },
							];
							setInvestmentPropertyAssets(updatedInvestmentPropertyAssets);
						}}
					/>
				)}
			</div>
			{investmentPropertyAssets.map((investmentPropertyAsset, index) => (
				<InvestmentPropertyAssetsDisplay
					key={index}
					index={index}
					control={control}
					errors={errors}
					investmentPropertyAsset={investmentPropertyAsset}
					onChangeInvestmentPropertyAssetHandler={(
						value: InvestmentPropertyAsset
					) => {
						handleInvestmentPropertyAssetChange(index, value);
					}}
					removeInvestmentPropertyAssetHandler={
						removeInvestmentPropertyAssetHandler
					}
				/>
			))}
			{investmentPropertyAssets.length === 0 && (
				<Button
					text={
						<span>
							<i className="icon-plus" /> {`Add ${investmentPropertyAssets.length > 0 ? "another " : ""}investment property`}
						</span>
					}
					type="button"
					size="fit"
					variant="accent"
					textAlign="center"
					handleClick={() => {
						const updatedInvestmentPropertyAssets = [
							...investmentPropertyAssets,
							{ ...emptyInvestmentPropertyAsset },
						];
						setInvestmentPropertyAssets(updatedInvestmentPropertyAssets);
					}}
				/>
			)}
		</section>
	);
};

export default InvestmentPropertyAssetsForm;
