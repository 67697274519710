import introAsideBadge from "../../../assets/intro-badge.png";
import introAsideBanner from "../../../assets/intro.png";
import type { FunctionComponent } from "../../../common/types";

const IntroAside = (): FunctionComponent => {
	return (
		<section
			className="hidden lg:block relative lg:pr-10 lg:pb-10"
			aria-labelledby="Intro Aside section"
		>
			<img
				src={introAsideBanner}
				alt="intro page Aside Banner"
				className="lg:w-full"
			/>
			<img
				src={introAsideBadge}
				alt="intro page Aside Badge"
				className="absolute w-[126px] bottom-1.5 right-0"
			/>
		</section>
	);
};

export default IntroAside;
