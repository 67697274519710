import type { FC } from "react";
import { LoanPurpose } from "@sucasa-finance/origination-trpc";
import { formatNumber } from "../../../helpers/format-number";
import { origination } from "../../../services/origination";
import HintCard from "../../ui/hint-card/HintCard";

export interface ApplicationSummaryProps {
    applicationId: number;
    className?: string;
}

export const ApplicationSummary: FC<ApplicationSummaryProps> = ({ applicationId, className }) => {
    const [application] = origination.application.getApplicationById.useSuspenseQuery({ applicationId });
    const isRefinance = application.purpose === LoanPurpose.Refinance;
    const fundsAvailable = Number(application.savingsAmount) + Number(application.giftedAmount);
    const priceFrom = isRefinance ? application.purchasePrice : application.idealPurchasePriceFrom;
    const priceTo = isRefinance ? undefined : application.idealPurchasePriceTo;
    return (
        <HintCard className={`flex flex-col w-full gap-0 px-8 ${className ?? ""}`} noGap>
            <div className="flex flex-col w-full py-4 border-b border-b-slate-950">
                {
                    priceFrom && (
                        <>
                            <span className="flex flex-row items-end justify-start font-bold pb-2">
                                <span className="text-sm pr-1">$</span>
                                <span className="text-xl leading-none">{formatNumber(priceFrom)}</span>
                                {
                                    priceTo && (
                                        <>
                                            <span className="text-normal px-1 font-normal">-</span>
                                            <span className="text-sm pr-1">$</span>
                                            <span className="text-xl leading-none">{formatNumber(priceTo)}</span>
                                        </>
                                    )
                                }
                            </span>
                            <span className="text-off-black-600 pb-4">{isRefinance ? "Estimated Property Valuation" : "Property Purchase Amount"}</span>
                        </>
                    )
                }
                <span className="flex flex-row items-center justify-start">
                    <i className="icon-map-pin text-xl pr-2 icon-no-width" />
                    <span className="font-bold">{application.property}</span>
                </span>
            </div>
            {
                isRefinance && (
                    <>
                        <div className="flex flex-col w-full py-4 border-b border-b-off-black-200">
                            <span className="flex flex-row items-end justify-start font-bold pb-2">
                                <span className="text-sm pr-1">$</span>
                                <span className="text-xl leading-none">{formatNumber(application.borrowAmount)}</span>
                            </span>
                            <span className="text-off-black-600">Requested Loan Amount</span>
                        </div>
                        <div className="flex flex-col w-full pt-4 pb-2">
                            <span className="flex flex-row items-end justify-start font-bold pb-2">
                                <span className="text-sm pr-1">$</span>
                                <span className="text-xl leading-none">{formatNumber(application.refinanceLoanAdditionalBorrowingAmount)}</span>
                            </span>
                            <span className="text-off-black-600">Additional Funds Requested</span>
                        </div>
                    </>
                )}
                {!isRefinance && (
                    <>
                        <div className="flex flex-col w-full py-4 border-b border-b-off-black-200">
                            <span className="flex flex-row items-end justify-start font-bold pb-2">
                                <span className="text-sm pr-1">$</span>
                                <span className="text-xl leading-none">{formatNumber(fundsAvailable)}</span>
                            </span>
                            <span className="text-off-black-600">Funds available  to purchase</span>
                        </div>
                        <div className="flex flex-row w-full pt-4 pb-2">
                            <div className="flex flex-col flex-grow">
                                <span className="flex flex-row items-end justify-start font-bold pb-2">
                                    <span className="text-sm pr-1">$</span>
                                    <span className="text-xl leading-none">{formatNumber(application.savingsAmount)}</span>
                                </span>
                                <span className="text-off-black-600">Savings</span>
                            </div>
                            <div className="flex flex-col flex-grow">
                                <span className="flex flex-row items-end justify-start font-bold pb-2">
                                    <span className="text-sm pr-1">$</span>
                                    <span className="text-xl leading-none">{formatNumber(application.giftedAmount)}</span>
                                </span>
                                <span className="text-off-black-600">Gifted Amount</span>
                            </div>
                        </div>
                    </>
                )
            }
        </HintCard>
    );
}
