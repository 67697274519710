import type React from "react";
import * as ToastPrimitive from "@radix-ui/react-toast";
import "./toast.css";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hooks";
import type { RootState } from "../../../store";
import { setToast } from "../../../store/slices/toast-slice";

export type ToastVariant = "error" | "success" | "info";

export interface ToasterProps {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	type: ToastVariant;
	title: string;
	description: string;
	actionText?: string;
	onAction?: () => void;
}

const ICON_MAP = {
	error: "icon-warning text-red-500",
	success: "icon-verified text-grass-hopper-900",
	info: "icon-warning text-lavender-100 rotate-180",
};

export const Toaster = () => {
	const dispatch = useAppDispatch();
	const { open, type, description } = useAppSelector(
		(state: RootState) => state.toast
	);
	return (
		<ToastPrimitive.Root
			className="ToastRoot ToastAction bg-off-black-900 flex items-center gap-4 p-4"
			open={open}
			onOpenChange={() =>
				dispatch(setToast({ open: false, type: "success", description: "" }))
			}
			duration={3500}
		>
			<i className={ICON_MAP[type]} />
			<ToastPrimitive.Description className="ToastDescription flex-1">
				<p className="font-normal text-sm text-white whitespace-normal">
					{description}
				</p>
			</ToastPrimitive.Description>
			<ToastPrimitive.Close className="flex-shrink-0">
				<i className="icon-remove text-white" />
			</ToastPrimitive.Close>
		</ToastPrimitive.Root>
	);
};
