import { useCallback, useState } from "react";
import { useForgotPasswordMutation } from "../services/api";
import type { ForgotPasswordRequest } from "../services/apis/auth";

export const useForgotPassword = () => {
	const [isError, setIsError] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);
	const [forgotPassword, { isLoading }] =
		useForgotPasswordMutation();

	const onSubmitHandler = useCallback(async ({ email }: ForgotPasswordRequest) => {
		try {
			const response = await forgotPassword({ email }).unwrap();
			if (response.data?.content?.success) {
				setIsError(false);
				setIsSuccess(true);
			} else {
				setIsError(true);
				setIsSuccess(false);
			}
		} catch {
			setIsError(true);
			setIsSuccess(false);
		}
	}, [forgotPassword]);

	return {
		onSubmitHandler,
		isLoading,
		isError,
		isSuccess
	};
};
