import type { FC } from "react";
import HintCard from "../hint-card/HintCard";

export const JointApplicantInviteHint: FC = () => (<HintCard
	icon={<i className="icon-two-users" />}
	text={
		<>
			<span className="font-medium">
				Clicking "Next" will invite your joint applicant to join this application.
			</span>
		</>
	}
/>);
