import { type FC, useCallback, useEffect, useMemo, useState } from "react";
import { VerificationCallback, VerificationResponseOwner } from "@sucasa-finance/origination-trpc";
import type { VerificationCase } from "../../../../../services/apis/origination/types";
import Button from "../../../../ui/button/Button";
import { useAddCaseResponse } from "../../../../../hooks/origination/use-add-case-response";
import { BiometricsInstructions } from "./BiometricsInstructions";
import { useTracking } from "../../../../../hooks/use-tracking";

type BiometricsModalProps = {
	verificationCase?: VerificationCase
}
export const BiometricsModal: FC<BiometricsModalProps> = ({verificationCase}) => {
	const [isDisabled, setIsDisabled] = useState(false);
	const [isDisabledSend, setIsDisabledSend] = useState(false);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const { onAcceptBiometrics, onSendBiometrics } = useAddCaseResponse();
	const {trackEvent} = useTracking();
	// const canTriggerBiometricsAccept =  useMemo(() =>
	// 		verificationCase?.callbacksAvailable?.includes(VerificationCallback.BiometricsAccept),
	// 	[verificationCase?.callbacksAvailable]);
	const hasTriggerBiometricsRun =  useMemo(() =>
			verificationCase?.callbacksTriggered?.includes(VerificationCallback.BiometricsRun),
		[verificationCase?.callbacksTriggered]);
	// const canTriggerBiometricsRun =  useMemo(() =>
	// 		verificationCase?.callbacksAvailable?.includes(VerificationCallback.BiometricsRun),
	// 	[verificationCase?.callbacksAvailable]);

	const sentCaseResponse = useMemo(() =>
			verificationCase?.caseResponses?.find(itm => itm.owner === VerificationResponseOwner.Admin
				&& itm.body?.['callback'] === VerificationCallback.BiometricsRun),
		[verificationCase?.caseResponses]);

	const onAcceptBiometricsCallback = useCallback(() => {
		trackEvent(hasTriggerBiometricsRun ? "resent_biometrics_clicked" : "biometrics_consent_clicked", {
			cta: 'modal',
		});
		verificationCase?.id && onAcceptBiometrics(verificationCase.id);
		verificationCase?.id && onSendBiometrics(verificationCase.id);
		setIsDisabled(true);
		setShowConfirmation(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [onAcceptBiometrics, onSendBiometrics, verificationCase?.id, hasTriggerBiometricsRun]);

	const onSendBiometricsCallback = useCallback(() => {
		verificationCase?.id && onSendBiometrics(verificationCase.id);
		setIsDisabledSend(true);
	}, [onSendBiometrics, verificationCase?.id]);

	useEffect(() => {
		if (hasTriggerBiometricsRun) {
			setShowConfirmation(true);
		}
	}, [hasTriggerBiometricsRun]);

	return (<div className="flex flex-col gap-4">
		{!showConfirmation && <>
			<div
				className="flex flex-col gap-4 md:gap-8 items-center text-[28.43px] font-medium leading-10 text-primary">
				<i className="icon-passport text-2xl md:text-[60px]" />
			</div>
			<h2 className="text-4xl text-center">Hey {verificationCase?.user.firstName}, happy to have you here!</h2>
			<div className="text-xl text-center">In order to proceed, we need your consent to do an ID verification check.
			</div>
			<div className="border border-off-black-900 p-8">
			<span>"I consent to collection, use and disclosure of my personal information in accordance
				with the <a href="https://www.sucasa.com.au/privacy-policy" target="_blank" rel="noopener noreferrer" className="underline">Sucasa Privacy Policy</a> and consent to my personal information being disclosed to a
				credit reporting agency or my information being checked with the document issuer or official
				record holder in connection with a request to verify my identity in accordance with the AML/CTF Act."</span>
				<div className="flex flex-col items-center pt-4">
					<Button
						isDisabled={isDisabled}
						text="Agree and continue"
						className="min-w-min text-lg rounded"
						iconSuffix={<i className="icon-check" />}
						variant="primary"
						textAlign="center"
						handleClick={onAcceptBiometricsCallback}
					/>
				</div>
			</div>
		</>}
		{showConfirmation && <>
			<div
				className="flex flex-col gap-4 md:gap-8 items-center text-[28.43px] font-medium leading-10 text-primary">
				<i className="icon-mobile text-2xl md:text-[60px]" />
			</div>
			<div className="text-2xl text-center">{hasTriggerBiometricsRun ? `We've sent an SMS to your number` : sentCaseResponse?.message ?? 'We just sent an SMS to your number'}</div>
			<div className="text-xl text-center">Here is what you need to do next:</div>
			<div className="border border-off-black-900 p-8">
				<div className="flex flex-col justify-normal items-start w-full gap-4">
					<BiometricsInstructions />
				</div>
			</div>
			<div className={`flex flex-col items-center p-8 `}>
				{hasTriggerBiometricsRun && <Button
					isDisabled={isDisabledSend}
					text={hasTriggerBiometricsRun ? "Re-send verification link" : "Send verification link"}
					className="min-w-min text-lg rounded w-2/3"
					iconSuffix={<i className="icon-send" />}
					variant="primary"
					textAlign="center"
						handleClick={onSendBiometricsCallback}
					/>
				}
			</div>
		</>}
	</div>)
};
