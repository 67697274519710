import { useEffect } from "react";
import { useAppSelector } from "../redux-hooks";
import { selectAccessToken } from "../../store/slices/auth-slice";
import { getDecodedToken } from "../../lib/utils";
import { useResetAuth } from "../use-reset-auth";

const FEATURE_ON = false;

export const useAccessTokenCheck = () => {
	const accessToken = useAppSelector(selectAccessToken);

	const resetAuth = useResetAuth();

	useEffect(() => {
		if (!FEATURE_ON) {
			return;
		}

		let interval: NodeJS.Timeout;
		if (accessToken) {
			interval = setInterval(() => {
				const decodedToken = getDecodedToken(accessToken)
				const now = Date.now() / 1000;
				if (decodedToken?.exp && (decodedToken?.exp - now) < 0) {
					clearInterval(interval);
					resetAuth();
				}
			}, 10_000);
		}
		return () => {
			clearInterval(interval);
		};
	}, [accessToken, resetAuth]);
}
