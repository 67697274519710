import type { ReactNode, WheelEvent } from "react";
import {
	type Control,
	type FieldValues,
	type PathValue,
	type Path,
	Controller,
} from "react-hook-form";
import { type InputAttributes, NumericFormat } from "react-number-format";

import ErrorMessage from "../error-message/ErrorMessage";
import HelperMessage from "../helper-message/HelperMessage";

type DollarInputProps<TFieldValues extends FieldValues> = {
	name: Path<TFieldValues>;
	control: Control<TFieldValues>;
	type: InputAttributes["inputMode"];
	label?: string;
	placeholder?: string;
	iconPrefix?: ReactNode;
	iconSuffix?: ReactNode;
	error?: string;
	value?: PathValue<TFieldValues, Path<TFieldValues>> | undefined;
	onValueChange?: (value?: number) => void;
	helperMessage?: string;
	disabled?: boolean;
	min?: number;
	size?: "small" | "medium" | "large";
	layout?: "tertiary" | "secondary";
};

const DollarInput = <TFieldValues extends FieldValues>({
	name,
	control,
	type,
	label,
	placeholder,
	iconPrefix,
	iconSuffix,
	error,
	value,
	onValueChange,
	helperMessage,
	disabled,
	min,
	size,
	layout = "tertiary",
}: DollarInputProps<TFieldValues>) => {
	const inputId = `input-${label?.replace(/\s+/g, "")}-${placeholder?.replace(/\s+/g, "")}`;
	return (
		<div
			className={`flex flex-col ${layout === "tertiary" ? "w-full gap-2" : "items-start md:flex-row md:items-center gap-4"}`}
			aria-labelledby={`${label}`}
		>
			{!!label && (
				<label
					htmlFor={inputId}
					className={`text-primary ${size === "small" ? "text-base" : "text-[21.33px]"} font-normal ${layout === "tertiary" ? "mb-2" : "w-full flex-shrink-0 md:w-1/2"}`}
				>
					{label}
				</label>
			)}
			<div
				className={`${layout === "secondary" && "flex flex-col flex-grow gap-2 w-full"}`}
			>
				<div
					className={`${error ? "border-b-error" : disabled ? "border-b-off-black-400 text-off-black-400" : "border-b-off-black-900"} flex gap-2 border-b ${layout === "tertiary" && "items-center pb-2"}`}
				>
					{iconPrefix}
					<Controller
						render={({ field }) => {
							return (
								<NumericFormat
									inputMode={type}
									getInputRef={field.ref}
									defaultValue={field?.value as number}
									className={`${error ? "placeholder-error" : field.disabled ? "placeholder-off-black-400 text-red" : "placeholder-off-black-600"} bg-transparent w-full focus-visible:outline-none`}
									placeholder={placeholder}
									onKeyDown={(event) => {
										if (event.key === "Enter") {
											event.preventDefault();
										}
									}}
									onWheel={(event: WheelEvent<HTMLInputElement>) => {
										event.currentTarget.blur();
									}}
									thousandSeparator=","
									onValueChange={(v) => {
										onValueChange?.(
											v.value === undefined || v.value === ""
												? undefined
												: Number.parseFloat(v.value)
										);
									}}
									disabled={field.disabled}
									min={min}
									allowNegative={min === undefined || min < 0}
								/>
							);
						}}
						name={name}
						control={control}
						defaultValue={value}
						disabled={disabled}
					/>
					{iconSuffix}
				</div>
				{layout === "secondary" && (
					<>
						{!!helperMessage && <HelperMessage message={helperMessage} />}
						{!!error && <ErrorMessage message={error} />}
					</>
				)}
			</div>
			{layout !== "secondary" && (
				<>
					{!!helperMessage && <HelperMessage message={helperMessage} />}
					{!!error && <ErrorMessage message={error} />}
				</>
			)}
		</div>
	);
};

export default DollarInput;
