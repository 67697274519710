 
import type {
	FieldValues,
	Path,
	UseFormRegister,
	UseFormSetValue,
} from "react-hook-form";
import ErrorMessage from "../error-message/ErrorMessage";
import { type ReactNode, useState } from "react";

type GenericInputType<TFieldValues extends FieldValues> = {
	name: keyof TFieldValues;
	label?: string;
	placeholder?: string;
	register: UseFormRegister<TFieldValues>;
	setValue?: UseFormSetValue<TFieldValues>;
	required?: boolean;
	error?: string;
	value?: string;
	iconPrefix?: ReactNode;
};
const GenericPasswordInput = <TFieldValues extends FieldValues>(
	props: GenericInputType<TFieldValues>
) => {
	const [showPassword, setShowPassword] = useState(false);
	const inputId = `input-${props?.label?.replace(/\s+/g, "")}-${props?.placeholder?.replace(/\s+/g, "")}`;

	return (
		<div
			className="w-full flex flex-col gap-2"
			aria-labelledby={`${props.label}`}
		>
			{!!props.label && (
				<label
					htmlFor={inputId}
					className="text-primary text-[21.33px] font-normal mb-2"
				>
					{props.label}
				</label>
			)}
			<div
				className={`${props.error ? "border-b-error" : "border-b-off-black-900"} flex items-center gap-2 border-b pb-2`}
			>
				{props.iconPrefix}
				<input
					{...props.register(props.name as Path<TFieldValues>, {
						setValueAs: (value: string): string => value,
					})}
					onKeyDown={(event) => {
						if (event.key === "Enter") {
							event.preventDefault();
						}
					}}
					id={inputId}
					type={showPassword ? "text" : "password"}
					defaultValue={props.value}
					placeholder={props.placeholder}
					required={props.required}
					autoComplete={String(props.name)}
					className={`${props.error ? "placeholder-error" : "placeholder-off-black-600"} bg-transparent w-full focus-visible:outline-none`}
					onWheel={(event) => {
						event.currentTarget.blur();
					}}
				/>
				<i
					className={`cursor-pointer ${showPassword ? "icon-eye-crossed" : "icon-eye"}`}
					onClick={() => {
						setShowPassword(!showPassword);
					}}
				/>
			</div>
			{!!props.error && <ErrorMessage message={props.error} />}
		</div>
	);
};

export default GenericPasswordInput;

export const PasswordRequirements = (
	<div className="flex flex-col gap-2">
		Please ensure your password meets the following requirements
		<br />
		<p className="font-normal text-sm">
			<i className="icon-asterisk"></i>Minimum 8 characters
		</p>
		<p className="font-normal text-sm">
			<i className="icon-asterisk"></i>Must contain numbers and letters
		</p>
		<p className="font-normal text-sm">
			<i className="icon-asterisk"></i>Upper and Lower Case
		</p>
		<p className="font-normal text-sm">
			<i className="icon-asterisk"></i>At least one special character
		</p>
		<p className="font-normal text-sm">
			<i className="icon-asterisk"></i>Must not include your name
		</p>
		<p className="font-normal text-sm">
			<i className="icon-asterisk"></i>Must not include password
		</p>
		<p className="font-normal text-sm">
			<i className="icon-asterisk"></i>Must not be too simple
		</p>
	</div>
);
