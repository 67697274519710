import { type RefObject, useEffect } from "react";

/**
 * Custom hook to scroll to the provided ref with a specified delay.
 *
 * @param ref The ref of the element to scroll to.
 * @param delay The delay before scrolling in milliseconds.
 * @param offsetMultiplier The multiplier for the header height to calculate the offset.
 */
const useScrollToRefWithDelay = (
	ref: RefObject<HTMLElement>,
	delay: number = 300,
	offsetMultiplier: number = 2
) => {
	useEffect(() => {
		const timer = setTimeout(() => {
			if (ref.current) {
				const elementPosition = ref.current.getBoundingClientRect().top;
				const headerHeight =
					document.querySelector("header")?.offsetHeight ?? 100;
				const offsetPosition =
					elementPosition +
					window.pageYOffset -
					headerHeight * offsetMultiplier;

				window.scrollTo({
					top: offsetPosition,
					behavior: "smooth",
				});
			}
		}, delay);

		return () => {
			clearTimeout(timer);
		};
	}, [ref, delay, offsetMultiplier]);
};

export default useScrollToRefWithDelay;
