import { Listbox } from "@headlessui/react";
import { Fragment, type ReactNode } from "react";
import type { FieldValues, Path, UseFormRegister } from "react-hook-form";
import type { OptionsType } from "../../../common/types";
import ErrorMessage from "../error-message/ErrorMessage";

interface SelectInputProps<TFieldValues extends FieldValues> {
	label?: string;
	name: keyof TFieldValues;
	value: OptionsType;
	options: Array<OptionsType>;
	iconPrefix?: ReactNode;
	iconSuffix?: ReactNode;
	register: UseFormRegister<TFieldValues>;
	error?: string;
	onChangeHandler?: (event: OptionsType) => void;
}

const SelectInput = <TFieldValues extends FieldValues>({
	label,
	name,
	value,
	options,
	iconPrefix,
	iconSuffix,
	register,
	error,
	onChangeHandler,
}: SelectInputProps<TFieldValues>) => {
	const inputId = `input-${label?.replace(/\s+/g, "")}-`;
	return (
		<div className="w-full flex flex-col gap-2" aria-labelledby={`${label}`}>
			{label && (
				<label
					htmlFor={inputId}
					className="text-primary text-[21.33px] font-normal mb-2"
				>
					{label}
				</label>
			)}
			<div
				className={`${
					error ? "border-b-error" : "border-b-off-black-900"
				} flex flex-col gap-2 border-b pb-2 relative`}
			>
				{iconPrefix}
				<Listbox
					value={value}
					by="id"
					onChange={async (value) => {
						await register(name as Path<TFieldValues>).onChange({
							target: { value, name: name as string },
						});
						onChangeHandler && onChangeHandler(value);
					}}
				>
					<Listbox.Button className="flex justify-between text-sm text-off-black-900">
						{value.label}
						<i className="icon-chevron-down text-off-black-900"></i>
					</Listbox.Button>
					<Listbox.Options className="bg-white border border-off-black-900 absolute top-8 left-0 right-0 z-10 max-h-80 overflow-y-auto">
						{options.map((item: OptionsType) => (
							<Listbox.Option key={item.id} value={item} as={Fragment}>
								{({ active }) => (
									<li
										className={`${
											active ? "bg-primary text-white" : ""
										} text-sm leading-6 p-4 cursor-pointer transition-all`}
									>
										{item.label}
									</li>
								)}
							</Listbox.Option>
						))}
					</Listbox.Options>
				</Listbox>
				{iconSuffix}
			</div>
			{!!error && <ErrorMessage message={error} />}
		</div>
	);
};

export default SelectInput;
