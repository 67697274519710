import { useCallback, useEffect } from "react";
import { useAppSelector } from "../redux-hooks";
import { selectAccessToken } from "../../store/slices/auth-slice";
import { FileStorageClient } from "../../services/file-storage";
import type { CreateFilesRequest } from "../../services/file-storage.types";

const fileStorageClient = new FileStorageClient();

export const useFileStorage = () => {
  const accessToken = useAppSelector(selectAccessToken);

  useEffect(() => {
    fileStorageClient.setAccessToken(accessToken);
  }, [accessToken]);

  const createFiles = useCallback(async (payload: CreateFilesRequest[]) => {
    return fileStorageClient.createFiles(payload);
  }, [])

  const upload = useCallback(async (url: string, file: File) => {
    return fileStorageClient.upload(url, file);
  }, [])

  return {
    createFiles,
    upload,
  }
};
