import BottomBar from "../../components/layout/footer/bottom-bar/BottomBar";
import Copyrights from "../../components/layout/footer/copyrights/Copyrights";
import Sitemap from "../../components/layout/footer/sitemap/Sitemap";
import ContactEmail from "../../components/ui/contact-email/ContactEmail";
import ContactNumber from "../../components/ui/contact-number/ContactNumber";
import SiteBrand from "../../components/ui/site-brand/SiteBrand";
import { useAppSelector } from "../../hooks/redux-hooks";
import type { FunctionComponent } from "../types";
import { selectHomeLoanSpecialist } from "../../store/slices/home-loan-specialist-slice";
import { selectIsLoggedInWithApplication } from "../../store/slices/auth-slice";
import { selectApplicationStep } from "../../store/slices/origination-slice";
import { Step } from "../../services/apis/create-application.schema";

const Footer = (): FunctionComponent => {
	const isLoggedIn = useAppSelector(selectIsLoggedInWithApplication);
	const homeLoanSpecialist = useAppSelector(selectHomeLoanSpecialist);
	const applicationStep = useAppSelector(selectApplicationStep);
	const meetingLink = applicationStep === Step.Submitted ? "https://calendly.com/d/ck3g-rn7-zs5/submitted-application" : "https://calendly.com/d/cpfr-q3m-mr5/pre-submission-application";
	
	return (
		<footer>
			{isLoggedIn && homeLoanSpecialist?.name && (
				<BottomBar
					name={homeLoanSpecialist.name}
					phone={homeLoanSpecialist.phone}
					email={homeLoanSpecialist.email}
					meetingLink={meetingLink}
				/>
			)}
			<section className="bg-white w-full py-4 lg:pt-6 lg:pb-8">
				<div className="container mx-auto px-4 flex flex-col">
					<section className="flex flex-col md:flex-row gap-4 md:justify-between md:items-center md:gap-0">
						<SiteBrand className="w-36" />
						<div className="flex flex-wrap items-center gap-4 lg:gap-12">
							<ContactNumber size="normal" showOnMobile={true} />
							<ContactEmail size="normal" />
						</div>
					</section>
					<Sitemap />
					<Copyrights />
				</div>
			</section>
		</footer>
	);
};

export default Footer;
