import type { FunctionComponent } from "../../../../common/types";
import HintCard from "../../../ui/hint-card/HintCard";

const WelcomeBackAside = (): FunctionComponent => {
	return (
		<section
			className="flex items-center lg:sticky lg:top-36 lg:h-full"
			aria-labelledby="Welcome Aside section"
		>
			<HintCard
				icon={<i className="icon-asterisk" />}
				text="To change details you’ve already submitted, contact your Home Loan Specialist using the
				details below."
			/>
		</section>
	);
};

export default WelcomeBackAside;
