import type { ReactNode, FC } from "react";

type WelcomeLoanItemType = {
	label: string | ReactNode;
	value: string | ReactNode;
};

const WelcomeLoanItem: FC<WelcomeLoanItemType> = ({ label, value }) => {
	return (
		<div>
			<h6 className="text-off-black-400 text-xs leading-3">{label}</h6>
			<p className="text-white text-sm leading-6 pt-1">{value}</p>
		</div>
	);
};

export default WelcomeLoanItem;
