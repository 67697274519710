import { useCallback } from "react";
import { useOTPResend } from "../services/api";
import { useAppDispatch, useAppSelector } from "./redux-hooks";
import { selectVerificationToken, setVerificationToken } from "../store/slices/auth-slice";
import { setToast } from "../store/slices/toast-slice";
import { captureException } from "../services/sentry";
import { track } from "../services/analytics";

export const useAuth0Token = () => {
	const dispatch = useAppDispatch();
	const verificationToken = useAppSelector(selectVerificationToken);

	const [otpResend, { isLoading }] = useOTPResend();

	const resendToken = useCallback(async () => {
		let requestFailed = false;
		if (verificationToken) {
			try {
				const response = await otpResend({
					verificationToken,
				}).unwrap();

				dispatch(setVerificationToken(response.data.verificationToken));
				track("Resend OTP Email", {
					status: "success",
				});
			} catch (error) {
				requestFailed = true;
				captureException(error as Error);
				track("Resend OTP Email", {
					status: "error",
					error: error as Error,
				});
			}
		} else {
			requestFailed = true;
		}
		if (requestFailed) {
			dispatch(
				setToast({
					open: true,
					type: "error",
					title: "Error",
					description: "An error occurred while sending you a token.",
				})
			);
			
			track("Resend OTP Email", {
				status: "error",
				error: 'No verification token found'
			});
		}
	}, [dispatch, otpResend, verificationToken]);

	const resetToken = useCallback(() => {
		// this will redirect the user to the RequestYourPin page
		track("Clicked Change Email");
		dispatch(setVerificationToken(undefined));
	}, [dispatch]);

	return {
		resendToken,
		resetToken,
		isLoading,
	}
};