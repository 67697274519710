import { useAppDispatch } from "./redux-hooks";
import { useCallback } from "react";
import { resetApplication } from "../store/slices/application-slice";
import { setNotInRemit, signOut } from "../store/slices/auth-slice";
import { resetForm } from "../store/slices/form-slice";
import { resetJointStepper } from "../store/slices/joint-stepper-slice";
import { resetOrigination } from "../store/slices/origination-slice";
import { resetStepper } from "../store/slices/stepper-slice";
import { resetSentryContext } from "../services/sentry";
import { setLogoutModal } from "../store/slices/ui-slice";

export const useResetSession = () => {
    const dispatch = useAppDispatch();

    return useCallback(() => {
		dispatch(signOut());
		dispatch(resetForm());
		dispatch(resetApplication());
		dispatch(resetOrigination());
		dispatch(setNotInRemit(false));
		dispatch(resetStepper());
		dispatch(resetJointStepper());
		resetSentryContext();
		dispatch(setLogoutModal(false));
    }, [dispatch])
}