import { Link } from "react-router-dom";
import type { FunctionComponent } from "../../../../common/types";
import { sitemapLinks } from "../../../../constants";

const Sitemap = (): FunctionComponent => {
	return (
		<nav
			className="flex flex-wrap items-center gap-4 lg:gap-0 py-6 lg:divide-x divide-off-black-900"
			aria-labelledby="Footer Sitemap section"
		>
			<Link
				to="/"
				className="lg:pe-3 text-sm font-normal underline leading-6 lg:leading-4 transition-all hover:text-off-black-600"
			>
				Home
			</Link>
			{sitemapLinks.map((item) => {
				return (
					<a
						key={item.id}
						href={item.route}
						target="_blank"
						rel="noopener noreferrer"
						className="lg:px-3 text-sm font-normal underline leading-6 lg:leading-4 transition-all hover:text-off-black-600"
					>
						{item.displayText}
					</a>
				);
			})}
		</nav>
	);
};

export default Sitemap;
