import { type FormEvent, useEffect } from "react";
import type {
	FunctionComponent,
	OtherIncome,
} from "../../../../../common/types";
import {
	useAppDispatch,
	useAppSelector,
} from "../../../../../hooks/redux-hooks";
import Button from "../../../../ui/button/Button";
import {
	setNetProfitAfterTax,
	setNoNetProfitAfterTax,
	setOtherIncomeAllowed,
	setOtherIncomes,
	setPreviousFinanceActiveForm,
} from "../../../../../store/slices/form-slice";
import { zodResolver } from "@hookform/resolvers/zod";

import { type Control, useForm } from "react-hook-form";
import { OtherIncomeAllowedOptions } from "../../../../utils/BootList";
import RadioGroup from "../../../radio-group/RadioGroup";
import { BusinessIncomeSchema } from "./BusinessIncomeSchema";
import OtherIncomesForm from "../../../other-incomes/OtherIncomes";
import { navigateToJointStep } from "../../../../../store/slices/joint-stepper-slice";
import { navigateToStep } from "../../../../../store/slices/stepper-slice";
import { selectUpdateIncomePayload } from "../../../../../store/selectors/update-income-payload";
import { useUpdateIncomeEmployment } from "../../../../../services/apis/origination/api";
import {
	type IncomeDetailsType,
	IncomeType,
	FrequencyType,
} from "../../../../../services/apis/update-income.schema";
import {
	ApplicationType,
	SubStep,
} from "../../../../../services/apis/create-application.schema";
import { setFormLoading } from "../../../../../store/slices/loader-slice";
import { setToast } from "../../../../../store/slices/toast-slice";
import FormLayout from "../../../../ui/form/Form";

import CheckboxInput from "../../../checkbox-input/CheckboxInput";
import DollarInput from "../../../dollar-input/DollarInput";
import useTrackPageViewOnMount from "../../../../../hooks/use-track-on-mount";
import { captureException } from "../../../../../services/sentry";

export type YourBusinessIncomeFormType = {
	netProfitAfterTax: number;
	otherIncomeAllowed: string;
	otherIncomes: Array<OtherIncome>;
	noNetProfitAfterTax: boolean;
};
const setIncomeDetails = (data: Partial<YourBusinessIncomeFormType>) => {
	const incomeDetails: Array<IncomeDetailsType> = [];
	if (data.netProfitAfterTax && data.netProfitAfterTax > 0) {
		const netProfitAfterTax: IncomeDetailsType = {
			incomeType: IncomeType.SelfEmployedBusinessOwner,
			currentIncomeAmount: data.netProfitAfterTax,
			priorIncomeAmount: 0,
			receivedForLastSixMonths: false,
			frequency: FrequencyType.Annually,
		};
		incomeDetails.push({ ...netProfitAfterTax });
	}
	if (
		data.otherIncomeAllowed === "true" &&
		data.otherIncomes &&
		data.otherIncomes.length > 0
	) {
		for (const income of data.otherIncomes) {
			const otherIncome: IncomeDetailsType = {
				incomeType: income.incomeType.value as IncomeType, // Use the IncomeType enum here
				currentIncomeAmount: income.estimatedAmount!,
				priorIncomeAmount: 0,
				receivedForLastSixMonths: false,
				frequency: income.frequency.value as FrequencyType,
			};
			incomeDetails.push({ ...otherIncome });
		}
	}
	return incomeDetails;
};
const YourBusinessIncome = ({
	isJoint,
}: {
	isJoint?: boolean;
}): FunctionComponent => {
	const dispatch = useAppDispatch();
	const formState = useAppSelector((state) => state.form);
	const [updateIncome, { isLoading }] = useUpdateIncomeEmployment();
	const updateIncomePayload = useAppSelector(selectUpdateIncomePayload);
	const YourBusinessIncomeDefaultValues: Partial<YourBusinessIncomeFormType> = {
		netProfitAfterTax: formState.netProfitAfterTax,
		otherIncomeAllowed: formState.otherIncomeAllowed,
		noNetProfitAfterTax: formState.noNetProfitAfterTax,
	};
	const {
		register,
		control,
		handleSubmit,
		watch,
		formState: { errors },
		setValue,
	} = useForm({
		resolver: zodResolver(BusinessIncomeSchema),
		defaultValues: { ...YourBusinessIncomeDefaultValues },
	});

	useTrackPageViewOnMount({
		page: "Your Finances",
		subPage: "Your Income",
	});

	const backToPreviousState = (): void => {
		if (isJoint) {
			dispatch(
				navigateToJointStep({
					step: "financeStep",
					subStep: SubStep.YourEmployment,
				})
			);
		} else {
			dispatch(
				navigateToStep({
					step: "financeStep",
					subStep: SubStep.YourEmployment,
				})
			);
		}
	};
	const saveData = async (data: Partial<YourBusinessIncomeFormType>) => {
		dispatch(setNetProfitAfterTax(data.netProfitAfterTax));
		dispatch(setNoNetProfitAfterTax(data.noNetProfitAfterTax!));
		dispatch(setOtherIncomeAllowed(data.otherIncomeAllowed!));
		dispatch(setOtherIncomes(data.otherIncomes!));
		dispatch(setPreviousFinanceActiveForm(SubStep.YourBusinessIncome));
		if (formState.applicationType === ApplicationType.Joint && !isJoint) {
			dispatch(
				navigateToStep({
					step: "financeStep",
					subStep: SubStep.YourJointBorrowerIncome,
				})
			);
		} else {
			try {
				await updateIncome({
					...updateIncomePayload,
					hasAnyOtherIncome: data.otherIncomeAllowed === "true",
					receivedCommissionOrBonus: false,
					receivedOverTimeOrAllowance: false,
					incomeDetails: setIncomeDetails(data),
					applicationSubStep: isJoint
						? SubStep.YourAssets
						: SubStep.YourHouseholdExpenses,
				}).unwrap();

				if (isJoint) {
					dispatch(
						navigateToJointStep({
							step: "financeStep",
							subStep: SubStep.YourAssets,
						})
					);
				} else {
					dispatch(
						navigateToStep({
							step: "financeStep",
							subStep: SubStep.YourHouseholdExpenses,
						})
					);
				}
			} catch (error) {
				captureException(new Error("Error saving income"), {data: {error}});
				dispatch(
					setToast({
						open: true,
						type: "error",
						title: "Error",
						description: "An error occurred while saving your income.",
					})
				);
			}
		}
	};

	useEffect(() => {
		dispatch(setFormLoading(isLoading));
		return () => {
			dispatch(setFormLoading(false));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);
	register("otherIncomeAllowed", {
		onChange: (event: FormEvent<HTMLInputElement>) => {
			if (event.currentTarget.value === "false") {
				setValue("otherIncomes", undefined);
			}
		},
	});
	register("noNetProfitAfterTax", {
		onChange: (event: FormEvent<HTMLInputElement>) => {
			if (event.currentTarget.checked) {
				setValue("netProfitAfterTax", 0);
			}
		},
	});
	const header = (
		<h1
			className="text-primary text-[37.9px] font-normal"
			aria-labelledby="Your Income"
		>
			Your Income
		</h1>
	);
	const content = (
		<div className="flex flex-col gap-9">
			<div className="w-full flex flex-col gap-2">
				<label
					htmlFor={"netProfitAfterTax"}
					className="text-primary text-[21.33px] font-normal mb-2"
				>
					What was your net profit after tax last financial year?
				</label>
				{watch("noNetProfitAfterTax") !== true && (
					<DollarInput
						name="netProfitAfterTax"
						control={control}
						type="numeric"
						value={formState.netProfitAfterTax}
						error={errors.netProfitAfterTax && errors.netProfitAfterTax.message}
						label=""
						placeholder="Net profit"
						iconPrefix={<i className="icon-dollar" />}
						onValueChange={(value) => {
							setValue("netProfitAfterTax", value);
						}}
						disabled={watch("noNetProfitAfterTax") === true}
					/>
				)}
			</div>

			<div className="-mt-6">
				<CheckboxInput
					label="I made a loss"
					name="noNetProfitAfterTax"
					checked={false}
					register={register}
					size="small"
				/>
			</div>
			<RadioGroup
				name="otherIncomeAllowed"
				radioOptions={OtherIncomeAllowedOptions}
				legend="Do you receive any other income (excluding rental income)?"
				register={register}
				error={errors.otherIncomeAllowed && errors.otherIncomeAllowed.message}
			/>
			{watch("otherIncomeAllowed") === "true" && (
				<OtherIncomesForm
					initialOtherIncomes={[
						{
							estimatedAmount: undefined,
							frequency: {
								id: 0,
								value: "",
								label: "Select frequency",
							},
							incomeType: {
								id: 0,
								value: "",
								label: "Select income type",
							},
						},
					]}
					onChange={(otherIncomes) => {
						setValue("otherIncomes", otherIncomes);
					}}
					control={
						control as Control<Pick<YourBusinessIncomeFormType, "otherIncomes">>
					}
					errors={errors.otherIncomes}
				/>
			)}
		</div>
	);
	const footer = (
		<div className="flex items-center justify-between gap-4">
			<Button
				text="Back"
				variant="accent"
				iconPrefix={<i className="icon-arrow rotate-180" />}
				handleClick={backToPreviousState}
				isDisabled={isLoading}
			/>

			<Button
				text="Next"
				variant="primary"
				iconSuffix={<i className="icon-arrow" />}
				isDisabled={isLoading}
			/>
		</div>
	);
	return (
		<FormLayout
			header={header}
			content={content}
			footer={footer}
			onSubmit={handleSubmit(saveData)}
		/>
	);
};

export default YourBusinessIncome;
