import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import type { z } from "zod";
import { useLogin } from "../../../hooks/use-login";
import Button from "../../ui/button/Button";
import { LoginSchema } from "../../utils/ValidationSchema";
import GenericInput from "../generic-input/GenericInput";
import { useAppDispatch } from "../../../hooks/redux-hooks";
import { setFormLoading } from "../../../store/slices/loader-slice";
import GenericPasswordInput from "../generic-password-input/GenericPasswordInput";

type LoginSchemaType = z.infer<typeof LoginSchema>;

const LoginForm = () => {
	const dispatch = useAppDispatch();
	const {
		register,
		handleSubmit,
		formState: { errors },
		setFocus,
		watch,
	} = useForm<LoginSchemaType>({
		resolver: zodResolver(LoginSchema),
	});

	const { loginHandler, isLoading, isError } = useLogin();

	useEffect(() => {
		if (isError) {
			toast.error("Please check your email and password.");
		}
	}, [isError]);

	useEffect(() => {
		dispatch(setFormLoading(isLoading));
		return () => {
			dispatch(setFormLoading(false));
		};
	}, [isLoading, dispatch]);

	const email = watch("email");
	const password = watch("password");

	useEffect(() => {
		const listener = (event: KeyboardEvent) => {
			if (event.code === "Enter" || event.code === "NumpadEnter") {
				event.preventDefault();
				if (email.length > 0 && password.length > 0) {
					void loginHandler({ email, password });
				} else {
					setFocus("password");
				}
			}
		};

		document.addEventListener("keydown", listener);
		return () => {
			document.removeEventListener("keydown", listener);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setFocus, email, password]);

	return (
		<form
			className="flex flex-col gap-8"
			onSubmit={handleSubmit(loginHandler)}
		>
			<GenericInput
				name="email"
				register={register}
				type="email"
				placeholder="Email"
				error={errors?.["email"] && "Email is required"}
				iconPrefix={<i className="icon-email" />}
			/>
			<GenericPasswordInput
				name="password"
				register={register}
				placeholder="Password"
				error={errors?.["password"] && "Password is required"}
				iconPrefix={<i className="icon-lock" />}
			/>
			<div className="lg:mt-10">
				<Button
					type="submit"
					text="Log in"
					variant="primary"
					size="full"
					iconSuffix={<i className="icon-arrow" />}
				/>
				<Link to="/forgot-password" className="font-medium flex px-6 mt-6">
					Forgot Password?
				</Link>
			</div>
		</form>
	);
};

export default LoginForm;
