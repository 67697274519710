import type { FC } from "react";
import HintCard from "../hint-card/HintCard";
import { DEFAULT_TOKEN_TYPE, OauthTokenType } from "../../../services/apis/auth.schema";

export const CreateAuth0AccountHint: FC = () => (<HintCard
	icon={<i className="icon-two-users" />}
	text={
		<>
			<span className="font-medium">
				Clicking "Next" will send a confirmation code to your
				{" "}{DEFAULT_TOKEN_TYPE === OauthTokenType.Email ? "email" : "mobile"}.
			</span>
			<span>

			It might take a few moments before you receive the code. Ensure you check your spam folder if you don't see the email.
			</span>

		</>
	}
/>);
