import { SubStep } from "../services/apis/create-application.schema";

const orderedProgress = [
	SubStep.LetsStart,
	SubStep.BeforeWeGetStarted,
	SubStep.Empty,
	SubStep.BuyingWorkflow,
	SubStep.CurrentHomeLoan,
	SubStep.NewHomeLoan,
	SubStep.TellUsAboutYou,
	SubStep.YouAndYourHousehold,
	SubStep.CreateYourAccount,
	SubStep.FineDetailsAboutYou,
	SubStep.TwoYearsAddressHistory,
	SubStep.JointApplicantInvite,
	SubStep.YourEmployment,
	SubStep.YourEmployeeIncome,
	SubStep.YourBusinessIncome,
	SubStep.YourHouseholdExpenses,
	SubStep.YourAssets,
	SubStep.YourLiabilities,
	SubStep.YourCircumstances,
	SubStep.YourHelp,
	SubStep.Completed,
	SubStep.Submitted,
];

const orderedProgressJoint = [
	SubStep.Welcome,
	SubStep.BeforeWeGetStarted,
	SubStep.Empty,
	SubStep.JointBorrowerAboutYou,
	SubStep.TwoYearsAddressHistory,
	SubStep.YourEmployment,
	SubStep.YourEmployeeIncome,
	SubStep.YourBusinessIncome,
	SubStep.YourAssets,
	SubStep.YourLiabilities,
	SubStep.YourCircumstances,
	SubStep.YourHelp,
	SubStep.Completed,
	SubStep.Submitted,
];

export const getProgress = (subStep?: SubStep, isJoint = false) =>  {
	if (!subStep) {
		return 0;
	}
	if (isJoint) {
		if (!orderedProgressJoint.includes(subStep)) {
			return;
		}
		return Math.round((orderedProgressJoint.indexOf(subStep) + 1) / orderedProgressJoint.length * 100);
	}
	if (!orderedProgress.includes(subStep)) {
		return;
	}
	return Math.round((orderedProgress.indexOf(subStep) + 1) / orderedProgress.length * 100);
}
