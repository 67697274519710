interface PriceCaps {
	[key: string]: number;
}

export const getMaxPurchasePriceCapForState = (
	state: string
): number | undefined => {
	const priceCaps: PriceCaps = {
		NSW: 900_000,
		VIC: 800_000,
		QLD: 700_000,
		WA: 600_000,
		SA: 600_000,
		TAS: 600_000,
	};

	return priceCaps[state];
};
