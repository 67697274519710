import type { ApplicationStep, ApplicationSubStep } from "@sucasa-finance/origination-trpc";
import { useCallback } from "react";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import { selectFormApplicationId } from "../../../../store/slices/application-slice";
import { selectCurrentOriginationUser } from "../../../../store/slices/auth-slice";

export function useMapFixedStepRequest(step: ApplicationStep, subStep: ApplicationSubStep) {
    const applicationId = useAppSelector(selectFormApplicationId);
    const user = useAppSelector(selectCurrentOriginationUser);
    return useCallback(() => ({
        applicationId: Number(applicationId),
        step,
        subStep,
        userId: Number(user?.userId)
    }), [applicationId, user, step, subStep])
}
