import { useEffect } from "react";
import type { FunctionComponent } from "../../../../../common/types";
import {
	useAppDispatch,
	useAppSelector,
} from "../../../../../hooks/redux-hooks";
import Button from "../../../../ui/button/Button";
import {
	setJointBorrowerIncome,
	setPreviousFinanceActiveForm,
} from "../../../../../store/slices/form-slice";
import { zodResolver } from "@hookform/resolvers/zod";

import { useForm } from "react-hook-form";
import {
	type YourJointBorrowerIncomeFormSlice,
	YourJointBorrowerIncomeSchema,
	selectJointBorrowerIncome,
	type YourJointBorrowerIncomeFormType,
} from "./YourJointBorrowerIncomeSchema";
import { navigateToStep } from "../../../../../store/slices/stepper-slice";
import { navigateToJointStep } from "../../../../../store/slices/joint-stepper-slice";

import { SubStep } from "../../../../../services/apis/create-application.schema";
import { setFormLoading } from "../../../../../store/slices/loader-slice";
import { setToast } from "../../../../../store/slices/toast-slice";
import FormLayout from "../../../../ui/form/Form";
import { selectUpdateIncomePayload } from "../../../../../store/selectors/update-income-payload";
import DollarInput from "../../../dollar-input/DollarInput";
import useTrackPageViewOnMount from "../../../../../hooks/use-track-on-mount";
import { useUpdateIncomeEmployment } from "../../../../../services/apis/origination/income-employment";
import { captureException } from "../../../../../services/sentry";

const YourJointBorrowerIncome = ({
	isJoint,
}: {
	isJoint?: boolean;
}): FunctionComponent => {
	const dispatch = useAppDispatch();
	const formState = useAppSelector(selectJointBorrowerIncome);
	const [updateIncome, { isLoading }] = useUpdateIncomeEmployment();
	const updateIncomePayload = useAppSelector(selectUpdateIncomePayload);
	const {
		control,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm({
		resolver: zodResolver(YourJointBorrowerIncomeSchema),
		defaultValues: formState,
	});

	useTrackPageViewOnMount({
		page: "Your Finances",
		subPage: "Joint Borrower Income",
	});

	const backToPreviousState = (): void => {
		if (isJoint) {
			dispatch(
				navigateToJointStep({
					step: "financeStep",
					subStep: formState.previousFinancesActiveForm as SubStep,
				})
			);
		} else {
			console.log(
				"formState.previousFinancesActiveForm",
				formState.previousFinancesActiveForm
			);
			dispatch(
				navigateToStep({
					step: "financeStep",
					subStep: formState.previousFinancesActiveForm as SubStep,
				})
			);
		}
	};
	const saveData = async (rawData: YourJointBorrowerIncomeFormSlice) => {
		// Assuming validation ensures all values are defined, we can assert the type here - this is a hack because the zod inference with the form schema is not working
		const data: YourJointBorrowerIncomeFormType =
			rawData as YourJointBorrowerIncomeFormType;
		dispatch(setPreviousFinanceActiveForm(SubStep.YourJointBorrowerIncome));
		dispatch(setJointBorrowerIncome(data.jointBorrowerIncome));
		try {
			await updateIncome({
				...updateIncomePayload,
				jointBorrowerIncome: data.jointBorrowerIncome,
				applicationSubStep: isJoint
					? SubStep.YourAssets
					: SubStep.YourHouseholdExpenses,
			}).unwrap();

			if (isJoint) {
				dispatch(
					navigateToJointStep({
						step: "financeStep",
						subStep: SubStep.YourAssets,
					})
				);
			} else {
				dispatch(
					navigateToStep({
						step: "financeStep",
						subStep: SubStep.YourHouseholdExpenses,
					})
				);
			}
		} catch (error) {
			captureException(new Error("Error saving income"), {data: {error}});
			dispatch(
				setToast({
					open: true,
					type: "error",
					title: "Error",
					description: "An error occurred while saving your income.",
				})
			);
		}
	};

	useEffect(() => {
		dispatch(setFormLoading(isLoading));
		return () => {
			dispatch(setFormLoading(false));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);
	const header = (
		<h1
			className="text-primary text-[37.9px] font-normal"
			aria-labelledby="Your Help"
		>
			{`${formState.jointBorrowerFirstName}'s Income`}
		</h1>
	);
	const content = (
		<div className="flex flex-col gap-9">
			<DollarInput
				name="jointBorrowerIncome"
				control={control}
				type="numeric"
				value={formState.jointBorrowerIncome}
				error={errors.jointBorrowerIncome && errors.jointBorrowerIncome.message}
				label={`What is ${formState.jointBorrowerFirstName}'s annual base salary, before tax, excluding super? (Excluding overtime, allowances, commissions and bonus)`}
				placeholder="Salary amount"
				iconPrefix={<i className="icon-dollar" />}
				onValueChange={(value) => {
					setValue("jointBorrowerIncome", value);
				}}
			/>
		</div>
	);
	const footer = (
		<footer className="flex items-center justify-between gap-4">
			<Button
				text="Back"
				variant="accent"
				type="button"
				iconPrefix={<i className="icon-arrow rotate-180" />}
				handleClick={backToPreviousState}
				isDisabled={isLoading}
			/>

			<Button
				text="Next"
				variant="primary"
				iconSuffix={<i className="icon-arrow" />}
				isDisabled={isLoading}
			/>
		</footer>
	);
	return (
		<>
			<FormLayout
				header={header}
				content={content}
				footer={footer}
				onSubmit={handleSubmit(saveData)}
			/>
		</>
	);
};

export default YourJointBorrowerIncome;
