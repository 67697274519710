import Button from "../../../../../ui/button/Button";
import type {
	FieldErrors,
	UseFormClearErrors,
	UseFormSetError,
} from "react-hook-form";
import AddressSearch from "../../../../address-search/AddressSearch";
import { ReasonOptions } from "../../../../../utils/BootList";
import SelectInputSecondary from "../../../../select-input-secondary/SelectInputSecondary";
import type {
	AddressHistoryFormSlice,
	TwoYearsAddressHistoryFormSlice,
} from "../TwoYearsAddressHistorySchema";
import { DatePickerInput } from "../../../../date-picker-input/DatePickerInput";
import type { AddressParts } from "../../../../../../services/apis/create-application.schema";

type AddressHistoryErrorType = FieldErrors<TwoYearsAddressHistoryFormSlice>;

const AddressHistoryDisplay = ({
	index,
	addressHistory,
	onChangeHandler,
	removeHandler,
	errors,
	setError,
	clearErrors,
}: {
	index: number;
	addressHistory: AddressHistoryFormSlice;
	onChangeHandler: (value: AddressHistoryFormSlice, index: number) => void;
	removeHandler: (value: number) => void;
	errors?: AddressHistoryErrorType;
	setError: UseFormSetError<TwoYearsAddressHistoryFormSlice>;
	clearErrors: UseFormClearErrors<TwoYearsAddressHistoryFormSlice>;
}) => {
	const options = {
		types: ["address"],
		...(index === 0
			? { componentRestrictions: { country: 'au' } }
			: {
				componentRestrictions: {},
					bounds: {
						north: -9.2,
						south: -43.7,
						west: 112.9,
						east: 153.6
					},
			  }),
	};
	return (
		<div className="flex flex-col gap-9">
			<AddressSearch
				id={`addressHistory-${index}`}
				// @ts-expect-error overwriting country on purpose
				options={options}
				name="livingAddress"
				onChange={({ place, addressParts, isManual }) => {
					clearErrors(`addressHistory.${index}.livingAddress`);
					const hasRequiredParts =
						addressParts?.streetNumber && addressParts?.streetName;
					if (!isManual && !hasRequiredParts) {
						setError(`addressHistory.${index}.livingAddress`, {
							type: "manual",
							message:
								"Please ensure you select a valid address that includes a street number.",
						});
						// Optionally, handle the error state here, e.g., show an error message
						return; // Stop further execution to ensure we don't update with incomplete addressParts
					}
					const updateAddressHistory = {
						...addressHistory,
						livingAddress: place!,
						livingAddressParts: addressParts!,
					};
					onChangeHandler(updateAddressHistory, index);
				}}
				label={index === 0 ? "Where do you live?" : "Previous Address"}
				value={addressHistory.livingAddress}
				error={
					errors?.addressHistory &&
					errors?.addressHistory[index]?.livingAddress &&
					errors?.addressHistory[index]?.livingAddress?.message
				}
				isSuburb={false}
				manualAddressParts={
					addressHistory.livingAddressParts || ({} as AddressParts)
				}
			/>
			{index === 0 && (
				<SelectInputSecondary
					value={
						addressHistory.currentLivingSituation || {
							id: 0,
							value: "",
							label: "Select an option",
						}
					}
					label="What's your living situation?"
					options={ReasonOptions}
					onChangeHandler={(value) => {
						const updateAddressHistory = {
							...addressHistory,
						};
						updateAddressHistory.currentLivingSituation = value;
						onChangeHandler(updateAddressHistory, index);
					}}
					error={
						errors?.addressHistory &&
						errors?.addressHistory[index]?.currentLivingSituation &&
						errors?.addressHistory[index]?.currentLivingSituation?.message
					}
				/>
			)}
			<label className="text-primary text-[21.33px] font-normal">
				How long have you lived there?
			</label>
			<div className="flex flex-col md:flex-row gap-4">
				<DatePickerInput
					name="livingStartDate"
					onChange={(value) => {
						const updateAddressHistory = {
							...addressHistory,
						};
						updateAddressHistory.livingStartDate = value!;
						onChangeHandler(updateAddressHistory, index);
					}}
					value={addressHistory.livingStartDate}
					label="Start Date"
					error={
						errors?.addressHistory &&
						errors?.addressHistory[index]?.livingStartDate &&
						errors?.addressHistory[index]?.livingStartDate?.message
					}
					maxDate={new Date()}
				/>
				{index !== 0 && (
					<DatePickerInput
						name="livingEndDate"
						onChange={(value) => {
							const updateAddressHistory = {
								...addressHistory,
							};
							updateAddressHistory.livingEndDate = value;
							onChangeHandler(updateAddressHistory, index);
						}}
						value={addressHistory.livingEndDate}
						label="End Date"
						error={
							errors?.addressHistory &&
							errors?.addressHistory[index]?.livingEndDate &&
							errors?.addressHistory[index]?.livingEndDate?.message
						}
					/>
				)}
			</div>
			<div className="flex flex-col md:flex-row gap-4">
				{index !== 0 && (
					<Button
						text="Remove"
						variant="plain"
						type="button"
						iconSuffix={<i className="icon-delete" />}
						handleClick={() => {
							removeHandler(index);
						}}
					/>
				)}
			</div>
		</div>
	);
};

export default AddressHistoryDisplay;
