import ErrorMessage from "../error-message/ErrorMessage";
import type { ChangeEvent } from "react";

export type CheckboxInputType = {
	label: string;
	name: string;
	value: string;
	checked: boolean;
	onChange: (event: ChangeEvent<HTMLInputElement>) => void;
	size?: "small" | "medium" | "large";
	checkPosition?: "start" | "center";
	error?: string;
};

const CheckboxInput = ({
	label,
	name,
	value,
	checked,
	onChange,
	size = "large",
	checkPosition = "center",
	error,
}: CheckboxInputType) => {
	const optionId = `checkbox-option--${Math.random().toString(36).slice(2, 9)}`;

	return (
		<>
			<div
				className={`w-full list-none flex ${
					checkPosition === "start" ? "items-start" : "items-center"
				} gap-4`}
				aria-labelledby={`Checkbox option for ${label}`}
			>
				<input
					type="checkbox"
					id={optionId}
					name={name}
					value={value}
					checked={checked}
					className={`w-4 h-4 accent-primary bg-transparent rounded-none border-black ${checkPosition === "start" && "mt-[0.12rem]"}`}
					onChange={onChange}
				/>
				<label
					tabIndex={0}
					htmlFor={optionId}
					className={`${size === "small" ? "text-sm" : "text-[21.33px] leading-8"} flex items-center justify-center transition-all cursor-pointer`}
				>
					{label}
				</label>
			</div>
			{!!error && (
				<div className="-mt-4 ml-8">
					<ErrorMessage message={error} />
				</div>
			)}
		</>
	);
};

export default CheckboxInput;
