const LoanStructureFC = () => {
	return (
		<div aria-labelledby="loan-Structure">
			<h2 className="text-primary text-[37.9px] font-normal">
				Loan Structure and Features
			</h2>
			<div className="p-6 border border-primary text-primary flex flex-col gap-2 leading-8 text-[21.33px]">
				<h3 className="text-[21.33px]">All Sucasa loans currently have:</h3>
				<ul className="list-disc ps-10 mb-6">
					<li>Principal and Interest Repayments</li>
					<li>Variable Interest</li>
					<li>Monthly Repayments</li>
					<li>Free Redraw Facility</li>
					<li>No Fees for Early Repayments</li>
				</ul>
				<h3 className="text-[21.33px]">We do not currently offer:</h3>
				<ul className="list-disc ps-10">
					<li>Interest Only Loans</li>
					<li>Fixed Rate Loans</li>
					<li>Guarantor Loans</li>
				</ul>
			</div>
		</div>
	);
};

export default LoanStructureFC;
