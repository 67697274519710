import TagManager, { type TagManagerArgs } from "react-gtm-module";
import config from "../config";

export const pushFullUrlToDataLayer = () => {
	const fullUrl = window.location.href;
	TagManager.dataLayer({
		dataLayer: {
			event: "fullUrlChange",
			fullUrl: fullUrl,
		},
	});
};

const tagManagerArgs: TagManagerArgs = {
	gtmId:
		config.VITE_APP_ENVIRONMENT === "production"
			? config.VITE_GTM_ID
			: "GTM-000000",
	events: {
		fullUrlChange: pushFullUrlToDataLayer,
	},
};

TagManager.initialize(tagManagerArgs);
