import { type FC, type FormEvent, type ReactNode, type RefObject, useEffect, useRef } from "react";
import { isChromeOrMozillaMobile } from "../../utils/helpers";
import { useAppSelector } from "../../../hooks/redux-hooks";
import { selectHeaderHeight } from "../../../store/slices/ui-slice";

type FormLayoutProps = {
	header: ReactNode;
	content: ReactNode;
	footer: ReactNode;
	onSubmit?: (event: FormEvent<HTMLFormElement>) => void;
	ref?: RefObject<HTMLFormElement>;
};

const FormLayout: FC<FormLayoutProps> = ({
	header,
	content,
	footer,
	onSubmit,
}) => {
	const topRef = useRef<HTMLDivElement>(null); // Ref for the top element or header
	const headerHeight = useAppSelector(selectHeaderHeight) ?? 100;

	useEffect(() => {
		const timer = setTimeout(() => {
			if (topRef.current) {
				const elementPosition = topRef.current.getBoundingClientRect().top;
				const offsetPosition =
					elementPosition + window.pageYOffset - headerHeight * 2;

				window.scrollTo({
					top: offsetPosition,
					behavior: "smooth",
				});
			}
		}, 300); // Added a 300ms delay before scrolling

		return () => {
			clearTimeout(timer);
		}; // Cleanup the timeout on component unmount
	}, [headerHeight]);

	const className = `flex flex-col gap-8 h-full ${isChromeOrMozillaMobile() ? "pb-4" : ""}`;

	return (
		<form
			aria-labelledby="form-layout"
			className={className}
			onSubmit={onSubmit}
		>
			{/* Assign the ref to the header or a div wrapping the header */}
			<div ref={topRef} className="text-primary text-[37.9px] font-normal">
				{header}
			</div>
			<div className="flex flex-col gap-9 grow md:grow-0">{content}</div>
			<div className="grow md:grow-0"></div>
			<footer className="gap-4 mt-5" aria-labelledby="Actions wrapper">
				{footer}
			</footer>
		</form>
	);
};

export default FormLayout;
