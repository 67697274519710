import type { ReactNode } from "react";
import type { KeyboardEvent } from "react";
import type {
	FieldValues,
	Path,
	UseFormRegister,
	UseFormSetValue,
} from "react-hook-form";
import ErrorMessage from "../error-message/ErrorMessage";

type GenericInputType<TFieldValues extends FieldValues> = {
	name: keyof TFieldValues;
	type: string;
	label?: string;
	placeholder?: string;
	iconPrefix?: ReactNode;
	iconSuffix?: ReactNode;
	register: UseFormRegister<TFieldValues>;
	setValue?: UseFormSetValue<TFieldValues>;
	required?: boolean;
	noBorder?: boolean;
	error?: string;
	value?: string | number;
	max?: string;
	maxLength?: number;
	disabled?: boolean;
	className?: string;
	inputContainerClassName?: string;
	inputClassName?: string;
	onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
};

const GenericInput = <TFieldValues extends FieldValues>(
	props: GenericInputType<TFieldValues>
) => {
	const inputId = `input-${props?.label?.replace(/\s+/g, "")}-${props?.placeholder?.replace(/\s+/g, "")}`;

	return (
		<div
			className={`w-full flex flex-col gap-2  ${props.className ?? ""}`}
			aria-labelledby={`${props.label}`}
		>
			{!!props.label && (
				<label
					htmlFor={inputId}
					className="text-primary text-[21.33px] font-normal mb-2"
				>
					{props.label}
				</label>
			)}
			<div
				className={`${props.noBorder ? "" : `${props.error ? "border-b-error" : props.disabled ? "border-b-off-black-400 text-off-black-400" : "border-b-off-black-900"} border-b pb-2`} flex items-center gap-2 ${props.inputContainerClassName ?? ""}`}
			>
				{props.iconPrefix}
				<input
					{...props.register(props.name as Path<TFieldValues>, {
						setValueAs: (value: string): number | string =>
							props.type === "number" ? Number.parseFloat(value) || 0 : value,
					})}
					onKeyDown={props?.onKeyDown ?? ((event) => {
						if (event.key === "Enter") {
							event.preventDefault();
						}
					})}
					id={inputId}
					type={props.type}
					defaultValue={props.value}
					placeholder={props.placeholder}
					required={props.required}
					autoComplete={String(props.name)}
					className={`${props.error ? "placeholder-error" : props.disabled ? "placeholder-off-black-400 text-red" : "placeholder-off-black-600"} bg-transparent focus-visible:outline-none w-full ${props.inputClassName ?? ""}`}
					onWheel={(event) => {
						event.currentTarget.blur();
					}}
					maxLength={props.maxLength}
					max={props.max}
					disabled={props.disabled}
				/>
				{props.iconSuffix}
			</div>
			{!!props.error && <ErrorMessage message={props.error} />}
		</div>
	);
};

export default GenericInput;
