import { useAppSelector } from "../hooks/redux-hooks";
import {
	selectIsLoggedInWithOriginationApplication,
} from "../store/slices/auth-slice";
import { WelcomeBackNew } from "./WelcomeBackNew";
import { WelcomeBack } from "./WelcomeBack";

export const WelcomeBackWrapper = () => {
	const isLoggedInWithOriginationApplication = useAppSelector(selectIsLoggedInWithOriginationApplication);

	return (<>
		{isLoggedInWithOriginationApplication && <WelcomeBackNew />}
		{!isLoggedInWithOriginationApplication && <WelcomeBack />}
	</>);
};
