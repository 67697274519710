import type { FC, ReactNode } from "react";
import { DetailsIcon } from "./DetailsIcon";

type DetailsSummaryProps = {
    disabled: boolean;
    isOpen: boolean;
    className?: string;
    isIconSuffix?: boolean;
    summary?: ReactNode;
    summarySuffix?: ReactNode;
    summaryFooter?: ReactNode;
}
export const DetailsSummary: FC<DetailsSummaryProps> = ({
    disabled,
    isOpen,
    className,
    isIconSuffix,
    summary,
    summarySuffix,
    summaryFooter,
}) => (
  <div className={`flex flex-col justify-start ${className ?? ""}`}>
      <div className={`flex flex-row items-center select-none w-full ${disabled ? "" : "cursor-pointer"}`}>
          <div className="flex flex-row items-center flex-start flex-grow">
              {!isIconSuffix && <DetailsIcon isOpen={isOpen} />}
              <div>
                  {summary}
              </div>
          </div>
          {summarySuffix}
          {isIconSuffix && <DetailsIcon isOpen={isOpen} />}
      </div>
      {summaryFooter}
  </div>);
