import { useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useNavigate } from "react-router-dom";

import Button from "../../ui/button/Button";

import {
	PASSWORD_REGEX_8ULNS16,
	COMMON_PASSWORDS_BLACKLIST_REGEX,
} from "../../../lib/utils";
import { useResetPassword } from "../../../hooks/use-reset-password";
import { useAppDispatch } from "../../../hooks/redux-hooks";
import { setFormLoading } from "../../../store/slices/loader-slice";
import GenericPasswordInput, {
	PasswordRequirements,
} from "../generic-password-input/GenericPasswordInput";

const resetPasswordSchema = z
	.object({
		password: z
			.string()
			.min(8, "Password must be at least 8 characters")
			.regex(
				PASSWORD_REGEX_8ULNS16,
				"Password must contain at least one uppercase, one lowercase, one number and one special character"
			)
			.regex(COMMON_PASSWORDS_BLACKLIST_REGEX, "Password is too common"),
		passwordConfirmation: z.string(),
		resetToken: z.string(),
	})
	.refine((data) => data.password === data.passwordConfirmation, {
		message: "Passwords do not match",
		path: ["passwordConfirmation"],
	});

type ResetPasswordSchemaType = z.infer<typeof resetPasswordSchema>;

const ResetPasswordForm = ({ resetToken }: { resetToken: string }) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const {
		register,
		handleSubmit,
		formState: { errors, isDirty },
	} = useForm<ResetPasswordSchemaType>({
		resolver: zodResolver(resetPasswordSchema),
		defaultValues: {
			resetToken,
		},
	});

	const { onSubmitHandler, isLoading } = useResetPassword(navigate);

	useEffect(() => {
		dispatch(setFormLoading(isLoading));
		return () => {
			dispatch(setFormLoading(false));
		};
	}, [isLoading, dispatch]);

	return (
		<form
			className="flex flex-col gap-8"
			onSubmit={handleSubmit(onSubmitHandler)}
		>
			<GenericPasswordInput
				name="password"
				register={register}
				placeholder="New Password"
				error={errors?.password?.message}
				iconPrefix={<i className="icon-lock" />}
			/>
			<GenericPasswordInput
				name="passwordConfirmation"
				register={register}
				placeholder="Confirm Password"
				error={errors?.passwordConfirmation?.message}
				iconPrefix={<i className="icon-lock" />}
			/>
			{PasswordRequirements}

			<div className="lg:mt-10">
				<Button
					type="submit"
					text="Reset password and Log in"
					variant="primary"
					size="full"
					iconSuffix={<i className="icon-arrow" />}
					isDisabled={isLoading || !isDirty}
				/>
			</div>
		</form>
	);
};

export default ResetPasswordForm;
