import type { FC, PropsWithChildren, ReactNode } from "react";

type HintCardType = {
	text?: string | ReactNode;
	icon?: ReactNode;
	className?: string
	noGap?: boolean
};

const HintCard: FC<PropsWithChildren<HintCardType>> = (props) => {
	const children = props.text ?? props.children;
	return (
		<section
			className={`flex flex-col items-start bg-white p-4 ${props.noGap ? "" : "gap-4"} rounded ${props.className ?? ""}`}
			aria-labelledby="Aside section"
		>
			{props?.icon}
			{typeof children === "string" ? (
				<p className="text-sm leading-6">{children}</p>
			) : (
				children
			)}
		</section>
	);
};

export default HintCard;
