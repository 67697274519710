import { type FC, useMemo } from "react";
import HintCard from "../hint-card/HintCard";
import CircularProgressBar from "../circular-progress-bar/CircularProgressBar";
import { useAppSelector } from "../../../hooks/redux-hooks";
import { selectTotalAddressHistory } from "../../../store/slices/form-slice";

interface AddressHistoryHintParameters {
	percentage?: number;
	totalMonths?: number;
}
export const AddressHistoryHint: FC<AddressHistoryHintParameters> = ({
	percentage: percentageParameter,
	totalMonths: totalMonthsParameter
}) => {
	const totalMonthsAddressHistory = useAppSelector(selectTotalAddressHistory);
	const percentageAddressHistory = (totalMonthsAddressHistory / 24) * 100;

	const percentage = useMemo(() =>
		percentageParameter === undefined ? percentageAddressHistory : percentageParameter,
		[percentageAddressHistory, percentageParameter]);
	const totalMonths = useMemo(() =>
		totalMonthsParameter === undefined ? totalMonthsAddressHistory : totalMonthsParameter,
		[totalMonthsAddressHistory, totalMonthsParameter]);

	return (<HintCard
		icon={
			<CircularProgressBar
				percentage={
					percentage >= 100 ? 100 : percentage
				}
			/>
		}
		text={
			<>
				<div>
					<span
						className={`${totalMonths >= 24 && "text-grass-hopper-900"}`}
					>
						{totalMonths}/
					</span>
					24 months
				</div>
				<span className="font-medium text-sm leading-6">
					We'll need your address history for the past 24 months, 
					including all places you have lived during that period.
				</span>
			</>
		}
	/>);
}
