import { useCallback, useEffect, useState } from "react";
import Button from "./Button";
import { useAuth0Token } from "../../../hooks/use-auth0-token";
import { useTracking } from "../../../hooks/use-tracking";

const COOL_DOWN_DURATION = 30; // 30s

export const ResendTokenButton = () => {
	const [coolDown, setCoolDown] = useState(0)
	const { resendToken, isLoading: isLoadingOtpResend } = useAuth0Token();
	const { trackEvent } = useTracking();

	const startCoolDown = () => {
		setCoolDown(COOL_DOWN_DURATION);
		const interval = setInterval(() => {
			setCoolDown((currentCoolDown) => {
				if (currentCoolDown <= 1) {
					clearInterval(interval);
					return 0;
				}
				return currentCoolDown - 1;
			});
		}, 1000);
	};

	const handleClick = useCallback(async () => {
		trackEvent("Clicked Resend Code");
		await resendToken();
		startCoolDown();
	}, [resendToken]);

	useEffect(() => {
		startCoolDown();
	}, []);

	return (<Button
		text={coolDown > 0 ? `Resend in ${coolDown}...` : "Resend code"}
		variant="accent"
		size="full"
		handleClick={handleClick}
		isDisabled={isLoadingOtpResend || coolDown > 0}
	/>);
};
