import { type FormEvent, useState } from "react";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux-hooks";
import { buyingOptions } from "../../../utils/BootList";
import RadioGroup from "../../radio-group/RadioGroup";
import BuyingWorkflow from "./buying-workflow/BuyingWorkflow";
import RefinancingWorkflow from "./refinancing-workflow/RefinancingWorkflow";
import { setLoanPurpose } from "../../../../store/slices/form-slice";
import Button from "../../../ui/button/Button";
import {
	navigateToStep,
	setSubStep,
	stepOrder,
} from "../../../../store/slices/stepper-slice";
import {
	LoanPurpose,
	SubStep,
} from "../../../../services/apis/create-application.schema";
import FormLayout from "../../../ui/form/Form";
import { useMarkFormDirty } from "../../../../hooks/use-mark-form-dirty";
import useTrackPageViewOnMount from "../../../../hooks/use-track-on-mount";

type LetsStartHomeFormType = {
	loanPurpose?: string;
};

const LetsStartHome = () => {
	const loanPurpose = useAppSelector((state) => state.form.loanPurpose);
	const [showNextButton, setShowNextButton] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [isValid, setIsValid] = useState(false);
	const dispatch = useAppDispatch();
	const [propertyValuation, setPropertyValuation] = useState<
		number | undefined
	>();

	const defaultValues: Partial<LetsStartHomeFormType> = {
		loanPurpose,
	};
	const {
		register,
		formState: { isDirty },
	} = useForm({
		defaultValues,
	});
	useMarkFormDirty(isDirty);
	register("loanPurpose", {
		onChange: (event: FormEvent<HTMLInputElement>) => {
			dispatch(setLoanPurpose(event.currentTarget.value as LoanPurpose));
			setShowNextButton(false);
		},
	});

	useTrackPageViewOnMount({
		page: "Your Home",
		subPage: "Loan Type Selection",
	});

	const header = (
		<h1
			className="text-primary text-[37.9px] font-normal"
			aria-labelledby="Let’s start your application Step Title"
		>
			Let’s start
		</h1>
	);

	const content = (
		<div className="flex flex-col gap-9">
			<RadioGroup
				selectedValue={loanPurpose}
				radioOptions={buyingOptions}
				legend="What are you doing?"
				register={register}
			/>
			{loanPurpose === LoanPurpose.Purchase && (
				<BuyingWorkflow
					onFinish={(value) => {
						setShowNextButton(value);
					}}
				/>
			)}
			{loanPurpose === LoanPurpose.Refinance && (
				<RefinancingWorkflow
					submitted={submitted}
					onFinish={(
						isValid: boolean,
						propertyValuation: number | undefined
					) => {
						setIsValid(isValid);
						setPropertyValuation(propertyValuation);
					}}
				/>
			)}
		</div>
	);

	const footer = (
		<div className="flex items-center justify-between gap-4">
			<Button
				text="Back"
				type="button"
				variant="accent"
				iconPrefix={<i className="icon-arrow rotate-180" />}
				handleClick={() => {
					// undefined renders the first step
					dispatch(setSubStep(undefined));
				}}
			/>

			{loanPurpose === LoanPurpose.Purchase && showNextButton && (
				<Button
					text="Next"
					type="button"
					variant="primary"
					iconSuffix={<i className="icon-arrow" />}
					handleClick={() => {
						dispatch(
							navigateToStep({
								step: stepOrder[1] as string,
								subStep: SubStep.BuyingWorkflow,
							})
						);
					}}
				/>
			)}
			{loanPurpose === LoanPurpose.Refinance && (
				<Button
					text="Next"
					type="button"
					variant="primary"
					isDisabled={!isValid}
					iconSuffix={<i className="icon-arrow" />}
					handleClick={() => {
						setSubmitted(true);
						console.log(propertyValuation);
						if (isValid && propertyValuation && propertyValuation >= 300_000) {
							dispatch(
								navigateToStep({
									step: stepOrder[1] as string,
									subStep: SubStep.CurrentHomeLoan,
								})
							);
						}
					}}
				/>
			)}
		</div>
	);

	return <FormLayout header={header} content={content} footer={footer} />;
};

export default LetsStartHome;
