import type { FunctionComponent } from "../common/types";
import { useAppSelector } from "../hooks/redux-hooks";
import useTrackPageViewOnMount from "../hooks/use-track-on-mount";
import { selectHeaderHeight } from "../store/slices/ui-slice";
import banner from "./../assets/not-in-remit-banner.jpg";

import { useEffect, useRef } from "react";

const NotInRemit = (): FunctionComponent => {
	const topRef = useRef<HTMLDivElement>(null); // Ref for the top element or header
	const headerHeight = useAppSelector(selectHeaderHeight) ?? 100;

	useTrackPageViewOnMount({
		page: "Not In Remit",
	});
	useEffect(() => {
		const timer = setTimeout(() => {
			if (topRef.current) {
				const elementPosition = topRef.current.getBoundingClientRect().top;
				const offsetPosition =
					elementPosition + window.pageYOffset - headerHeight * 2;

				window.scrollTo({
					top: offsetPosition,
					behavior: "smooth",
				});
			}
		}, 300); // Added a 300ms delay before scrolling

		return () => {
			clearTimeout(timer);
		}; // Cleanup the timeout on component unmount
	}, [headerHeight]);

	return (
		<div
			ref={topRef}
			className="bg-white p-4 md:p-16 flex flex-col items-center gap-8"
		>
			<img
				src={banner}
				alt="not-in-remit-banner"
				className="w-full hidden lg:block"
			/>
			<div className="flex flex-col items-center gap-2">
				<h1
					className="text-primary text-[37.9px] font-normal"
					aria-labelledby="Welcome back!"
				>
					Looks like Sucasa isn't a fit (yet)
				</h1>
				<div className="text-off-black-600 text-[21.33px] font-normal leading-8 md:text-center">
					<p>
						From what you've told us, Sucasa isn't the best fit for your needs
						right now.
					</p>
					<p>We'll reach out as soon as we can help.</p>
				</div>
			</div>
		</div>
	);
};

export default NotInRemit;
