import { useEffect, useState } from "react";
import type { FunctionComponent } from "../../../../../common/types";
import {
	useAppDispatch,
	useAppSelector,
} from "../../../../../hooks/redux-hooks";
import Button, { type ButtonType } from "../../../../ui/button/Button";
import {
	setAilmonyMaintenanceExpenses,
	setChildcareExpenses,
	setChronicHealthExpenses,
	setClothingPersonalCareExpenses,
	setCounsellingServicesExpenses,
	setEducationExpenses,
	setFoodGroceriesExpenses,
	setGeneralInsuranceExpenses,
	setInternetTVPhoneExpenses,
	setMedicalHealthExcludingInsuranceExpenses,
	setOtherExpenses,
	setPostSettlementRent,
	setPrivateHealthInsuranceExpenses,
	setPrivateSchoolFeesExpenses,
	setPropertyUtilitiesExpenses,
	setRecreationEntertainmentExpenses,
	setTransportExpenses,
	setlifeOrAccidentExpenses,
} from "../../../../../store/slices/form-slice";
import {
	addDynamicHint,
	removeDynamicHint,
	setDynamicHints,
} from "../../../../../store/slices/ui-slice";
import { zodResolver } from "@hookform/resolvers/zod";

import { useForm } from "react-hook-form";
import {
	type ExpensesFormSlice,
	HouseHoldExpensesSchema,
	selectHouseHoldExpenses,
	type HouseHoldExpensesFormType,
} from "./HouseHoldExpensesSchema";
import Modal from "../../../modal/Modal";
import { selectUpdateExpensesPayload } from "../../../../../store/selectors/update-expenses-payload";
import TextArea from "../../../text-area/TextArea";
import { navigateToStep } from "../../../../../store/slices/stepper-slice";
import { SubStep } from "../../../../../services/apis/create-application.schema";
import useIsMobile from "../../../../../hooks/use-is-mobile";
import { HouseHoldExpensesHint } from "../../../../ui/hint/HouseHoldExpensesHint";
import { setFormLoading } from "../../../../../store/slices/loader-slice";
import { setToast } from "../../../../../store/slices/toast-slice";
import FormLayout from "../../../../ui/form/Form";
import DollarInput from "../../../dollar-input/DollarInput";
import useTrackPageViewOnMount from "../../../../../hooks/use-track-on-mount";
import {useTracking} from "../../../../../hooks/use-tracking";
import { useUpdateExpenses } from "../../../../../services/apis/origination/expense";
import { captureException } from "../../../../../services/sentry";
import { formatCurrency } from "../../../../utils/helpers";

const YourHouseHoldExpenses = (): FunctionComponent => {
	const dispatch = useAppDispatch();
	const isMobile = useIsMobile();
	const formState = useAppSelector(selectHouseHoldExpenses);
	const [updateExpenses, { isLoading }] = useUpdateExpenses();
	const updateExpensesPayload = useAppSelector(selectUpdateExpensesPayload);
	const [isOpen, setIsOpen] = useState(false);
	const [lowExpensesExplanationError, setlowExpensesExplanationError] =
		useState(false);
	const {
		register,
		control,
		handleSubmit,
		watch,
		setValue,
		formState: { errors },
	} = useForm({
		resolver: zodResolver(HouseHoldExpensesSchema),
		defaultValues: formState,
	});

	useTrackPageViewOnMount({
		page: "Your Finances",
		subPage: "Household Expenses",
	});

	const {trackEvent} = useTracking();

	const updateExpensesCall = async (moveForward: boolean) => {
		try {
			const response = await updateExpenses({
				...updateExpensesPayload,
				lowExpensesExplanation: watch("lowExpensesExplanation"),
				alimony: watch("ailmonyMaintenanceExpenses")!.toString(),
				childcare: watch("childcareExpenses")!.toString(),
				chronicHealthExpenses: watch("chronicHealthExpenses")!.toString(),
				clothingAndPersonal: watch("clothingPersonalCareExpenses")!.toString(),
				education: watch("educationExpenses")!.toString(),
				foodAndGroceries: watch("foodGroceriesExpenses")!.toString(),
				generalInsurance: watch("generalInsuranceExpenses")!.toString(),
				internetTVPhone: watch("internetTVPhoneExpenses")!.toString(),
				lifeOrAccidentInsurance: watch("lifeOrAccidentExpenses")!.toString(),
				medicalAndHealth: watch(
					"medicalHealthExcludingInsuranceExpenses"
				)!.toString(),
				otherExpenses: watch("otherExpenses")!.toString(),
				privateHealthInsurance: watch(
					"privateHealthInsuranceExpenses"
				)!.toString(),
				privateSchoolFees: watch("privateSchoolFeesExpenses")!.toString(),
				propertyExpenses: watch("propertyUtilitiesExpenses")!.toString(),
				recreationAndEntertainment: watch(
					"recreationEntertainmentExpenses"
				)!.toString(),
				rent: watch("postSettlementRent")!.toString(),
				transport: watch("transportExpenses")!.toString(),
				counselling: watch("counsellingServicesExpenses")!.toString(),
			}).unwrap();

			if (!moveForward && response.data?.content.expensesSeemLow) {
				setIsOpen(true);
			} else {
				setIsOpen(false);
				dispatch(
					navigateToStep({
						step: "financeStep",
						subStep: SubStep.YourAssets,
					})
				);
			}
		} catch (error) {
			captureException(new Error("Error saving expenses"), {data: {error}});
			dispatch(
				setToast({
					open: true,
					type: "error",
					title: "Error",
					description: "An error occurred while saving your expenses.",
				})
			);
		}
	};
	const modalAction: Array<ButtonType> = [
		{
			text: "Review your expenses",
			variant: "accent",
			handleClick: () => {
				trackEvent("Review your expenses Clicked");
				setIsOpen(false);
			},
			type: "button",
			textAlign: "start",
			size: "full",
		},
		{
			text: "Proceed with current expenses",
			variant: "primary",
			handleClick: () => {
				trackEvent("Proceed with current expenses Clicked")
				if (
					!watch("lowExpensesExplanation") ||
					watch("lowExpensesExplanation") === ""
				) {
					setlowExpensesExplanationError(true);
				} else {
					setIsOpen(false);
					void updateExpensesCall(true);
				}
			},
			type: "button",
			textAlign: "start",
			size: "full",
			iconSuffix: <i className="icon-arrow" />,
			isDisabled: isLoading,
		},
	];

	const saveData = (rawData: ExpensesFormSlice) => {
		// Assuming validation ensures all values are defined, we can assert the type here - this is a hack because the zod inference with the form schema is not working
		const data: HouseHoldExpensesFormType =
			rawData as HouseHoldExpensesFormType;
		dispatch(setPostSettlementRent(data.postSettlementRent));
		dispatch(setInternetTVPhoneExpenses(data.internetTVPhoneExpenses));
		dispatch(setPropertyUtilitiesExpenses(data.propertyUtilitiesExpenses));
		dispatch(setFoodGroceriesExpenses(data.foodGroceriesExpenses));
		dispatch(
			setMedicalHealthExcludingInsuranceExpenses(
				data.medicalHealthExcludingInsuranceExpenses
			)
		);
		dispatch(
			setClothingPersonalCareExpenses(data.clothingPersonalCareExpenses)
		);
		dispatch(
			setRecreationEntertainmentExpenses(data.recreationEntertainmentExpenses)
		);
		dispatch(setTransportExpenses(data.transportExpenses));
		dispatch(setChildcareExpenses(data.childcareExpenses));
		dispatch(setPrivateSchoolFeesExpenses(data.privateSchoolFeesExpenses));
		dispatch(setEducationExpenses(data.educationExpenses));
		dispatch(
			setPrivateHealthInsuranceExpenses(data.privateHealthInsuranceExpenses)
		);
		dispatch(setlifeOrAccidentExpenses(data.lifeOrAccidentExpenses));
		dispatch(setGeneralInsuranceExpenses(data.generalInsuranceExpenses));
		dispatch(setAilmonyMaintenanceExpenses(data.ailmonyMaintenanceExpenses));
		dispatch(setCounsellingServicesExpenses(data.counsellingServicesExpenses));
		dispatch(setChronicHealthExpenses(data.chronicHealthExpenses));
		dispatch(setOtherExpenses(data.otherExpenses));
		void updateExpensesCall(false);
	};

	useEffect(() => {
		dispatch(setDynamicHints([]));
		dispatch(addDynamicHint("HouseHoldExpensesHint"));
		return () => {
			dispatch(removeDynamicHint("HouseHoldExpensesHint"));
		};
	}, [dispatch]);

	const calculateHousingExpenses = () => {
		return (
			(watch("postSettlementRent") || 0) +
			(watch("internetTVPhoneExpenses") || 0) +
			(watch("propertyUtilitiesExpenses") || 0)
		);
	};

	const calculatePersonalExpenses = () => {
		return (
			(watch("foodGroceriesExpenses") || 0) +
			(watch("medicalHealthExcludingInsuranceExpenses") || 0) +
			(watch("clothingPersonalCareExpenses") || 0) +
			(watch("recreationEntertainmentExpenses") || 0) +
			(watch("transportExpenses") || 0)
		);
	};
	const calculateEducationExpenses = () => {
		return (
			(watch("childcareExpenses") || 0) +
			(watch("privateSchoolFeesExpenses") || 0) +
			(watch("educationExpenses") || 0)
		);
	};

	const calculateInsuranceAndOtherExpenses = () => {
		return (
			(watch("privateHealthInsuranceExpenses") || 0) +
			(watch("lifeOrAccidentExpenses") || 0) +
			(watch("generalInsuranceExpenses") || 0) +
			(watch("ailmonyMaintenanceExpenses") || 0) +
			(watch("counsellingServicesExpenses") || 0) +
			(watch("chronicHealthExpenses") || 0) +
			(watch("otherExpenses") || 0)
		);
	};

	const calculateTotalExpenses = () => {
		return (
			calculateHousingExpenses() +
			calculateEducationExpenses() +
			calculatePersonalExpenses() +
			calculateInsuranceAndOtherExpenses()
		);
	};

	useEffect(() => {
		dispatch(setFormLoading(isLoading));
		return () => {
			dispatch(setFormLoading(false));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);
	const header = (
		<h1
			className="text-primary text-[37.9px] font-normal"
			aria-labelledby="Your Household Expenses"
		>
			Your Household Expenses
		</h1>
	);
	const content = (
		<div className="flex flex-col gap-9">
			<div className="flex flex-col gap-4">
				<div className="flex flex-row justify-between items-center">
					<h4 className="text-primary text-[21.33px] font-medium">Housing</h4>
					<h4 className="text-primary text-[21.33px]">
						{formatCurrency(calculateHousingExpenses())} monthly
					</h4>
				</div>
				<DollarInput
					name="postSettlementRent"
					control={control}
					type="numeric"
					value={formState.postSettlementRent}
					error={errors.postSettlementRent && errors.postSettlementRent.message}
					label="Rent (Post Settlement)"
					placeholder="Amount"
					iconPrefix={<i className="icon-dollar" />}
					iconSuffix={<span className="flex shrink-0">/ month</span>}
					onValueChange={(value) => {
						setValue("postSettlementRent", value);
					}}
					layout="secondary"
					size="small"
					min={0}
				/>

				<DollarInput
					name="internetTVPhoneExpenses"
					control={control}
					type="numeric"
					value={formState.internetTVPhoneExpenses}
					error={
						errors.internetTVPhoneExpenses &&
						errors.internetTVPhoneExpenses.message
					}
					label="Internet, TV, and Phone, etc."
					placeholder="Amount"
					iconPrefix={<i className="icon-dollar" />}
					iconSuffix={<span className="flex shrink-0">/ month</span>}
					onValueChange={(value) => {
						setValue("internetTVPhoneExpenses", value);
					}}
					layout="secondary"
					size="small"
					min={0}
				/>

				<DollarInput
					name="propertyUtilitiesExpenses"
					control={control}
					type="numeric"
					value={formState.propertyUtilitiesExpenses}
					error={
						errors.propertyUtilitiesExpenses &&
						errors.propertyUtilitiesExpenses.message
					}
					label="Property Expenses and Utilities"
					placeholder="Amount"
					iconPrefix={<i className="icon-dollar" />}
					iconSuffix={<span className="flex shrink-0">/ month</span>}
					onValueChange={(value) => {
						setValue("propertyUtilitiesExpenses", value);
					}}
					layout="secondary"
					size="small"
					min={0}
				/>
			</div>
			<div className="flex flex-col gap-4">
				<div className="flex flex-row justify-between items-center">
					<h4 className="text-primary text-[21.33px] font-medium">Personal</h4>
					<h4 className="text-primary text-[21.33px]">
						{formatCurrency(calculatePersonalExpenses())} monthly
					</h4>
				</div>
				<DollarInput
					name="foodGroceriesExpenses"
					control={control}
					type="numeric"
					value={formState.foodGroceriesExpenses}
					error={
						errors.foodGroceriesExpenses && errors.foodGroceriesExpenses.message
					}
					label="Food and Groceries"
					placeholder="Amount"
					iconPrefix={<i className="icon-dollar" />}
					iconSuffix={<span className="flex shrink-0">/ month</span>}
					onValueChange={(value) => {
						setValue("foodGroceriesExpenses", value);
					}}
					layout="secondary"
					size="small"
					min={0}
				/>

				<DollarInput
					name="medicalHealthExcludingInsuranceExpenses"
					control={control}
					type="numeric"
					value={formState.medicalHealthExcludingInsuranceExpenses}
					error={
						errors.medicalHealthExcludingInsuranceExpenses &&
						errors.medicalHealthExcludingInsuranceExpenses.message
					}
					label="Medical and Health (excl. insurance)"
					placeholder="Amount"
					iconPrefix={<i className="icon-dollar" />}
					iconSuffix={<span className="flex shrink-0">/ month</span>}
					onValueChange={(value) => {
						setValue("medicalHealthExcludingInsuranceExpenses", value);
					}}
					layout="secondary"
					size="small"
					min={0}
				/>

				<DollarInput
					name="clothingPersonalCareExpenses"
					control={control}
					type="numeric"
					value={formState.clothingPersonalCareExpenses}
					error={
						errors.clothingPersonalCareExpenses &&
						errors.clothingPersonalCareExpenses.message
					}
					label="Clothing and Personal Care"
					placeholder="Amount"
					iconPrefix={<i className="icon-dollar" />}
					iconSuffix={<span className="flex shrink-0">/ month</span>}
					onValueChange={(value) => {
						setValue("clothingPersonalCareExpenses", value);
					}}
					layout="secondary"
					size="small"
					min={0}
				/>

				<DollarInput
					name="recreationEntertainmentExpenses"
					control={control}
					type="numeric"
					value={formState.recreationEntertainmentExpenses}
					error={
						errors.recreationEntertainmentExpenses &&
						errors.recreationEntertainmentExpenses.message
					}
					label="Recreation and Entertainment"
					placeholder="Amount"
					iconPrefix={<i className="icon-dollar" />}
					iconSuffix={<span className="flex shrink-0">/ month</span>}
					onValueChange={(value) => {
						setValue("recreationEntertainmentExpenses", value);
					}}
					layout="secondary"
					size="small"
					min={0}
				/>

				<DollarInput
					name="transportExpenses"
					control={control}
					type="numeric"
					value={formState.transportExpenses}
					error={errors.transportExpenses && errors.transportExpenses.message}
					label="Transport"
					placeholder="Amount"
					iconPrefix={<i className="icon-dollar" />}
					iconSuffix={<span className="flex shrink-0">/ month</span>}
					onValueChange={(value) => {
						setValue("transportExpenses", value);
					}}
					layout="secondary"
					size="small"
					min={0}
				/>
			</div>
			<div className="flex flex-col gap-4">
				<div className="flex flex-row justify-between items-center">
					<h4 className="text-primary text-[21.33px] font-medium">Education</h4>
					<h4 className="text-primary text-[21.33px]">
						{formatCurrency(calculateEducationExpenses())} monthly
					</h4>
				</div>
				<DollarInput
					name="childcareExpenses"
					control={control}
					type="numeric"
					value={formState.childcareExpenses}
					error={errors.childcareExpenses && errors.childcareExpenses.message}
					label="Childcare"
					placeholder="Amount"
					iconPrefix={<i className="icon-dollar" />}
					iconSuffix={<span className="flex shrink-0">/ month</span>}
					onValueChange={(value) => {
						setValue("childcareExpenses", value);
					}}
					layout="secondary"
					size="small"
					min={0}
				/>

				<DollarInput
					name="privateSchoolFeesExpenses"
					control={control}
					type="numeric"
					value={formState.privateSchoolFeesExpenses}
					error={
						errors.privateSchoolFeesExpenses &&
						errors.privateSchoolFeesExpenses.message
					}
					label="Private School Fees"
					placeholder="Amount"
					iconPrefix={<i className="icon-dollar" />}
					iconSuffix={<span className="flex shrink-0">/ month</span>}
					onValueChange={(value) => {
						setValue("privateSchoolFeesExpenses", value);
					}}
					layout="secondary"
					size="small"
					min={0}
				/>

				<DollarInput
					name="educationExpenses"
					control={control}
					type="numeric"
					value={formState.educationExpenses}
					error={errors.educationExpenses && errors.educationExpenses.message}
					label="Education (excl. HECs/HELP)"
					placeholder="Amount"
					iconPrefix={<i className="icon-dollar" />}
					iconSuffix={<span className="flex shrink-0">/ month</span>}
					onValueChange={(value) => {
						setValue("educationExpenses", value);
					}}
					layout="secondary"
					size="small"
					min={0}
				/>
			</div>
			<div className="flex flex-col gap-4">
				<div className="flex flex-row justify-between items-center">
					<h4 className="text-primary text-[21.33px] font-medium">
						Insurance and Other
					</h4>
					<h4 className="text-primary text-[21.33px]">
						{formatCurrency(calculateInsuranceAndOtherExpenses())} monthly
					</h4>
				</div>
				<DollarInput
					name="privateHealthInsuranceExpenses"
					control={control}
					type="numeric"
					value={formState.privateHealthInsuranceExpenses}
					error={
						errors.privateHealthInsuranceExpenses &&
						errors.privateHealthInsuranceExpenses.message
					}
					label="Private Health Insurance"
					placeholder="Amount"
					iconPrefix={<i className="icon-dollar" />}
					iconSuffix={<span className="flex shrink-0">/ month</span>}
					onValueChange={(value) => {
						setValue("privateHealthInsuranceExpenses", value);
					}}
					layout="secondary"
					size="small"
					min={0}
				/>

				<DollarInput
					name="lifeOrAccidentExpenses"
					control={control}
					type="numeric"
					value={formState.lifeOrAccidentExpenses}
					error={
						errors.lifeOrAccidentExpenses &&
						errors.lifeOrAccidentExpenses.message
					}
					label="Life or Accident Insurance"
					placeholder="Amount"
					iconPrefix={<i className="icon-dollar" />}
					iconSuffix={<span className="flex shrink-0">/ month</span>}
					onValueChange={(value) => {
						setValue("lifeOrAccidentExpenses", value);
					}}
					layout="secondary"
					size="small"
					min={0}
				/>

				<DollarInput
					name="generalInsuranceExpenses"
					control={control}
					type="numeric"
					value={formState.generalInsuranceExpenses}
					error={
						errors.generalInsuranceExpenses &&
						errors.generalInsuranceExpenses.message
					}
					label="General Insurance (Car, Personal, etc.)"
					placeholder="Amount"
					iconPrefix={<i className="icon-dollar" />}
					iconSuffix={<span className="flex shrink-0">/ month</span>}
					onValueChange={(value) => {
						setValue("generalInsuranceExpenses", value);
					}}
					layout="secondary"
					size="small"
					min={0}
				/>

				<DollarInput
					name="ailmonyMaintenanceExpenses"
					control={control}
					type="numeric"
					value={formState.ailmonyMaintenanceExpenses}
					error={
						errors.ailmonyMaintenanceExpenses &&
						errors.ailmonyMaintenanceExpenses.message
					}
					label="Alimony/Maintenance Payments"
					placeholder="Amount"
					iconPrefix={<i className="icon-dollar" />}
					iconSuffix={<span className="flex shrink-0">/ month</span>}
					onValueChange={(value) => {
						setValue("ailmonyMaintenanceExpenses", value);
					}}
					layout="secondary"
					size="small"
					min={0}
				/>

				<DollarInput
					name="counsellingServicesExpenses"
					control={control}
					type="numeric"
					value={formState.counsellingServicesExpenses}
					error={
						errors.counsellingServicesExpenses &&
						errors.counsellingServicesExpenses.message
					}
					label="Counselling Services"
					placeholder="Amount"
					iconPrefix={<i className="icon-dollar" />}
					iconSuffix={<span className="flex shrink-0">/ month</span>}
					onValueChange={(value) => {
						setValue("counsellingServicesExpenses", value);
					}}
					layout="secondary"
					size="small"
					min={0}
				/>

				<DollarInput
					name="chronicHealthExpenses"
					control={control}
					type="numeric"
					value={formState.chronicHealthExpenses}
					error={
						errors.chronicHealthExpenses && errors.chronicHealthExpenses.message
					}
					label="Chronic Health Expenses"
					placeholder="Amount"
					iconPrefix={<i className="icon-dollar" />}
					iconSuffix={<span className="flex shrink-0">/ month</span>}
					onValueChange={(value) => {
						setValue("chronicHealthExpenses", value);
					}}
					layout="secondary"
					size="small"
					min={0}
				/>

				<DollarInput
					name="otherExpenses"
					control={control}
					type="numeric"
					value={formState.otherExpenses}
					error={errors.otherExpenses && errors.otherExpenses.message}
					label="Other Expenses"
					placeholder="Amount"
					iconPrefix={<i className="icon-dollar" />}
					iconSuffix={<span className="flex shrink-0">/ month</span>}
					onValueChange={(value) => {
						setValue("otherExpenses", value);
					}}
					layout="secondary"
					size="small"
					min={0}
				/>
			</div>
			<div className="w-full border-t border-b-2 border-l border-r-2 border-primary p-4">
				<div className="flex flex-row justify-between items-center">
					<h4 className="text-primary text-[21.33px] font-medium">
						Total Monthly Expenses
					</h4>
					<h4 className="text-primary text-[21.33px]">
						{formatCurrency(calculateTotalExpenses())} monthly
					</h4>
				</div>
			</div>
			{isMobile && (<HouseHoldExpensesHint />)}
		</div>
	);
	const footer = (
		<div
			className="flex items-center justify-end gap-4"
			aria-labelledby="Actions wrapper"
		>
			<Button
				text="Next"
				variant="primary"
				iconSuffix={<i className="icon-arrow" />}
				isDisabled={isLoading}
			/>
		</div>
	);
	return (
		<>
			<FormLayout
				header={header}
				content={content}
				footer={footer}
				onSubmit={handleSubmit(saveData)}
			/>
			<Modal
				isOpen={isOpen}
				actions={modalAction}
				title={
					<div className="flex flex-col gap-4 md:gap-8 items-center text-[28.43px] font-medium leading-10 text-primary">
						<i className="icon-document-success text-3xl md:text-[80px]" />
						Nothing critical, but let’s double check your expenses before you
						proceed
					</div>
				}
				onClose={() => {
					setIsOpen(false);
				}}
				content={
					<div className="flex flex-col gap-4">
						<p className="text-sm text-primary">
							For a household in your circumstances, these expenses look low.
							The more accurate your estimates are, the faster we're able to
							assess your loan.
						</p>
						<TextArea
							name="lowExpensesExplanation"
							register={register}
							placeholder="Please provide an explanation for the lower-than-expected expenses"
							error={
								lowExpensesExplanationError
									? "You must provide an explanation for the lower-than-expected expenses in order to proceed further"
									: undefined
							}
						/>
					</div>
				}
			/>
		</>
	);
};

export default YourHouseHoldExpenses;
