import { useMemo } from "react";
import type { ApplicationStep } from "@sucasa-finance/origination-trpc";
import WelcomeLoanItem from "../../../ui/welcome-loan-item/WelcomeLoanItem";
import { formatDate } from "../../../../helpers/format-date";
import banner from "../../../../assets/welcome-back.jpg";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import {
	selectCurrentUserFullName,
	selectIsJointApplicant,
	selectIsNotInRemit,
} from "../../../../store/slices/auth-slice";
import {
	selectAppCreatedDate,
	selectAppId,
	selectCanUseApplication,
	selectLoanAmount,
	selectLoanPurchasePrice,
	selectPropertyAddress,
} from "../../../../store/slices/application-slice";
import { selectApplicationStep } from "../../../../store/slices/origination-slice";
import { WbStepperNew } from "./components/WbStepperNew";
import { formatPrice } from "../../../../helpers/format-price";
import { Status } from "../../../ui/status/Status";
import { WbProgress } from "./components/WbProgress";
import Button from "../../../ui/button/Button";
import { useContinueApplication } from "../../../../hooks/use-continue-application";

export const WbLoanDetailsLegacy = () => {
	const canUseApplication = useAppSelector(selectCanUseApplication);
	const notInRemit = useAppSelector(selectIsNotInRemit);

	const isJointApplicant = useAppSelector(selectIsJointApplicant);
	const fullName = useAppSelector(selectCurrentUserFullName);
	const propertyAddress = useAppSelector(selectPropertyAddress);
	const purchasePrice = useAppSelector(selectLoanPurchasePrice);
	const loanAmount = useAppSelector(selectLoanAmount);
	const createdDate = useAppSelector(selectAppCreatedDate);
	const appId = useAppSelector(selectAppId);
	const applicationStep = useAppSelector(selectApplicationStep) as ApplicationStep;

	const { continueApplication } = useContinueApplication()

	const trimmedAppId = appId.replace(/APP-0+/, "APP-");

	const isActive = useMemo(() => !notInRemit, []);

	if (!canUseApplication) {
		return null;
	}

	return (
		<section className={`py-2 flex flex-col lg:flex-row ${!isActive && 'opacity-40'}`}>
			<div
				className="card-bg min-h-56 bg-off-black-400 relative overflow-hidden flex flex-col justify-between before:right-0 before:left-0 before:top-0 before:bottom-0 before:absolute before:z-10 before:bg-no-repeat bg-gradient-to-r from-off-black-900 from-0% to-off-black-400 to-100% lg:w-3/5">
				<img
					src={banner}
					alt="banner"
					className="absolute top-0 left-0 right-0 bottom-0 object-cover w-full h-full"
				/>
				<div className="flex flex-row h-full relative z-10">
					{isActive && <div className="w-1/3">
						<WbStepperNew
							isJointApplicant={isJointApplicant}
							applicationStep={applicationStep}
						/>
					</div>}
					<div className={`${isActive ? 'md:w-2/3' : 'w-full'} flex flex-col justify-end`}>
						<h2 className="p-4 md:px-8 text-white text-4xl font-medium">
							{propertyAddress}
						</h2>
						<div
							aria-labelledby="loan details"
							className="flex justify-between flex-wrap gap-6 md:gap-0 p-4 md:pt-4 md:pb-5 md:px-8"
						>
							{createdDate && (
								<WelcomeLoanItem label="Date started" value={formatDate(createdDate)} />
							)}
							<WelcomeLoanItem label="Reference number" value={trimmedAppId} />
							<WelcomeLoanItem
								label="Property value"
								value={formatPrice(purchasePrice)}
							/>
							<WelcomeLoanItem
								label="Loan amount"
								value={formatPrice(loanAmount)}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="flex flex-col justify-between bg-white lg:w-2/5 p-4 md:p-8">
				<div className="flex justify-end">
					<Status status={isActive ? 'active' : "inactive"} />
				</div>
				<div className="py-4 border-b border-off-black-400">
					<div className="pb-2 text-xl">Borrower</div>
					<div className="flex flex-row justify-between">
						<div className="text-sm">
							<i className="icon-user-1" /> {fullName}
						</div>
					</div>
				</div>
				<div>
					<WbProgress
						applicationStep={applicationStep}
						isJointApplicant={isJointApplicant}
					/>
					<div className="flex flex-row justify-between">
						<Button
							className="rounded"
							text="Resume application"
							variant="primary"
							iconSuffix={<i className="icon-arrow" />}
							handleClick={continueApplication}
						/>
						{/* <Button
							className="rounded"
							text="Need Help?"
							variant="accent"
							iconSuffix={<i className="icon-message" />}
						/> */}
					</div>
				</div>
			</div>
		</section>
	);
};
