import { useMemo } from "react";
import { wbAllSteps, wbJointSteps } from "../components/layout/welcome-back/wb-stepper/WbStepper.config";
import { ApplicationStep } from "@sucasa-finance/origination-trpc";


export type WbStepperOptions = {
	isJointApplicant: boolean;
	applicationStep: ApplicationStep;
}

export const useWbSteps = ({ isJointApplicant, applicationStep }: WbStepperOptions) => {
    const steps = useMemo(() => isJointApplicant ? wbJointSteps : wbAllSteps,
		[isJointApplicant]);

    const currentStepIndex = useMemo(() =>
        applicationStep === ApplicationStep.Submitted
            ? 4
            : steps.findIndex((step) => step.stepType === applicationStep),
        [applicationStep, steps]);

    return useMemo(() => ({
        steps,
        currentStepIndex
    }), [steps, currentStepIndex])
}