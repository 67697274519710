import { useState, useCallback, useEffect } from "react";
import { origination } from "../../services/origination";

export const useApprovals = (applicationId: number) => {
  const [downloadApprovalId, setDownloadApprovalId] = useState<number | null>(null);
  const [approvals] = origination.approvals.getApprovals.useSuspenseQuery({applicationId: Number(applicationId)})
  
  const { data: downloadUrl, refetch } = origination.approvals.getDownloadUrlForApproval.useQuery(
    { approvalId: downloadApprovalId! },
    { enabled: downloadApprovalId !== null }
  );

  const fetchDownloadUrl = useCallback((approvalId: number) => {
    setDownloadApprovalId(approvalId);
  }, []);

  useEffect(() => {
    if (downloadApprovalId !== null) {
      void refetch();
    }
  }, [downloadApprovalId, refetch]);

  useEffect(() => {
    if (downloadUrl?.signedUrl) {
      window.open(downloadUrl.signedUrl, "_blank");
    }
  }, [downloadUrl])

  return {
    approvals,
    fetchDownloadUrl
  }
};