import Cookies from "js-cookie";
import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
import { jwtDecode } from "jwt-decode";
import config from "../config";

export const getPasswordRegex = (
	firstName: string | undefined,
	middleName: string | undefined,
	surname: string | undefined,
	email: string
): RegExp => {
	const names = [firstName, middleName, surname, email.split("@")[0]].filter(
		Boolean
	); // Filter out empty or undefined names and add email username
	const fullName = names.join("|");
	const nameRegex = names.length > 0 ? `(?!.*?(${fullName}))` : ""; // Exclude full name and email username if not provided
	const regexString = `^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\\d)(?=.*?[#?!@$%^&*-])(?!.*?\\s)${nameRegex}.{8,16}$`;
	return new RegExp(regexString, "i");
};
export const PASSWORD_REGEX_8ULNS16 = new RegExp(
	"^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\\d)(?=.*?[#?!@$%^&*-]).{8,16}$",
	"i"
); // Minimum 8 and maximum 16 characters, at least one uppercase letter, one lowercase letter, one number and one special character

export const COMMON_PASSWORDS_BLACKLIST_REGEX =
	/^(?:(?!password|Password|PASSWORD|12345678|87654321|qwertyui|asdfghjk|zxcvbnm|QWERTYUI|ASDFGHJK|ZXCVBNM).)*$/;

export function cn(...inputs: Array<ClassValue>) {
	return twMerge(clsx(inputs));
}

export const getDocumentsRedirect = (JWToken: string) => {
	return `${config.VITE_DOCUMENTS_URL.trim().replace(/\/$/, "")}/redirect?jwtoken=${encodeURIComponent(JWToken)}`;
};

export const getMaxBirthDate = () => {
	return new Date(
		new Date().getFullYear() - 18,
		new Date().getMonth(),
		new Date().getDate()
	);
};

export const getMarketingCookies = () => {
	const utmParameters: Record<
		| "utmCampaign"
		| "utmContent"
		| "utmMedium"
		| "utmSource"
		| "utmTerm"
		| "utmId"
		| "fbclid"
		| "gclid",
		string | undefined
	> = {
		utmCampaign: Cookies.get("utm_campaign"),
		utmContent: Cookies.get("utm_content"),
		utmMedium: Cookies.get("utm_medium"),
		utmSource: Cookies.get("utm_source"),
		utmTerm: Cookies.get("utm_term"),
		utmId: Cookies.get("utm_id"),
		fbclid: Cookies.get("fbclid"),
		gclid: Cookies.get("gclid"),
	};

	return utmParameters;
};

export const auLocalAreaCodeFormatter = (number: string) => {
	if (!number) return number;
	return number.replace(/(\d{4})(\d{3})(\d{3})/, "$1 $2 $3");
};

export const getDecodedToken = (token?: string | null) => {
	try {
		return token ? jwtDecode<{ exp?: number; email?: string; mobile?: string; }>(token) : undefined;
	} catch {
		return;
	}
}
