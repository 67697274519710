import { AustraliaStateOptions } from "../../../utils/BootList";
import GenericInputTeriary from "../../generic-input-teriary/GenericInputTeriary";
import type { AddressParts } from "../../../../services/apis/create-application.schema";
import SelectInputSecondary from "../../select-input-secondary/SelectInputSecondary";

interface ManualAddressProps {
	manualAddressParts: AddressParts;
	onUpdateAddressParts: (addressParts: AddressParts) => void;
	isSuburb: boolean;
}
const ManualAddress = ({
	manualAddressParts,
	isSuburb,
	onUpdateAddressParts,
}: ManualAddressProps) => {
	return (
		<div className="w-full flex flex-col gap-4">
			{!isSuburb && (
				<>
					<div className="flex flex-col md:flex-row gap-4">
						<GenericInputTeriary
							type="text"
							value={manualAddressParts?.unitNumber}
							placeholder="Unit number (if relevant)"
							onChangeHandler={(value) => {
								const updateAddressParts = {
									...manualAddressParts,
								};
								updateAddressParts.unitNumber = value.toString();
								onUpdateAddressParts(updateAddressParts);
							}}
						/>
						<GenericInputTeriary
							type="text"
							value={manualAddressParts?.streetNumber}
							placeholder="Street number"
							required={!isSuburb}
							onChangeHandler={(value) => {
								const updateAddressParts = {
									...manualAddressParts,
								};
								updateAddressParts.streetNumber = value.toString();
								onUpdateAddressParts(updateAddressParts);
							}}
						/>
					</div>
					<GenericInputTeriary
						type="text"
						value={manualAddressParts?.streetName}
						placeholder="Street name"
						required={!isSuburb}
						onChangeHandler={(value) => {
							const updateAddressParts = {
								...manualAddressParts,
							};
							updateAddressParts.streetName = value.toString();
							onUpdateAddressParts(updateAddressParts);
						}}
					/>
					<GenericInputTeriary
						type="text"
						value={manualAddressParts?.streetType}
						placeholder="Street type"
						onChangeHandler={(value) => {
							const updateAddressParts = {
								...manualAddressParts,
							};
							updateAddressParts.streetType = value.toString();
							onUpdateAddressParts(updateAddressParts);
						}}
					/>
				</>
			)}
			<div className="flex flex-col md:flex-row gap-4">
				<GenericInputTeriary
					type="text"
					value={manualAddressParts?.suburb}
					placeholder="Suburb"
					required
					onChangeHandler={(value) => {
						const updateAddressParts = {
							...manualAddressParts,
						};
						updateAddressParts.suburb = value.toString();
						onUpdateAddressParts(updateAddressParts);
					}}
				/>
				<GenericInputTeriary
					type="text"
					value={manualAddressParts?.postalCode}
					placeholder="Postal code"
					required
					onChangeHandler={(value) => {
						const updateAddressParts = {
							...manualAddressParts,
						};
						updateAddressParts.postalCode = value.toString();
						onUpdateAddressParts(updateAddressParts);
					}}
				/>
			</div>
			<SelectInputSecondary
				value={
					manualAddressParts?.stateOptions || {
						id: 0,
						value: "",
						label: "State",
					}
				}
				label=""
				options={AustraliaStateOptions}
				onChangeHandler={(option) => {
					const updateAddressParts = {
						...manualAddressParts,
					};
					updateAddressParts.stateOptions = option;
					updateAddressParts.state = option.value;
					onUpdateAddressParts(updateAddressParts);
				}}
			/>
		</div>
	);
};

export default ManualAddress;
