import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import AuthLayout from "../common/layout/AuthLayout";
import type { FunctionComponent } from "../common/types";
import LoginForm from "../components/forms/login-form/LoginForm";
import { useLogOut } from "../hooks/use-logout";
import useTrackPageViewOnMount from "../hooks/use-track-on-mount";

const Login = (): FunctionComponent => {
	const { clearOrRedirect } = useLogOut();
	const [searchParameters] = useSearchParams();
	const logout = !!searchParameters.get("logout");
	useTrackPageViewOnMount({
		page: "Login",
	});

	useEffect(() => {
		clearOrRedirect(logout);
	}, [clearOrRedirect, logout]);

	return (
		<AuthLayout>
			<section className="lg:max-w-96 flex flex-col justify-center h-full p-4">
				<h1 className="text-primary text-6xl leading-snug font-normal">
					Welcome!
				</h1>
				<p className="font-normal leading-8 mb-10 lg:mb-16">
					Please log in using your Sucasa email and password
				</p>
				<LoginForm />
			</section>
		</AuthLayout>
	);
};

export default Login;
