import { useForm } from "react-hook-form";
import type z from "zod";
import {
	useAppDispatch,
	useAppSelector,
} from "../../../../../hooks/redux-hooks";
import {
	selectCouldBeEligibleForFHB,
	setDependantAges,
	setisRelationStatus,
	setMaritalStatus,
	setNumberOfDependants,
	setResidencyStatus,
	setVisaSubclass,
} from "../../../../../store/slices/form-slice";
import {
	ResidencyStatusOptions,
	isRelationStatusToSecondaryBorrower,
} from "../../../../utils/BootList";
import GenericInput from "../../../generic-input/GenericInput";
import RadioGroup from "../../../radio-group/RadioGroup";
import SelectInput from "../../../select-input/SelectInput";
import DependentInfoForm from "../../../dependents/DependentInput";
import useScrollTopOnMount from "../../../../../hooks/use-scroll-top-on-mount";
import {
	SelectYouAndYourHouseholdForm,
	getYouAndYourHouseholdSchema,
} from "./YouAndYourHouseholdSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import {
	ResidencyStatus,
	SubStep,
} from "../../../../../services/apis/create-application.schema";
import { navigateToStep } from "../../../../../store/slices/stepper-slice";
import FormLayout from "../../../../ui/form/Form";
import { useMarkFormDirty } from "../../../../../hooks/use-mark-form-dirty";
import { maritalStatusOptions } from "./your-household.form-options";

import Button from "../../../../ui/button/Button";
import useTrackPageViewOnMount from "../../../../../hooks/use-track-on-mount";

const DEFAULT_OPTION = {
	id: 0,
	value: "",
	label: "Select an option",
};

const YouAndYourHousehold = () => {
	useScrollTopOnMount();
	const dispatch = useAppDispatch();
	const formState = useAppSelector(SelectYouAndYourHouseholdForm);

	const isJoint = useAppSelector(
		(state) => state.form.applicationType === "Joint"
	);

	const formSchema = getYouAndYourHouseholdSchema(isJoint);
	type schemaType = z.infer<typeof formSchema>;

	const {
		register,
		handleSubmit,
		watch,
		formState: { errors, isDirty },
	} = useForm<typeof formState, unknown, schemaType>({
		resolver: zodResolver(formSchema),
		defaultValues: formState,
	});

	useMarkFormDirty(isDirty);
	useTrackPageViewOnMount({
		page: "Your Details",
		subPage: "You & Your Household",
	});
	const couldBeEligigbleForFHB = useAppSelector((state) =>
		selectCouldBeEligibleForFHB(state, watch("residencyStatus")?.value)
	);

	const maritalStatusFormValue = watch("maritalStatus");
	const maritalStatusValue = maritalStatusFormValue?.value;
	const isDefactoOrMarried =
		maritalStatusValue === "De-Facto" || maritalStatusValue === "Married";

	const residencyStatusFormValue = watch("residencyStatus");

	const shouldShowVisaSubclass = [
		ResidencyStatus.OtherVisa,
		ResidencyStatus.PermanentResident,
	].includes(residencyStatusFormValue?.value as ResidencyStatus);

	const saveData = (data: schemaType) => {
		dispatch(setMaritalStatus(data.maritalStatus));
		dispatch(setisRelationStatus(data.isRelationStatus!));
		dispatch(setResidencyStatus(data.residencyStatus));
		data.visaSubclass && dispatch(setVisaSubclass(Number(data.visaSubclass)));

		if (couldBeEligigbleForFHB) {
			dispatch(
				navigateToStep({
					step: "detailsStep",
					subStep: SubStep.FHBFederalGuarantee,
				})
			);
		} else {
			dispatch(
				navigateToStep({
					step: "loanStep",
					subStep: SubStep.CreateYourAccount,
				})
			);
		}
	};

	return (
		<FormLayout
			header={
				<h1 className="text-primary text-[37.9px] font-normal">
					You & Your Household
				</h1>
			}
			content={
				<div className="flex flex-col gap-9">
					<SelectInput
						name="maritalStatus"
						value={maritalStatusFormValue || DEFAULT_OPTION}
						label="Marital Status"
						options={maritalStatusOptions}
						register={register}
						error={errors.maritalStatus && errors.maritalStatus.message}
					/>
					{isJoint && isDefactoOrMarried && (
						<RadioGroup
							radioOptions={isRelationStatusToSecondaryBorrower}
							legend={`Are you ${maritalStatusValue === "De-Facto" ? "de-facto" : "married"} to the plus one on this application?`}
							register={register}
							error={errors.isRelationStatus && errors.isRelationStatus.message}
						/>
					)}
					<DependentInfoForm
						initialDependents={formState.dependantAges}
						onChange={(dependantAges) => {
							dispatch(setDependantAges(dependantAges));
							dispatch(setNumberOfDependants(dependantAges.length));
						}}
					/>
					<SelectInput
						name="residencyStatus"
						value={residencyStatusFormValue || DEFAULT_OPTION}
						label="What is your Australian residency status?"
						options={ResidencyStatusOptions}
						register={register}
						error={errors.residencyStatus && errors.residencyStatus.message}
					/>
					{shouldShowVisaSubclass && (
						<GenericInput
							name="visaSubclass"
							register={register}
							type="number"
							label="What is your visa subclass number?"
							placeholder="Visa type"
							error={errors.visaSubclass && errors.visaSubclass.message}
						/>
					)}
				</div>
			}
			footer={
				<div
					className="flex items-center justify-between gap-4"
					aria-labelledby="Actions wrapper"
				>
					<Button
						text="Back"
						variant="accent"
						iconPrefix={<i className="icon-arrow rotate-180" />}
						handleClick={() => {
							dispatch(
								navigateToStep({
									step: "detailsStep",
									subStep: SubStep.TellUsAboutYou,
								})
							);
						}}
					/>
					<Button
						text="Next"
						variant="primary"
						iconSuffix={<i className="icon-arrow" />}
					/>
				</div>
			}
			onSubmit={handleSubmit(saveData)}
		/>
	);
};

export default YouAndYourHousehold;
