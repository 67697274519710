import { z } from "zod";
import type { OptionsType } from "../../../../../common/types";
import type { RootState } from "../../../../../store";
import { createSelector } from "@reduxjs/toolkit";
import { ResidencyStatus } from "../../../../../services/apis/create-application.schema";

const YouAndYourHouseholdSchema = z
	.object({
		maritalStatus: z.object({
			id: z.number(),
			value: z.string(),
			label: z.string(),
		}),
		isRelationStatus: z.string().optional(),
		residencyStatus: z.object({
			id: z.number(),
			value: z.string(),
			label: z.string(),
		}),
		visaSubclass: z.number().optional().nullish(),
		numberOfDependants: z
			.number()
			.min(0, "Please enter a valid number of dependants")
			.max(10, "Please enter a valid number of dependants")
			.optional(),
		dependantAges: z
			.array(
				z
					.number()
					.min(0, "Please enter a valid dependant age")
					.max(100, "Please enter a valid dependant age")
			)
			.optional(),
	})
	.superRefine(
		(
			{ maritalStatus, isRelationStatus, residencyStatus, visaSubclass },
			context
		) => {
			if (!residencyStatus || residencyStatus.id === 0) {
				context.addIssue({
					code: z.ZodIssueCode.custom,
					message: `Please select residency status`,
					path: ["residencyStatus"],
				});
			}
			if (
				residencyStatus &&
				[ResidencyStatus.OtherVisa, ResidencyStatus.PermanentResident].includes(
					residencyStatus.value as ResidencyStatus
				) &&
				!visaSubclass
			) {
				context.addIssue({
					code: z.ZodIssueCode.custom,
					message: `Please enter a valid visa subclass`,
					path: ["visaSubclass"],
				});
			}
			if (!maritalStatus || maritalStatus.id === 0) {
				context.addIssue({
					code: z.ZodIssueCode.custom,
					message: `Please select marital status`,
					path: ["maritalStatus"],
				});
			}
			if (
				maritalStatus &&
				(maritalStatus.id === 2 || maritalStatus.id === 3) &&
				(!isRelationStatus || isRelationStatus?.length === 0)
			) {
				context.addIssue({
					code: z.ZodIssueCode.custom,
					message: `Please select relation status`,
					path: ["isRelationStatus"],
				});
			}
		}
	);

export const getYouAndYourHouseholdSchema = (isJoint: boolean) =>
	z
		.object({
			maritalStatus: z.object({
				id: z.number(),
				value: z.string(),
				label: z.string(),
			}),
			isRelationStatus: z.string().optional(),
			residencyStatus: z.object({
				id: z.number(),
				value: z.nativeEnum(ResidencyStatus),
				label: z.string(),
			}),
			visaSubclass: z.number().optional().nullish(),
			numberOfDependants: z
				.number()
				.min(0, "Please enter a valid number of dependants")
				.max(10, "Please enter a valid number of dependants")
				.optional(),
			dependantAges: z
				.array(
					z
						.number()
						.min(0, "Please enter a valid dependant age")
						.max(100, "Please enter a valid dependant age")
				)
				.optional(),
		})
		.superRefine(
			(
				{ maritalStatus, isRelationStatus, residencyStatus, visaSubclass },
				context
			) => {
				if (!residencyStatus || residencyStatus.id === 0) {
					context.addIssue({
						code: z.ZodIssueCode.custom,
						message: `Please select residency status`,
						path: ["residencyStatus"],
					});
				}
				if (
					residencyStatus &&
					[3, 4].includes(residencyStatus.id) &&
					!visaSubclass
				) {
					context.addIssue({
						code: z.ZodIssueCode.custom,
						message: `Please enter a valid visa subclass`,
						path: ["visaSubclass"],
					});
				}
				if (!maritalStatus || maritalStatus.id === 0) {
					context.addIssue({
						code: z.ZodIssueCode.custom,
						message: `Please select marital status`,
						path: ["maritalStatus"],
					});
				}
				if (
					isJoint &&
					maritalStatus &&
					(maritalStatus.id === 2 || maritalStatus.id === 3) &&
					(!isRelationStatus || isRelationStatus?.length === 0)
				) {
					context.addIssue({
						code: z.ZodIssueCode.custom,
						message: `Please select relation status`,
						path: ["isRelationStatus"],
					});
				}
			}
		);

export type YouAndYourHouseholdFormType = z.infer<
	typeof YouAndYourHouseholdSchema
>;
export type YouAndYourHouseholdFormSlice = {
	maritalStatus: OptionsType;
	isRelationStatus?: string;
	residencyStatus: OptionsType;
	visaSubclass?: number;
	numberOfDependants?: number;
	dependantAges?: Array<number>;
};

export const SelectYouAndYourHouseholdForm = createSelector(
	(state: RootState) => state.form,
	(form) => ({
		maritalStatus: form.maritalStatus,
		isRelationStatus: form.isRelationStatus,
		residencyStatus: form.residencyStatus,
		...(form.visaSubclass && { visaSubclass: form.visaSubclass }),
		numberOfDependants: form.numberOfDependants,
		dependantAges: form.dependantAges,
	})
);
