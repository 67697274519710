// import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';

export const useLDFlags = () => {
  // const ldClient = useLDClient();
  // const auth0Login = ldClient?.variation('auth-0-login', true) as boolean;
  // const { auth0Login } = useFlags<{auth0Login: boolean}>();
  const auth0Login = true;


  return {
    auth0Login,
  };
};
