import { useEffect } from "react";
import { useGetApplication } from "./use-get-application";
import { useAppDispatch } from "./redux-hooks";
import { setApplication } from "../store/slices/application-slice";
import { setNotInRemit, setUser } from "../store/slices/auth-slice";
import { setHomeLoanSpecialist } from "../store/slices/home-loan-specialist-slice";

export const useApplicationPolling = () => {
	const dispatch = useAppDispatch();
	const { data } = useGetApplication();

	const userDetails = data?.data?.content?.content?.userDetails;
	const homeLoanSpecialist = data?.data?.content?.content?.homeLoanSpecialist;
	const notInRemit = data?.data?.content?.content?.notInRemit;
	const application = data?.data?.content?.content?.application;

	useEffect(() => {
		if (notInRemit !== undefined && notInRemit !== null) {
			dispatch(setNotInRemit(notInRemit));
		}
		if (userDetails) {
			dispatch(setUser(userDetails));
		}
		if (homeLoanSpecialist) {
			dispatch(setHomeLoanSpecialist(homeLoanSpecialist));
		}
		if (application) {
			dispatch(setApplication(application));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userDetails, homeLoanSpecialist, notInRemit, application]);
};
