import type { OptionsType } from "../../common/types";
import type { AddressHistoryFormSlice } from "../forms/steps/your-details/two-years-address-history/TwoYearsAddressHistorySchema";
import type { FullTimeEmployeeFormSlice } from "../forms/steps/your-finances/your-employment/full-time-employee/FullTimeEmployee.Schema";
// import { ApplicationPayload } from "../../services/apis/create-application.schema";
// import type { FormState } from "../../store/slices/form-slice";
export const generateYearOptions = (
	numberYears: number
): Array<OptionsType> => {
	return Array.from({ length: numberYears }, (_, index) => ({
		id: index + 1,
		label: `${index + 1} Year${index > 0 ? "s" : ""}`,
		value: `${index + 1}`,
	}));
};
export const convertDate = (dateString: string): Date => {
	const parts = dateString.split("/");
	return new Date(
		// @ts-expect-error asd
		// eslint-disable-next-line no-constant-binary-expression
		+parts[2] ?? 0,
		+(parts[1] ?? 1) - 1,
		// @ts-expect-error asd
		// eslint-disable-next-line no-constant-binary-expression
		+parts[0] ?? 1
	);
};
export const generateMonthsOptions = (
	numberMonths: number
): Array<OptionsType> => {
	return Array.from({ length: numberMonths }, (_, index) => ({
		id: index + 1,
		label: `${index + 1} Month${index > 0 ? "s" : ""}`,
		value: `${index + 1}`,
	}));
};

const calculateAgeFromBirthday = (birthday: string): number => {
	const today = new Date();
	const parts = birthday.split("/");
	const birthDate = new Date(
		// @ts-expect-error asd
		// eslint-disable-next-line no-constant-binary-expression
		+parts[2] ?? 0,
		+(parts[1] ?? 1) - 1,
		// @ts-expect-error asd
		// eslint-disable-next-line no-constant-binary-expression
		+parts[0] ?? 1
	); // Adjust for zero-based month index and provide default values
	const diffInMilliseconds = today.getTime() - birthDate.getTime();
	const age = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24 * 365.25)); // Using 365.25 to account for leap years
	return age;
};

export const isLoanTermValid = (
	birthday: string,
	loanTermYears: number
): boolean => {
	const age = calculateAgeFromBirthday(birthday);
	const totalYears = Number(age) + Number(loanTermYears);
	const isValid = totalYears <= 70;
	return isValid;
};

export const sumYearsAndMonths = (years: number, months: number): number => {
	const totalYears = years + months / 12;
	return totalYears;
};

export const getCurrentDateString = () => {
	return new Date().toISOString().split("T")[0];
};

export const calculateTotalYears = (date: Date): number => {
	const currentDate = new Date();
	const diffMilliseconds = currentDate.getTime() - date.getTime();
	const millisecondsPerYear = 1000 * 60 * 60 * 24 * 365.25; // Account for leap years

	return diffMilliseconds / millisecondsPerYear;
};

export const totalMonthsBetweenDates = (
	start: string,
	end?: string
): number => {
	const startDate = start ? convertDate(start) : new Date();
	const endDate = end ? convertDate(end) : new Date();
	const monthsBetween =
		(endDate.getFullYear() - startDate.getFullYear()) * 12 +
		(endDate.getMonth() - startDate.getMonth());
	return monthsBetween;
};

export const sumYearsMonths = (
	addressHistories: Array<AddressHistoryFormSlice>
): number => {
	// Filter out entries with empty livingStartDate
	const validAddressHistories = addressHistories.filter(
		(history) => history.livingStartDate?.trim() !== ""
	);

	if (validAddressHistories.length === 0) {
		return 0;
	}

	const earliestStartDate = validAddressHistories.reduce(
		(earliest, current) => {
			const currentStartDateString = current.livingStartDate?.trim() ?? "";
			const currentStartDate = currentStartDateString
				? new Date(currentStartDateString.split("/").reverse().join("-"))
				: new Date();
			const earliestDateString = earliest?.trim() ?? "";
			const earliestDate = earliestDateString
				? new Date(earliestDateString.split("/").reverse().join("-"))
				: new Date();
			return currentStartDate < earliestDate
				? current.livingStartDate
				: earliest;
		},
		""
	);

	const totalMonths = totalMonthsBetweenDates(earliestStartDate);

	return totalMonths;
};

export const calculateTotalMonths = (startDate: Date, endDate: Date) => {
	const months =
		(endDate.getFullYear() - startDate.getFullYear()) * 12 +
		(endDate.getMonth() - startDate.getMonth());
	return Math.abs(months);
};

export const totalMonthsBetweenDatesTemp = (
	start: string,
	end?: string
): number => {
	const startDate = new Date(start);
	const endDate = end ? new Date(end) : new Date(); // Use current date if end date is not provided

	if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
		throw new TypeError(
			`Invalid date(s) provided. Start: ${start}, End: ${end}`
		);
	}

	const yearsDifference = endDate.getFullYear() - startDate.getFullYear();
	const monthsDifference = endDate.getMonth() - startDate.getMonth();
	const monthsBetween = yearsDifference * 12 + monthsDifference;

	return monthsBetween;
};

export const sumYearsMonthsEmploymentHistory = (
	employeeHistories: Array<FullTimeEmployeeFormSlice>
): number => {
	if (employeeHistories.length === 0) {
		return 0;
	}

	const formattedStartDates = employeeHistories
		.map((history) => history.jobStartDate?.trim())
		.filter(Boolean)
		.map((date) => {
			const [day, month, year] = date.split("/");
			return new Date(`${year}-${month}-${day}`);
		});

	if (formattedStartDates.length === 0) {
		return 0;
	}

	// Ensure earliestStartDate is always defined by checking if formattedStartDates is not empty

	const earliestStartDate = formattedStartDates.reduce((earliest, current) => {
		return current < earliest ? current : earliest;
	}, formattedStartDates[0] as Date); // Use the first date as the initial value to avoid invalid date

	// Since we now ensure earliestStartDate is always defined, the following checks are not strictly necessary
	// but are kept for demonstration. In practice, you should remove unnecessary checks.
	if (earliestStartDate) {
		if (earliestStartDate.toISOString().split("T")[0] === undefined) {
			return 0;
		}
		const totalMonths = totalMonthsBetweenDatesTemp(
			earliestStartDate.toISOString().split("T")[0]!
		);

		return totalMonths;
	} else {
		// Handle the unexpected case where earliestStartDate is undefined
		// This block should theoretically never be reached due to the checks above
		console.error("No valid start dates found.");
		return 0;
	}
};

export const isChromeOrMozillaMobile = () => {
	const userAgent = navigator.userAgent;

	return (
		/Chrome|Mozilla/.test(userAgent) &&
		/android|iphone|ipad|ipod/i.test(userAgent)
	);
};


export const formatCurrency = (value: number): string => {
  return new Intl.NumberFormat('en-AU', { 
    style: 'currency', 
    currency: 'AUD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  }).format(value).replace(/\.00$/, '');
};