import { type FC, useEffect } from "react";
import {
	useAppDispatch,
	useAppSelector,
} from "../../../../../hooks/redux-hooks";
import Button from "../../../../ui/button/Button";
import {
	setHasAdverseCreditHistory,
	setHasAdverseCreditHistoryExplanation,
	setHasAnticipateChangesToCircumstances,
	setHasAnticipateChangesToCircumstancesExplanation,
	setHasOffshoreLiabilities,
	setHasOffshoreLiabilitiesExplanation,
} from "../../../../../store/slices/form-slice";
import { zodResolver } from "@hookform/resolvers/zod";

import { useForm } from "react-hook-form";
import {
	type YourCircumstancesFormSlice,
	YourCircumstancesSchema,
	selectYourCircumstances,
	type YourCircumstancesFormType,
} from "./YourCircumstancesSchema";
import {
	HasAdverseCreditHistoryOptions,
	HasOffshoreLiabilitiesOptions,
} from "../../../../utils/BootList";
import RadioGroup from "../../../radio-group/RadioGroup";
import TextArea from "../../../text-area/TextArea";
import { navigateToJointStep } from "../../../../../store/slices/joint-stepper-slice";
import { navigateToStep } from "../../../../../store/slices/stepper-slice";
import {
	LoanPurpose,
	Step,
	SubStep,
} from "../../../../../services/apis/create-application.schema";
import FormLayout from "../../../../ui/form/Form";
import { setFormLoading } from "../../../../../store/slices/loader-slice";
import { setToast } from "../../../../../store/slices/toast-slice";
import useTrackPageViewOnMount from "../../../../../hooks/use-track-on-mount";
import { useUpdateApplicationCircumstances } from "../../../../../services/apis/origination/application";
import { selectPurposeOfApplication } from "../../../../../store/slices/origination-slice";
import { useNavigate } from "react-router-dom";
import { NamedRoute } from "../../../../utils/NamedRoutes";
import { selectFormApplicationId } from "../../../../../store/slices/application-slice";
import { captureException } from "../../../../../services/sentry";

type YourCircumstancesProps = {
	isJoint?: boolean;
}
const YourCircumstances: FC<YourCircumstancesProps> = ({
	isJoint,
}) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const applicationId = useAppSelector(selectFormApplicationId);
	const formState = useAppSelector(selectYourCircumstances);
	const [updateCircumstances, { isLoading }] = useUpdateApplicationCircumstances(isJoint ?? false);
	const purposeOfApplication = useAppSelector(selectPurposeOfApplication);
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm({
		resolver: zodResolver(YourCircumstancesSchema),
		defaultValues: formState,
	});

	useTrackPageViewOnMount({
		page: "Your Finances",
		subPage: "Your Circumstances",
	});
	const saveData = async (rawData: YourCircumstancesFormSlice) => {
		// Assuming validation ensures all values are defined, we can assert the type here - this is a hack because the zod inference with the form schema is not working
		const data: YourCircumstancesFormType =
			rawData as YourCircumstancesFormType;
		dispatch(setHasAdverseCreditHistory(data.hasAdverseCreditHistory));
		dispatch(
			setHasAdverseCreditHistoryExplanation(
				data.hasAdverseCreditHistory === "true"
					? data.hasAdverseCreditHistoryExplanation
					: ""
			)
		);
		dispatch(setHasOffshoreLiabilities(data.hasOffshoreLiabilities));
		dispatch(
			setHasOffshoreLiabilitiesExplanation(
				data.hasOffshoreLiabilities === "true"
					? data.hasOffshoreLiabilitiesExplanation
					: ""
			)
		);
		dispatch(
			setHasAnticipateChangesToCircumstances(
				data.hasAnticipateChangesToCircumstances
			)
		);
		dispatch(
			setHasAnticipateChangesToCircumstancesExplanation(
				data.hasAnticipateChangesToCircumstances === "true"
					? data.hasAnticipateChangesToCircumstancesExplanation
					: ""
			)
		);
		if (
			isJoint ||
			formState.loanPurpose === LoanPurpose.Refinance ||
			(purposeOfApplication as LoanPurpose) === LoanPurpose.Refinance
		) {
			try {
				let applicationStep = Step.Submitted;
				let applicationSubStep = SubStep.Submitted;
				if (!applicationId) {
					applicationStep = Step.Confirmation;
					applicationSubStep = SubStep.Completed;
				}
				await updateCircumstances({
					applicationStep,
					applicationSubStep,
					offShoreLiabilities: {
						indicated: rawData.hasOffshoreLiabilities === "true",
						description:
							rawData.hasOffshoreLiabilities === "true"
								? rawData.hasOffshoreLiabilitiesExplanation
								: "",
					},
					adverseCreditHistory: {
						indicated: rawData.hasAdverseCreditHistory === "true",
						description:
							rawData.hasAdverseCreditHistory === "true"
								? rawData.hasAdverseCreditHistoryExplanation
								: "",
					},
					anticipateChanges: {
						indicated: rawData.hasAnticipateChangesToCircumstances === "true",
						description:
							rawData.hasAnticipateChangesToCircumstances === "true"
								? rawData.hasAnticipateChangesToCircumstancesExplanation
								: "",
					},
				}).unwrap();

				if (applicationId) {
					if (isJoint) {
						dispatch(navigateToJointStep({step: "uploadStep", subStep: SubStep.Completed}))
					} else {
						dispatch(
							navigateToStep({
								step: "uploadStep",
								subStep: SubStep.Completed,
							})
						);
					}
					navigate(NamedRoute.verification, {state: {applicationId}});
				} else {
					if (isJoint) {
						dispatch(
							navigateToJointStep({
								step: "confirmationStep",
								subStep: SubStep.Completed,
							})
						);
					} else {
						dispatch(
							navigateToStep({
								step: "confirmationStep",
								subStep: SubStep.Completed,
							})
						);
					}
				}
			} catch (error) {
				captureException(error as Error);
				dispatch(
					setToast({
						open: true,
						type: "error",
						title: "Error",
						description: "An error occurred while saving.",
					})
				);
			}
		} else {
			dispatch(
				navigateToStep({
					step: "financeStep",
					subStep: SubStep.YourHelp,
				})
			);
		}
	};
	useEffect(() => {
		dispatch(setFormLoading(isLoading));
		return () => {
			dispatch(setFormLoading(false));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);
	const header = (
		<h1
			className="text-primary text-[37.9px] font-normal"
			aria-labelledby="Your Circumstances"
		>
			Your Circumstances
		</h1>
	);
	const content = (
		<div className="flex flex-col gap-9">
			<div className="flex flex-col gap-2">
				<RadioGroup
					name="hasOffshoreLiabilities"
					selectedValue={formState.hasOffshoreLiabilities}
					radioOptions={HasOffshoreLiabilitiesOptions}
					legend="Do you have any offshore liabilities not disclosed above?"
					register={register}
					error={
						errors.hasOffshoreLiabilities &&
						errors.hasOffshoreLiabilities.message
					}
				/>
				{watch("hasOffshoreLiabilities") === "true" && (
					<TextArea
						name="hasOffshoreLiabilitiesExplanation"
						register={register}
						resize
						placeholder="Please provide explanation"
						error={
							errors.hasOffshoreLiabilitiesExplanation &&
							errors.hasOffshoreLiabilitiesExplanation.message
						}
					/>
				)}
			</div>
			<div className="flex flex-col gap-2">
				<RadioGroup
					name="hasAdverseCreditHistory"
					selectedValue={formState.hasAdverseCreditHistory}
					radioOptions={HasAdverseCreditHistoryOptions}
					legend="Are you aware of any adverse credit history that is on your credit file? Eg. Missed repayments more than 30 days, hardship or defaults."
					register={register}
					error={
						errors.hasAdverseCreditHistory &&
						errors.hasAdverseCreditHistory.message
					}
				/>
				{watch("hasAdverseCreditHistory") === "true" && (
					<TextArea
						name="hasAdverseCreditHistoryExplanation"
						register={register}
						resize
						placeholder="Please provide explanation"
						error={
							errors.hasAdverseCreditHistoryExplanation &&
							errors.hasAdverseCreditHistoryExplanation.message
						}
					/>
				)}
			</div>
			<div className="flex flex-col gap-2">
				<RadioGroup
					name="hasAnticipateChangesToCircumstances"
					selectedValue={formState.hasAnticipateChangesToCircumstances}
					radioOptions={HasOffshoreLiabilitiesOptions}
					legend="Do you anticipate any changes to your current circumstances that may impact your ability to meet your loan repayments?"
					register={register}
					error={
						errors.hasAnticipateChangesToCircumstances &&
						errors.hasAnticipateChangesToCircumstances.message
					}
				/>
				{watch("hasAnticipateChangesToCircumstances") === "true" && (
					<TextArea
						name="hasAnticipateChangesToCircumstancesExplanation"
						register={register}
						resize
						placeholder="Please provide explanation"
						error={
							errors.hasAnticipateChangesToCircumstancesExplanation &&
							errors.hasAnticipateChangesToCircumstancesExplanation.message
						}
					/>
				)}
			</div>
		</div>
	);
	const footer = (
		<div className="flex items-center justify-end gap-4">
			<Button
				text="Next"
				variant="primary"
				iconSuffix={<i className="icon-arrow" />}
			/>
		</div>
	);
	return (
		<FormLayout
			header={header}
			content={content}
			footer={footer}
			onSubmit={handleSubmit(saveData)}
		/>
	);
};

export default YourCircumstances;
