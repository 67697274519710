/* eslint-disable camelcase */

import { z } from "zod";
import { createSelector } from "@reduxjs/toolkit";
import type { RootState } from "../../../../../store";

export const YourHelpSchema = z.object({
	idealPurchasePriceFrom: z
		.number({
			invalid_type_error:
				"This field is required, please enter a value greater than 0",
			required_error:
				"This field is required, please enter a value greater than 0",
		})
		.min(1, "This field is required, please enter a value greater than 0"),
	idealPurchasePriceTo: z
		.number({
			invalid_type_error:
				"This field is required, please enter a value greater than 0",
			required_error:
				"This field is required, please enter a value greater than 0",
		})
		.min(1, "This field is required, please enter a value greater than 0"),
	idealMonthlyRepaymentAmount: z
		.number({
			invalid_type_error:
				"This field is required, please enter a value greater than 0",
			required_error:
				"This field is required, please enter a value greater than 0",
		})
		.min(1, "This field is required, please enter a value greater than 0"),
	idealDepositAmount: z
		.number({
			invalid_type_error:
				"This field is required, please enter a value greater than 0",
			required_error:
				"This field is required, please enter a value greater than 0",
		})
		.min(1, "This field is required, please enter a value greater than 0"),
	idealSavingAmount: z
		.number({
			invalid_type_error: "This field is required, please enter 0 if none",
			required_error: "This field is required, please enter 0 if none",
		})
		.min(0, "This field is required, please enter 0 if none"),
});

export type YourHelpFormType = z.infer<typeof YourHelpSchema>;

export type YourHelpFormSlice = {
	idealPurchasePriceFrom?: number;
	idealPurchasePriceTo?: number;
	idealMonthlyRepaymentAmount?: number;
	idealDepositAmount?: number;
	idealSavingAmount?: number;
	hasOffshoreLiabilities: string;
	hasOffshoreLiabilitiesExplanation?: string;
	hasAdverseCreditHistory: string;
	hasAdverseCreditHistoryExplanation?: string;
	hasAnticipateChangesToCircumstances: string;
	hasAnticipateChangesToCircumstancesExplanation?: string;
};

export const selectYourHelp = createSelector(
	(state: RootState) => state.form,
	(form): YourHelpFormSlice => ({
		idealPurchasePriceFrom: form.idealPurchasePriceFrom,
		idealPurchasePriceTo: form.idealPurchasePriceTo,
		idealMonthlyRepaymentAmount: form.idealMonthlyRepaymentAmount,
		idealDepositAmount: form.idealDepositAmount,
		idealSavingAmount: form.idealSavingAmount,
		hasOffshoreLiabilities: form.hasOffshoreLiabilities,
		hasOffshoreLiabilitiesExplanation: form.hasOffshoreLiabilitiesExplanation,
		hasAdverseCreditHistory: form.hasAdverseCreditHistory,
		hasAdverseCreditHistoryExplanation: form.hasAdverseCreditHistoryExplanation,
		hasAnticipateChangesToCircumstances:
			form.hasAnticipateChangesToCircumstances,
		hasAnticipateChangesToCircumstancesExplanation:
			form.hasAnticipateChangesToCircumstancesExplanation,
	})
);
