/* eslint-disable camelcase */

import { z } from "zod";

export const EmployeeIncomeSchema = z
	.object({
		annualBaseSalaryAmount: z
			.number({
				invalid_type_error: "This field is required",
			})
			.min(1, { message: "Annual base salary must be greater than 0" }),
		overtimeOrShiftAllowance: z
			.string({
				required_error: "This field is required",
				invalid_type_error: "This field is required",
			})
			.min(1, { message: "This field is required" }),
		allowanceAmount: z
			.number({
				invalid_type_error: "This field is required",
			})
			.optional(),
		allowanceForSixMonths: z.boolean().optional(),
		overtimeAmount: z
			.number({ invalid_type_error: "This field is required" })
			.optional(),
		overtimeForSixMonths: z.boolean().optional(),
		commissionsOrBonuses: z
			.string({
				required_error: "This field is required",
				invalid_type_error: "This field is required",
			})
			.min(1, { message: "This field is required" }),
		commissionsLastTwelveMonthsPercentage: z
			.number({ invalid_type_error: "This field is required" })
			.optional(),
		commissionsPriorTwelveMonthsPercentage: z
			.number({ invalid_type_error: "This field is required" })
			.optional(),
		bonusesLastTwelveMonthsPercentage: z
			.number({ invalid_type_error: "This field is required" })
			.optional(),
		bonusesPriorTwelveMonthsPercentage: z
			.number({ invalid_type_error: "This field is required" })
			.optional(),
		otherIncomeAllowed: z
			.string({
				required_error: "This field is required",
				invalid_type_error: "This field is required",
			})
			.min(1, { message: "This field is required" }),
		otherIncomes: z
			.object({
				incomeType: z.object({
					id: z.number(),
					label: z.string(),
					value: z.string(),
				}),
				frequency: z.object({
					id: z.number(),
					label: z.string(),
					value: z.string(),
				}),
				estimatedAmount: z.number(),
			})
			.array()
			.optional(),
	})
	.superRefine(
		(
			{
				overtimeOrShiftAllowance,
				allowanceAmount,
				overtimeAmount,
				commissionsOrBonuses,
				commissionsLastTwelveMonthsPercentage,
				commissionsPriorTwelveMonthsPercentage,
				bonusesLastTwelveMonthsPercentage,
				bonusesPriorTwelveMonthsPercentage,
			},
			context
		) => {
			if (overtimeOrShiftAllowance === "true") {
				if (allowanceAmount === undefined) {
					context.addIssue({
						code: z.ZodIssueCode.custom,
						message: "Please enter zero if none",
						path: ["allowanceAmount"],
					});
				}
				if (overtimeAmount === undefined) {
					context.addIssue({
						code: z.ZodIssueCode.custom,
						message: "Please enter zero if none",
						path: ["overtimeAmount"],
					});
				}
			}
			if (commissionsOrBonuses === "true") {
				if (commissionsLastTwelveMonthsPercentage === undefined) {
					context.addIssue({
						code: z.ZodIssueCode.custom,
						message: "Please enter zero if none",
						path: ["commissionsLastTwelveMonthsPercentage"],
					});
				}
				if (commissionsPriorTwelveMonthsPercentage === undefined) {
					context.addIssue({
						code: z.ZodIssueCode.custom,
						message: "Please enter zero if none",
						path: ["commissionsPriorTwelveMonthsPercentage"],
					});
				}
				if (bonusesLastTwelveMonthsPercentage === undefined) {
					context.addIssue({
						code: z.ZodIssueCode.custom,
						message: "Please enter zero if none",
						path: ["bonusesLastTwelveMonthsPercentage"],
					});
				}
				if (bonusesPriorTwelveMonthsPercentage === undefined) {
					context.addIssue({
						code: z.ZodIssueCode.custom,
						message: "Please enter zero if none",
						path: ["bonusesPriorTwelveMonthsPercentage"],
					});
				}
			}
		}
	);
