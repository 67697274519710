import type { OptionsType } from "../../../../../common/types";

export const maritalStatusOptions: Array<OptionsType> = [
	{
		id: 1,
		label: "Single",
		value: "Single",
	},
	{
		id: 2,
		label: "Married",
		value: "Married",
	},
	{
		id: 3,
		label: "De Facto",
		value: "De-Facto",
	},
	{
		id: 4,
		label: "Divorced",
		value: "Divorced",
	},
	{
		id: 5,
		label: "Separated",
		value: "Separated",
	},
];
