import type { FC } from "react";
import { useCaseSummary } from "../../../hooks/origination/use-case-summary";
import { Details } from "../../ui/details/Details";

type CaseSummaryContentsProps = {
  applicationId: number;
}

export const CaseSummaryContents: FC<CaseSummaryContentsProps> = ({applicationId}) => {
  const {data, active, waiting, finished} = useCaseSummary(applicationId);
  return (
    <>
      <Details
        isDefaultOpen={active.length > 0}
        disabled={active.length === 0}
        summary={<div className="text-lg font-bold py-4">Outstanding</div>}
        summarySuffix={<span className="bg-peach-60 text-peach-100 w-8 h-8 text-center flex items-center justify-center">{active.length}</span>}
      >
        <ul className="list-none py-2 border-t border-off-black-200">
          {active.map(item => (
            <li key={item.id} className="flex flex-row flex-nowrap py-1">
              <i className="icon-upload text-peach-100 pr-2" />
              <span>{item.title}</span>
            </li>
          ))}
        </ul>
      </Details>

      <Details
        className="border-t border-off-black-200"
        disabled={waiting.length === 0}
        summary={<div className="text-lg font-bold py-4">Under Review</div>}
        summarySuffix={<span className="bg-lavender-100 w-8 h-8 text-center flex items-center justify-center">{waiting.length}</span>}
      >
        <ul className="list-none py-2 border-t border-off-black-200">
          {waiting.map(item => (
            <li key={item.id} className="flex flex-row flex-nowrap py-1">
              <i className="icon-progress text-off-black-600 pr-2" />
              <span>{item.title}</span>
            </li>
          ))}
        </ul>
      </Details>

      <Details
        isDefaultOpen={finished.length > 0 && finished.length === data.length}
        className="border-t border-off-black-200"
        disabled={finished.length === 0}
        summary={<div className="text-lg font-bold py-4">Complete</div>}
        summarySuffix={<span className="bg-grass-hopper-400 w-8 h-8 text-center flex items-center justify-center">{finished.length}</span>}
      >
        <ul className="list-none py-2 border-t border-off-black-200">
          {finished.map(item => (
            <li key={item.id} className="flex flex-row flex-nowrap py-1">
              <i className="icon-check text-grass-hopper-900 pr-2" />
              <span>{item.title}</span>
            </li>
          ))}
        </ul>
      </Details>
    </>
  );
}
