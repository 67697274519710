import { useMemo, type FC } from "react";
import { origination } from "../../../services/origination";
import HintCard from "../../ui/hint-card/HintCard";
import { Details } from "../../ui/details/Details";

export interface JointApplicantProps {
    applicationId: number;
}

export const JointApplicant: FC<JointApplicantProps> = ({applicationId}) => {
    const [application] = origination.application.getApplicationById.useSuspenseQuery({ applicationId });
    const otherApplicant = useMemo(() => application.applicants.find(applicant => applicant.userId !== Number(application.currentUserId)), [application]);
    if (!otherApplicant) {
        return null;
    }
    return <HintCard className="mt-4 px-8">
        <Details 
            summary={
                <div className="py-2 text-lg font-bold flex items-center">
                        <i className="icon-user text-2xl font-normal" />&nbsp;
                        <span>Joint Applicant</span>
                </div>
            }
            isIconSuffix
        >
            <ul className="list-none border-t border-off-black-200 pt-1 divide-y divide-off-black-200">
                <li className="py-2 flex flex-row">
                    <i className="icon-check text-grass-hopper-900 pr-2" />
                    <span className="flex-grow overflow-hidden text-ellipsis whitespace-nowrap">{otherApplicant.firstName} {otherApplicant.lastName}</span>
                </li>
                <li className="py-2 flex flex-row">
                    <i className="icon-email pr-2" />
                    <span className="flex-grow overflow-hidden text-ellipsis whitespace-nowrap">{otherApplicant.email}</span>
                </li>
                <li className="py-2 flex flex-row">
                    <i className="icon-mobile pr-2" />
                    <span className="flex-grow overflow-hidden text-ellipsis whitespace-nowrap">{otherApplicant.phone}</span>
                </li>
            </ul>
        </Details>
    </HintCard>;
};
