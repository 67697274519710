import type { FieldValues, Path, UseFormRegister } from "react-hook-form";
import ErrorMessage from "../error-message/ErrorMessage";
import type { ReactNode } from "react";

export type CheckboxInputType<TFieldValues extends FieldValues = FieldValues> =
	{
		label: string | ReactNode;
		name: keyof TFieldValues;
		checked: boolean;
		register: UseFormRegister<TFieldValues>;
		size?: "small" | "medium" | "large";
		checkPosition?: "start" | "center";
		error?: string;
	};

const CheckboxInput = <TFieldValues extends FieldValues = FieldValues>({
	label,
	name,
	checked,
	register,
	size = "large",
	checkPosition = "center",
	error,
}: CheckboxInputType<TFieldValues>) => {
	const optionId = `checkbox-option--${Math.random().toString(36).slice(2, 9)}`;

	// Adjust the label class to handle inline elements correctly
	const labelClass = `flex-1 ${size === "small" ? "text-sm" : "text-lg"} flex items-center transition-all cursor-pointer`;

	return (
		<>
			<div
				className={`w-full flex ${
					checkPosition === "start" ? "items-start" : "items-center"
				} gap-4`}
			>
				<div className="flex-shrink-0">
					<input
						type="checkbox"
						id={optionId}
						defaultChecked={checked}
						className={`form-checkbox w-4 h-4 accent-primary bg-transparent rounded-none border-black ${checkPosition === "start" && "mt-[0.12rem]"}`}
						{...register(name as Path<TFieldValues>)}
					/>
				</div>
				<label htmlFor={optionId} className={labelClass}>
					{label}
				</label>
			</div>
			{!!error && (
				<div className="-mt-8 ml-8">
					<ErrorMessage message={error} />
				</div>
			)}
		</>
	);
};

export default CheckboxInput;
