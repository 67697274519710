import { useMemo } from "react";
import Layout from "../common/layout/Layout";
import type { FunctionComponent } from "../common/types";
import JointBorrowerAboutYouForm from "../components/forms/joint-borrower-forms/about-you-form/AboutYouForm";
import ConfirmationStep from "../components/forms/steps/confirmation/ConfirmationStep";
import TwoYearsAddressHistory from "../components/forms/steps/your-details/two-years-address-history/TwoYearsAddressHistory";
import YourAssets from "../components/forms/steps/your-finances/your-assets/YourAssets";
import YourBusinessIncome from "../components/forms/steps/your-finances/your-business-income/YourBusinessIncome";
import YourCircumstances from "../components/forms/steps/your-finances/your-circumstances/YourCircumstances";
import YourEmployeeIncome from "../components/forms/steps/your-finances/your-employee-income/YourEmployeeIncome";
import YourEmployment from "../components/forms/steps/your-finances/your-employment/YourEmployment";
import YourHelp from "../components/forms/steps/your-finances/your-help/YourHelp";
import YourLiabilities from "../components/forms/steps/your-finances/your-liabilities/YourLiabilities";
import YourHomeLanding from "../components/forms/steps/your-home/YourHomeLanding";
import HintsColumn from "../components/layout/hints-column/HintsColumn";
import { Stepper } from "../components/layout/stepper/Stepper";
import { useAppSelector } from "../hooks/redux-hooks";
import { SubStep } from "../services/apis/create-application.schema";
import { useGetApplicationById } from "../hooks/origination/use-get-application-by-id";

const JointBorrowerHome = (): FunctionComponent => {
	const { applicationId } = useGetApplicationById();
	console.log('applicationId', applicationId);

	const jointStepperState = useAppSelector((state) => state.jointStepper);
	const selectedSubStep = useMemo(() =>
			jointStepperState.selectedSubStep === SubStep.TellUsAboutYou ? SubStep.JointBorrowerAboutYou : jointStepperState.selectedSubStep,
		[jointStepperState.selectedSubStep]);
	return (
		<Layout
			leftColumn={<Stepper />}
			rightColumn={<HintsColumn />}
		>
			{(jointStepperState.steps?.["detailsStep"] || jointStepperState.steps?.["details"]) &&
				(jointStepperState.steps?.["detailsStep"]?.isCurrent || jointStepperState.steps?.["details"]?.isCurrent) && <>
					{[SubStep.JointBorrowerAboutYou, 'aboutYou'].includes(selectedSubStep) && <JointBorrowerAboutYouForm />}
					{[SubStep.TwoYearsAddressHistory, 'address'].includes(selectedSubStep) && <TwoYearsAddressHistory isJoint={true} />}
					{[SubStep.Welcome, SubStep.BeforeWeGetStarted, SubStep.Empty, 'invited', undefined].includes(selectedSubStep)
						&& <YourHomeLanding isJoint={true} />}
				</>}
			{(jointStepperState.steps?.["financeStep"] || jointStepperState.steps?.["finances"]) &&
				(jointStepperState.steps?.["financeStep"]?.isCurrent || jointStepperState.steps?.["finances"]?.isCurrent) && <>
					{[SubStep.YourEmployment, 'employment'].includes(selectedSubStep) && <YourEmployment isJoint={true} />}
					{[SubStep.YourEmployeeIncome, 'employeeIncome'].includes(selectedSubStep) && <YourEmployeeIncome isJoint={true} />}
					{[SubStep.YourBusinessIncome, 'businessIncome'].includes(selectedSubStep) && <YourBusinessIncome isJoint={true} />}
					{[SubStep.YourAssets, 'assets'].includes(selectedSubStep) && <YourAssets isJoint={true} />}
					{[SubStep.YourLiabilities, 'liabilities'].includes(selectedSubStep) && <YourLiabilities isJoint={true} />}
					{[SubStep.YourCircumstances, 'circumstances'].includes(selectedSubStep) && <YourCircumstances isJoint={true} />}
					{[SubStep.YourHelp, 'help'].includes(selectedSubStep) && <YourHelp isJoint={true} />}
				</>}
			{/* Retained confirmation step for v1 applications */}
			{jointStepperState.steps?.["confirmationStep"] &&
				jointStepperState.steps["confirmationStep"].isCurrent && (
					<ConfirmationStep />
				)}
		</Layout>
	);
};

export default JointBorrowerHome;
