import { legacy } from "../../api";
import { origination } from "../../origination";
import { useMapSubmitRequest } from "./mapping/submit";
import { useOriginationCallback, useOriginationMutationResult } from "./mutation";
import { emptyOkResponse } from "./mapping/response";

export function useSubmit() {
    const [invokeOriginal, originalMutation] = legacy.useSubmitMutation();
    const mutation = origination.application.setApplicationStep.useMutation();
    const mapRequest = useMapSubmitRequest();
    const create = useOriginationCallback(
        invokeOriginal,
        mutation.mutateAsync,
        mapRequest,
        emptyOkResponse
    )
    const mutationResult = useOriginationMutationResult(originalMutation, mutation);
    return [
        create,
        mutationResult
    ] as const;
}