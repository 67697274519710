import type { FC } from "react";
import { formatTime } from "../../../../../helpers/format-time";

type ChatMessageFileProps = {
	name: string;
	size?: string;
	time?: string;
	// caseId?: number;
}
export const ChatMessageFile: FC<ChatMessageFileProps> = ({
	name,
	size,
	time,
	// caseId,
}) => {
	// const getFile = useCallback(() => {
	// 	// TODO[matej]: download file for caseId
	// 	console.log(`downloadUrl is missing for ${caseId}`);
	// }, [caseId]);

	return (<div className="flex flex-row justify-end items-end w-full gap-4">
		<div className="p-4 bg-lavender-200 rounded">
			<div className="flex flex-row items-center">
				<div className="flex items-center gap-2 text-base font-normal">
					<i className="icon-document text-2xl" />
					<span>{name}</span>
				</div>
			</div>
			{size && <span className="text-xs text-off-black-600">{size}</span>}
			{time && <div className="flex flex-row justify-end pt-1 text-xs text-off-black-600">
				<span>{formatTime(time)}</span>
			</div>}
		</div>
	</div>)
};
