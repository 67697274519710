import transparentLogoGif from "../../../assets/logoTransparent.gif";
import logoGif from "../../../assets/logo.gif";
import type { FC, PropsWithChildren } from "react";

export interface LoaderProps extends PropsWithChildren {
    isLoading?: boolean;
    isFullScreen?: boolean;
    showCreateAccountLoaderText?: boolean;
}

export const Loader: FC<LoaderProps> = ({ isLoading = true, isFullScreen = false, showCreateAccountLoaderText, children }) => {
    if (!isLoading) {
        return null;
    }

	return (
        <div className={`fixed inset-0 ${isFullScreen ? "loader-bg" : "bg-white bg-opacity-65"} z-10 text-white flex items-center justify-center text-[37.9px]`}>
            <div className="flex flex-col items-center justify-center gap-1 md:gap-4">
                {!isFullScreen && <img src={transparentLogoGif} alt="Sucasa Logo" width={80} />}
                {isFullScreen && <img src={logoGif} alt="Sucasa Logo" width={50} />}
                {(showCreateAccountLoaderText || children) && (<div
                    className="m-4 bg-white text-off-black-900 p-4 md:p-8 border border-lavender flex flex-col md:items-center justify-center gap-1 md:gap-2">
                    {children || (
                        <>
                            <h1 className="text-xl text-center md:text-[37.9px] mb-2 md:mb-8">
                                Please wait while we setup your account
                            </h1>
                            <p className="text-sm text-center md:text-[21.33px]">
                                We are verifying the details on your loan application. This may take up
                                to one minute.
                            </p>
                            <p className="text-sm text-center md:text-[21.33px]">
                                Please don't close this window until this is completed.
                            </p>
                        </>
                    )}
                </div>)}
            </div>
        </div>
    );
};