import type { FieldErrors, FieldValues, UseFormRegister } from "react-hook-form";
import {
	loanMeetsNeedsAndObjectivesOptions,
	loanTermInYearsOptions,
} from "../../../../../../utils/BootList";
import RadioGroup from "../../../../../radio-group/RadioGroup";
import type { BuyingWorkflowLoanFormType } from "../../BuyingWorkflow";
import LoanStructureFC from "../../loan-structure/LoanStructure";
import type { NewHomeLoanFormSlice } from "../../../refinancing-workflow/new-home-loan/NewHomeLoanSchema";

type LoanTermNoType<TFieldValues extends FieldValues = FieldValues> = {
	register: UseFormRegister<TFieldValues>;
	showQuestion: Partial<BuyingWorkflowLoanFormType | NewHomeLoanFormSlice>;
	errors?: FieldErrors<BuyingWorkflowLoanFormType | NewHomeLoanFormSlice>
};

const LoanTermNoFC = <TFieldValues extends FieldValues = FieldValues>({
	register,
	showQuestion,
	errors,
}: LoanTermNoType<TFieldValues>) => {
	return (
		<>
			<RadioGroup
				radioOptions={loanTermInYearsOptions}
				legend={`What is your maximum preferred loan term?`}
				register={register}
				selectedValue={showQuestion.loanTermInYears}
				error={errors && errors.loanTermInYears && errors.loanTermInYears.message}
			/>
			{showQuestion.loanTermInYears && (
				<>
					<LoanStructureFC />
					<RadioGroup
						radioOptions={loanMeetsNeedsAndObjectivesOptions}
						legend="Does this meet your needs and objectives?"
						register={register}
						error={errors && errors.meetsNeedsAndObjectives && errors.meetsNeedsAndObjectives.message}
					/>
				</>
			)}
		</>
	);
};

export default LoanTermNoFC;
