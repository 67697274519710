import {
	EmploymentType,
	type UpdateIncomeRequest,
	type EmploymentInformationType,
	type IncomeDetailsType,
	IncomeType,
	FrequencyType,
} from "../../services/apis/update-income.schema";
import type { RootState } from "../index";
import { createSelector } from "@reduxjs/toolkit";
import type { FormState } from "../slices/form-slice";
import { calculateTotalMonths } from "../../components/utils/helpers";
import { SubStep, Step } from "../../services/apis/create-application.schema";
import { captureException } from "../../services/sentry";

// Where an employment type is known to not have an income type, use undefined
const employmentTypeToIncomeBaseTypeMapping: Record<EmploymentType, IncomeType | undefined> = {
	[EmploymentType.FullTimeEmploymentPAYG]: IncomeType.EmploymentAndSalary,
	[EmploymentType.Contractor]: IncomeType.ContractIncomeFixed,
	[EmploymentType.PartTimeEmploymentPAYG]: IncomeType.EmploymentAndSalary,
	[EmploymentType.Casual]: IncomeType.CasualIncome,
	[EmploymentType.SelfEmployedBusinessOwner]: IncomeType.SelfEmployedBusinessOwner,
	[EmploymentType.NotEmployed]: undefined,
}
export const employmentIncomeTypes = Object.values(employmentTypeToIncomeBaseTypeMapping).filter(Boolean);
export const employmentTypeToIncomeTypeMapping: Record<string, IncomeType | undefined> = employmentTypeToIncomeBaseTypeMapping;

export const getEmployemntInformation = (form: FormState) => {
	const employmentInformations: Array<EmploymentInformationType> = [];
	if (
		[
			EmploymentType.FullTimeEmploymentPAYG,
			EmploymentType.Contractor,
			EmploymentType.PartTimeEmploymentPAYG,
			EmploymentType.Casual,
		].includes(form.currentEmploymentType.value as EmploymentType)
	) {
		const employmentHistory = form.employmentHistory;
		if (employmentHistory) {
			for (const [index, employment] of employmentHistory.entries()) {
				const currentWorkingMonth = calculateTotalMonths(
					new Date(employment.jobStartDate),
					employment.jobEndDate ? new Date(employment.jobEndDate) : new Date()
				);
				let industryExperienceInMonths = 0;
				if (currentWorkingMonth < 12) {
					industryExperienceInMonths =
						Number(employment.industryMonths?.value || 0) +
						Number(employment.industryYears?.value || 0) * 12;
				}
				const currentEmploymentInformation: EmploymentInformationType = {
					isCurrent: index === 0,
					employerName: employment?.employerName,
					jobTitle: employment?.jobTitle,
					jobStartDate: employment?.jobStartDate,
					jobEndDate: employment?.jobEndDate,
					inProbation: employment?.inProbation === "true",
					industryExperienceInMonths: industryExperienceInMonths,
					currentEmploymentType: form.currentEmploymentType
						.value as EmploymentType,
				};
				employmentInformations.push({ ...currentEmploymentInformation });
			}
		}
	}
	if (
		(form.currentEmploymentType.value as EmploymentType) ===
		EmploymentType.SelfEmployedBusinessOwner
	) {
		const selfEmployedBusinessOwner: EmploymentInformationType = {
			isCurrent: true,
			businessIncorporationDate: form.businessIncorporationDate,
			employerName: form.businessName,
			jobStartDate: form.businessStartDate,
			industryExperienceInMonths: undefined,
			currentEmploymentType: form.currentEmploymentType.value as EmploymentType,
			nameOfTheBusiness: form.businessName,
			abn: form.businessABN?.toString(),
			inProbation: false,
		};
		employmentInformations.push({ ...selfEmployedBusinessOwner });
	}
	if (
		(form.currentEmploymentType.value as EmploymentType) ===
		EmploymentType.NotEmployed
	) {
		const casualOrNotEmployed: EmploymentInformationType = {
			isCurrent: true,
			currentEmploymentType: form.currentEmploymentType.value as EmploymentType,
			inProbation: false,
		};
		employmentInformations.push({ ...casualOrNotEmployed });
	}
	return employmentInformations.length > 0 ? employmentInformations : undefined;
};

export const mapEmploymentTypeToIncomeType = (
	employmentType: EmploymentType
) => {
	const incomeType = employmentTypeToIncomeBaseTypeMapping[employmentType];
	if (!incomeType) {
		captureException(new Error(`Unknown employment type: ${employmentType}`));
	}
	return incomeType ?? IncomeType.OtherIncome;
};

export const getIncomeDetails = (form: FormState) => {
	const incomeDetails: Array<IncomeDetailsType> = [];
	if (
		[
			EmploymentType.FullTimeEmploymentPAYG,
			EmploymentType.Contractor,
			EmploymentType.PartTimeEmploymentPAYG,
			EmploymentType.Casual,
		].includes(form.currentEmploymentType.value as EmploymentType)
	) {
		if (form.annualBaseSalaryAmount && form.annualBaseSalaryAmount > 0) {
			const baseSalary: IncomeDetailsType = {
				incomeType: mapEmploymentTypeToIncomeType(
					form.currentEmploymentType.value as EmploymentType
				),
				currentIncomeAmount: form.annualBaseSalaryAmount,
				priorIncomeAmount: 0,
				receivedForLastSixMonths: false,
				frequency: FrequencyType.Annually,
			};
			incomeDetails.push({ ...baseSalary });
		}
		if (form.overtimeOrShiftAllowance === "true") {
			if (form.allowanceAmount && form.allowanceAmount > 0) {
				const shiftAllowance: IncomeDetailsType = {
					incomeType: IncomeType.OvertimeShiftAllowance,
					currentIncomeAmount: form.allowanceAmount,
					priorIncomeAmount: 0,
					receivedForLastSixMonths: form.allowanceForSixMonths,
					frequency: FrequencyType.Other,
				};
				incomeDetails.push({ ...shiftAllowance });
			}
			if (form.overtimeAmount && form.overtimeAmount > 0) {
				const overtime: IncomeDetailsType = {
					incomeType: IncomeType.Overtime,
					currentIncomeAmount: form.overtimeAmount,
					priorIncomeAmount: 0,
					receivedForLastSixMonths: form.overtimeForSixMonths,
					frequency: FrequencyType.Other,
				};
				incomeDetails.push({ ...overtime });
			}
		}
		if (form.commissionsOrBonuses === "true") {
			if (
				(form.commissionsLastTwelveMonthsPercentage &&
					form.commissionsLastTwelveMonthsPercentage > 0) ||
				(form.commissionsPriorTwelveMonthsPercentage &&
					form.commissionsPriorTwelveMonthsPercentage > 0)
			) {
				const commissionsOrBonuses: IncomeDetailsType = {
					incomeType: IncomeType.Commission,
					currentIncomeAmount: form.commissionsLastTwelveMonthsPercentage || 0,
					priorIncomeAmount: form.commissionsPriorTwelveMonthsPercentage || 0,
					receivedForLastSixMonths: false,
					frequency: FrequencyType.Annually,
				};
				incomeDetails.push({ ...commissionsOrBonuses });
			}
			if (
				(form.bonusesLastTwelveMonthsPercentage &&
					form.bonusesLastTwelveMonthsPercentage > 0) ||
				(form.bonusesPriorTwelveMonthsPercentage &&
					form.bonusesPriorTwelveMonthsPercentage > 0)
			) {
				const bonuses: IncomeDetailsType = {
					incomeType: IncomeType.Bonus,
					currentIncomeAmount: form.bonusesLastTwelveMonthsPercentage || 0,
					priorIncomeAmount: form.bonusesPriorTwelveMonthsPercentage || 0,
					receivedForLastSixMonths: false,
					frequency: FrequencyType.Annually,
				};
				incomeDetails.push({ ...bonuses });
			}
		}
	}
	if (
		(form.currentEmploymentType.value as EmploymentType) ===
			EmploymentType.SelfEmployedBusinessOwner &&
		form.netProfitAfterTax &&
		form.netProfitAfterTax > 0
	) {
		const netProfitAfterTax: IncomeDetailsType = {
			incomeType: IncomeType.SelfEmployedBusinessOwner,
			currentIncomeAmount: form.netProfitAfterTax,
			priorIncomeAmount: 0,
			receivedForLastSixMonths: false,
			frequency: FrequencyType.Annually,
		};
		incomeDetails.push({ ...netProfitAfterTax });
	}
	if (
		form.otherIncomeAllowed === "true" &&
		form.otherIncomes &&
		form.otherIncomes.length > 0
	) {
		for (const income of form.otherIncomes) {
			const otherIncome: IncomeDetailsType = {
				incomeType: income.incomeType.value as IncomeType, // Use the IncomeType enum here
				currentIncomeAmount: income.estimatedAmount!,
				priorIncomeAmount: 0,
				receivedForLastSixMonths: false,
				frequency: income.frequency.value as FrequencyType,
			};
			incomeDetails.push({ ...otherIncome });
		}
	}

	return incomeDetails;
};
export const selectUpdateIncomePayload = createSelector(
	[(state: RootState) => state.form],
	(form) => {
		const payload: Partial<UpdateIncomeRequest> = {
			currentEmploymentType: form.currentEmploymentType.value as EmploymentType,
			employmentInformation: getEmployemntInformation(form),
			receivedOverTimeOrAllowance: form.overtimeOrShiftAllowance === "true",
			receivedCommissionOrBonus: form.commissionsOrBonuses === "true",
			hasAnyOtherIncome: form.otherIncomeAllowed === "true",
			incomeDetails: getIncomeDetails(form),
			jointBorrowerIncome: form.jointBorrowerIncome,
			applicationStep: Step.YourFinances,
			applicationSubStep: SubStep.YourHouseholdExpenses,
		};
		// Filter out undefined, null, or "" values
		Object.keys(payload).forEach((key) => {
			const payloadKey = key as keyof typeof payload;
			if (
				payload[payloadKey] === undefined ||
				payload[payloadKey] === null ||
				Number.isNaN(payload[payloadKey])
			) {
				delete payload[payloadKey];
			}
		});
		return payload as UpdateIncomeRequest;
	}
);
