import { useMemo } from "react";
import Layout from "../common/layout/Layout";
import ConfirmationStep from "../components/forms/steps/confirmation/ConfirmationStep";
import FineDetailsAboutYou from "../components/forms/steps/your-details/fine-details-about-you/FineDetailsAboutYou";
import JointApplicantInvite from "../components/forms/steps/your-details/joint-applicant-invite/JointApplicantInvite";
import TwoYearsAddressHistory from "../components/forms/steps/your-details/two-years-address-history/TwoYearsAddressHistory";
import YourAssets from "../components/forms/steps/your-finances/your-assets/YourAssets";
import YourBusinessIncome from "../components/forms/steps/your-finances/your-business-income/YourBusinessIncome";
import YourCircumstances from "../components/forms/steps/your-finances/your-circumstances/YourCircumstances";
import YourEmployeeIncome from "../components/forms/steps/your-finances/your-employee-income/YourEmployeeIncome";
import YourEmployment from "../components/forms/steps/your-finances/your-employment/YourEmployment";
import YourHelp from "../components/forms/steps/your-finances/your-help/YourHelp";
import YourHouseHoldExpenses from "../components/forms/steps/your-finances/your-household-expenses/YourHouseHoldExpenses";
import YourLiabilities from "../components/forms/steps/your-finances/your-liabilities/YourLiabilities";
import HintsColumn from "../components/layout/hints-column/HintsColumn";
import { Stepper } from "../components/layout/stepper/Stepper";
import { useAppSelector } from "../hooks/redux-hooks";
import NotInRemit from "./NotInRemit";
import { SubStep } from "../services/apis/create-application.schema";
import YourJointBorrowerIncome from "../components/forms/steps/your-finances/your-joint-borrower-income/YourJointBorrowerIncome";
import { useGetApplicationById } from "../hooks/origination/use-get-application-by-id";

const Home = () => {
	const { applicationId } = useGetApplicationById();
	console.log('applicationId', applicationId);

	const stepperState = useAppSelector((state) => state.stepper);
	const selectedSubStep = useMemo(() =>
			stepperState.selectedSubStep === SubStep.TellUsAboutYou ? SubStep.FineDetailsAboutYou : stepperState.selectedSubStep,
		[stepperState.selectedSubStep]);

	return (
		<Layout
			leftColumn={<Stepper />}
			rightColumn={<HintsColumn />}
		>
			{stepperState.steps?.["detailsStep"] &&
				stepperState.steps["detailsStep"].isCurrent && <>
					{selectedSubStep === SubStep.FineDetailsAboutYou && <FineDetailsAboutYou />}
					{selectedSubStep === SubStep.TwoYearsAddressHistory && <TwoYearsAddressHistory />}
					{selectedSubStep === SubStep.JointApplicantInvite && <JointApplicantInvite />}
					{selectedSubStep === SubStep.NotInRemit && <NotInRemit />}
				</>}
			{stepperState.steps?.["financeStep"] &&
				stepperState.steps["financeStep"].isCurrent && <>
					{selectedSubStep === SubStep.YourEmployment && <YourEmployment />}
					{selectedSubStep === SubStep.YourEmployeeIncome && <YourEmployeeIncome />}
					{selectedSubStep === SubStep.YourBusinessIncome && <YourBusinessIncome />}
					{selectedSubStep === SubStep.YourJointBorrowerIncome && <YourJointBorrowerIncome />}
					{selectedSubStep === SubStep.YourHouseholdExpenses && <YourHouseHoldExpenses />}
					{selectedSubStep === SubStep.YourAssets && <YourAssets />}
					{selectedSubStep === SubStep.YourLiabilities && <YourLiabilities />}
					{selectedSubStep === SubStep.YourCircumstances && <YourCircumstances />}
					{selectedSubStep === SubStep.YourHelp && <YourHelp />}
				</>}
			{/* retained confirmation step for v1 applications */}
			{stepperState.steps?.["confirmationStep"] &&
				stepperState.steps["confirmationStep"].isCurrent && (
					<ConfirmationStep />
				)}
		</Layout>
	);
};

export default Home;
