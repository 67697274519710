import type { FC } from "react";
import ErrorMessage from "../../../../error-message/ErrorMessage";

type TotalSavingsType = {
	label?: string;
	value?: number;
	errorMessage?: string;
};

const TotalSavings: FC<TotalSavingsType> = ({
	label,
	value = 0,
	errorMessage,
}) => {
	const hasDecimals = value % 1 !== 0;
	return (
		<>
			<section className="p-4 border border-primary text-primary flex flex-col md:flex-row md:items-center justify-between gap-2 leading-8 text-[21.33px] font-medium">
				<p>{label}</p>
				<span>
					$
					{value?.toLocaleString(undefined, {
						minimumFractionDigits: hasDecimals ? 2 : 0,
						maximumFractionDigits: hasDecimals ? 2 : 0,
					})}
				</span>
			</section>
			{errorMessage && (
				<ErrorMessage
					message={errorMessage}
					className="-mt-6 md:-mt-8 leading-4"
				/>
			)}
		</>
	);
};

export default TotalSavings;
