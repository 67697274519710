import { type FC, useMemo } from "react";
import HintCard from "../hint-card/HintCard";
import CircularProgressBar from "../circular-progress-bar/CircularProgressBar";
import { useAppSelector } from "../../../hooks/redux-hooks";
import { selectTotalEmploymentHistory } from "../../../store/slices/form-slice";

interface EmploymentHistoryHintParameters {
	percentage?: number;
	totalMonths?: number;
}
export const EmploymentHistoryHint: FC<EmploymentHistoryHintParameters> = ({
	percentage: percentageParameter,
	totalMonths: totalMonthsParameter
}) => {
	const totalMonthsEmploymentHistory = useAppSelector(
		selectTotalEmploymentHistory
	);
	const percentageEmploymentHistory = (totalMonthsEmploymentHistory / 24) * 100;

	const percentage = useMemo(() =>
		percentageParameter === undefined ? percentageEmploymentHistory : percentageParameter,
		[percentageEmploymentHistory, percentageParameter]);
	const totalMonths = useMemo(() =>
		totalMonthsParameter === undefined ? totalMonthsEmploymentHistory : totalMonthsParameter,
		[totalMonthsEmploymentHistory, totalMonthsParameter]);

	return (<HintCard
		icon={
			<CircularProgressBar
				percentage={percentage >= 100 ? 100 : percentage}
			/>
		}
		text={
			<>
				<div>
					<span
						className={`${totalMonths >= 24 && "text-grass-hopper-900"}`}
					>
						{totalMonths}/
					</span>
					24 months
				</div>
				<span className="font-medium text-sm leading-6">
					We'll need your employment history for the past 24 months, 
					including all places you have worked during that period.
				</span>
			</>
		}
	/>);
}
