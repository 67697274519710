import { type FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
	useAppDispatch,
	useAppSelector,
} from "../../../../../hooks/redux-hooks";
import Button from "../../../../ui/button/Button";
import {
	setIdealDepositAmount,
	setIdealMonthlyRepaymentAmount,
	setIdealPurchasePriceFrom,
	setIdealPurchasePriceTo,
	setIdealSavingAmount,
} from "../../../../../store/slices/form-slice";

import {
	type YourHelpFormSlice,
	YourHelpSchema,
	selectYourHelp,
	type YourHelpFormType,
} from "./YourHelpSchema";
import { navigateToStep } from "../../../../../store/slices/stepper-slice";
import { navigateToJointStep } from "../../../../../store/slices/joint-stepper-slice";

import {
	Step,
	SubStep,
} from "../../../../../services/apis/create-application.schema";
import { setFormLoading } from "../../../../../store/slices/loader-slice";
import { setToast } from "../../../../../store/slices/toast-slice";
import FormLayout from "../../../../ui/form/Form";
import DollarInput from "../../../dollar-input/DollarInput";
import useTrackPageViewOnMount from "../../../../../hooks/use-track-on-mount";
import { useUpdateApplicationCircumstances } from "../../../../../services/apis/origination/application";
import { selectFormApplicationId } from "../../../../../store/slices/application-slice";
import { NamedRoute } from "../../../../utils/NamedRoutes";
import { captureException } from "../../../../../services/sentry";

type YourHelpProps = { isJoint?: boolean };

const YourHelp: FC<YourHelpProps> = ({ isJoint }) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const applicationId = useAppSelector(selectFormApplicationId);
	const formState = useAppSelector(selectYourHelp);
	const [updateCircumstances, { isLoading }] = useUpdateApplicationCircumstances(isJoint ?? false);
	const {
		control,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm({
		resolver: zodResolver(YourHelpSchema),
		defaultValues: formState,
	});

	useTrackPageViewOnMount({
		page: "Your Finances",
		subPage: "Tailor Solution",
	});

	const backToPreviousState = (): void => {
		if (isJoint) {
			dispatch(
				navigateToJointStep({
					step: "financeStep",
					subStep: SubStep.YourCircumstances,
				})
			);
		} else {
			dispatch(
				navigateToStep({
					step: "financeStep",
					subStep: SubStep.YourCircumstances,
				})
			);
		}
	};
	const saveData = async (rawData: YourHelpFormSlice) => {
		// Assuming validation ensures all values are defined, we can assert the type here - this is a hack because the zod inference with the form schema is not working
		const data: YourHelpFormType = rawData as YourHelpFormType;
		dispatch(setIdealPurchasePriceFrom(data.idealPurchasePriceFrom));
		dispatch(setIdealPurchasePriceTo(data.idealPurchasePriceTo));
		dispatch(setIdealMonthlyRepaymentAmount(data.idealMonthlyRepaymentAmount));
		dispatch(setIdealDepositAmount(data.idealDepositAmount));
		dispatch(setIdealSavingAmount(data.idealSavingAmount));
		try {
			let applicationStep = Step.Submitted;
			let applicationSubStep = SubStep.Submitted;
			if (!applicationId) {
				applicationStep = Step.Confirmation;
				applicationSubStep = SubStep.Completed;
			}
			await updateCircumstances({
				applicationStep,
				applicationSubStep,
				offShoreLiabilities: {
					indicated: formState.hasOffshoreLiabilities === "true",
					description:
						formState.hasOffshoreLiabilities === "true"
							? formState.hasOffshoreLiabilitiesExplanation
							: "",
				},
				adverseCreditHistory: {
					indicated: formState.hasAdverseCreditHistory === "true",
					description:
						formState.hasAdverseCreditHistory === "true"
							? formState.hasAdverseCreditHistoryExplanation
							: "",
				},
				anticipateChanges: {
					indicated: formState.hasAnticipateChangesToCircumstances === "true",
					description:
						formState.hasAnticipateChangesToCircumstances === "true"
							? formState.hasAnticipateChangesToCircumstancesExplanation
							: "",
				},
				idealPurchasePriceRange: {
					fromAmount: data.idealPurchasePriceFrom,
					toAmount: data.idealPurchasePriceTo,
				},
				idealMonthlyRepaymentMaximum: data.idealMonthlyRepaymentAmount,
				depositSizeForContribution: data.idealDepositAmount,
				savingsEachMonth: data.idealSavingAmount,
			}).unwrap();

			if (applicationId) {
				if (isJoint) {
					dispatch(navigateToJointStep({step: "uploadStep", subStep: SubStep.Completed}))
				} else {
					dispatch(
						navigateToStep({
							step: "uploadStep",
							subStep: SubStep.Completed,
						})
					);
				}
				navigate(NamedRoute.verification, {state: {applicationId}});
			} else {
				if (isJoint) {
					dispatch(
						navigateToJointStep({
							step: "confirmationStep",
							subStep: SubStep.Completed,
						})
					);
				} else {
					dispatch(
						navigateToStep({
							step: "confirmationStep",
							subStep: SubStep.Completed,
						})
					);
				}
			}
		} catch (error) {
			captureException(new Error("Error saving help details"), {data: {error}});
			dispatch(
				setToast({
					open: true,
					type: "error",
					title: "Error",
					description: "An error occurred while saving.",
				})
			);
		}
	};

	useEffect(() => {
		dispatch(setFormLoading(isLoading));
		return () => {
			dispatch(setFormLoading(false));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);
	const header = (
		<h1
			className="text-primary text-[37.9px] font-normal"
			aria-labelledby="Your Help"
		>
			Help us tailor some solutions for you
		</h1>
	);
	const content = (
		<div className="flex flex-col gap-9">
			<div className="flex flex-col gap-2">
				<h4 className="text-primary text-[21.33px] font-normal">
					What’s your ideal purchase price range?
				</h4>
				<div className="flex flex-col md:flex-row gap-4">
					<DollarInput
						name="idealPurchasePriceFrom"
						control={control}
						type="numeric"
						value={formState.idealPurchasePriceFrom}
						error={
							errors.idealPurchasePriceFrom &&
							errors.idealPurchasePriceFrom.message
						}
						label=""
						placeholder="From amount"
						iconPrefix={<i className="icon-dollar" />}
						onValueChange={(value) => {
							setValue("idealPurchasePriceFrom", value);
						}}
					/>
					<DollarInput
						name="idealPurchasePriceTo"
						control={control}
						type="numeric"
						value={formState.idealPurchasePriceTo}
						error={
							errors.idealPurchasePriceTo && errors.idealPurchasePriceTo.message
						}
						label=""
						placeholder="To amount"
						iconPrefix={<i className="icon-dollar" />}
						onValueChange={(value) => {
							setValue("idealPurchasePriceTo", value);
						}}
					/>
				</div>
			</div>
			<DollarInput
				name="idealMonthlyRepaymentAmount"
				control={control}
				type="numeric"
				value={formState.idealMonthlyRepaymentAmount}
				error={
					errors.idealMonthlyRepaymentAmount &&
					errors.idealMonthlyRepaymentAmount.message
				}
				label="Ideally, what's a monthly repayment you'd like to stay below?"
				placeholder="Repayment amount"
				iconPrefix={<i className="icon-dollar" />}
				onValueChange={(value) => {
					setValue("idealMonthlyRepaymentAmount", value);
				}}
			/>
			<DollarInput
				name="idealDepositAmount"
				control={control}
				type="numeric"
				value={formState.idealDepositAmount}
				error={errors.idealDepositAmount && errors.idealDepositAmount.message}
				label="What size deposit do you think you'll want to contribute towards the purchase (including deposit, stamp duty and fees)?"
				placeholder="Deposit amount"
				iconPrefix={<i className="icon-dollar" />}
				onValueChange={(value) => {
					setValue("idealDepositAmount", value);
				}}
			/>
			<DollarInput
				name="idealSavingAmount"
				control={control}
				type="numeric"
				value={formState.idealSavingAmount}
				error={errors.idealSavingAmount && errors.idealSavingAmount.message}
				label="How much are you saving every month?"
				placeholder="Savings amount"
				iconPrefix={<i className="icon-dollar" />}
				onValueChange={(value) => {
					setValue("idealSavingAmount", value);
				}}
			/>
		</div>
	);
	const footer = (
		<footer className="flex items-center justify-between gap-4">
			<Button
				text="Back"
				variant="accent"
				iconPrefix={<i className="icon-arrow rotate-180" />}
				handleClick={backToPreviousState}
				isDisabled={isLoading}
			/>

			<Button
				text="Next"
				variant="primary"
				iconSuffix={<i className="icon-arrow" />}
				isDisabled={isLoading}
			/>
		</footer>
	);
	return (
		<>
			<FormLayout
				header={header}
				content={content}
				footer={footer}
				onSubmit={handleSubmit(saveData)}
			/>
		</>
	);
};

export default YourHelp;
