import { z } from "zod";
import type { OptionsType } from "../../../../../../common/types";
import {
	LoanFeature,
	type LoanOnProperty,
	ReasonForRefinancing,
} from "../../../../../../services/apis/create-application.schema";
import type { RootState } from "../../../../../../store";
import { createSelector } from "@reduxjs/toolkit";

export const NewHomeLoanSchema = z
	.object({
		additionalBorrowingAmount: z.number().optional(),
		additionalFundsReason: z.string().optional(),
		sameBorrowersAsExistingLoan: z.union([
			z.boolean(),
			z.string().transform((value) => value === "true"),
		]),
		loanPreference: z.object({
			id: z.number(),
			label: z.string(),
			value: z.string(),
		}),
		loanTerm: z.enum(["yes", "no"]),
		loanTermInYears: z.union([z.number(), z.string().transform(Number)]),
		meetsNeedsAndObjectives: z.enum(["true", "false"]),
		requestedLoanRefinancingFeatures: z.array(
			z.nativeEnum(ReasonForRefinancing)
		),
		requestedLoanRefinancingFeaturesOther: z.string().optional(),
		requestedLoanBuyingFeatures: z.array(z.nativeEnum(LoanFeature)).optional(),
		requestedLoanBuyingFeaturesOther: z.string().optional(),
	})
	.superRefine(
		(
			{
				additionalBorrowingAmount,
				additionalFundsReason,
				loanPreference,
				meetsNeedsAndObjectives,
				requestedLoanRefinancingFeatures,
				requestedLoanRefinancingFeaturesOther,
				requestedLoanBuyingFeatures,
				requestedLoanBuyingFeaturesOther,
				loanTermInYears,
			},
			context
		) => {
			if (
				additionalBorrowingAmount &&
				additionalBorrowingAmount > 0 &&
				(!additionalFundsReason || additionalFundsReason.length === 0)
			) {
				context.addIssue({
					code: z.ZodIssueCode.custom,
					message: `Please state reason for additional funds`,
					path: ["additionalFundsReason"],
				});
			}
			if (!loanPreference || loanPreference.id === 0) {
				context.addIssue({
					code: z.ZodIssueCode.custom,
					message: `Please select you loan preference`,
					path: ["loanPreference"],
				});
			}
			if (
				!requestedLoanRefinancingFeatures ||
				requestedLoanRefinancingFeatures.length === 0
			) {
				context.addIssue({
					code: z.ZodIssueCode.custom,
					message: `Please select at least one reason for refinancing`,
					path: ["requestedLoanRefinancingFeatures"],
				});
			}
			if (
				requestedLoanRefinancingFeatures &&
				requestedLoanRefinancingFeatures.includes(ReasonForRefinancing.Other) &&
				(!requestedLoanRefinancingFeaturesOther ||
					requestedLoanRefinancingFeaturesOther?.length === 0)
			) {
				context.addIssue({
					code: z.ZodIssueCode.custom,
					message: `Please state reason for other`,
					path: ["requestedLoanRefinancingFeaturesOther"],
				});
			}
			if (meetsNeedsAndObjectives === "false") {
				if (
					!requestedLoanBuyingFeatures ||
					requestedLoanBuyingFeatures.length === 0
				) {
					context.addIssue({
						code: z.ZodIssueCode.custom,
						message: `Please select at least one feature you require in a loan`,
						path: ["requestedLoanBuyingFeatures"],
					});
				}
				if (
					requestedLoanBuyingFeatures &&
					requestedLoanBuyingFeatures.includes(LoanFeature.Other) &&
					(!requestedLoanBuyingFeaturesOther ||
						requestedLoanBuyingFeaturesOther?.length === 0)
				) {
					context.addIssue({
						code: z.ZodIssueCode.custom,
						message: `Please state reason for other`,
						path: ["requestedLoanBuyingFeaturesOther"],
					});
				}
			}
			if (!loanTermInYears) {
				context.addIssue({
					code: z.ZodIssueCode.custom,
					message: `Please select loan terms`,
					path: ["loanTermInYears"],
				});
			}
		}
	);

export type NewHomeLoanFormType = z.infer<typeof NewHomeLoanSchema>;

export type NewHomeLoanFormSlice = {
	additionalBorrowingAmount: number;
	additionalFundsReason: string;
	sameBorrowersAsExistingLoan?: boolean;
	requestedLoanRefinancingFeaturesOther?: string;
	loanPreference?: OptionsType;
	loanTerm: string;
	loanTermInYears?: 30 | 25 | 20;
	meetsNeedsAndObjectives?: string;
	requestedLoanBuyingFeatures: Array<LoanFeature>;
	requestedLoanRefinancingFeatures: Array<ReasonForRefinancing>;
	requestedLoanBuyingFeaturesOther?: string;
	currentLoanBalance?: number;
	loansOnProperty?: Array<LoanOnProperty>;
};

export const selectNewHomeLoanForm = createSelector(
	(state: RootState) => state.form,
	(form): NewHomeLoanFormSlice => ({
		additionalBorrowingAmount: form.additionalBorrowingAmount,
		additionalFundsReason: form.additionalFundsReason,
		sameBorrowersAsExistingLoan: form.sameBorrowersAsExistingLoan,
		loanPreference: form.loanPreference,
		loanTerm: form.loanTerm,
		loanTermInYears: form.loanTermInYears,
		meetsNeedsAndObjectives: form.meetsNeedsAndObjectives,
		requestedLoanRefinancingFeatures: form.requestedLoanRefinancingFeatures,
		requestedLoanRefinancingFeaturesOther:
			form.requestedLoanRefinancingFeaturesOther,
		requestedLoanBuyingFeatures: form.requestedLoanBuyingFeatures,
		requestedLoanBuyingFeaturesOther: form.requestedLoanBuyingFeaturesOther,
		loansOnProperty: form.loansOnProperty,
	})
);

export const selectPropertyValuation = createSelector(
	(state: RootState) => state.form,
	(form) => form.propertyValuation
);
