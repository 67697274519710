import { Box } from "./Box";
import Button from "../../../../ui/button/Button";
import AddressSearch from "../../../address-search/AddressSearch";
import { useState } from "react";
import { origination } from "../../../../../services/origination";
import type { AddressHistoryItem } from "../../../../../services/apis/update-details.schema";
import { useAppDispatch } from "../../../../../hooks/redux-hooks";
import { setFormLoading } from "../../../../../store/slices/loader-slice";

export const BoxUnconditional = ({applicationId}: {applicationId: number}) => {
	const [isAddressSelected, setIsAddressSelected] = useState(false);
	const [address, setAddress] = useState<AddressHistoryItem | null>(null);
	const dispatch = useAppDispatch();
	const utils = origination.useUtils()

	const {mutateAsync} = origination.application.updatePropertyAddress.useMutation();
	

	return (
		<Box className="flex flex-col gap-4">
			<h2 className="text-2xl">Signed a contract of sale?</h2>
			<span className="text-off-black-800">Please enter the address of the property you’ve purchased</span>
			<div>
				<AddressSearch
					id={`addressHistory`}
					options={{
						types: ["address"],
						componentRestrictions: {
							country: "au",
						},
					}}
					name="livingAddress"
					onChange={(payload) => {
						setAddress(payload?.addressParts);
						setIsAddressSelected(!!payload?.addressParts);
					}}
					label=""
					isSuburb={false}
					manualAddressParts={{
						suburb: 'Paramatta',
						state: 'NSW',
						postalCode: '12345',
					}}
				/>
			</div>
			<div className="flex flex-row justify-between items-center">
				<Button
					isDisabled={!isAddressSelected}
					text="Confirm Property Purchased"
					className="min-w-min text-lg rounded"
					iconSuffix={<i className="icon-arrow" />}
					variant="primary"
					textAlign="center"
					handleClick={async () => {
						if (!address) return;
						if (!address.postalCode || !address.state || !address.suburb) {
							window.alert('Please enter a valid address');
							return;
						}
						dispatch(setFormLoading(true));
						await mutateAsync({
							applicationId,
							address: {
								state: address?.state,
								postCode: Number(address?.postalCode),
								country: 'Australia',
								number: address?.streetNumber,
								street: address?.streetName,
								unit: address?.unitNumber,
							}
						});
						
						setTimeout(() => {
							void utils.application.getApplicationById.invalidate({applicationId});
							dispatch(setFormLoading(false));
						}, 2000);

					}}
				/>
				{/* <Button
					text="Need Help?"
					className="min-w-min text-lg rounded"
					iconSuffix={<i className="icon-message" />}
					variant="accent"
					textAlign="center"
					handleClick={() => {
						const emailSubject = encodeURIComponent("Property Found - Unconditional Approval Request");
						const emailAddress = "applications@sucasa.com.au";
						window.location.href = `mailto:${emailAddress}?subject=${emailSubject}`;
					}}
				/> */}
			</div>
		</Box>
	);
};
