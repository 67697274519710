import { useCallback, useState, type ReactNode, type FC, type PropsWithChildren, type SyntheticEvent } from "react";
import { DetailsSummary } from "./DetailsSummary";

interface DetailsProps extends PropsWithChildren {
    isDefaultOpen?: boolean;
    summary: ReactNode;
    summaryFooter?: ReactNode;
    isIconSuffix?: boolean;
    summarySuffix?: ReactNode;
    summaryClassName?: string;
    className?: string;
    disabled?: boolean;
}
export const Details: FC<DetailsProps> = ({
    isDefaultOpen = false,
    children,
    summary,
    summarySuffix,
    className,
    disabled = false,
    isIconSuffix = false,
    summaryFooter,
    summaryClassName,
}) => {
    const [isOpen, setIsOpen] = useState(isDefaultOpen ?? false);
    const onToggleOpen = useCallback((event: SyntheticEvent<HTMLDetailsElement>) => {
        setIsOpen(!disabled && event.currentTarget.open);
    }, [setIsOpen, disabled])

    return (<>
        {disabled && <div className={`w-full ${className ?? ""}`}>
            <DetailsSummary
              disabled={disabled}
              isOpen={isOpen}
              className={summaryClassName}
              isIconSuffix={isIconSuffix}
              summary={summary}
              summarySuffix={summarySuffix}
              summaryFooter={summaryFooter}
            />
        </div>}
        {!disabled && <details
          open={!disabled && isOpen}
          onToggle={onToggleOpen}
          className={`no-marker w-full ${className ?? ""}`}
        >
            <summary>
                <DetailsSummary
                  disabled={disabled}
                  isOpen={isOpen}
                  className={summaryClassName}
                  isIconSuffix={isIconSuffix}
                  summary={summary}
                  summarySuffix={summarySuffix}
                  summaryFooter={summaryFooter}
                />
            </summary>
            <div
              className={`transition-all duration-500 ${isOpen ? "opacity-100 height-auto" : "height-0 opacity-30"}`}>
                {children}
            </div>
        </details>}
    </>)
}
