import type { FC, ReactNode } from "react";
import { formatTime } from "../../../../../helpers/format-time";
import { BiometricsInstructions } from "./BiometricsInstructions";

type ChatMessageAdminProps = {
	message: ReactNode | string;
	sender: string;
	time?: string;
	notSeen?: boolean;
	isBiometrics?: boolean;
}
export const ChatMessageAdmin: FC<ChatMessageAdminProps> = ({
	message,
	sender,
	time,
	notSeen = false,
	isBiometrics
}) => (<div className="flex flex-row justify-start items-end w-full gap-4">
	<div className="relative block bg-white rounded-full p-2">
		<i className="icon-sucasa-logo text-2xl" />
		{notSeen && <div
			className="h-2 w-2 absolute top-0 right-0 rounded-full bg-red-brick-100 text-white text-xs"></div>}
	</div>
	<div className="p-4 bg-white rounded w-full">
		<div>{message}</div>
		{isBiometrics && <div>
			<div className="py-4">Here is what you need to do next:</div>
			<BiometricsInstructions />
		</div>}
		<div className="flex flex-row justify-between pt-2 text-xs text-off-black-600">
			<span>{sender}</span>
			{time && <span>{formatTime(time)}</span>}
		</div>
	</div>
</div>);
