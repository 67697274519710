import type { FC } from "react";

type MessageProps = {
	message: string;
};

const HelperMessage: FC<MessageProps> = (props) => {
	return (
		<span className="text-xs text-gray-500 font-medium mt-1 md:mt-0 md:leading-3">
			{props.message}
		</span>
	);
};

export default HelperMessage;
