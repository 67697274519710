import type { FC } from "react"
import type { TWbStepsType } from "../../wb-stepper/WbStepper.config";

export const WbStepperItemNew: FC<TWbStepsType> = (item) => {
	return (
		<li
			className={`shrink-0 flex flex-row items-center gap-1 font-medium relative before:w-0.5 before:h-full ${item.isCompleted ? "before:bg-grass-hopper-900" : "before:bg-off-black-200"} before:block before:absolute before:top-3 before:bottom-0 before:left-3 before:-z-10 ${item.isLastItem ? "before:hidden pb-0" : "pb-4"}`}
		>
			{item.isCompleted && <span className="relative w-6 h-6 flex items-center justify-center">
				<i className="bg-grass-hopper-900 ms-0.5 w-2 h-2 rounded-full block" />
				</span>}

			{!item.isCompleted && <span
				className={`bg-primary text-white border border-off-black-400 w-6 h-6 rounded-full flex items-center justify-center text-xs leading-6 font-medium`}
			>
					{item.id}
				</span>}
			<span
				className={`${item.isCurrent ? "font-medium text-sm" : " text-off-black-400"} ${item.isCompleted ? "text-white" : ""} block overflow-hidden whitespace-nowrap text-ellipsis w-[calc(95%-2rem)]`}
			>
				{item.label}
			</span>
		</li>
	);
};
