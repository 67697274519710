import WelcomeLoanItem from "../../../ui/welcome-loan-item/WelcomeLoanItem";
import { formatDate } from "../../../../helpers/format-date";
import banner from "../../../../assets/welcome-back.jpg";
import WbUserInfo from "../wb-user-info/WbUserInfo";
import WbStepper from "../wb-stepper/WbStepper";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import {
	selectCurrentUserFullName,
	selectIsJointApplicant,
} from "../../../../store/slices/auth-slice";
import {
	selectAppCreatedDate,
	selectAppId,
	selectLoanAmount,
	selectLoanPurchasePrice,
	selectPropertyAddress,
} from "../../../../store/slices/application-slice";

export const WbLoanDetails = () => {
	const isJointApplicant = useAppSelector(selectIsJointApplicant);
	const fullName = useAppSelector(selectCurrentUserFullName);
	const propertyAddress = useAppSelector(selectPropertyAddress);
	const purchasePrice = useAppSelector(selectLoanPurchasePrice);
	const loanAmount = useAppSelector(selectLoanAmount);
	const createdDate = useAppSelector(selectAppCreatedDate);
	const appId = useAppSelector(selectAppId);

	const formattedDateStarted = formatDate(createdDate);
	const trimmedAppId = appId.replace(/APP-0+/, "APP-");

	return (<section
		className="card-bg min-h-56 bg-off-black-400 relative overflow-hidden flex flex-col justify-between before:right-0 before:left-0 before:top-0 before:bottom-0 before:absolute before:z-10 before:bg-no-repeat bg-gradient-to-r from-off-black-900 from-0% to-off-black-400 to-100%">
		<img
			src={banner}
			alt="banner"
			className="absolute top-0 left-0 right-0 bottom-0 object-cover w-full h-full"
		/>
		<WbUserInfo fullName={fullName ?? ""} address={propertyAddress ?? ""} />
		<div
			aria-labelledby="loan details"
			className="relative z-10 flex justify-between flex-wrap gap-6 md:gap-0 p-4 md:pt-7 md:pb-5 md:px-8"
		>
			{formattedDateStarted && (
				<WelcomeLoanItem label="Date started" value={formattedDateStarted} />
			)}
			<WelcomeLoanItem label="Reference number" value={trimmedAppId} />
			<WelcomeLoanItem
				label="Property value"
				value={`$${Number.parseInt(purchasePrice).toLocaleString()}`}
			/>
			<WelcomeLoanItem
				label="Loan amount"
				value={`$${Number.parseInt(loanAmount).toLocaleString()}`}
			/>
		</div>
		<WbStepper isJointApplicant={isJointApplicant} />
	</section>);
};
