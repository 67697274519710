import { z } from "zod";

const envSchema = z.object({
	VITE_APP_ENVIRONMENT: z.string(),
	VITE_SITE_TITLE: z.string(),
	VITE_GTM_ID: z.string(),
	VITE_HOTJAR_ID: z.string(),
	VITE_SENTRY_DSN: z.string(),
	VITE_SENTRY_TRACES_SAMPLE_RATE: z
		.string()
		.transform((value) => Number.parseFloat(value)),
	VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE: z
		.string()
		.transform((value) => Number.parseFloat(value)),
	VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE: z
		.string()
		.transform((value) => Number.parseFloat(value)),
	VITE_SENTRY_TRACE_PROPAGATION_TARGETS: z.string(),
	VITE_DOCUMENTS_URL: z.string(),
	VITE_API_URL: z
		.string()
		.transform((value) => value || "https://uatmw.sucasa.com.au/api"),
	VITE_API_KEY: z
		.string()
		.transform((value) => value || "thisissampleaccesskey"),
	VITE_AMPLITUDE_API_KEY: z.string(),
	VITE_LD_CLIENT_ID: z.string(),
	VITE_AUTH0_API_CLIENT_ID: z.string(),
	VITE_AUTH0_LOGIN_DOMAIN: z.string(),
	VITE_ORIGINATION_API: z.string().optional(),
});

const validatedEnv = envSchema.parse(import.meta.env);

export default validatedEnv;
