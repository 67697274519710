import { useEffect, useState } from "react";
import Button from "../../../../../ui/button/Button";
import type { AssetsFormSlice, SharesEquitiesInvestmentsAsset } from "../YourAssetsSchema";
import type { Control, FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import SelectInputSecondary from "../../../../select-input-secondary/SelectInputSecondary";
import { MonthsOptions, YearsOptions } from "../../../../../utils/BootList";
import GenericInputTeriary from "../../../../generic-input-teriary/GenericInputTeriary";
import DollarInput from "../../../../dollar-input/DollarInput";

type ShareEquitiesInvestmentErrorType = Merge<
	FieldError,
	Array<
		| Merge<FieldError, FieldErrorsImpl<SharesEquitiesInvestmentsAsset>>
		| undefined
	>
>;

const ShareEquitiesInvestmentsDisplay = ({
	index,
	sharesEquitiesInvestmentsAsset,
	control,
	errors,
	onChangeShareEquitiesInvestmentHandler,
	removeShareEquitiesInvestmentsHandler,
}: {
	index: number;
	sharesEquitiesInvestmentsAsset: SharesEquitiesInvestmentsAsset;
	onChangeShareEquitiesInvestmentHandler: (
		value: SharesEquitiesInvestmentsAsset
	) => void;
	removeShareEquitiesInvestmentsHandler: (value: number) => void;
	control: Control<AssetsFormSlice>;
	errors?: ShareEquitiesInvestmentErrorType;
}) => (
	<div className="w-full flex flex-col gap-4">
		<GenericInputTeriary
			type="text"
			value={sharesEquitiesInvestmentsAsset.investmentName}
			error={
				errors &&
				errors[index]?.investmentName &&
				errors[index]?.investmentName?.message
			}
			label=""
			placeholder="Investment Name"
			onChangeHandler={(value) => {
				const updateSharesEquitiesInvestmentsAsset = {
					...sharesEquitiesInvestmentsAsset,
				};
				updateSharesEquitiesInvestmentsAsset.investmentName = value.toString();
				onChangeShareEquitiesInvestmentHandler(
					updateSharesEquitiesInvestmentsAsset
				);
			}}
		/>
		<DollarInput
			name={`sharesEquitiesInvestmentsAssets.${index}.currentValue`}
			control={control}
			type="numeric"
			value={sharesEquitiesInvestmentsAsset.currentValue}
			error={
				errors &&
				errors[index]?.currentValue &&
				errors[index]?.currentValue?.message
			}
			label=""
			placeholder="Enter Current Value"
			iconPrefix={<i className="icon-dollar" />}
			onValueChange={(value) => {
				const updateSharesEquitiesInvestmentsAsset = {
					...sharesEquitiesInvestmentsAsset,
				};
				updateSharesEquitiesInvestmentsAsset.currentValue = value || 0;
				onChangeShareEquitiesInvestmentHandler(
					updateSharesEquitiesInvestmentsAsset
				);
			}}
		/>
		<p className="text-primary text-sm">Time owned</p>
		<div className="flex flex-col md:flex-row gap-8">
			<SelectInputSecondary
				value={
					sharesEquitiesInvestmentsAsset.timeOwnedMonths || {
						id: 0,
						value: "",
						label: "Months",
					}
				}
				label=""
				options={MonthsOptions}
				onChangeHandler={(value) => {
					const updateSharesEquitiesInvestmentsAsset = {
						...sharesEquitiesInvestmentsAsset,
					};
					updateSharesEquitiesInvestmentsAsset.timeOwnedMonths = value;
					onChangeShareEquitiesInvestmentHandler(
						updateSharesEquitiesInvestmentsAsset
					);
				}}
				error={
					errors &&
					errors[index]?.timeOwnedMonths &&
					errors[index]?.timeOwnedMonths?.message
				}
			/>
			<SelectInputSecondary
				value={
					sharesEquitiesInvestmentsAsset.timeOwnedYears || {
						id: 0,
						value: "",
						label: "Years",
					}
				}
				label=""
				options={YearsOptions}
				onChangeHandler={(value) => {
					const updateSharesEquitiesInvestmentsAsset = {
						...sharesEquitiesInvestmentsAsset,
					};
					updateSharesEquitiesInvestmentsAsset.timeOwnedYears = value;
					onChangeShareEquitiesInvestmentHandler(
						updateSharesEquitiesInvestmentsAsset
					);
				}}
				error={
					errors &&
					errors[index]?.timeOwnedYears &&
					errors[index]?.timeOwnedYears?.message
				}
			/>
		</div>
		<Button
			text="Remove"
			variant="plain"
			type="button"
			iconSuffix={<i className="icon-remove" />}
			handleClick={() => {
				removeShareEquitiesInvestmentsHandler(index);
			}}
		/>
	</div>
);
const emptyShareEquitiesInvestmentAsset = {} as SharesEquitiesInvestmentsAsset;
const ShareEquitiesInvestmentsForm = ({
	initialShareEquitiesInvestments,
	label,
	onChange,
	control,
	errors,
}: {
	initialShareEquitiesInvestments?: Array<SharesEquitiesInvestmentsAsset>;
	control: Control<AssetsFormSlice>;
	errors?: ShareEquitiesInvestmentErrorType;
	label: string;
	onChange: (
		sharesEquitiesInvestmentsAssets: Array<SharesEquitiesInvestmentsAsset>
	) => void;
}) => {
	const [sharesEquitiesInvestmentsAssets, setShareEquitiesInvestments] =
		useState<Array<SharesEquitiesInvestmentsAsset>>(
			initialShareEquitiesInvestments ?? []
		);

	const handleShareEquitiesInvestmentChange = (
		index: number,
		shareEquitiesInvestment: SharesEquitiesInvestmentsAsset
	) => {
		const updatedShareEquitiesInvestments = [
			...sharesEquitiesInvestmentsAssets,
		];
		updatedShareEquitiesInvestments[index] = { ...shareEquitiesInvestment };
		setShareEquitiesInvestments(updatedShareEquitiesInvestments);
	};

	useEffect(() => {
		onChange(sharesEquitiesInvestmentsAssets);
	}, [sharesEquitiesInvestmentsAssets, onChange]);

	const removeShareEquitiesInvestmentsHandler = (index: number) => {
		const updatedShareEquitiesInvestments = [
			...sharesEquitiesInvestmentsAssets,
		];
		updatedShareEquitiesInvestments.splice(index, 1);
		setShareEquitiesInvestments(updatedShareEquitiesInvestments);
	};
	return (
		<section className="flex flex-col gap-4">
			{sharesEquitiesInvestmentsAssets.map(
				(sharesEquitiesInvestmentsAsset, index) => (
					<ShareEquitiesInvestmentsDisplay
						key={index}
						index={index}
						control={control}
						errors={errors}
						sharesEquitiesInvestmentsAsset={sharesEquitiesInvestmentsAsset}
						onChangeShareEquitiesInvestmentHandler={(
							value: SharesEquitiesInvestmentsAsset
						) => {
							handleShareEquitiesInvestmentChange(index, value);
						}}
						removeShareEquitiesInvestmentsHandler={
							removeShareEquitiesInvestmentsHandler
						}
					/>
				)
			)}
			<Button
				text={
					<span>
						<i className="icon-plus" />{" "}
						{`Add ${sharesEquitiesInvestmentsAssets.length > 0 ? "another " : ""}${label}`}
					</span>
				}
				type="button"
				variant="accent"
				size="fit"
				textAlign="center"
				handleClick={() => {
					const updatedShareEquitiesInvestments = [
						...sharesEquitiesInvestmentsAssets,
						{ ...emptyShareEquitiesInvestmentAsset },
					];
					setShareEquitiesInvestments(updatedShareEquitiesInvestments);
				}}
			/>
		</section>
	);
};

export default ShareEquitiesInvestmentsForm;
