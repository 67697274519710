import {
	type BaseQueryFn,
	type FetchArgs,
	type FetchBaseQueryError,
	type FetchBaseQueryMeta,
	createApi,
	fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import config from "../config";
import type { RootState } from "../store";
import { setAccessToken, setAuthToken } from "../store/slices/auth-slice";
import { setLogoutModal } from "../store/slices/ui-slice";
import type {
	CreateApplicationRequest,
	CreateApplicationResponse,
	CreateApplicationV2Request,
} from "./apis/create-application.schema";
import type {
	UpdateDetailResponse,
	UpdateDetailsRequest,
} from "./apis/update-details.schema";
import type {
	UpdateExpensesRequest,
	UpdateExpensesResponse,
} from "./apis/update-expenses.schema";
import type { JointApplicantInviteRequest } from "./apis/joint-applicant-invite";
import type {
	UpdateLiabilitiesRequest,
	UpdateLiabilitiesResponse,
} from "./apis/update-liabilities.schema";
import type {
	UpdateIncomeRequest,
	UpdateIncomeResponse,
} from "./apis/update-income.schema";
import type {
	UpdateAssetsRequest,
	UpdateAssetsResponse,
} from "./apis/update-assets.schema";
import type {
	UpdateCircumstancesResponse,
	UpdateCircumstancesRequest,
} from "./apis/update-circumstances.schema";
import type { SubmitResponse, SubmitRequest } from "./apis/submit.schema";
import type {
	AcceptInviteRequest,
	AcceptInviteResponse,
	ForgotPasswordRequest,
	ForgotPasswordResponse,
	LoginRequest,
	TokenLoginRequest,
	ReInviteResponse,
	ResetPasswordRequest,
	ResetPasswordResponse,
	UserResponse,
} from "./apis/auth";
// import { redirectToDocumentDashboardInterceptor } from "./api.interceptors";
import {
	SIGNIN_URL,
	FETCH_APPLICATION_URL,
	SET_PASSWORD_URL,
} from "./api.urls";
import type { ApiResponse } from "./api.types";
import type { PostcodeVerificationResponse } from "./apis/postcode-verification.schema";
import type {
	AcceptInviteV2Request,
	UserResendOtpTokenRequest,
	UserSendOtpTokenRequest,
	UserSendOtpTokenResponse,
	UserVerifyOtpTokenRequest,
} from "./apis/auth.schema";
import { captureException, captureMessage } from "./sentry";

export const baseQuery = fetchBaseQuery({
	baseUrl: config.VITE_API_URL,
	prepareHeaders: (headers, { getState }) => {
		// By default, if we have a token in the store, let's use that for authenticated requests
		const token = (getState() as RootState).auth.token;

		if (token) {
			headers.set("x-auth-token", `${token}`);
		}
		headers.set("Content-Type", "application/json");
		headers.set("x-api-key", config.VITE_API_KEY);
		return headers;
	},
	responseHandler: async (response) => {
		const data = (await response.json()) as ApiResponse;
		if ([400, 500, 502, 503].includes(response.status)) {
			captureException(new Error(`API error: ${response.status}`), {extra: {data}});
		}

		if (![200, 201, 204].includes(response.status)) {
			captureException(new Error(`Non-positive API response: ${response.status}`), {extra: {data}});
		}

		// TODO[matej]: we can't use redirect to dashboard any more
		// we need to catch the state change in the app
		// and redirect to the verification screen
		// redirectToDocumentDashboardInterceptor({
		// 	status: response.status,
		// 	headers: response.headers,
		// 	url: response.url,
		// 	data,
		// });

		return { data };
	},
});

const baseQueryWithReAuth: BaseQueryFn<
	string | FetchArgs,
	unknown,
	FetchBaseQueryError,
	// eslint-disable-next-line @typescript-eslint/ban-types
	{},
	FetchBaseQueryMeta
> = async (args, api, extraOptions) => {
	let result = await baseQuery(args, api, extraOptions);

	// if the query returns a 401 (Unauthorised) error
	if (result.error && result.error.status === 401 && !result?.meta?.response?.url?.includes("/auth")) {
		// if we have a valid Auth0 access token, we set it to auth with api
		const accessToken = (api.getState() as RootState)?.auth?.accessToken;
		if (accessToken) {
			const endpointUrl = typeof args === "string" ? args : args?.url;
			captureMessage(`Token expired, retrying ${endpointUrl} with access token.`);
			api.dispatch(setAuthToken(accessToken));
			// retry the initial query
			result = await baseQuery(args, api, extraOptions);
			if (result.error) {
				captureMessage(`Retrying ${endpointUrl} failed.`, {extra: {status: result.error.status}});
			}
			if (result.error && result.error.status === 401) {
				// if the request fails, clear the access token and show a logout modal
				api.dispatch(setAccessToken(null));
				api.dispatch(setLogoutModal(true));
			}
		} else {
			// Show modal. If the re-auth is unsuccessful
			api.dispatch(setLogoutModal(true));
		}
	}

	/*  Check if the user has logged out before setting the new auth token
			This stops in-flight requests from setting an auth token and logging the user back in
		*/
	const token = (api.getState() as RootState)?.auth?.token;
	if (token) {
		const newAuthToken = result?.meta?.response?.headers?.get("x-auth-token");
		if (newAuthToken) {
			api.dispatch(setAuthToken(newAuthToken));
		}
	}

	return result
}

export const api = createApi({
	baseQuery: baseQueryWithReAuth,
	endpoints: (builder) => ({
		login: builder.mutation<UserResponse & { authToken: string }, LoginRequest>(
			{
				query: (credentials) => ({
					url: SIGNIN_URL,
					method: "POST",
					body: credentials,
				}),
				transformResponse: (
					response: UserResponse,
					meta
				): UserResponse & { authToken: string } => {
					// Extract the authToken from the response headers - it won't get here if we don't have one
					const authToken = meta?.response?.headers.get(
						"x-auth-token"
					) as string;
					return { ...response, authToken }; // Include authToken in the response body
				},
			}
		),
		tokenLogin: builder.mutation<UserResponse & { authToken: string }, TokenLoginRequest>(
			{
				query: (credentials) => ({
					url: "v2/guest/apply/auth/token-login",
					method: "POST",
					body: credentials,
				}),
				transformResponse: (
					response: UserResponse,
					meta
				): UserResponse & { authToken: string } => {
					// Extract the authToken from the response headers - it won't get here if we don't have one
					const authToken = meta?.response?.headers.get(
						"x-auth-token"
					) as string;
					return { ...response, authToken }; // Include authToken in the response body
				},
			}
		),
		forgotPassword: builder.mutation<
			ForgotPasswordResponse,
			ForgotPasswordRequest
		>({
			query: (credentials) => ({
				url: "v2/guest/resetpwd",
				method: "POST",
				body: credentials,
			}),
		}),
		resetPassword: builder.mutation<
			ResetPasswordResponse & { authToken?: string },
			ResetPasswordRequest
		>({
			query: (credentials) => ({
				url: SET_PASSWORD_URL,
				method: "POST",
				body: credentials,
			}),
			transformResponse: (
				response: ResetPasswordResponse,
				meta
			): ResetPasswordResponse & { authToken: string } => {
				// Optionally extract the authToken from the response headers
				const authToken = meta?.response?.headers.get("x-auth-token") as string;
				return { ...response, authToken }; // Include authToken in the response body if available
			},
		}),
		application: builder.query<UserResponse, void>({
			query: () => ({
				url: FETCH_APPLICATION_URL,
				method: "GET",
			}),
		}),
		createApplication: builder.mutation<
			CreateApplicationResponse & { authToken: string },
			CreateApplicationRequest
		>({
			query: (credentials) => ({
				url: "v2/guest/application/create",
				method: "POST",
				body: credentials,
			}),
			transformResponse: (
				response: CreateApplicationResponse,
				meta
			): CreateApplicationResponse & { authToken: string } => {
				// Optionally extract the authToken from the response headers
				const authToken = meta?.response?.headers.get("x-auth-token") as string;
				return { ...response, authToken }; // Include authToken in the response body if available
			},
		}),
		createApplicationV2: builder.mutation<
			CreateApplicationResponse & { authToken: string },
			CreateApplicationV2Request
		>({
			query: (credentials) => ({
				url: "v2/guest/application/v2/create",
				method: "POST",
				body: credentials,
			}),

			transformResponse: (
				response: CreateApplicationResponse,
				meta
			): CreateApplicationResponse & { authToken: string } => {
				// Optionally extract the authToken from the response headers
				const authToken = meta?.response?.headers.get("x-auth-token") as string;
				return { ...response, authToken }; // Include authToken in the response body if available
			},
		}),
		updateDetails: builder.mutation<UpdateDetailResponse, UpdateDetailsRequest>(
			{
				query: (credentials) => ({
					url: "v2/user/application/update-details",
					method: "POST",
					body: credentials,
				}),
			}
		),
		updateExpenses: builder.mutation<
			UpdateExpensesResponse,
			UpdateExpensesRequest
		>({
			query: (credentials) => ({
				url: "v2/user/application/update-expenses",
				method: "POST",
				body: credentials,
			}),
		}),
		inviteJointApplicant: builder.mutation<
			UpdateExpensesResponse,
			JointApplicantInviteRequest
		>({
			query: (credentials) => ({
				url: "v2/user/application/invite-joint-applicant",
				method: "POST",
				body: credentials,
			}),
		}),
		reInviteJointApplicant: builder.mutation<ReInviteResponse, void>({
			query: () => ({
				url: "v2/user/application/re-invite-joint-applicant",
				method: "GET",
			}),
		}),
		acceptInvite: builder.mutation<
			AcceptInviteResponse & { authToken: string },
			AcceptInviteRequest
		>({
			query: (credentials) => ({
				url: `v2/guest/application/accept-invite`,
				method: "POST",
				body: credentials,
			}),
			transformResponse: (
				response: AcceptInviteResponse,
				meta
			): AcceptInviteResponse & { authToken: string } => {
				// Extract the authToken from the response headers - it won't get here if we don't have one
				const authToken = meta?.response?.headers.get("x-auth-token") as string;
				return { ...response, authToken }; // Include authToken in the response body
			},
		}),
		acceptInviteV2: builder.mutation<
			UserSendOtpTokenResponse,
			AcceptInviteV2Request
		>({
			query: (credentials) => ({
				url: "v2/guest/application/v2/accept-invite",
				method: "POST",
				body: credentials,
			}),
		}),
		updateLiabilities: builder.mutation<
			UpdateLiabilitiesResponse,
			UpdateLiabilitiesRequest
		>({
			query: (credentials) => ({
				url: "v2/user/application/update-liabilities",
				method: "POST",
				body: credentials,
			}),
		}),
		updateIncome: builder.mutation<UpdateIncomeResponse, UpdateIncomeRequest>({
			query: (credentials) => ({
				url: "v2/user/application/update-income",
				method: "POST",
				body: credentials,
			}),
		}),
		updateAssets: builder.mutation<UpdateAssetsResponse, UpdateAssetsRequest>({
			query: (credentials) => ({
				url: "v2/user/application/update-assets",
				method: "POST",
				body: credentials,
			}),
		}),
		updateCircumstances: builder.mutation<
			UpdateCircumstancesResponse,
			UpdateCircumstancesRequest
		>({
			query: (credentials) => ({
				url: "v2/user/application/update-circumstances",
				method: "POST",
				body: credentials,
			}),
		}),
		submit: builder.mutation<SubmitResponse, SubmitRequest>({
			query: (credentials) => ({
				url: "v2/user/application/submit",
				method: "POST",
				body: credentials,
			}),
		}),
		queryPostcodeVerification: builder.query<
			PostcodeVerificationResponse,
			string
		>({
			query: (postcode) => ({
				url: `v2/guest/postcode-verification?postcode=${postcode}`,
				method: "GET",
			}),
		}),
		otpVerify: builder.mutation<
			AcceptInviteResponse & { authToken: string },
			UserVerifyOtpTokenRequest
		>({
			query: (credentials) => ({
				url: "v2/guest/apply/auth/otp-verify",
				method: "POST",
				body: credentials,
			}),
			transformResponse: (
				response: AcceptInviteResponse,
				meta
			): AcceptInviteResponse & { authToken: string } => {
				// Extract the authToken from the response headers - it won't get here if we don't have one
				const authToken = meta?.response?.headers.get("x-auth-token") as string;
				return { ...response, authToken }; // Include authToken in the response body
			},
		}),
		otpSend: builder.mutation<
			UserSendOtpTokenResponse,
			UserSendOtpTokenRequest
		>({
			query: (credentials) => ({
				url: "v2/guest/apply/auth/otp-send",
				method: "POST",
				body: credentials,
			}),
		}),
		otpResend: builder.mutation<
			UserSendOtpTokenResponse,
			UserResendOtpTokenRequest
		>({
			query: (credentials) => ({
				url: "v2/guest/apply/auth/otp-resend",
				method: "POST",
				body: credentials,
			}),
		}),
	}),
});

export const {
	useQueryPostcodeVerificationQuery,
	useApplicationQuery,
	useLoginMutation,
	useTokenLoginMutation,
	useForgotPasswordMutation,
	useResetPasswordMutation,
	useCreateApplicationMutation,
	// useCreateApplicationV2Mutation,
	// useUpdateDetailsMutation,
	// useUpdateExpensesMutation,
	// useInviteJointApplicantMutation,
	useReInviteJointApplicantMutation,
	useAcceptInviteMutation,
	useAcceptInviteV2Mutation,
	// useUpdateLiabilitiesMutation,
	// useUpdateIncomeMutation,
	// useUpdateAssetsMutation,
	// useUpdateCircumstancesMutation,
	// useSubmitMutation,
	useOtpVerifyMutation,
	// useOtpSendMutation,
	// useOtpResendMutation,
} = api;

export const legacy = api;

export * from './apis/origination/api';
