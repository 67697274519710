// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "../index";
import type { Application } from "../../services/apis/create-application.schema";

export const applicationSlice = createSlice({
	name: "application",
	initialState: {} as Application | Record<string, never>,
	reducers: {
		setApplication: (_state, { payload }: PayloadAction<Application>) => {
			return payload;
		},
		resetApplication: () => {
			return {} as Application;
		},
	},
});

export const { setApplication, resetApplication } = applicationSlice.actions;

export default applicationSlice.reducer;

export const selectApplication = (state: RootState) => state?.application;
export const selectLoanPurchasePrice = (state: RootState) =>
	state?.application?.loanDetails?.purchasePrice;
export const selectLoanAmount = (state: RootState) =>
	state.application?.loanDetails?.loanAmount;
export const selectLoanTermInYears = (state: RootState) =>
	state.application?.loanDetails?.loanTermInYears || 0;
export const selectPropertyAddress = (state: RootState) =>
	(state.application?.loanDetails?.propertyAddress || "").replace(/^,\s*/, "");

export const selectAppId = (state: RootState) => state.application?.appId || "";
export const selectFormApplicationId = (state: RootState) => state.form?.applicationId || "";
export const selectAppCreatedDate = (state: RootState) =>
	state.application?.createdDate || "";

const selectApplicationType = (state: RootState) =>
	state.application?.applicationType;

export const selectJointApplicantHasBeenInvited = (state: RootState) =>
	state.application?.jointApplicantHasBeenInvited || false;

export const selectCanUseApplication = (state: RootState) =>
	state.application?.applicationVersionCanUseNewPortal || false;

export const selectApplicantType = (state: RootState) =>
	state.auth?.user?.applicantType;

export const selectCanReInviteJointApplication = createSelector(
	[
		selectApplicationType,
		selectJointApplicantHasBeenInvited,
		selectApplicantType,
	],
	(applicationType, jointApplicantHasBeenInvited, applicantType) =>
		applicationType === "Joint" &&
		jointApplicantHasBeenInvited &&
		applicantType === "Primary Applicant"
);
