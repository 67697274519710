import Layout from "../common/layout/Layout";
import { useAppSelector } from "../hooks/redux-hooks";
import { selectCurrentUser } from "../store/slices/auth-slice";
import useTrackPageViewOnMount from "../hooks/use-track-on-mount";
import { WbHeaderNew } from "../components/layout/welcome-back/wb-header/WbHeaderNew";
import { WbLoanDetailsNew } from "../components/layout/welcome-back/wp-loan-details/WbLoanDetailsNew";
import { origination } from "../services/origination";
import { selectAppId } from "../store/slices/application-slice";
import { WbLoanDetailsLegacy } from "../components/layout/welcome-back/wp-loan-details/WbLoanDetailsLegacy";

export const WelcomeBackNew = () => {
	const query = origination.application.getUserApplications.useQuery();
	const currentUser = useAppSelector(selectCurrentUser);
	const appId = useAppSelector(selectAppId);
	useTrackPageViewOnMount({
		page: "Welcome Back",
	});

	return (
		<Layout isFull={true} progress={<span />}>
			<WbHeaderNew firstName={currentUser?.firstName} />
			{appId && <WbLoanDetailsLegacy />}
			<div className="flex flex-col gap-4">
				{query.data?.map(application => (<WbLoanDetailsNew
					key={application.id}
					application={application}
				/>))}
			</div>
		</Layout>
	);
};
