import type { FC } from "react";
import { CaseSummaryHeader } from "./CaseSummaryHeader";
import { CaseSummaryContents } from "./CaseSummaryContents";
import { CaseSummaryProgress } from "./CaseSummaryProgress";
import { Details } from "../../ui/details/Details";
import { useCasesForApplicationId } from "../../../hooks/origination/use-cases-for-application-id";

export interface CaseSummaryMobileProps {
    applicationId: number;
    className?: string;
}

export const CaseSummaryMobile: FC<CaseSummaryMobileProps> = ({applicationId, className}) => {
    const data = useCasesForApplicationId(applicationId)

    return (<>
        {data.length > 0 && <Details
          isIconSuffix
          className={`bg-white flex flex-col w-full gap-0 pt-4 pb-8 px-8 ${className ?? ""} lg:hidden relative z-40`}
          summary={<CaseSummaryHeader applicationId={applicationId} />}
          summaryFooter={<CaseSummaryProgress applicationId={applicationId} />}
          summaryClassName="container mx-auto md:px-4"
        >
            <div className="relative w-full">
                <div className="absolute w-screen h-screen left-0 -mx-8 z-10">
                    <div className="bg-white w-full p-4 shadow-lg">
                        <div className="container mx-auto px-4">
                            <CaseSummaryContents applicationId={applicationId} />
                        </div>
                    </div>
                </div>
            </div>
        </Details>}
    </>);
}
