import type { FieldValues, UseFormRegister } from "react-hook-form";
import RadioInput, {
	type RadioInputHeight,
	type RadioInputType,
} from "../radio-input/RadioInput";
import ErrorMessage from "../error-message/ErrorMessage";

export type RadioGroupType<TFieldValues extends FieldValues = FieldValues> = {
	selectedValue?: string | number | Array<string> | undefined;
	legend?: string;
	name?: string;
	radioOptions: Array<RadioInputType>;
	register: UseFormRegister<TFieldValues>;
	error?: string;
	centerLabels?: boolean;
	height?: RadioInputHeight;
};

const RadioGroup = <TFieldValues extends FieldValues = FieldValues>({
	legend,
	radioOptions,
	name,
	selectedValue,
	register,
	error,
	centerLabels,
	height,
}: RadioGroupType<TFieldValues>) => {
	return (
		<div className="flex flex-col gap-4">
			{legend && (
				<h4 className="text-primary text-[21.33px] font-normal">{legend}</h4>
			)}
			<ul className="flex items-center gap-4">
				{radioOptions.map((option, index) => {
					return (
						<RadioInput
							key={index}
							label={option.label}
							name={name || option.name}
							value={option.value}
							checked={option.value === selectedValue}
							register={register}
							centerLabel={centerLabels}
							height={height}
						/>
					);
				})}
			</ul>
			{!!error && <ErrorMessage message={error} />}
		</div>
	);
};

export default RadioGroup;
