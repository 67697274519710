import { type FC, useEffect, useRef, useState } from "react";

type MobileProgressProps = {
	progress: number;
}
export const MobileProgress: FC<MobileProgressProps> = ({
	progress,
}) => {
	const [value, setValue] = useState(0);
	const incrementedValue = useRef(0);

	useEffect(() => {
		let count = incrementedValue.current.valueOf();
		const increment = count < progress;
		const speed = count === 0 ? 10 : 100;
		const interval = setInterval(() => {
			count = increment ? count + 1 : count - 1;
			setValue(count);
			if (
				(increment && count >= progress) ||
				(!increment && count < progress)
			) {
				incrementedValue.current = progress;
				clearInterval(interval);
			}
		}, speed);

		return () => {
			clearInterval(interval);
		};
	}, [progress]);

	return (
		<div className="lg:hidden flex items-center gap-2">
			<i className="icon-progress" />
			<span className="text-lg leading-9 font-medium w-0">{`${value || 0}%`}</span>
		</div>
	);
};
