import { legacy } from "../../api";
import { origination } from "../../origination";
import { mapUpdateExpensesResponse, useMapUpdateExpensesRequest } from "./mapping/expense";
import { useOriginationCallback, useOriginationMutationResult } from "./mutation";

export function useUpdateExpenses() {
    const [invokeOriginal, originalMutation] = legacy.useUpdateExpensesMutation();
    const mutation = origination.application.updateExpenses.useMutation();
    const mapRequest = useMapUpdateExpensesRequest();
    const create = useOriginationCallback(
        invokeOriginal,
        mutation.mutateAsync,
        mapRequest,
        mapUpdateExpensesResponse
    )
    const mutationResult = useOriginationMutationResult(originalMutation, mutation);
    return [
        create,
        mutationResult
    ] as const;
}