import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../index";

type LoaderState = {
	isLoading: boolean;
	isFullScreen: boolean;
	showCreatAccountLoaderText?: boolean;
};

const initialState: LoaderState = {
	isLoading: false,
	isFullScreen: false,
	showCreatAccountLoaderText: false,
};

export const loaderSlice = createSlice({
	name: "loader",
	initialState,
	reducers: {
		setFormLoading: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
			state.isFullScreen = false;
		},
		setIsFullScreen: (state, action: PayloadAction<boolean>) => {
			state.isFullScreen = action.payload;
		},
		setShowCreatAccountLoaderText: (state, action: PayloadAction<boolean>) => {
			state.showCreatAccountLoaderText = action.payload;
		},
	},
});

export const {
	setFormLoading,
	setIsFullScreen,
	setShowCreatAccountLoaderText
} =
	loaderSlice.actions;

export default loaderSlice.reducer;


export const selectIsLoading = (state: RootState) => state.loader.isLoading;
export const selectIsFullScreen = (state: RootState) => state.loader.isFullScreen;
export const selectShowCreatAccountLoaderText = (state: RootState) => state.loader.showCreatAccountLoaderText;
