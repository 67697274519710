import type { FC } from "react";
import { useCaseSummary } from "../../../hooks/origination/use-case-summary";

export interface CaseSummaryHeaderProps {
    applicationId: number;
    className?: string;
}

export const CaseSummaryHeader: FC<CaseSummaryHeaderProps> = ({applicationId, className}) => {
	const {data, finished} = useCaseSummary(applicationId)
    if (data.length === 0) {
        return null;
    }
    return (
        <div className={`font-bold text-xl ${className ?? ""}`}>
            <span className={finished.length === data.length ? "" : "text-off-black-600"}>{finished.length}/</span>
            <span>{data.length}</span>
            &nbsp;Requests Complete
        </div>
    );
}
