import type { FC, ReactNode } from "react";

export type ButtonType = {
	type?: "button" | "submit";
	size?: "full" | "fit";
	text: string | ReactNode;
	iconPrefix?: ReactNode;
	iconSuffix?: ReactNode;
	variant: "primary" | "accent" | "link" | "link-bold" | "plain";
	handleClick?: () => void;
	isDisabled?: boolean;
	textAlign?: "start" | "end" | "center";
	className?: string;
	disableHover?: boolean;
};

const Button: FC<ButtonType> = ({
	type,
	handleClick,
	variant,
	size,
	text,
	iconPrefix,
	iconSuffix,
	isDisabled,
	textAlign = "start",
	className = "",
	disableHover = false,
}) => {
	return (
		<button
			type={type}
			onClick={handleClick}
			disabled={isDisabled}
			className={`flex items-center gap-2 leading-6 transition-all ${isDisabled ? "opacity-20 cursor-not-allowed" : disableHover ? "" : "hover:bg-white hover:text-primary"} ${variant === "link" && "text-base underline font-normal"} ${variant === "link-bold" && "text-base underline font-medium"} ${variant === "plain" && "text-base font-normal w-fit"} ${!["link", "link-bold", "plain"].includes(variant) && "py-3 px-3 min-w-40 font-medium text-sm"} ${variant === "primary" && "border border-primary bg-primary text-white"} ${variant === "accent" ? "border border-primary text-primary" : ""} ${size === "full" && "w-full"} ${size === "fit" && "w-fit"} ${className}`}
		>
			{iconPrefix}
			<span className={`flex-grow text-${textAlign}`}>{text}</span>
			{iconSuffix}
		</button>
	);
};

export default Button;
