import { useCallback } from "react";
import { ApplicationUserRole } from "@sucasa-finance/origination-trpc";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import { selectFormApplicationId } from "../../../../store/slices/application-slice";
import type { JointApplicantInviteRequest } from "../../joint-applicant-invite";
import type { AcceptInviteResponse, SuccessfulOriginationAuthentication } from "../types";

export interface InviteSecondaryApplicantWithApplicationIdSchema {
    firstName: string;
    middleName?: string;
    lastName: string;
    email: string;
    phone: string;
    applicationId: number
}

export function mapJointApplicantInviteRequest(from: JointApplicantInviteRequest, applicationId: string | number): InviteSecondaryApplicantWithApplicationIdSchema {
    return {
        firstName: from.firstName.trim(),
        middleName: from.middleName?.trim(),
        lastName: from.surname.trim(),
        email: from.email,
        phone: from.mobile,
        applicationId: Number(applicationId),
    };
}

export function useMapJointApplicantInviteRequest() {
    const applicationId = useAppSelector(selectFormApplicationId);
    return useCallback((from: JointApplicantInviteRequest) => mapJointApplicantInviteRequest(from, applicationId), [applicationId]);
}


export function mapJointInviteDetailsToSuccessfulAuthentication({ application, applicants, accessToken, applicationUserRole, property, userId}: AcceptInviteResponse): SuccessfulOriginationAuthentication {
    if (!application || !applicants || !accessToken || !applicationUserRole || !property || !userId) {
        throw new Error("Invalid application user invite details");
    }

    const applicant = applicants.find(applicant => applicant.userId === userId);

    if (!applicant) {
        throw new Error("Invalid application user invite details");
    }

    return {
        accessToken,
        user: {
            applications: [application.id],
            ...applicant.user,
        },
        isSecondaryApplicant: applicationUserRole === ApplicationUserRole.Secondary
    };
}
