import { useEffect, useState } from "react";
import Button from "../../../../../ui/button/Button";
import type { Asset, AssetsFormSlice} from "../YourAssetsSchema";
import type { Control, FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import GenericInputTeriary from "../../../../generic-input-teriary/GenericInputTeriary";
import DollarInput from "../../../../dollar-input/DollarInput";

type AssetErrorType = Merge<
	FieldError,
	Array<Merge<FieldError, FieldErrorsImpl<Asset>> | undefined>
>;

type TSubFormName = "superannuationAssets" | "cashOrSavingAssets" | "otherAssets";

const AssetsDisplay = ({
	index,
	accountName,
	amount,
	subFormName,
	control,
	errors,
	accountNamePlaceholder,
	onChangeAmountHandler,
	onChangeAccountNameHandler,
	removeAssetsHandler,
}: {
	index: number;
	accountName: string;
	accountNamePlaceholder: string;
	amount: number;
	onChangeAccountNameHandler: (value: string | number) => void;
	onChangeAmountHandler: (value?: number) => void;
	removeAssetsHandler: (value: number) => void;
	subFormName: TSubFormName;
	control: Control<AssetsFormSlice>;
	errors?: AssetErrorType;
}) => (
	<div className="w-full flex flex-col md:flex-row gap-4">
		<GenericInputTeriary
			type="text"
			value={accountName}
			error={
				errors &&
				errors[index]?.name &&
				errors[index]?.name?.message
			}
			label=""
			placeholder={accountNamePlaceholder}
			onChangeHandler={onChangeAccountNameHandler}
		/>
		<DollarInput
			name={`${subFormName}.${index}.value`}
			control={control}
			type="numeric"
			value={amount}
			error={errors && errors[index]?.value && errors[index]?.value?.message}
			label=""
			placeholder="Enter Value"
			iconPrefix={<i className="icon-dollar" />}
			onValueChange={onChangeAmountHandler}
		/>
		<Button
			text="Remove"
			variant="plain"
			type="button"
			iconSuffix={<i className="icon-remove" />}
			handleClick={() => {
				removeAssetsHandler(index);
			}}
		/>
	</div>
);
const emptyAsset = {} as Asset;
const AssetsForm = ({
	initialAssets,
	addButtonLabel,
	accountNamePlaceholder,
	onChange,
	subFormName,
	control,
	errors,
}: {
	initialAssets?: Array<Asset>;
	subFormName: TSubFormName;
	control: Control<AssetsFormSlice>;
	errors?: AssetErrorType;
	addButtonLabel: string;
	accountNamePlaceholder: string;
	onChange: (assets: Array<Asset>) => void;
}) => {
	const [assets, setAssets] = useState<Array<Asset>>(initialAssets ?? []);

	const handleAmountChange = (index: number, value?: number) => {
		const updatedAssets = [...assets];
		const currentAssets = { ...updatedAssets[index] };
		if (currentAssets) {
			currentAssets.value = value;
			updatedAssets[index] = currentAssets as Asset;
		}
		setAssets(updatedAssets);
	};
	const handleAccountNameChange = (index: number, name: string) => {
		const updatedAssets = [...assets];
		const currentAssets = { ...updatedAssets[index] };
		if (currentAssets) {
			currentAssets.name = name;
			updatedAssets[index] = currentAssets as Asset;
		}
		setAssets(updatedAssets);
	};

	useEffect(() => {
		onChange(assets);
	}, [assets, onChange]);

	const removeAssetsHandler = (index: number) => {
		const updatedAssets = [...assets];
		updatedAssets.splice(index, 1);
		setAssets(updatedAssets);
	};
	return (
		<section className="flex flex-col gap-4">
			{assets.map((asset, index) => (
				<AssetsDisplay
					key={index}
					index={index}
					subFormName={subFormName}
					control={control}
					errors={errors}
					accountName={asset.name}
					amount={asset.value}
					accountNamePlaceholder={accountNamePlaceholder}
					onChangeAmountHandler={(value) => {
						handleAmountChange(index, value);
					}}
					onChangeAccountNameHandler={(value) => {
						handleAccountNameChange(index, value.toString());
					}}
					removeAssetsHandler={removeAssetsHandler}
				/>
			))}
			<Button
				text={
					<span>
						<i className="icon-plus" /> {`Add ${assets.length > 0 ? "another " : ""}${addButtonLabel}`}
					</span>
				}
				type="button"
				variant="accent"
				textAlign="center"
				size="fit"
				handleClick={() => {
					const updatedAssets = [...assets, { ...emptyAsset }];
					setAssets(updatedAssets);
				}}
			/>
		</section>
	);
};

export default AssetsForm;
