import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { SubStep } from "../../services/apis/create-application.schema";
import { getProgress } from "../../lib/progress";

export const stepOrder = [
	"homeStep",
	"loanStep",
	"detailsStep",
	"financeStep",
	"confirmationStep",
	"uploadStep",
	"assessmentStep",
];
export type stepperState = {
	steps: Record<
		(typeof stepOrder)[number],
		{
			isCompleted: boolean;
			isCurrent: boolean;
		}
	>;
	selectedSubStep?: SubStep;
	progress: number;
};

const initialState: stepperState = {
	steps: {
		homeStep: {
			isCompleted: false,
			isCurrent: true,
		},
		loanStep: {
			isCompleted: false,
			isCurrent: false,
		},
		detailsStep: {
			isCompleted: false,
			isCurrent: false,
		},
		financeStep: {
			isCompleted: false,
			isCurrent: false,
		},
		confirmationStep: {
			isCompleted: false,
			isCurrent: false,
		},
		uploadStep: {
			isCompleted: false,
			isCurrent: false,
		},
		assessmentStep: {
			isCompleted: false,
			isCurrent: false,
		},
	},
	selectedSubStep: SubStep.BeforeWeGetStarted,
	progress: 0,
};

export const stepperSlice = createSlice({
	name: "stepper",
	initialState: initialState,
	reducers: {
		navigateToStep: (
			state,
			{
				payload,
			}: PayloadAction<{ step: keyof stepperState["steps"]; subStep: SubStep }>
		) => {
			// Mark all steps as not current initially
			Object.keys(state).forEach((key) => {
				const step = state.steps?.[key];
				if (step) step.isCurrent = false;
			});

			state.selectedSubStep = payload.subStep;
			const progress = getProgress(payload.subStep);
			// if progress is undefined for some reason, leave previous progress as it is
			if (progress !== undefined) {
				state.progress = progress;
			}
			// Mark the target step as current
			const currentStep = state.steps?.[payload.step];
			if (currentStep) currentStep.isCurrent = true;

			const currentIndex = stepOrder.indexOf(payload.step);

			// Reset isCompleted for all steps
			stepOrder.forEach((step, index) => {
				if (index < currentIndex) {
					// For steps before the current one, mark as completed
					const previousStep = state.steps?.[step];
					if (previousStep) previousStep.isCompleted = true;
				} else if (index > currentIndex) {
					// For steps after the current one, mark as not completed
					const nextStep = state.steps?.[step];
					if (nextStep) nextStep.isCompleted = false;
				}
			});
		},
		setSubStep: (state, { payload }: PayloadAction<SubStep | undefined>) => {
			state.selectedSubStep = payload;
			const progress = getProgress(payload);
			if (progress !== undefined) {
				state.progress = progress;
			}
		},
		resetStepper: () => initialState,
		// Create the remaining reducers for the other steps.
	},
});

export const {
	navigateToStep,
	setSubStep,
	resetStepper,
} = stepperSlice.actions;

export default stepperSlice.reducer;
