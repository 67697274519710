import type { FieldValues, Path, UseFormRegister } from "react-hook-form";

export type RadioInputHeight = 10 | 12 | 14 | 16 | 20 | 24;

export type RadioInputTypeWithRegister<
	TFieldValues extends FieldValues = FieldValues,
> = {
	label: string;
	name: keyof TFieldValues;
	value: string | number | Array<string> | undefined;
	checked: boolean;
	register: UseFormRegister<TFieldValues>;
	centerLabel?: boolean;
	height?: 10 | 12 | 14 | 16 | 20 | 24;
};

export type RadioInputType<TFieldValues extends FieldValues = FieldValues> =
	Omit<RadioInputTypeWithRegister<TFieldValues>, "register">;

const RadioInput = <TFieldValues extends FieldValues = FieldValues>({
	label,
	name,
	value,
	checked,
	register,
	centerLabel = false,
	height,
}: RadioInputTypeWithRegister<TFieldValues>) => {
	const optionId = `radio-option--${Math.random().toString(36).slice(2, 9)}`;

	return (
		<li
			className="w-full list-none"
			aria-labelledby={`Radio option for ${label}`}
		>
			<input
				type="radio"
				id={optionId}
				value={value}
				defaultChecked={checked}
				className="hidden peer"
				{...register(name as Path<TFieldValues>)}
			/>
			<label
				tabIndex={0}
				htmlFor={optionId}
				className={`h-${height} peer-checked:bg-primary peer-checked:text-white bg-white w-full p-4 text-sm flex items-center justify-center transition-all cursor-pointer hover:opacity-75 ${
					centerLabel ? "text-center" : ""
				}`}
			>
				{label}
			</label>
		</li>
	);
};

export default RadioInput;
